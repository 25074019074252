@import 'list';
@import "singleList";
@import "singleList-feed";
@import 'table';



.emptyScreenStyle {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(xs) {
    flex-direction: column-reverse;
  }

  &:before {
    content: '';
    display: block;
    width: 154px;
    height: 154px;
    background: url('/layouts/frontend/img/shoplist/shoplistPromo.png') no-repeat top center;
    background-size: cover;
    opacity: 0.35;
    margin: 20px auto 30px;

    @include media-breakpoint-down(sm) {
      width: 100px;
      height: 100px;
      background-size: cover;
    }
    @include media-breakpoint-down(xs) {
      margin: 30px auto 10px;
    }
  }

  &--chef {
    &:before {
      background-image: url('/layouts/frontend/img/emptyList.png');
      opacity: 1;
    }
  }

  &--imageReversed {
    position: relative;

    &:before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 60px;
      bottom: -250px;

      @include media-breakpoint-down(sm) {
        bottom: -150px;
      }
    }

    &:after {
      display: none;
    }
  }

  &__header {
    @include media-breakpoint-down(xs) {
      order: 1;
      margin-top: 20px !important;
    }
  }

  &__header,
  &__text {
    max-width: 500px;
    margin: 0 auto;
    margin-bottom: 35px;

    @include media-breakpoint-down(xs) {
      margin: 0;

    }
  }

  &__header {
    font-size: 20px;
    font-weight: 400;
  }

  &__text {
    line-height: 25px;
    margin-top: 15px;
  }
}

.createBtn {
  display: none;

  @include media-breakpoint-down(xs) {
    display: block;
    position: fixed;
    bottom: 0;
    left: 50%;
    right: 0;
    border: 5px solid #fff;
    transform: translateX(-50%);
    width: 100%;
    z-index: 5;
  }
  &--sticky {
    display: none;

    @include media-breakpoint-down(xs) {
     width: 100%;
      display: flex;

    }
  }
}


.emptyScreenButtons {
  display: flex;
  justify-content: center;

  &--mobile {
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex !important;
      margin-top: 60px !important;
      padding: 0 15px;
    }
  }

  @include media-breakpoint-down(xs) {
    justify-content: space-around;
  }

  a {
    width: auto;
    padding: 0 20px;
    margin-right: 18px;

    @include media-breakpoint-down(xs) {
      padding: 0 16px;
    }
  }

  .button-lidl__blue {
    @include media-breakpoint-down(xs) {
      width: 100%;
      order: 3;
    }
  }

  .button-lidl__blue--bordered {
    @include media-breakpoint-down(xs) {
      flex: 1;
    }
  }

  @include media-breakpoint-down(xs) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;

    a {
      margin-top: 20px;

      &:nth-child(2) {
        margin-right: 7px;
      }
      margin-right: 0;
    }
  }
}
.shoplist__loader {
  position: fixed;
}

