.shoplist-landing-main {

  &.section7 {
    padding: 80px 0;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding: 64px 0;
    }

    h5 {
      font-weight: 600;
      font-size: 34px;
      line-height: 120%;
      margin-bottom: 40px;

      @include media-breakpoint-down(sm) {
        font-size: 32px;
      }
    }

    .buttonBox {
      display: flex;
      width: 100%;
      justify-content: center;

      a {
        margin-right: 0;
        width: 201px;
      }
    }
  }
}