.cookbook {
  padding: 20px 0 0 0;

  .emptyScreenButtons {
    display: none;
  }

  &--withoutBreadcrumb {
    padding-top: 50px;
  }

  &--recipeSearchBiggerPictures {
    @include media-breakpoint-up(md) {
      .single-item.search-item {
        width: calc((100% / 2) - 5px);
        margin-bottom: 15px;
      }
      .single-item.search-item:not(:nth-child(2n)) {
        margin-right: 10px;
      }
    }

    @include media-breakpoint-up(lg) {
      .single-item.search-item {
        width: calc((100% / 3) - 7px);
      }

      .single-item.search-item:not(:nth-child(3n)) {
        margin-right: 10px;
      }

      .single-item.search-item:nth-child(3n) {
        margin-right: 0;
      }
    }

    .wizard--personalizedHeader {
      padding-bottom: 16px;
      height: auto;

      @include media-breakpoint-down(xs) {
        height: auto;
        padding: 15px;
      }
    }

    @include media-breakpoint-down(sm) {
      .single-item {
        margin-bottom: 20px;
      }
    }
  }

  &--emptyList {
    .cookbook__search-container .cookbook__recipes-filters,
    .cookbook__search-container .update-filters__wrapper,
    .cookbook__search-container .filter-button__wrapper,
    .cookbook__search-container .cookbook__search-form {display: none !important;}

    .cookbook__search-left,
    .cookbook__search-right {
      //width: 100%;
      //padding-right: 0;
    }

    .cookbook__search__topBar {
      justify-content: flex-end;

      .button-lidl__blue {
        margin-right: 0;
      }
    }

    .emptyScreenButtons {
      display: flex;
    }

    .cookbook__search-left .cookbook__title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(xs) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .cookbook__search-container .cookbook__recipes-list {
      @include media-breakpoint-down(md) {
        min-height: auto;
      }
    }
  }

  &--tagLanding {
    h2 {
      //text-align: center;
    }
  }

  h2 {
    margin-bottom: 25px;
  }

  .loader--cookbook {
    position: relative;
    background: white;
    width: 100%;
    opacity: 1;
    z-index: 9;

    .sk-folding-cube {
      margin: -10px auto 0 auto;
      width: 30px;
      height: 30px;
    }
  }

  &__search {
    &__options {
      display: flex;
    }

    $optionMargin: 5px;
    &__option {
      cursor: pointer;
      display: block;
      border: 1px solid $borderColor;
      text-align: center;
      width: calc(100% / 3);
      margin: 0 0 $optionMargin 0;
      //color: #6D757C;
      font-size: 14px;
      padding-bottom: 2px;

      $iconMargin: 8px;
      span {
        display: block;
        margin-top: $iconMargin;
      }

      &:nth-child(3n - 1) {
        margin: 0 8px $optionMargin;
      }

      &--checked {
        border: 2px solid $lidlPrimaryColor;
        margin-bottom: $optionMargin - 1px !important;

        span {
          margin-top: $iconMargin - 1px;
        }
      }

      input[type="checkbox"],
      input[type="radio"] {
        display: none;
      }
    }
  }

  .cookbook__search-video {
    margin-bottom: 20px;
  }

  &__quickFilters {
    margin-top: 15px;
    margin-bottom: 10px;
    position: relative;

    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      overflow: hidden;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 35px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        z-index: 9;
      }

      &:after {
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        left: auto;
        right: 0;

      }
    }

    .checkbox-wrapper input[type=checkbox] ~ label:before,
    .checkbox-wrapper input[type=checkbox] ~ label:after,
    .search__category-name {
      display: none;
    }

    .cookbook__search-quickFilters {
      width: 100%;
      display: none;

      @include media-breakpoint-down(sm) {
        overflow: auto;
        display: block;
      }
    }

    .checkbox-wrapper {
      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
      }

      .checkbox {
          &:nth-child(2n) {
            @include media-breakpoint-up(sm) {
              input[type=checkbox] ~ label {
                margin-left: 10px;
              }
            }
          }
      }

      input[type=checkbox] ~ label {
        padding-left: 0;
        //margin-right: 15px;
        white-space: nowrap;
        border: 1px solid rgba(#000000, 0.1);
        border-radius: 4px;
        padding: 4px 5px;

        @include media-breakpoint-down(sm) {
          padding: 4px 8px;
        }
      }

      input[type=checkbox]:checked ~ label {
        background: $lidlPrimaryColor;
        border-color: $lidlPrimaryColor;
        color: #FFFFFF;
      }


      .checkbox {
        width: auto;
        max-width: 100%;

        @include media-breakpoint-down(sm) {
          &:first-child {
            margin-left: 15px;
          }

          &:last-child {
            label {
              margin-right: 15px;
            }
          }
        }
      }

    }
  }
}

.cookbook__recipes-list {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.cookbook__single-recipe {
  margin-bottom: 35px;
  position: relative;
  width: 23%;

  &:not(:nth-child(4n)) {
    margin-right: 2.66%
  }

  @include media-breakpoint-down(sm) {
    width: 48%;

    &:not(:nth-child(4n)) {
      margin-right: 0%
    }
    &:not(:nth-child(2n)) {
      margin-right: 2%;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    &:not(:nth-child(2n)) {
      margin-right: 0;
    }
  }
}

.cookbook__single-recipe--ico {
  @extend .cookbook__single-recipe;
  background-color: $lidlGrey;
  font-weight: 500;
  font-size: 1.2rem;
  height: 180px;
  margin-top: 45px;

  @include media-breakpoint-down(xs) {
    margin-top: 0;
  }

  @include transition(0.3s all);

  a {
    color: white;
    height: 100%;
    width: 100%;
    display: block;
    padding-top: 50px;

    &:hover {
      text-decoration: none;
    }
  }
  span {
    display: block;
    text-align: center;
    margin-top: 15px;
  }

  &:hover {
    background-color: $dirty_white;
    a {
      color: black;
    }

    svg {
      fill: black;
      circle, line {
        stroke: black;
      }
    }
  }
}

.cookbook__loader {
  text-align: center;
}

.related-recipes__loader {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.cookbook__popup-backdrop {
  background-color: rgba(0, 0, 0, .8);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  display: none;
}

.cookbook__popup {
  background-color: $dirty_white;
  flex-wrap: wrap;;
  left: 50%;
  max-width: 600px;
  min-height: 400px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  z-index: 9999;
  display: none;

  @media (max-width: 500px) {
    max-height: 100vh;
    min-height: 0;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.cookbook__popup--open {
  display: flex
}


.cookbook__popup-source {
  background-color: rgba(0, 0, 0, .5);
  bottom: 0;
  color: $white;
  height: 24px;
  left: 0;
  line-height: 24px;
  padding: 0 15px;
  position: absolute;
  width: 100%;
}

.cookbook__popup-content {
  padding: 35px;
  position: relative;
  width: 100%;

  @include media-breakpoint-down(xs) {
    padding: 30px 20px;
  }

  @media (max-width: 340px) {
    padding: 25px 3px;
  }
}

.cookbook__popup-close-btn {
  cursor: pointer;
  font-size: 2rem;
  line-height: 2rem;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 100;
}

.cookbook__popup-link {
  border: 1px solid $white;
  color: white;
  display: block;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  width: 230px;

  &:hover {
    color: black;
    text-decoration: none;
    &:after {
      background-color: white;
    }
  }
}

.print-icon {
  content: '';
  background-image: url('/layouts/frontend/img/svg/inne/drukuj.svg');
  width: 20px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  cursor: pointer;
}

.add-to-stash {
  content: '';
  background-image: url('/layouts/frontend/img/svg/inne/kalendarz.svg');
  width: 20px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  cursor: pointer;
  &:hover {
    background-image: url('/layouts/frontend/img/svg/inne/kalendarz-hover.svg');
  }
}

.add-to-stash--full {
  background-image: url('/layouts/frontend/img/svg/inne/kalendarz-full.svg');
}

// Popup animation
.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.page__cookbook__search {

  .cookbook__search-cookbookSource {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  .single-item--myRecipe {
    // hide like buttons in my recipes
    .single-item__likes {
      visibility: hidden;
    }
  }

  .recipe__h1 {
    padding-top: 0;
    display: flex;
    align-items: center;
    font-family: 'Lidl Font'; font-weight: bold;

    &:before {
      display: block;
      content: '';
      width: 40px;
      height: 40px;
      background: url('/layouts/frontend/img/svg/kochbuch.svg');
      margin-right: 15px;
    }
  }

  @include media-breakpoint-down(sm) {
    .cookbook__addRecipe {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 15px;
      background: #FFF;
      z-index: 11;

      .button__recipe--add {
        width: 100%;
      }
    }
  }

  .button-lidl__blue {
    .iconset--plus {
      margin-right: 10px;
    }
  }
}

.buttonBubble--cookbookAddRecipeButton .buttonBubble__element {
  background: rgba(#353B42, 0.9);
  bottom: 75px;
  top: auto;
  width: calc(100% - 30px);
  border-radius: 4px;

  &:after {
    border: none;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(#353B42, 0.85);

    top: auto;
    bottom: -8px;
  }

  .button-lidl__blue {
    margin-bottom: 0;
  }

  .buttonBubble__element__tip {
    font-weight: normal;

    span {
      font-weight: bold;
    }
  }
}

.single-item--recipeSearchAdditionalInformation {

  .single-item__info {
    margin-top: -10px;
    padding-top: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  .single-item__name {
    border: 0;
    height: 72px;
  }

  .single-item__additionalInformation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 10px;
    font-size: 14px;

    span {
      display: inline-block; width: 16px; height: 16px;
      //background-size: cover;
      margin-right: 5px;
    }

    &__time {
      display: flex;
      align-items: center;

      span {
        background-image: url('/layouts/frontend/img/svg/search/time.svg');
        margin-right: 7px;
      }
    }

    &__difficulty {
      display: flex;
      align-items: center;

      span {
        background-image: url('/layouts/frontend/img/svg/search/chef_hat.svg');
        margin-right: 7px;
      }
    }

    &__kcal {
      display: flex;
      align-items: center;

      span {
        height: 18px;
        background-image: url('/layouts/frontend/img/svg/search/fire.svg');
      }
    }
  }
}
