.rettertuteCampaign {
  background: #AED4D9;
  padding: 50px 0 80px;

  @media (min-width: 750px) and (max-width: 1400px) {
    min-height: 600px;
  }

  @media (max-width: 500px) {
    padding-bottom: 10px;
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
      flex-wrap: wrap;
    }
  }

  &__left {
    padding-top: 50px;
    width: 680px;

    @media (max-width: 500px) {
      width: 100%;
      padding-top: 0;
    }
  }

  &__header {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    color: #376633;

    @media (max-width: 500px) {
      font-size: 32px;
      line-height: 38px;
    }

    span {
      display: block;
      font-size: 80px;
      line-height: 96px;

      @media (max-width: 500px) {
        font-size: 50px;
        line-height: 60px;
      }
    }
  }

  &__desc {
    padding-top: 8px;
    font-size: 18px;
    line-height: 25px;
  }

  &__search {
    padding-top: 15px;
    display: flex;

    &__input {
      box-sizing: border-box;
      padding-left: 56px;
      background: #FFFFFF no-repeat 18px center;
      border: 1px solid #4E5761;
      border-radius: 4px;
      height: 56px;
      width: 100%;

      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 31px;
      color: #4E5761;

      @media (max-width: 1000px) {
        background-position-x: 15px;
        padding-left: 51px;
        font-size: 14px;
      }

      @media (max-width: 390px) {
        background-position-x: 10px;
        padding-left: 49px;
        font-size: 12px;
      }

      @media (max-width: 350px) {
        background-position-x: 10px;
        padding-left: 49px;
        font-size: 12px;
      }
    }
  }

  &__popularIngredients {
    display: flex;
    padding-top: 15px;
    font-size: 14px;
    line-height: 20px;

    &__header {

    }

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        padding-left: 8px;

        a {
          color: #0050AA;
          text-decoration: none;

          span {
            border-bottom: 1px solid #0050AA;
          }
        }
      }
    }
  }

  &__chosenIngredients {
    padding-top: 30px;
    display: none;

    &__title {
      font-size: 18px;
      line-height: 25px;
      color: #000000;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &__list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;

      &__element {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 10px;

        padding-left: 8px;
        height: 30px;

        background: #D6E3F1;
        border-radius: 4px;

        color: #0050AA;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;

        a {
          content: '';
          display: inline-block;
          width: 30px;
          height: 30px;
          margin-left: -3px;
          background: no-repeat;
          background-position: center;
        }
      }

      &__clearAll {
        margin-left: 5px;

        a {
          font-size: 14px;
          font-weight: 600;
          color: #0050AA;
        }
      }
    }
  }

  &__buttons {
    padding-top: 30px;
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__searchButton {
      max-width: 230px;

      button {
        transition: width 0.1s;
        width: auto;
        padding: 0 20px;
        margin-right: 0;

        span {
          display: none;
        }

        &.resultsActive {
          span {
            display: inline;
          }
        }
      }
    }

    &__zeroRecipes {
      display: none;

      margin-left: 25px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      max-width: 240px;

      @media (max-width: 500px) {
        margin-left: 0;
        margin-bottom: 15px;
      }
    }
  }

  &__right {
    width: 451px;
    height: 375px;
    position: relative;
    background: no-repeat -25px 0;
    background-size: 375px 375px;

    @media (max-width: 500px) {
      background-size: 175px 175px;
      width: 100%;
      height: 200px;
      margin-top: 40px;
    }
  }

  &__bag {
    position: absolute;
    bottom: 50px;
    right: 0;
    width: 150px;

    @media (min-width: 750px) and (max-width: 1400px) {
      bottom: -100px;
    }

    @media (max-width: 500px) {
      bottom: auto;
      right: auto;
      top: 63px;
      left: 170px;
    }

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }

    &__desc {
      padding-top: 10px;

      a {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 25px;
        color: #0050AA;
        text-decoration: none;

        span {
          display: inline-block;
          border-bottom: 1px solid #0050AA;
        }

        &:before {
          content: '';
          display: inline-block;
          margin-right: 5px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  &__overlay {
    .popup__content {
      padding: 30px;
      padding-bottom: 10px;
    }

    &__title {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      color: #000000;
      flex-wrap: wrap;

      &__label {
        margin-left: 20px;

        display: flex;
        align-items: center;

        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #348553;

        @media (max-width: 500px) {
          font-size: 20px;
          margin-left: 0;
          margin-top: 10px;
        }

        &:before {
          content: '';
          display: inline-block;
          margin-right: 7px;
          width: 25px;
          height: 25px;
          background: no-repeat;
        }
      }
    }

    &__content {
      text-align: left;
    }
  }

  &.bgImageActive {
    .rettertuteCampaign__right {
      background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/bag_2024.png');
    }
    .rettertuteCampaign__bag__desc a:before {
      background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/help-circle.svg');
    }
    .rettertuteCampaign__overlay__title__label:before {
      background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/zero_waste.svg')
    }
    .rettertuteCampaign__search__input {
      background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/magnifier.svg');
    }
    .rettertuteCampaign__chosenIngredients__list__element a {
      background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/x.svg');
    }
  }
}
