.slider-container {
  @media (max-width: 1023px) {
    order: -1;
    //margin-top: 30px;
  }
}

.slider__item {
  width: auto;
  height: 460px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  &__recipe {

    &--addBlackSpaces {
      background-image: none !important;
      background-color: black !important;
      z-index: 11;

      .img-optimized {
        visibility: hidden;
      }
    }

  }

  img {
    width: 100%;

    &.img-optimized {
      // visibility: hidden;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  //z-index: 10;

  @include media-breakpoint-down(xs) {
    height: 280px;
  }
}

.single-item__likes.slider-item-likes {
  position: absolute;
  top: 30px;
  right: 30px;
  justify-content: space-around;
}

@include media-breakpoint-down(xs) {
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slider__item--background {
  @extend .slider__item;
  position: absolute;
  top: 0;
  z-index: 1;
  -webkit-filter: #{ "blur(15px)" };
  filter: #{ "blur(15px)" };
  background-size: cover;
}

.recipe-slider {
  position: relative;

  .slick-dots {
    bottom: 1.5rem;

    li {
      button {
        background: transparent;
        border-color: white;
        border-width: 2px;

        &:before {
          display: none;
        }

        &:hover {
          border-color: $gray_140;
        }
      }

      &.slick-active {
        button {
          background: white;
          border-color: white;
          width: 9px;
          height: 9px;
          border-width: 2px;
        }
      }
    }
  }

  .slider__videoButton {
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    background: url('/layouts/frontend/img/svg/play.svg');
    background-color: rgba(#FFFFFF, 0.9);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 6px;
    color: $lidlPrimaryColor !important;
    font-size: 16px;
    font-weight: 600;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.25s opacity;

    @include media-breakpoint-down(sm) {
      background-size: 24px 24px;
      background-position: center;
      width: 48px;
      height: 48px;
      font-size: 14px;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .slider__video {
    z-index: 11;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    max-width: 818px;
    width: 100%;
    transition: 0.25s all;

    &__old-recipe {
      top: 50%;
      transform: translateY(-50%);
    }

    @media (max-width: 833px) {
      left: 0;
      transform: translateX(0);
    }

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    div {
      position: relative;
      padding-bottom: 56.25%;
      /* 16:9 */

      @media (max-width: 833px) {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.slider__pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  line-height: 40px;
  width: 100%;
}

.slider__pagination-container {
  width: 620px;
  max-width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.recipe-slider .beszamel-img {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}