.sallys-landing {
  &__section5 {
    &.bgImageActive {
      .sallys-landing__section5 {
        &__box {
          .sallys-landing__section5__content--left {
            background-image: url("/layouts/frontend/img/sally-landing/sally-section5.png");

            @include media-breakpoint-down(sm) {
              background-image: url("/layouts/frontend/img/sally-landing/sally-mobile2.png");
            }
          }
          &__icon {
            &__div {
              &__icon1 {
                span {
                  background-image: url("/layouts/frontend/img/svg/sally-landing/yt-sally.svg");
                }
              }
              &__icon2 {
                span {
                  background-image: url("/layouts/frontend/img/svg/sally-landing/like-sally.svg");
                }
              }
              &__icon3 {
                span {
                  background-image: url("/layouts/frontend/img/svg/sally-landing/cook-sally.svg");
                }
              }
              &__icon4 {
                span {
                  background-image: url("/layouts/frontend/img/svg/sally-landing/video-sally.svg");
                }
              }
            }
          }
        }
      }
    }

    .container {
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    background-color: #F8EBF1;
    padding: 56px 0;

    @include media-breakpoint-down(sm) {
      padding: 32px 0;
    }

    &__box {
      display: flex;
      background: white;
      border: 1px solid $sallysPink;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex-direction: column;
      }

      &__data {
        text-align: center;
        max-width: 576px;
        margin-top: 25px;
        position: relative;

        @include media-breakpoint-down(sm) {
          max-width: 305px;
        }

        @include media-breakpoint-down(xs) {
          max-width: initial;
        }

        &__text {
          font-weight: 600;
          font-size: 14px !important;
          line-height: 140%;
          color: $sallysPink !important;

          &:after,
          &:before {
            content: '';
            width: 30%;
            top: 9px;
            height: 1px;
            background-color: $sallysPink;
            display: block;
            position: absolute;

            @media (max-width: 1400px) {
              width: 20%;
            }

            @include media-breakpoint-down(sm) {
              width: 15%;
              top: 10px;
            }
          }

          &:before {
            left: 0;
          }

          &:after {
            right: 0;
          }
        }
      }

      &__icon {
        padding: 34px 24px 34px;
        border-left: 1px solid $sallysPink;
        border-right: 1px solid $sallysPink;
        border-bottom: 1px solid $sallysPink;
        display: inline-flex;
        margin-top: -10px;

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          flex-direction: column;
          align-items: stretch;
          margin-top: -9px;
        }

        &__div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 1;

          @include media-breakpoint-down(sm) {
            justify-content: space-between;
          }

          > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-right: 3px;

            p {
              font-size: 14px;
              max-width: 80%;
              @media (max-width: 1400px) {
                max-width: 100%;
              }
            }

            @include media-breakpoint-down(sm) {
              min-width: 140px;
              max-width: 140px;
              margin-right: 0;
            }

            @media (max-width: 390px) {
              min-width: 134px;
              max-width: 134px;
            }

            span {
              background-repeat: no-repeat;
              width: 64px;
              height: 64px;
              display: block;
              background-size: contain;
            }
          }
        }

        @media (max-width: 1400px) {
          padding: 17px 11px;
        }

        @include media-breakpoint-down(xs) {
          width: 100%;
        }


        p {
          font-weight: 600;
          font-size: 14px;
          line-height: 140%;
          margin-top: 8px;
          margin-bottom: 0;
        }

        span {
          background-repeat: no-repeat;
          width: 64px;
          height: 64px;
          display: block;
          background-size: contain;

        }

      }
    }

    &__content {
      &--left {
        background-repeat: no-repeat;
        min-width: 411px;
        display: block;
        background-size: cover;
        background-position: center;

        @include media-breakpoint-down(sm) {
          height: 332px;
          min-width: initial;
          width: 100%;
          background-position: top;
        }
        @include media-breakpoint-down(xs) {
          height: 227px;
        }
      }

      &--right {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 32px 20px;
        margin-left: 80px;

        @media (max-width: 1400px) {
          margin-left: 50px;
        }

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }

        @media (max-width: 390px) {
          padding: 32px 15px;
        }

        h3 {

          @include media-breakpoint-down(xs) {
            font-size: 32px;
          }
        }

        span {
          display: block;
          font-size: 18px;

          @media (max-width: 1400px) {
            font-size: 16px;
          }
        }

        p {
          margin-bottom: 20px;

          @media (max-width: 1400px) {
            font-size: 16px;
          }
          @include media-breakpoint-down(sm) {
            max-width: 80%;
          }
          @include media-breakpoint-down(sm) {
            max-width: 100%;
          }
        }

        div {
          img {
            width: 160px;
            height: 74px;
            margin-top: 25px;
          }
        }
      }
    }
  }
}