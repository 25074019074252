.sallys-landing {
  &__section7 {

    &.bgImageActive {
      .sallys-landing__section7__content--left {
        background-image: url("/layouts/frontend/img/sally-landing/sally-section7-background.png");

        @include media-breakpoint-down(sm) {
          background-image: url("/layouts/frontend/img/sally-landing/sally-section7.png");
        }

        &:before {
          background-image: url("/layouts/frontend/img/sally-landing/sally-sign-section7.png");

          @include media-breakpoint-down(sm) {
            background-image: url("/layouts/frontend/img/sally-landing/sally-sign-section7--mobile.png");
          }
        }

        &:after {
          background-image: url("/layouts/frontend/img/sally-landing/sally-face-section7.png");
          @include media-breakpoint-down(sm) {
            display: none;
          }

        }
      }

      .sallys-landing__section7__content__iconBoxMobile {
        a {
          @include media-breakpoint-down(sm) {
            &:first-child {
              &:before {
                @include media-breakpoint-down(sm) {
                  background-image: url("/layouts/frontend/img/sally-landing/sally-sign-section7--mobile.png");
                }
              }
            }
          }
        }
      }
    }

    padding: 56px 0;
    background-color: #F8EBF1;

    @include media-breakpoint-down(sm) {
      padding: 32px 0px;
    }

    &__box {
      background-color: white;
      display: flex;
      border: 1px solid $sallysPink;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }

    &__content {
      &--left {
          flex: 1;
          background-repeat: no-repeat;
          width: 1000px;
          min-height: 330px;
          display: block;
          background-size: cover;
          background-position: center;
          position: relative;
          margin-right: 50px;

          @media (max-width: 1400px) {
            background-position-x: 80%;
          }


          @include media-breakpoint-down(sm) {
            max-width: 525px;
            width: 100%;
            background-position: center;
            margin-right: 0;
          }

        &:after {
          content: '';
          display: block;
          width: 359px;
          height: 330px;
          position: absolute;
          background-size: cover;
          bottom: 0;
          left: -30px;

          @media (max-width: 1400px) {
            left: -60px;
          }

          @include media-breakpoint-down(xs) {
            left: 0;
            height: 228px;
            width: 209px;
          }
        }

          &:before {
            content: '';
            display: inline-block;
            width: 300px;
            height: 244px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            right: 0px;
            top: 54px;

            @media (max-width: 1400px) {
              height: 214px;
              right: -80px;
              top: 78px;
            }

            @include media-breakpoint-down(sm) {
              display: none;
              width: 170px;
              height: 147px;
              left: 70%;
            }
          }

      }
      &--right {
        flex: 1;
        padding: 35px 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down(sm) {
          padding: 16px 16px 0;
        }

        h3 {
          font-size: 30px !important;
          margin-bottom: 16px !important;
          font-weight: 600;

          @media (max-width: 1400px) {
            font-size: 27px !important;
          }
        }

        h3, p {
          max-width: 80%;

          @include media-breakpoint-down(sm) {
            max-width: 100%;
          }
        }

        p {
          margin-bottom: 40px;
        }
      }

      &__iconBox {
        margin-top: 25px;
        display: flex;

        @include media-breakpoint-down(sm) {
          display: none;
        }

        a {
          padding: 15px 17px;
          border: 1px solid #0050AA;
          border-radius: 2px;
          margin-right: 8px;

        }
      }

      &__iconBoxMobile {
        display: none;

        @include media-breakpoint-down(sm) {
          position: relative;
        }

        a {
          @include media-breakpoint-down(sm) {
            &:first-child {
              position: absolute;
              background: white;
              width: initial;
              bottom: 80px;
              left: 14px;
              padding: 12px 0;
              right: 14px;

              &:before {
                display: none;
                @include media-breakpoint-down(sm) {
                  content: '';
                  display: inline-block;
                  width: 170px;
                  height: 147px;
                  position: absolute;
                  background-image: url("/layouts/frontend/img/sally-landing/sally-sign-section7--mobile.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  right: -10px;
                  bottom: 100px;
                }
              }
            }
          }
          @media (max-width: 340px) {
            bottom: 100px;
          }
          @include media-breakpoint-down(sm) {
            padding: 8px 30px;
          }

          @media (max-width: 340px) {
            padding: 8px 15px;
          }
        }

        @include media-breakpoint-down(sm) {
          background-color: white;
          display: block;
          margin-top: 0;
          padding: 20px 0;
          text-align: center;
        }

      }
    }
  }
}