

.shoppingListPopup__buttons--without-promotion {
  .popup--withPromoBanner & {
    display: none;
  }
}

.shopinglistPopup {

  .shoppingListPopup__buttons {

    &--without-promotion {
      background-color: white;
      padding: 20px 0;
      width: 100%;
      margin-top: 0;

      button {
        margin-right: 40px;
      }
    }
  }


  .close-popup-btn {
    background-image: url("/layouts/frontend/img/svg/closeModal.svg");
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    &:before,
    &:after {
      display: none;
    }
  }

  input {
    width: 100%;
  }

  .addShoppingList__input {
    width: 100%;
  }

  .shoppingList__header {
    @include media-breakpoint-down(xs) {
      display: flex;
      margin-bottom: 35px;
    }

    span {
      @include media-breakpoint-down(xs) {
        margin-right: 15px;
        margin-top: 5px;
      }
    }
  }
  .popup {
    &__content {
      padding: 35px;
      margin-top: -295px;
      width: 440px;
      text-align: left;

      @include media-breakpoint-down(xs) {
        max-width: 100%;
        min-height: calc(var(--vh, 1vh) * 100);
        margin-top: 0;
        min-width: 100%;
        margin-right: 0;
        margin-left: 0;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      .close-popup-btn {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 130%;
        margin-bottom: 32px;
      }

      input {
        border: none;
        border-bottom: 1px solid;
        font-size: 18px;
        line-height: 150%;
        padding-bottom: 5px;
        padding-left: 10px;
        -webkit-appearance: none;
        border-radius: 0;

        @include media-breakpoint-down(xs) {
          font-size: 16px;
        }
      }

      button {
        margin-top: 30px;
        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-top: 300px;
        }
      }
    }
  }
}

.addNewProductBanner {

  .shoppingListPopup__buttons {
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .main-popup__header {
    padding: 35px 35px 0 35px;
    @include media-breakpoint-down(xs) {
      padding: 20px 15px 0 15px;
    }
  }

  &__inputTypeProduct {
    width: 100%;
  }
  &__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 35px;

    @include media-breakpoint-down(xs) {
      //margin-top: 30px;
      margin-top: 20px;
      padding: 0 15px;
    }
  }

  &__priceBox {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    font-family: "Lidl Font Price";

    span {
      padding: 5px 7px;
      border: 1px solid #C6C9CC;
    }

    img {
      max-width: 46px;
      max-height: 46px;
      margin-left: 13px;
    }
  }

  &__button {
    border: 1px solid;
  }

  &__info {
    margin-top: 30px;

    &__header {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 8px;

      strong {
        font-weight: 700;
      }
    }

    &__desc {
      font-size: 16px;
      line-height: 160%;

      a {
        font-weight: bold;
      }
    }
  }

  &__tab {
    min-height: 340px;
    max-height: 340px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-down(xs) {
      height: calc(var(--vh, 1vh) * 100 - 131px);
      min-height: 0;
      max-height: 100%;

      .popup--withPromoBanner & {
        height: calc(var(--vh, 1vh) * 70 - 140px);
      }
    }

    label {
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;
      padding-left: 50px;
    }
  }

  &__text {
    display: flex;
    align-items: center;
    //margin-bottom: 32px;
    position: relative;

    .clear-input {
      background-image: url("/layouts/frontend/img/svg/decline-input.svg");
      width: 12px;
      height: 12px;
      display: block;
      position: absolute;
      right: 0;
      padding: 0 5px;
      cursor: pointer;
      margin-right: 5px;

      @include media-breakpoint-down(xs) {
        right: 55px;
      }
    }

    span {
      margin-right: 15px;
    }

    p {
      display: none;
      @include media-breakpoint-down(xs) {
        display: block;
        margin-left: 15px;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $lidlPrimaryColor;
        margin-bottom: 0;
        text-transform: uppercase;
      }
    }
  }

  .popup__content {
    top: 292px;
    padding: 0 !important;
    //top: 50%;
    //transform: scale(0) translateY(-50%);
    //margin-top: 0 !important;

    @include media-breakpoint-down(xs) {
      top: 0 !important;
      margin-top: 0 !important;
      max-height: 100vh;
      padding: 0 !important;
      width: auto !important;
      overflow: hidden;
    }

    h3 {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  .close-popup-btn-blue {
    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }

  &__product-box {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
    a {
      color: #212121;
      flex: 1;
      font-weight: 700;
      text-align: center;
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #F1F2F3;

      @include media-breakpoint-down(xs) {
        line-height: 16px;
        font-size: 16px; /* @TODO maybe 13px? */
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.active {
        color: $lidlPrimaryColor;
        border-bottom: 2px solid $lidlPrimaryColor;
      }

      &:hover {
        border-bottom: 2px solid $lidlPrimaryColor;
        text-decoration: none;
        color: $lidlPrimaryColor;
      }
    }
  }

  &__buttonBox {
    padding-top: 13px;
    padding-bottom: 8px;
    margin-top: 0;
    flex-direction: column;
    background-color: white;
    box-shadow: 2px 0 4px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    display: none;

    .popup--withPromoBanner & {
      display: block;
    }

    @include media-breakpoint-down(xs) {
      padding: 11px 8px 11px 0;

      .popup--withPromoBanner & {
        height: calc(var(--vh, 1vh) * 70 - 140px);
      }
    }

    .popup__content {
      padding: 0 !important;
    }

    p {
      font-size: 10px;
      line-height: 140%;
      color: #353B42;
      padding-left: 80px;
      padding-right: 123px;
      margin-bottom: 0;
      margin-top: 8px;
      width: 100%;

      @include media-breakpoint-down(xs) {
        padding-left: 15px;
        padding-right: 0;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &__top {
      width: 100%;
      display: flex;


      label {
        margin-left: 35px;
        margin-right: 10px;


        @include media-breakpoint-down(xs) {
          margin-left: 15px;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          height: 45px !important;
          line-height: 1.3em;
        }
      }
    }

    button {
      width: 80px !important;
      margin-left: 50px;
      border-left: 1px solid #E3E4E5;
      position: relative;
      margin-right: 32px;

      &:before {
        content: '';
        display: block;
        width: 1px;
        height: 100vh;
        background-color: #E3E4E5;
        position: absolute;
        left: -25px;

      }

      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }

    label {
      width: 100%;
      justify-content: flex-start;
    }
  }
}

//.shopinglistPopup.popup--open .popup__content {
//  transform: scale(1) translateY(-50%);
//
//  @include media-breakpoint-down(xs) {
//    transform: scale(1);
//  }
//}

