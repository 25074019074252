* {
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
  cursor: pointer;
}

.link-black {
  color: #212529;
}

.link-black:hover {
  color: #212529;
  text-decoration: none;
}

body, html {
  overflow-scrolling: touch;
  -webkit-overflow-scrolling: touch;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.text-justify {
  text-align: justify;
}

.move-left {
  margin-right: auto;
  margin-left: 0 !important;
}

.move-right {
  margin-left: auto;
  margin-right: 0 !important;
}

.no-float {
  float: initial;
}

.relative {
  position: relative;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-tb {
  padding-top: 0;
  padding-bottom: 0;
}

.no-padding-lr {
  padding-left: 0;
  padding-right: 0;
}

.no-padding-l {
  padding-left: 0;
}

.no-padding-r {
  padding-right: 0;
}

.padding-10 {
  padding: 10px !important;
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-40 {
  padding: 40px;
}

.padding-50 {
  padding: 50px;
}

.padding-60 {
  padding: 60px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-45 {
  padding-left: 45px !important;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-lr-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-lr-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-lr-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.margin-left-15 {
  margin-left: 15px;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.top-10 {
  margin-top: 10px !important;
}
.top-15 {
  margin-top: 15px;
}
.top-20 {
  margin-top: 20px !important;
}

.top-25 {
  margin-top: 25px !important;
}

.top-30 {
  margin-top: 30px !important;
}

.top-40 {
  margin-top: 40px !important;
}

.top-45 {
  margin-top: 45px !important;
}

.top-50 {
  margin-top: 50px !important;
}

.top-60 {
  margin-top: 60px !important;
}

.top-70 {
  margin-top: 70px !important;
}

.top-80 {
  margin-top: 80px !important;
}

.top-90 {
  margin-top: 90px !important;
}

.top-100 {
  margin-top: 100px !important;
}

.top-120 {
  margin-top: 120px !important;
}

.bottom-10 {
  margin-bottom: 10px !important;
}

.bottom-15 {
  margin-bottom: 15px;
}

.bottom-20 {
  margin-bottom: 20px !important;
}

.bottom-30 {
  margin-bottom: 30px !important;
}

.bottom-40 {
  margin-bottom: 40px !important;
}

.bottom-50 {
  margin-bottom: 50px !important;
}

.bottom-60 {
  margin-bottom: 60px !important;
}

.bottom-80 {
  margin-bottom: 80px !important;
}

.bottom-90 {
  margin-bottom: 90px !important;
}

.bottom-100 {
  margin-bottom: 100px !important;
}

.left-35 {
  margin-left: 35px !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-r {
  margin-right: 0px;
}

.no-margin-l {
  margin-left: 0px;
}

.no-margin-lr {
  margin-left: 0px;
  margin-right: 0px;
}

.display-none {
  display: none;
}

.inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.table-cell {
  display: table-cell !important;
}

.middle {
  vertical-align: middle;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase !important;
}

.none-transform {
  text-transform: none !important;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100%;
}

.half-width {
  width: 50%;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-2 {
  letter-spacing: 2px;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
}

.overflow-hidden {
  overflow: hidden;
}

.no-transform {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
}

.cliclable {
  cursor: pointer;
}

.open > .dropdown-menu {
  display: block;
}

[disabled=disabled], .disabled {
  pointer-events: none !important;
  opacity: 0.5;

  &.add-to-favourites {
    opacity: 1;
  }
}

*:hover, *:active, *:focus {
  outline: 0 !important;
}

body, html {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin placeholder {
  &.placeholder {
    @content
  }
  &:-moz-placeholder {
    @content
  }
  &::-webkit-input-placeholder {
    @content
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recipeView__bottomText, 
.tagLanding__bottomText {
  text-align: left;
  color: #212121;

  img {
    display: block;
    border-radius: 4px;
    max-width: min(100%, 800px);
    max-height: 300px;
    margin: 24px 0;
  
    @media (min-width: 640px) {
      max-height: 435px;
      margin: 40px 0;
    }
  }

  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 39px;
    margin-bottom: 12px;

    @media (min-width: 640px) {
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 16px;
    }
  }

  h3 {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 12px;

    @media (min-width: 640px) {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 24px;

    @media (min-width: 640px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  }
}