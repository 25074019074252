#lidl-footer {
  font-weight: 400;

  .list__item {
    font-size: 15px;

    a {
      font-size: 15px !important;
      border: none !important;
    }
  }

  .footer {
    padding-top: 70px;
    padding-bottom: 0;

    &__secondary {
      background-color: #EAEBEC;
      font-weight: 400;
    }

    &__trustbar {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      text-align: center;
      flex-wrap: wrap;

      li {
        width: 25%;

        @media (max-width: 900px) {
          width: 50%;
        }

        a {
          display: block;
          padding: 11.25px 20px;
          font-weight: 400;
          font-size: 18px;
          text-decoration: none;
          color: #2F3A44;
          text-align: center;
          line-height: 24px;

          &:hover {
            color: #2F3A44;
            text-decoration: underline;
          }

          i {
            display: block;
            color: #0076BF;
            font-size: 46px;
            line-height: 37px;
          }
        }
      }
    }
  }

  @media (max-width: 479px) {
    .hidden\@xs {
      display: none !important;
    }
  }

  @media (min-width: 480px) and (max-width: 899px) {
    .hidden\@sm {
      display: none !important;
    }
  }

  @media (min-width: 900px) and (max-width: 1439px) {
    .hidden\@md {
      display: none !important;
    }
  }

  img:not([src]) {
    display: none;
  }


  .text {
    font-weight: 300;
    text-decoration: none;
    line-height: 120%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  .footer, .text--bodycopy-small {
    font-size: 1.063rem;
    line-height: 1.125rem;
  }

  .text--bodycopy-small-head {
    font-size: 1.063rem;
    line-height: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .text--bodycopy-small-margin {
    margin-bottom: 5px;
  }

  .navigation--inverted .navigation__link, .text--meta {
    font-size: .9375rem;
    line-height: 1rem;
  }

  @media (min-width: 900px) {
    .footer, .text--meta\@md {
      //font-size: .9375rem;
      //line-height: 1rem;
    }
  }

  .text--meta-head {
    font-size: .9375rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  @media (min-width: 900px) {
    .text--meta-head\@md {
      font-size: .9375rem;
      line-height: 1rem;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .text--no-outline {
    outline: 0;
  }

  .navigation--inverted {
    .navigation__link {
      &:focus {
        color: #acb0b4;
      }

      color: #fff;
    }

    .navigation__text {
      color: #fff;
    }
  }

  .text--inverted {
    color: #fff;
  }

  .text--bold {
    font-weight: 700;
  }

  .text--link {
    color: #0076bf;
    text-decoration: underline;
  }

  a.text--link:focus, .text--link.text--active {
    color: #06507f;
  }

  .text--link-inverted, a.text--link-inverted:visited {
    color: #fff;
  }

  .text--link-inverted:focus {
    color: #fff;
    background-color: #4e5761;
  }

  a.text--link-inverted {
    text-decoration: underline;
  }

  .text--paragraph {
    margin-top: 17px;
    margin-bottom: 0;
  }

  .text--space {
    padding-bottom: 10px;
  }

  .text--padding-left {
    padding-left: 10px;
  }

  .text--click-padding {
    padding: 6px 6px 6px 10px;
    display: inline-block;
  }

  .text--space-medium {
    padding-bottom: 15px;
  }

  .text--center {
    text-align: center;
  }

  .button--small {
    padding: .188rem .625rem 0;
    font-weight: 400;
  }

  .button--no-padding {
    padding: 0;
  }

  .button--margin-bottom {
    margin-bottom: 10px;
  }

  @media (min-width: 480px) {
    .button--margin-bottom {
      margin-bottom: 20px;
    }
  }

  .button--margin-top {
    margin-top: 20px;
  }

  @media (min-width: 480px) {
    .button--margin-right\@sm {
      margin-right: 10px;
    }
  }

  .button--inverted {
    color: #2f3a44;

    &:focus {
      color: #2f3a44;
      -webkit-transition: all .2s linear;
      transition: all 0.2s linear;
    }
  }

  a.button--inverted:focus {
    color: #2f3a44;
    -webkit-transition: all .2s linear;
    transition: all 0.2s linear;
  }

  .button--left {
    text-align: left;
  }

  .button--align-right {
    padding: 0;
    text-align: right;
  }

  .button--link {
    color: #2f3a44;

    &:focus {
      color: #2f3a44;
      -webkit-transition: all .2s linear;
      transition: all 0.2s linear;
    }
  }

  a.button--link:focus {
    color: #2f3a44;
    -webkit-transition: all .2s linear;
    transition: all 0.2s linear;
  }

  .button--link {
    text-decoration: underline;
    text-transform: none;
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;

    &:focus {
      text-decoration: underline;
      text-transform: none;
      background: 0 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  a.button--link:focus {
    text-decoration: underline;
    text-transform: none;
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .button--icon, footer .button--icon {
    padding-top: 7px;
    padding-left: 6px;
    width: 33px;
  }

  @media (min-width: 900px) {
    .button--icon, footer .button--icon {
      padding: 11px;
      width: 44px;
    }
  }

  .button--icon {
    &.button--large {
      width: 59px;
      padding: 0;
    }

    &.button--medium {
      width: 48px;
      height: 48px;
      padding: 10px;
    }

    &:focus {
      text-decoration: none;
    }
  }

  a.button--icon:focus {
    text-decoration: none;
  }

  .button--icon:focus .button__text, a.button--icon:focus .button__text {
    text-decoration: underline;
  }

  .button--link-icon {
    color: #2f3a44;
    font-weight: 300;
    padding: 5px;
    text-decoration: none;

    &:focus {
      color: #000;
      text-decoration: none;
    }

    .button__text {
      text-decoration: underline;
      text-transform: none;
      font-size: 1.125rem;
      vertical-align: middle;
      padding-left: 3px;
    }
  }

  @media (max-width: 480px) {
    .button--link-icon .button__text {
      font-size: 0.875rem;
    }
  }

  .footer__content {
    max-width: 1216px;
    margin: auto;
  }

  .footer__mainfooter {
    background-color: #6d757c;
    padding-bottom: 10px;
  }

  @media (min-width: 900px) {
    .footer__mainfooter {
      padding-top: 10px;
    }
  }

  .footer__content--awards {
    display: none;
  }

  .icon {
    display: inline-block;
    position: relative;
    height: 32px;
    width: 32px;
    fill: currentColor;
  }

  .icon__title {
    opacity: 0;
  }

  .icon svg {
    height: 100%;
    vertical-align: top;
    width: 100%;
  }

  .icon--extra-large {
    width: 100px;
    height: 32px;
  }

  @media (min-width: 900px) {
    .icon--extra-large {
      width: 140px;
      height: 45px;
    }
  }

  .icon--large {
    width: 32px;
    height: 32px;
  }

  @media (min-width: 900px) {
    .icon--large {
      width: 48px;
      height: 48px;
    }
  }

  .icon--medium {
    height: 32px;
    width: 32px;
  }

  .icon--medium-small {
    height: 24px;
    width: 24px;
  }

  .icon--small {
    height: 20px;
    width: 20px;
  }

  .icon--text {
    height: 12px;
    width: 12px;
  }

  .icon--text-large {
    height: 14px;
    width: 14px;
  }

  .icon--text-small {
    height: 10px;
    width: 10px;
  }

  @media (min-width: 900px) {
    .icon--medium\@md {
      height: 32px;
      width: 32px;
    }
    .icon--small\@md {
      height: 20px;
      width: 20px;
    }
    .icon--medium-small\@md {
      height: 24px;
      width: 24px;
    }
  }

  @media (min-width: 640px) {
    .icon--large\@640 {
      width: 48px;
      height: 48px;
    }
  }

  .icon--top {
    vertical-align: top;
  }

  .icon--middle {
    vertical-align: middle;
    margin-bottom: 0.25em;
  }

  .icon--no-margin {
    margin: 0;
  }

  .icon--middle-small {
    margin: 0 9px 4px 0;
    vertical-align: middle;
  }

  .icon--bottom {
    vertical-align: bottom;
  }

  .icon--margin-left {
    margin-left: 3px;
  }

  .icon--margin-left-medium {
    margin-left: 6px;
  }

  .icon--margin-right {
    margin-right: 3px;
  }

  .icon--margin-right-medium {
    margin-right: 6px;
  }

  .icon--margin-right-large {
    margin-right: 7px;
  }

  .icon--margin-right-extra-large {
    margin-right: 20px;
  }

  .icon--no-pointer-events {
    pointer-events: none;
  }

  .link {
    text-decoration: underline;
    color: #2f3a44;
    padding: 3px 0;

    &:focus {
      text-decoration: underline;
      color: #000;
    }
  }

  @media (min-width: 900px) {
    .link {
      padding: 0;
    }
  }

  .link--no-padding {
    padding: 0;
  }

  .link--icon-right {
    width: auto;

    .link__content, .link__icon {
      float: right;
      display: block;
    }
  }

  .list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  @media (min-width: 480px) {
    .list--table {
      display: table;
      width: 100%;

      > .list__item {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  .list--table-justify {
    overflow: auto;

    > .list__item:last-child {
      float: right;
    }
  }

  @media (min-width: 480px) {
    .list--table-justify {
      display: table;
      width: 100%;

      > .list__item {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  @media (min-width: 480px) {
    .list--table-align-bottom {
      display: table;
      width: 100%;

      > .list__item {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  .list--table-align-bottom > .list__item {
    vertical-align: middle;
  }

  .list--inverted a {
    color: #fff;

    &:visited {
      color: #fff;
    }

    &:focus {
      text-decoration: underline;
      background-color: #4e5761;
    }
  }

  .list--spread > .list__item {
    margin-bottom: 12px;
  }

  @media (min-width: 480px) {
    .list--spread\@sm > .list__item {
      margin-bottom: 12px;
    }
  }

  .list--spread-small > .list__item > .list__link {
    padding: 15px;
  }

  .list--columns, .list--columns-spread {
    overflow: auto;
  }

  .list--columns > .list__item {
    &:nth-child(odd) {
      clear: left;
      float: left;
      width: auto;
    }

    &:nth-child(even) {
      float: left;
      width: auto;
    }
  }

  .list--columns-spread > .list__item {
    &:nth-child(odd) {
      clear: left;
      float: left;
      width: 50%;
    }

    &:nth-child(even) {
      float: left;
      width: 50%;
    }
  }

  @media (min-width: 480px) and (max-width: 899px) {
    .list--columns-none\@sm > .list__item {
      &:nth-child(even), &:nth-child(odd) {
        float: none;
        width: auto;
      }
    }
  }

  @media (min-width: 900px) {
    .list--columns-none\@md > .list__item {
      &:nth-child(even), &:nth-child(odd) {
        float: none;
        width: auto;
      }
    }
  }

  @media (min-width: 1440px) {
    .list--columns\@md {
      overflow: auto;

      > .list__item {
        &:nth-child(odd) {
          clear: left;
          float: left;
          width: auto;
        }

        &:nth-child(even) {
          float: left;
          width: auto;
        }
      }
    }
  }

  .list--separate {
    border-bottom: 1px solid #acb0b4;
  }

  .list--separate-top {
    border-top: 1px solid #acb0b4;
    padding-top: 10px;
  }

  .list--margin-vertical > .list__item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .list--pad-vertical > .list__item > .list__link {
    padding: 15px;
  }

  .list--pad-vertical-small > .list__item > .list__link {
    padding: 7px 10px;
  }

  .list--align-top > .list__item {
    vertical-align: top;
  }

  .list--align-right {
    text-align: right;
  }

  .list--horizontal > .list__item, .list--horizontal-spread-small > .list__item {
    display: inline-block;
  }

  @media (min-width: 480px) and (max-width: 899px) {
    .list--horizontal-3col\@sm {
      font-size: 0;

      > .list__item {
        display: inline-block;
        font-size: 1rem;
        width: 33%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &:nth-child(n+4) {
          border-top: 1px solid #acb0b4;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 899px) {
    .list--horizontal-4col\@sm {
      font-size: 0;

      > .list__item {
        display: inline-block;
        font-size: 1rem;
        width: 25%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }

  @media (min-width: 900px) {
    .list--horizontal-4col\@md > .list__item {
      display: inline-block;
      overflow: hidden;
      border: none;
      width: 22%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &:nth-child(1) {
        width: 15%;
      }

      &:nth-child(2) {
        width: 39%;
      }

      &:nth-child(4) {
        float: right;
      }
    }
    .list--horizontal-5col\@md {
      font-size: 0;

      > .list__item {
        display: inline-block;
        font-size: 1rem;
        overflow: hidden;
        border: none;
        width: 25%;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
      }
    }
  }

  .list--horizontal-spread > .list__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 36px;
    }
  }

  .list--horizontal-spread-small > .list__item:not(:last-child) {
    margin-right: 25px;
  }

  @media (min-width: 480px) {
    .list--horizontal-spread-small\@sm > .list__item {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 25px;
      }
    }
  }

  .list--horizontal-spread-medium > .list__item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 80px;
    }
  }

  .list--pad-top {
    padding-top: 7px;
  }

  .list--pad-top-medium {
    padding-top: 17px;
  }

  @media (min-width: 900px) {
    .list--pad-top-medium\@md {
      padding-top: 17px;
    }
  }

  .list--pad-top-small {
    padding-top: 10px;
  }

  @media (min-width: 900px) {
    .list--pad-top-small\@md {
      padding-top: 10px;
    }
  }

  .list--pad-top-large {
    padding-top: 22px;
  }

  @media (min-width: 900px) {
    .list--pad-top-large\@md {
      padding-top: 22px;
    }
  }

  .list--pad-right {
    padding-right: 90px;
  }

  @media (min-width: 480px) {
    .list--pad-right\@sm {
      padding-right: 90px;
    }
  }

  .list--pad-left {
    padding-left: 20px;
  }

  .list--pad-left-medium {
    padding-left: 17px;
  }

  @media (min-width: 900px) {
    .list--pad-left-medium\@md {
      padding-left: 17px;
    }
  }

  .list--pad-bottom {
    padding-bottom: 5px;
  }

  @media (min-width: 900px) {
    .list--pad-bottom\@md {
      padding-bottom: 5px;
    }
  }

  .list--pad-bottom-small {
    padding-bottom: 10px;
  }

  .list--pad-bottom-medium {
    padding-bottom: 17px;
  }

  @media (min-width: 900px) {
    .list--pad-bottom-medium\@md {
      padding-bottom: 17px;
    }
  }

  .list--awards {
    text-align: center;

    .list__item {
      margin: 0 6px;
      display: inline;

      &:nth-child(3)::after {
        content: "\A0\A";
        white-space: pre;
      }
    }
  }

  @media (min-width: 900px) {
    .list--awards {
      padding-top: 10px;
      padding-bottom: 30px;
    }
  }

  @media (min-width: 900px) {
    .list--awards .list__item:nth-child(3)::after {
      content: none;
    }
  }

  .list--appstores {
    overflow: hidden;

    .list__link .icon {
      margin-right: 0;
    }
  }

  .list__item--separate {
    border-bottom: 1px solid #eaebec;
  }

  @media (min-width: 480px) {
    .list__item--separate-none\@sm {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .list__item--pad-small {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .list__item--padding-bottom-medium {
    padding-bottom: 10px;
  }

  @media (min-width: 900px) {
    .list__item--pad-small\@md {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  @media (min-width: 480px) {
    .list__item--pad-none\@sm {
      padding-top: 0;
      padding-bottom: 0;
    }
    .list__item--pad-right\@sm {
      padding-right: 10px;
    }
  }

  @media (min-width: 900px) {
    .list__item--pad-right\@md {
      padding-right: 20px;
    }
  }

  @media (min-width: 900px) and (max-width: 1200px) {
    .list__item--pad-right-small\@md {
      padding-right: 10px;
    }
  }

  .list__item--table {
    display: table;
  }

  .list__link {
    display: block;
    text-decoration: none;

    .icon {
      margin-right: 10px;
    }
  }

  .list__link--pad-horizontal {
    padding-bottom: 7px;
    padding-top: 7px;
  }

  @media (min-width: 480px) {
    .list__link--pad-none\@sm {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .list__link--h6 {
    padding: 10px;
    font-size: 1.25rem;
  }

  .navigation--vertical .navigation__item {
    display: block;

    .navigation__link {
      display: block;
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .navigation--company {
    display: inline-block;

    .navigation__list {
      line-height: 30px;
    }

    .navigation__item {
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .navigation__link {
      padding: 5px 10px 4px;

      &:focus {
        text-decoration: underline;
        color: #fff;
        background-color: #4e5761;
      }
    }

    .navigation__text--before {
      display: inline-block;
      margin-right: 8px;
    }
  }

  @media (min-width: 900px) {
    .navigation--company.navigation--space {
      margin-right: 25px;

      .navigation__link {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  @media (min-width: 900px) {
    .navigation--company .navigation__item {
      display: inline;
    }
  }

  @media (max-width: 899px) {
    .navigation--company {
      padding: 5px 0;
    }
  }

  .navigation--company-margin-right .navigation__item {
    margin-right: 18px;
  }

  .navigation__container--hidden {
    opacity: 0;
    visibility: collapse;
  }

  @media (max-width: 899px) {
    .navigation--social {
      margin: 5px 30px 60px;
    }
  }

  .navigation--social {
    .navigation__link {
      padding: 10px;
      display: block;

      &:focus {
        color: #fff;
        background-color: #4e5761;
      }
    }

    .navigation__item {
      margin-right: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 900px) {
    .navigation--social .navigation__item {
      margin-right: 14px;
    }
  }

  .navigation--user {
    .navigation__item {
      vertical-align: top;
      margin-left: 5px;
      padding: 5px 0;
    }

    .navigation__link {
      color: #2f3a44;
    }

    .navigation__text {
      display: block;
    }

    .navigation__text--before {
      line-height: 0;
    }
  }

  @media (min-width: 480px) {
    .navigation--user .navigation__item {
      margin-left: 10px;
    }
  }

  @media (max-width: 899px) {
    .navigation--user .navigation__item {
      margin: 0 10px;
    }
  }

  @media (min-width: 900px) {
    .navigation--user {
      .navigation__item {
        width: 60px;
        text-align: center;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }
      }

      .navigation__text--center {
        font-size: .875rem;
        line-height: 1rem;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }

  @media (min-width: 1440px) {
    .navigation--user {
      .navigation__item {
        width: 80px;
        text-align: center;
        display: inline-block;

        &:first-child {
          margin-left: 0;
        }
      }

      .navigation__text--center {
        font-size: 1rem;
        line-height: 1rem;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
  }

  .navigation__container, .navigation__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .navigation__item {
    display: inline-block;
  }

  .page {
    min-width: 240px;
  }

  .picture {
    line-height: 0;
    display: inline-block;

    img:not([src]) {
      min-height: 1px;
      display: inline-block;
    }
  }

  .picture--center {
    margin: auto;
    text-align: center;
    display: block;

    img {
      margin: auto;
      text-align: center;
      display: block;
    }
  }

  .picture--badge {
    width: 100%;
    overflow: hidden;

    img {
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      max-height: 85px;
      max-width: 70px;
      height: auto;
      width: auto;
    }
  }

  @media (min-width: 900px) {
    .picture--badge {
      width: 120px;
    }
  }

  @media (min-width: 900px) {
    .picture--badge img {
      max-height: 120px;
      max-width: 100px;
    }
  }

  .picture--badge-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 105px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    width: 100%;
    overflow: hidden;

    img {
      position: relative;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      max-height: 85px;
      max-width: 70px;
      height: auto;
      width: auto;
    }
  }

  @media (min-width: 900px) {
    .picture--badge-text {
      width: 120px;
      height: 140px;
    }
  }

  @media (min-width: 900px) {
    .picture--badge-text img {
      max-height: 120px;
      max-width: 100px;
    }
  }

  .badge {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .badges {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 20px 0 0;
  }

  .badge {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px 10px;
    max-width: 200px;
    font-size: .875rem;
    font-weight: 300;
    line-height: 120%;
    color: #2f3a44;
  }

  @media (min-width: 900px) {
    .badge {
      max-width: 280px;
    }
  }


  .navigation__container--user {
    position: relative;
  }

  .navigation--company {
    overflow: hidden;
    display: block;
  }

  @media screen and (max-width: 992px) {
    .footer {
      text-align: initial;
    }
  }

  .list--separate {
    border-bottom: 1px solid #acb0b4;
  }

  .navigation--user .navigation__text--center {
    overflow: visible;
  }

  .page__footer .footer {
    width: auto !important;
    margin: 0 auto;
    height: auto !important;
    border: none;
  }

  .list--inverted a {
    &:active, &:focus, &:hover {
      text-decoration: underline;
      background-color: #4e5761;
    }
  }

  .text--link-inverted {
    &:active, &:focus, &:hover {
      color: #fff;
      background-color: #4e5761;
    }
  }

  .navigation--company .navigation__link {
    &:active, &:focus, &:hover {
      text-decoration: underline;
      color: #fff;
      background-color: #4e5761;
    }
  }

  .navigation--social .navigation__link {
    &:focus, &:hover {
      color: #fff;
      background-color: #4e5761;
    }
  }
}
