.christmas-section40 {
  padding-bottom: 50px;
  background-size: 440px 440px;
  position: relative;

  @include media-breakpoint-down(xs) {
    padding-bottom: 20px;
  }

  &.bgImageActive {
    background-image: url("/layouts/frontend/img/christmas-landing/section4/section4-background.jpg");
   .feedSection {
     &--section4 {
       &:before {
         background-image: url("/layouts/frontend/img/christmas-landing/section4/section4-photo.jpg");
       }
     }
   }
  }


 .feedSection {
   border-radius: 0;
   border: none;

   &:after {
     @include media-breakpoint-down(xs) {
       display: none;
     }
   }

   @media (max-width: 575px) {
     margin-left: 0;
     margin-right: 0;
   }
 }

  .decoration {
    &:before {
      content: '';
      width: 300px;
      display: inline-block;
      height: 400px;
      position: absolute;
      background-image: url(/layouts/frontend/img/christmas-landing/cone.png);
      background-repeat: no-repeat;
      background-size: contain;
      top: -140px;
      right: 165px;

      @include media-breakpoint-down(sm) {
        right: 65px;
        top: -157px;
        width: 280px;
      }

      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .feedElement {

    &:after,
    &:before {
      top: 146px;
      height: calc(100% - 146px);
    }

    &:after {
      background: linear-gradient(270deg, #ECDCBA 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:before {
      background: linear-gradient(90deg, #ECDCBA 0%, rgba(255, 255, 255, 0) 100%);
    }

    //Damian, daj znac czy tak moze byc okej

    &__header {
      &__text {
        color: #BC2C35;
      }
    }
  }
}
