//.newsletter__remove {
//  display: none;
//}
.account-details__newsletter {
  .terms__newsletter__afterText {
    margin-bottom: 30px;
  }
}
//.account-details__newsletter--subscribed {
//  .newsletter__add {
//    display: none;
//  }
//  .newsletter__remove {
//    display: block;
//  }
//}
