.preparation {
  margin-top: 50px;

  .recipe__h2 {
    margin-bottom: 0;
  }

  &__portionsInfo {
    padding: 10px 0;
  }

  &__step {


    &-content {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;

      &-text {
        width: 100%;
      }

      @media (max-width: 640px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__photo {
    margin-left: 20px;
    margin-bottom: 20px;

    @media (max-width: 640px) {
      margin-left: 0;
      //margin-top: 20px;
    }
    img {
      max-width: 276px;
      @media (max-width: 640px) {
        max-width: 100%;
      }
    }
  }

  &__step {
    &-number {
      font-weight: 700;
    }
  }

  &.bgImageActive {
    .recipe__cookingMode .iconset--pan_blue {
      background-image: url('/layouts/frontend/img/svg/pan_blue.svg');
    }
  }
}
