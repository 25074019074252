@import "shoplist-landing/shoplist-landing-section1";
@import "shoplist-landing/shoplist-landing-section2";
@import "shoplist-landing/shoplist-landing-section3";
@import "shoplist-landing/shoplist-landing-section4";
@import "shoplist-landing/shoplist-landing-section5";
@import "shoplist-landing/shoplist-landing-section6";
@import "shoplist-landing/shoplist-landing-section7";
@import "shoplist-landing/seo_content";

.shoplist-landing {
  margin-bottom: -70px;

    .shoplist-landing-main {
      &.bgImageActive {
        .shoplist-section__rightBox {
          .shoplist-landing-main__number {
            &:after {
              background-image: url("/layouts/frontend/img/svg/shoplist/arrow-shoplist.svg");
            }

            &--leftContent {
              &:after {
                background-image: url("/layouts/frontend/img/svg/shoplist/arrow-right-shoplist.svg");
              }
            }
          }
        }
      }
    }

  &__bannerMobile {
    display: none;

    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
      z-index: 7;
      background-color: white;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px;

      a {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  .shoplist-landing-main {

    &--content {
      display: flex;
      flex-direction: row;
      height: 480px;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        height: auto !important;
      }

      &:after {
        content: '';
        position: absolute;
        background-repeat: no-repeat;
        width: 309px;
        height: 464px;
        background-size: contain;
        z-index: 4;
        left: 50%;
        transform: translateX(-50%);

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
        background: #F1F2F3;
      }

      .shoplist-section {

        &__arrow {
          position: relative;
          left: -200px;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &__leftBox {
          background-size: cover;
          background-repeat: no-repeat;
          width: 50%;
          height: 100%;
          position: relative;

          @include media-breakpoint-down(sm) {
            width: 100%;
            overflow: hidden;
            min-height: 330px;
            background-position: center;
          }

          &:before {
            position: absolute;
            top: 0px;
            right: 0;
            content: "";
            width: 400px;
            height: 100%;
            background: linear-gradient(270deg,#fff,hsla(0,0%,100%,0));
            transition: all .5s;
            z-index: 3;

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }

          &:after {
            display: none;

            @include media-breakpoint-down(sm) {
              display: block;
              content: '';
              position: absolute;
              background-repeat: no-repeat;
              width: 320px;
              height: 320px;
              background-size: contain;
              z-index: 4;
              right: initial;
              top: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }


        &__rightBox {
          width: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          @include media-breakpoint-down(sm) {
            width: 100%;
            padding-top: 64px;
          }
        }
      }
    }

    &__text {
      font-size: 26px;
      line-height: 120%;
      color: #353B42;
      max-width: 41%;
      text-align: center;

      @include media-breakpoint-down(sm) {
        margin-bottom: 40px;
        font-size: 24px;
        max-width: 75%;
      }
    }

    &__number {
      font-weight: 600;
      font-size: 34px;
      line-height: 120%;
      color: #0050AA;
      margin-bottom: 24px;
      position: relative;

      &:after {
        content: '';
        background-repeat: no-repeat;
        background-size: cover;
        width: 114px;
        height: 50px;
        position: absolute;
        left: -185px;
        top: -50px;
        
        @media (max-width: 1330px) {
          left: -150px;
        }
        @media (max-width: 1183px) {
          left: -130px;
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &--leftContent {
        &:after {
          width: 116px;
          height: 60px;
          right: -185px;
          left: initial;

          @media (max-width: 1330px) {
            right: -140px;
          }

          @media (max-width: 1183px) {
            left: 33px;
          }
        }
      }
    }

    .container {
      z-index: 3;
    }
  }
}

