.cookbook__searchAddRecipeBanner {
  background: #D6E3F1;
  border-radius: 3px;
  padding: 22px 30px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;

  @include media-breakpoint-down(sm) {
    padding: 20px;
  }

  &__left {
    width: 60%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__header {
    font-size: 22px;
    line-height: 26px;
    color: #353B42;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 25px;
    margin-top: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 16px;
      line-height: 22px;

      br {
        display: none;
      }
    }

    span {
      font-weight: bold;
    }
  }

  &__right {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 20px;
      justify-content: flex-start;
    }

    a {
      margin-right: 0;
      border: 1px solid #0050AA;
      width: auto;
      padding-left: 10px;
      padding-right: 10px;

      &:hover {
        text-decoration: none;
      }

      .iconset--plus {
        color: #0050AA;
        margin-right: 10px;
        font-size: 20px;
        background: url('/layouts/frontend/img/search/plus.svg');
        width: 24px;
        height: 24px;
        background-size: 24px 24px;
      }
    }
  }
}