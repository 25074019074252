.shoplist-landing-main {
  &.section3 {
    &.bgImageActive {
      &:after {
        background-image: url("/layouts/frontend/img/shoplist-landing/section3-phone.png");
      }
      .shoplist-section {
        &__leftBox {
          background-image: url("/layouts/frontend/img/shoplist-landing/section3-background.png");
          &:after {
            @include media-breakpoint-down(sm) {
              background-image: url("/layouts/frontend/img/shoplist-landing/section3-phone-mobile.png");
            }
          }
        }
      }
    }

    &:after {
      top: 0;
    }
  }
}