.feeds {
  position: relative;
  display: flex;
  flex-direction: column;

  .feedNavigation {
    ul {
      display: flex;
      justify-content: space-between;
      margin-left: 0;
      padding-left: 0;

      @include media-breakpoint-down(md) {
        overflow: scroll;
      }

      li {
        list-style-type: none;

        &:first-child {
          a {
            color: $lidlPrimaryColor;
            border-bottom: 2px solid $lidlPrimaryColor;
          }
        }

        a {
          font-family: 'Lidl Font'; font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          color: black;
          display: block;
          padding: 18px 15px;
          white-space: nowrap;
          border-bottom: 1px solid #EEEEEE;
          text-decoration: none;
          font-weight: 600;

          &:hover {
            color: $lidlPrimaryColor;
            border-bottom: 2px solid $lidlPrimaryColor;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  &__name {
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: black;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    &-top {
      font-weight: 600;
      color: #000000;
      opacity: 0.5;
    }
    &-bottom {
      margin-top: 10px;
      opacity: 0.5;
    }
  }
  &__bottom {
    font-size: 12px;
    line-height: 14px;
    margin-top: 30px;
  }

  &__header {
    font-family: 'Lidl Font'; font-weight: bold;
    font-size: 32px;
    padding: 20px 0;
    word-wrap: break-word;
  }

  &__desc {
    font-size: 19px;
    padding-bottom: 20px;
    max-width: 75%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    span.bold {
      font-family: 'Lidl Font'; font-weight: bold;
    }
  }
}

.feedElement {
  position: relative;
  display: none;

  &--visible {
    display: block;
  }

  &__header {
    padding: 50px 0 20px;
    display: flex;
    align-items: center;

    &__text {
      font-size: 26px;
      font-weight: 600;
    }

    @include media-breakpoint-down(xs) {
      align-items: self-start;
      flex-direction: column;
    }

    @include media-breakpoint-down(sm) {
      padding-top: 15px;
    }

    span {
      font-family: 'Lidl Font';
    }

    &__link {
      margin-left: 20px;
      font-weight: 600;
      font-size: 13px;
      text-transform: uppercase;
      color: $lidlPrimaryColor;
      margin-top: 2px;

      @include media-breakpoint-down(xs) {
        margin-left: 0;
        margin-top: 3px;
      }
    }
  }

  &.feedElement--withArrow {
    &.bgImageActive {
      .feedElement__arrowText {
        background-image: url('/layouts/frontend/img/homepage_de/slider_arrow_text.svg');
      }
    }

    .feedElement__arrowText {
      display: block;

      @media (max-width: 1300px) {
        display: none;
      }
    }
  }

  .feedElement__arrowText {
    display: none;
    width: 100px;
    height: 100px;
    position: absolute;
    right: 0;
    top: 50%;
    background: no-repeat;
    background-position: top left;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;

    padding-top: 60px;
    padding-left: 30px;
    z-index: 10;
    right: -90px;
    margin-top: -45px;

    transition: 0.5s all;

    @media (max-width: 1400px) {
      right: -80px;
    }
    @media (max-width: 1350px) {
      padding-left: 25px;
    }
  }

  &__scrollLeft,
  &__scrollRight {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    margin-top: 40px;
    left: 0;
    background-color: $lidlPrimaryColor;
    background-position: center;
    z-index: 10;
    transition: 0.5s all;

    @media (max-width: 1000px) {
      margin-top: 0;
    }

    @include media-breakpoint-down(xs) {
      display: none !important;
    }
  }

  &.bgImageActive {
    .feedElement__scrollLeft,
    .feedElement__scrollRight {
      background-image: url('/layouts/frontend/img/homepage_de/slider_arrow_light.svg');
    }
  }

  &__scrollLeft {
    visibility: hidden;
    opacity: 0;
    transition: 0.25s all;
  }

  &__scrollRight {
    left: auto;
    right: 0;
  }

  &__scrollbarHidden {
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__scroll {
    min-height: 330px;
    overflow-x: scroll;
    overflow-y: hidden;
    transform: translateZ(0);
    will-change: transform;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;

    margin-bottom: -16px;
    padding-bottom: 16px;

    @include media-breakpoint-down(sm) {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__recipes {
    display: flex;
    align-items: stretch;

    &__promoImg {
      margin-right: 15px;

      @include media-breakpoint-down(sm) {
        margin-right: 5%;
      }
    }

    &__recipe {
      margin-bottom: 0 !important;
      min-width: 230px;

      @include media-breakpoint-down(sm) {
        margin-right: 15px !important;
      }

      @include media-breakpoint-down(xs) {
        min-width: 80%;
      }

      &:first-child {
        margin-left: 0;
      }

      .single-item__name {
        margin-bottom: 0;
      }

      &--moreLink {
        border: 1.5px solid #EEEEEE;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-decoration: none !important;
        min-height: 330px;
        padding: 0 10px;

        button {
          width: auto;
          margin: 20px auto 0;
          padding: 0 20px;
        }

        span {
          font-size: 18px;
          line-height: 156%;
          text-align: center;
          color: #000000;
          font-family: 'Lidl Font'; font-weight: bold;

          &:before {
            content: '';
            display: block;
            margin: 0 auto 20px;
            width: 64px;
            height: 64px;
            background: url('/layouts/frontend/img/svg/cookbook.svg');
          }
        }
      }

      &--lastSpace {
        min-width: 30px;

        @include media-breakpoint-down(xs) {
          min-width: calc(100vw - 290px);
        }
      }
    }
  }
}

.feedSection {
  margin: 40px 0 0;
  padding: 40px;
  border-radius: 8px;
  min-height: 280px;

  background: #F5F5F5;
  background-size: cover;
  background-position: center;

  @include media-breakpoint-down(sm) {
    word-wrap: break-word;
    padding: 230px 20px 20px;
    background-position: top center;
    background-repeat: no-repeat;
    margin: 20px 0;
  }

  &--planer.bgImageActive {
    background-image: url('/layouts/frontend/img/homepage_de/feeds/planner_2.png');

    @include media-breakpoint-down(sm) {
      background-image: url('/layouts/frontend/img/homepage_de/feeds/planer_mobile_new.png');
    }
  }

  &--diet.bgImageActive{
    background-image: url('/layouts/frontend/img/homepage_de/feeds/diet.jpg');

    @include media-breakpoint-down(sm) {
      background-image: url('/layouts/frontend/img/homepage_de/feeds/diet_mobile.jpg');
    }
  }

  &--oneStop.bgImageActive {
    background-image: url('/layouts/frontend/img/homepage_de/feeds/shop_new.png');

    @include media-breakpoint-down(sm) {
      background-image: url('/layouts/frontend/img/homepage_de/feeds/shop_mobile_new.png');
    }
  }

  &--stayHomePlaner {
    background-color: #D2AA80;
    border-radius: 6px;

    &.bgImageActive {
      background-image: url('/layouts/frontend/img/homepage_de/feeds/planer_stayhome.png');
      background-size: contain;
      background-repeat: no-repeat;

      color: #FFFFFF;
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);

      @include media-breakpoint-down(sm) {
        background-size: cover;
        background-image: url('/layouts/frontend/img/homepage_de/feeds/planer_stayhome_mobile.png');
      }
    }
  }

  &--stayHome {
    color: #FFF;
    background-image: url('/layouts/frontend/img/homepage_de/feeds/stay_home.png');
    text-shadow: 0 0 5px rgba(#000, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      padding: 40px 20px;
      height: 410px;
      background-image: url('/layouts/frontend/img/homepage_de/feeds/stay_home_mobile.png');

      .feedSection__title {
        margin-bottom: 0;
        margin-top: 10px;
      }

      .feedSection__desc {
        margin-top: 40px;
      }
    }
  }

  &--grillen {
    color: #FFF;
    background-image: url('/layouts/frontend/img/homepage_de/slider/grillen.png');
    text-shadow: 0 0 5px rgba(#000, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      padding: 40px 20px;
      height: 410px;
      background-image: url('/layouts/frontend/img/homepage_de/slider/grillen_mobile.png');

      .feedSection__title {
        margin-bottom: 0;
        margin-top: 10px;
      }

      .feedSection__desc {
        margin-top: 40px;
      }
    }
  }

  &--veganGrillen {
    color: #FFF;
    background-image: url('/layouts/frontend/img/homepage_de/feeds/vegangrillen.jpg');
    text-shadow: 0 0 5px rgba(#000, 0.6);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include retina {
      background-image: url('/layouts/frontend/img/homepage_de/feeds/vegangrillen_2x.jpg');
    }

    @include media-breakpoint-down(sm) {
      padding: 40px 20px;
      height: 410px;
      background-image: url('/layouts/frontend/img/homepage_de/feeds/vegangrillen_mobile.jpg');

      .feedSection__title {
        margin-bottom: 0;
        margin-top: 10px;
      }

      .feedSection__desc {
        margin-top: 40px;
      }
    }
  }

  &--searchSection {
    margin: -10px 0 30px;
    color: #FFF;
    background-image: url('/layouts/frontend/img/homepage_de/testAB__search__title.png');
    text-shadow: 0 0 5px rgba(#000, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;

    display: none; /* .testB__searchTitle */

    @include media-breakpoint-down(sm) {
      margin: 0 0 20px;
      padding: 40px 20px 70px;
      height: 240px;
      background-image: url('/layouts/frontend/img/homepage_de/testAB__search__title__mobile.png');
      background-position: center;

      .feedSection__title {
        margin-bottom: 30px;
        margin-top: 0
      }

      .feedSection__desc {
        margin-top: 0px;
      }
    }
  }


  &__title {
    font-size: 32px;
    font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }

    span, b, strong {
      font-weight: bold;
    }
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
  }

  &__desc {
    max-width: 50%;
    font-size: 16px;
    margin-top: 5px;

    ul {
      padding-left: 20px;
      padding-top: 10px;
    }
  }

  &__title,
  &__subtitle,
  &__desc {
    max-width: 50%;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    a {
      padding: 0 25px;
      width: auto;
      margin-bottom: 10px;
      max-width: 100%;
    }
  }
}

.homepage .feeds {
  padding-top: 40px;

  @include media-breakpoint-down(sm) {
    padding-top: 10px;
  }
}

.ingredientPromotion {
  background: #FFFFFF;
  border: 2px solid #F2F2F2;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 20px 0 0;

  &--recipeView {
    .ingredientPromotion {
      &__sup {
        font-size: 16px;
        top: -5px;
      }

      &__element {
        border: 2px solid #E3E4E5;
        border-top-color: #0050AA;
      }

      &__top {
        background-color: #0050AA;
      }

      &__name {
        margin-bottom: 24px;
      }

      &__ml {
        font-size: 14px;
        line-height: 140%;
        bottom: 62px;
        color: #4E5761;
        font-family: 'Lidl Font';
        font-weight: initial;
      }
      &__price {
        font-family: "Lidl Font Price";
        border: 1px solid #C6C9CC;
      }

      &__priceInfo {
        font-size: 14px;
        line-height: 140%;
        color: #4E5761;
      }

      &__data {
        padding: 5px 10px;
        font-weight: 600;
        color: #E60A14;
        font-size: 14px;
        line-height: 140%;
      }
    }
  }

  &__sup {
    font-size: 10px;
    top: -1em;
  }

  &__header {
    font-family: 'Lidl Font';
    font-weight: bold;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    padding: 30px 0;
  }

  &__info {
    font-size: 10px;
    line-height: 12px;
    font-style: italic;
    padding: 10px 0 25px;
    max-width: 230px;
    margin: 0 auto;
  }

  &__element {
    border: 2px solid #C6C9CC;
    border-radius: 4px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    width: 230px;
    min-width: 230px;
    border-top-color: #113770;
    position: relative;
    min-height: 325px;
    cursor: pointer;

    &[data-link=""] {
      cursor: default;
    }

    &--recipeView {
      width: 220px;
      min-height: 325px;
      margin: 0 auto;
    }

    &--ingredient373 {
      .ingredientPromotion__photo {
        margin-top: -10px;
        max-height: 125px;
      }
    }
    &--ingredient455 {
      .ingredientPromotion__photo {
        margin-top: 15px;
      }
    }
  }

  &__top {
    padding: 10px 15px;
    background-color: #113770;
    color: white;
    align-self: flex-end;
    border-bottom-left-radius: 4px;
    font-size: 13px;
    line-height: 12px;
    position: relative;
    top: -1px;
    right: -2px;
    font-family: 'Lidl Font';
    font-weight: bold;
  }

  &__photo {
    max-width: 160px;
    align-self: center;
    margin-bottom: 10px;

    &:nth-child(3) {
      max-width: 100%;
    }
  }

  &__content {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 0 20px;
    margin-bottom: 10px;
    position: absolute;
    left: 0;
    bottom: 65px;
  }

  &__desc {
    font-size: 12px;
    line-height: 16px;
    margin-top: 2px;
  }

  &__ml {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: #ADB0B4;
    position: absolute;
    bottom: 50px;
    font-family: 'Lidl Font';
    font-weight: bold;
  }

  &__price-box {
    position: absolute;
    right: 5px;
    bottom: 20px;
    height: 58px;
    text-align: center;
  }

  &__price {
    font-size: 20px;
    line-height: 24px;
    display: inline-block;
    border: 1px solid #4E5761;
    display: inline-block;
    font-weight: 600;
    padding: 3px 10px;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  &__priceInfo {
    width: 80px;
    font-size: 8px;
    line-height: 10px;
  }
  &__data-box {
    border: 1px solid #d43027;
    border-radius: 3px;
    display: inline-block;
    position: absolute;
    bottom: 15px;
  }

  &__data {
    padding: 5px 10px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    color: #d43027;
  }

}


.feedParent {
  position: relative;

  &:before,
  &:after {
    position: absolute;
    top: 90px;
    right: 0;
    content: '';
    width: 80px;
    width: 145px;
    height: calc(100% - 90px);
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 9;
    transition: 0.5s all;

    @include media-breakpoint-down(sm) {
      width: 30px;
      right: -20px;
      top: 70px;
    }

    @include media-breakpoint-down(xs) {
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  &:before {
    visibility: hidden;
    opacity: 0;
    right: auto;
    left: 0;
    transition: 0.25s all;
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

    @include media-breakpoint-down(sm) {
      left: -20px;
    }
  }

  &.scrolled {
    &:before,
    &:after {
      width: 80px;
    }

    &:before,
    .feedElement__scrollLeft {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }

  &.scrolledToEnd {
    .feedElement__scrollRight,
    .feedElement__arrowText,
    &:after {
      opacity: 0;
      visibility: hidden;
    }
  }
}
