.iconset {

  &--info {
    display: inline-block;
    width: 17px;
    height: 17px;
    background: url('/layouts/frontend/img/svg/infoIcon.svg') no-repeat;
  }

  &--trash {
    display: inline-block;
    width: 13px;
    height: 17px;
    background: url('/layouts/frontend/img/svg/trash.svg') no-repeat;
  }

  &--shoplist {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('/layouts/frontend/img/svg/shoplist_icon.svg') no-repeat;
  }

  &--search {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('/layouts/frontend/img/svg/search_white.svg') no-repeat;
  }

  &--plus {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: url('/layouts/frontend/img/svg/plus.svg') no-repeat;
  }

  &--pan {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/pan.svg') no-repeat;
    background-size: cover;
  }

  &--carrot {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/carrot.svg') no-repeat;
    background-size: cover;
  }

  &--check {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url('/layouts/frontend/img/svg/check.svg') no-repeat;
    background-size: cover;
  }

  &--reload {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/reload.svg') no-repeat;
    background-size: cover;
  }

  &--voice {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/voice.svg') no-repeat;
    background-size: cover;
  }

  &--voiceGray {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/voice_gray.svg') no-repeat;
    background-size: cover;
  }

  &--cart {
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('/layouts/frontend/img/svg/cart.png') no-repeat;
    background-size: cover;
  }

  &--arrow {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/chevron_left.svg') no-repeat;
  }

  &--utensilPan {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('/layouts/frontend/img/svg/utensilPan.svg') no-repeat;
  }

  &--arrowDown {
    display: inline-block;
    width: 13px;
    height: 9px;
    background: url('/layouts/frontend/img/svg/arrowDown.svg') no-repeat;
  }

  &--confirm {
    display: inline-block;
    width: 13px;
    height: 9px;
    background: url('/layouts/frontend/img/svg/confirm.svg') no-repeat;
  }

  &--smallHeart {
    display: inline-block;
    background: url('/layouts/frontend/img/svg/smallHeart.svg') no-repeat;
  }

  &--deleteShoppingList {
    display: inline-block;
    width: 12px;
    height: 16px;
    background: url('/layouts/frontend/img/svg/delete-shopping-list.svg') no-repeat;
  }

  &--renameShoppingList {
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url('/layouts/frontend/img/svg/rename-shopping-list.svg') no-repeat;
  }

  &--cross {
    display: block;
    background-image: url("/layouts/frontend/img/svg/closeModal.svg");
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--arrowLeft {
    display: inline-block;
    background-image: url('/layouts/frontend/img/svg/arrow_right.svg');
    width: 16px;
    height: 16px;
    transform: rotate(-180deg);
  }

  &--arrowRight {
    display: inline-block;
    background-image: url('/layouts/frontend/img/svg/arrow_right.svg');
    width: 16px;
    height: 16px;
  }

  &--warning {
    display: inline-block;
    background-image: url('/layouts/frontend/img/svg/warning.svg');
    width: 16px;
    height: 16px;
  }
}
