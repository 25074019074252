@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "/layouts/frontend/fonts/slick/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "/layouts/frontend/img/" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */


/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    background: transparent;
    border: 0px solid rgb(228, 228, 228);
    color: transparent;
    cursor: pointer;
    display: block;
    height: 43px;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: 43px;
    z-index: 1;
    &:hover, &:focus {
        outline: none;
        &:before {}
    }
    &.slick-disabled:before {}
    &:before {}
}

.slick-prev {
    left: 25px;

    //&:before {
    //    content: url("/layouts/frontend/img/svg/ico-arrow-left.svg");
    //}
}

.slick-next {
    right: 25px;

    //&:before {
    //    content: url("/layouts/frontend/img/svg/ico-arrow-right.svg");
    //}
}

/* Dots */

.slick-dotted.slick-slider {
    //margin-bottom: 30px;
}

.slick-dots {
    bottom: 80px;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    li {
        cursor: pointer;
        display: inline-block;
        height: 20px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        width: 20px;
        button {
            background: transparent;
            border: 1px solid #91959E;
            border-radius: 50%;
            color: transparent;
            cursor: pointer;
            display: block;
            font-size: 0px;
            height: 14px;
            line-height: 0px;
            outline: none;
            padding: 5px;
            width: 14px;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: .5;
                }
            }
            &:before {
                position: absolute;
                top: 2px;
                left: 2px;
                content: "";
                width: 10px;
                height: 10px;
                background-color: #FF0000;
                text-align: center;
                opacity: 0;
                border-radius: 50%;
            }
        }
        &.slick-active {
            button {
                width: 16px;
                height: 16px;
                border-width: 2px;
            }

            button:before {
                top: 3px;
                left: 3px;
                opacity: 1;
            }
        }
    }
}
