.christmas-landing.christmasSection--11 {
  @media (max-width: 575px) {
    padding-top: 50px;

    .feedElement--red-background .feedElement__header:before {
      top: -27px;
      left: -30px;
    }
  }

  .feedSection {

    @media (max-width: 575px) {
      &:after {
        display: none !important;
      }
    }

    &--section3 {
      margin-bottom: 100px;
      padding: 30px 325px 25px 25px;

      @media (max-width: 910px) {
        padding: 30px 50px 25px 50px;
      }

      @media (max-width: 575px) {
        padding: 40px 20px 25px 20px;
        margin-top: 0;
        margin-bottom: 0;
      }

      &:before {
        left: auto;
        right: 25px;
        width: 240px;
        background-image: url("/layouts/frontend/img/christmas-landing/section11/section11-international.jpg");
      }
    }
  }

  .feedElement__header__text {
    line-height: 35px;
  }


}