.showPopupIngredients {
  width: 100%;

  &__button {
    margin-top: 40px;
  }

  &__box {
    display: flex;
    text-align: left;

    &--header {
      background: #F8F8F8;
      border-radius: 6px;
    }

    &--sum {
      font-weight: bold;

      .showPopupIngredients__table > div {
        border-top-width: 3px;
      }
    }

    p {
      margin-bottom: 0;
      padding: 16px;
      font-size: 14px;
      line-height: 140%;
      font-family: 'Lidl Font'; font-weight: bold;
      border: 1px solid #EEEEEE;
    }
  }

  h3 {
    font-size: 40px;
    line-height: 140%;
    font-family: 'Lidl Font'; font-weight: bold;
    margin-bottom: 32px;
  }

  .input-select {
    background-color: white;
    border: 1px solid;
  }

  .popup__content {
    margin-top: -389px;
    text-align: left;
  }

  &__popup {
    width: 100% !important;
    max-height: initial;
  }

  &__table {
    width: 14%;
    &--left {
      width: 30%;
    }
    > div {
      border: 1px solid #EEEEEE;
      padding: 16px;
    }
  }
}


div.showPopupZeroWasteScore__box--score {
  font-size: 16px;
  grid-template-columns: 1fr !important;
  &--item-container {
    display: flex;
  }
  &--item {
    flex: 1;
  }
}

.showPopupHealthScore,
.showPopupZeroWasteScore {
  width: 100%;

  &__button {
    margin-top: 40px;
  }

  &__box {
    display: grid;
    grid-gap: 2px;
    background-color: #F8F8F8;
    padding: 2px;
    text-align: left;

    > div {
      background-color: rgba(255, 255, 255, 0.8);
      text-align: center;
    }

    &--sum {
      font-weight: bold;
      font-size: 30px;
      grid-template-columns: 2fr 1fr 2fr 1fr;
    }

    &--score {
      font-size: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    &--details {
      font-size: 14px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    .showPopupHealthScore__item--gradeValue,
    .showPopupZeroWasteScore__item--gradeValue {
      color: #1e7e34;
      font-size: 40px;
    }
  }

  p {
    margin: 0;
    padding: 2px;
  }

  h3 {
    font-size: 36px;
    line-height: 140%;
    font-family: 'Lidl Font'; font-weight: bold;
    margin-top: 20px;
  }

  h4 {
    font-size: 24px;
    line-height: 140%;
    font-family: 'Lidl Font'; font-weight: bold;
    margin-top: 20px;
  }

  .popup__content {
    margin-top: -389px;
    text-align: left;
  }

  &__popup {
    width: 100% !important;
    max-height: initial;
  }
}


.calculate-nutritional-button {
  margin-top: 0px;
  width: 324px;
  @media (min-width: 1400px){
    width: 352px;
  }

}

.calculate-health-score-button,
.calculate-zero-waste-score-button {
  margin-top: 0px;
  width: 324px;
  @media (min-width: 1400px){
    width: 352px;
  }

}

.form-control {
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid black;
  text-align: center;
  padding: 0 5px;
  height: 40px;
  border-radius: 0;
  color: black;

  &:focus, &:active {
    outline: none;
    border-color: black;
  }
}

.input-label, .col-form-label {
  font-weight: bold;
}

.input-text {
  @extend .form-control;
  text-transform: none;
  background-color: white;
  text-align: left;
  padding: 0;

  &:focus {
    background-color: white;
  }
  &.error {
    border-color: $red;
    color: $red;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $red;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $red;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $red;
    }
  }
}

.selectParent {
  position: relative;

  &--arrow {
    &:after {
      content: '';
      position: absolute;
      width: 12px;
      height: 7px;
      background: url('/layouts/frontend/img/svg/selectArrow.svg');
      right: 25px;
      bottom: 14px;
    }
  }
}

.input-textarea {
  @extend .form-control;
  text-transform: none;
  background-color: transparent;
  text-align: left;
  padding: 0;

  &:focus {
    background-color: $dirty_white;
  }
  height: auto;
}

.input-text-select {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
}

.input-select {
  background-color: $dirty_white;
  border: none;
}

.input-tag {
  border: 1px solid;
  padding: 5px 15px 5px 15px;
  background-color: $dirty_white;
}
.checkbox-ios-wrapper {
  input[type=checkbox],
  input[type=radio] {
    position: absolute;
    visibility: hidden;

    ~ label {
      margin-left: 15px;
      padding-left: 52px;
      position: relative;
      cursor: pointer;
      line-height: 26px;

      i {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        margin-right: .5rem;
        width: 42px;
        height: 24px;
        background-color: $lidlPrimaryColor;
        border-radius: 23px;
        vertical-align: text-bottom;
        transition: background 0.3s linear;

        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 18px;
          height: 18px;
          background-color: $lidlPrimaryColor;
          border-radius: 11px;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
          transform: translate3d(4px, 3px, 0);
          transition: all 0.2s ease-in-out;
        }

        &:before {
          content: "";
          position: absolute;
          left: 0;
          width: 40px;
          height: 22px;
          background-color: #fff;
          border-radius: 11px;
          transform: translate3d(1px, 1px, 0) scale3d(1, 1, 1);
          transition: all 0.25s linear;
        }
      }
    }

    &:checked ~ label {
      i {
        background: $lidlPrimaryColor;

        &:before {
          transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
        }

        &:after {
          transform: translate3d(21px, 3px, 0);
          background: #FFF;
        }
      }

    }

    //&:disabled ~ label {
    //  color: #8c8c8c;
    //
    //  &:before {
    //    border-color: #8c8c8c;
    //  }
    //
    //  &:after {
    //    color: #8c8c8c;
    //  }
    //}
  }
}

.checkbox-wrapper {
  input[type=checkbox],
  input[type=radio] {
    position: absolute;
    visibility: hidden;

    ~ label {
      margin-left: 15px;
      padding-left: 30px;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        top: 0px;
        left: 0;
        height: 20px;
        width: 20px;
        position: absolute;
        background-color: white;
        border: 1px solid black;
      }
      &:after {
        content: '✓';
        text-align: center;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        font-weight: 800;

        @include transition(0.2s transform ease-in-out);
        transform: scale(0);
      }
    }
    &:checked ~ label {
      &:after {
        transform: scale(1);
      }
    }
    &:disabled ~ label {
      color: #8c8c8c;

      &:before {
        border-color: #8c8c8c;
      }

      &:after {
        color: #8c8c8c;
      }
    }
  }

  input[type=radio] {
    ~ label {
      &:before {
        border-radius: 50%;
      }
    }
  }

}

.alert {
  border-radius: 0;
}

.alert-danger {
  color: $red;
}

.has-danger {
  .form-control-feedback {
    color: $red;
    font-size: 0.86rem;
  }
}

.form-control-danger {
  color: $red;
}

.js-maxlength {
  color: $gray_140;
  text-align: right;
  &.js-maxlength--error {
    color: $red;
  }
}

.radiobuttons {
  &.error {
    label:after {
      background-color: $red;
    }
  }
}

.radiobuttons__input {
  display: none;
}

.radiobuttons__label {
  color: $gray_140;
  cursor: pointer;
  display: inline-block;
  font-size: 0.67em;
  line-height: 1em;
  margin-top: 30px;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &:after {
    background-color: $gray_140;
    content: '';
    display: block;
    height: 20px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    top: -30px;
    width: 20px;
  }
}

.radiobuttons__label--sex {
  @extend .radiobuttons__label;
  margin-right: 10px;
  width: 30px;

  &:after {
    height: 30px;
    width: 30px;
    top: -40px
  }
}

.radiobuttons__label--meal {
  @extend .radiobuttons__label;
  width: 78px;
}

.radiobuttons__label--double {
  @extend .radiobuttons__label;
  top: 12px;
}

.radiobuttons__label--numbers {
  @extend .radiobuttons__label;
  margin-right: 10px;
  line-height: 3em;
  margin-top: 0;
  width: 60px;
  font-size: 1em;
  &:after {
    top: 0;
    width: 40px;
    height: 40px;
  }
}

.radiobuttons__label-text {
  color: white;
  display: block;
  line-height: 40px;
  position: relative;
  z-index: 1;
}

.radiobuttons__input:checked {
  + .radiobuttons__label {
    color: black;
    &:after {
      background-color: black;
    }
  }
}

.radiobuttons-title {
  color: $gray_140;
  font-size: 0.67em;
  text-transform: uppercase;
}

.validate-error {
  font-size: 0.67em;
  text-transform: uppercase;
  color: $red;
  letter-spacing: 1px;
  margin-top: 0.5em;
}

.profile__radiobuttons--input {
  display: none;

  &:not(:checked) + .profile__radiobuttons--label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &:checked + .profile__radiobuttons--label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.profile__radiobuttons--label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;

  &.error {
    color: $red;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid black;
    border-radius: 100%;
    background: white;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    background: black;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
}
