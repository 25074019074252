.shoplist-landing-main {
  &.section2 {

    &.bgImageActive {
      .shoplist-section2__icon.shoplist-section2__icon {

        &--photo1 {
          span {
            background-image: url("/layouts/frontend/img/svg/shoplist/completely-free-icon2.svg");
          }
        }
        &--photo2 {
          span {
            background-image: url("/layouts/frontend/img/svg/shoplist/surface-icon.svg");
          }
        }

        &--photo3 {
          span {
            background-image: url("/layouts/frontend/img/svg/shoplist/share-icon.svg");
          }
        }

        &--photo4 {
          span {
            background-image: url("/layouts/frontend/img/svg/shoplist/category-icon.svg");
          }
        }

      }
    }

    .shoplist-section2 {

      &__header {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 40px;

        h3 {
          font-weight: 600;
          font-size: 34px;
          line-height: 120%;
          color: #353B42;

          @include media-breakpoint-down(sm) {
            font-size: 32px;
          }
        }
      }

      &__box {
        background: #0050AA;
        padding: 80px 50px;

        @include media-breakpoint-down(sm) {
          padding: 56px 0;
        }
      }

      &__icons-box {
        display: flex;

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          flex-direction: column;
        }
      }

      &__icon {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;

        &:not(:last-child) {
          @include media-breakpoint-down(sm) {
            margin-bottom: 56px;
          }
        }

        &--photo1 {
          span {
            width: 48px;
            height: 48px;
          }
        }

        &--photo2 {
          span {
            width: 30px;
            height: 48px;
          }
        }

        &--photo3 {
          span {
            width: 50px;
            height: 48px;
          }
        }

        &--photo4 {
          span {
            width: 46px;
            height: 48px;
          }
        }

        span {
          background-size: contain;
          margin-bottom: 32px;

          @include media-breakpoint-down(sm) {
            margin-bottom: 24px;
          }
        }

        p {
          color: white;
          font-weight: 600;
          font-size: 26px;
          line-height: 120%;
          text-align: center;

          @include media-breakpoint-down(sm) {
            font-size: 24px;
          }
        }
      }

      &__bottomText {
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include media-breakpoint-down(sm) {
          padding: 56px 0;
        }

        h4 {
          margin-bottom: 40px;
          font-weight: 600;
          font-size: 34px;
          line-height: 120%;
          color: #353B42;

          @include media-breakpoint-down(sm) {
            text-align: center;
            font-size: 32px;
            margin-bottom: 24px;
          }
        }
      }

      &__desc {
        p {
          margin-bottom: 0;
          font-size: 22px;
          line-height: 140%;
          color: #353B42;

          @include media-breakpoint-down(sm) {
            text-align: center;
            font-size: 20px;
          }

          &:first-child {
            @include media-breakpoint-down(sm) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}
