.christmasMenuLanding {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: -70px;
  min-height: 500px;
  background: #FAFAFA;
  overflow: hidden;


  @include media-breakpoint-down(sm) {
    padding-top: 70px;
    overflow: hidden;
  }

  &:after {
    content: '';
    width: 200px;
    height: 200px;
    position: absolute;
    background-image: url('/layouts/frontend/img/christmas-landing/cone.png');
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    right: 50%;
    margin-right: -680px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      right: 0;
      bottom: -20px;
    }
  }

  //&:before {
  //  content: '';
  //  position: absolute;
  //  right: 50%;
  //  margin-right: -740px;
  //  top: 130px;
  //  width: 386px;
  //  height: 212px;
  //  background: url('/layouts/frontend/img/christmas-landing/section20/glitter.png');
  //  z-index: 12;
  //
  //  @include media-breakpoint-down(sm) {
  //    right: -200px;
  //    margin-right: 0;
  //  }
  //}


  &__header {
    h1 {
      position: relative;
      z-index: 12;
      font-family: 'Norican', sans-serif;
      font-size: 56px;
      color: #FFFFFF;
      height: 160px;
      padding-top: 50px;
      display: flex;
      align-items: center;

      @media (max-width: 780px) {
        font-size: 44px;
      }

      @include media-breakpoint-down(xs) {
        //bg change
        font-size: 32px;
        max-width: 270px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: -60px;
      background: url('/layouts/frontend/img/christmas-landing/menu-landing/menu_header.png');
      width: 776px;
      height: 270px;
      z-index: 11;

      @include media-breakpoint-down(xs) {
        left: 0;
        top: 40px;
        width: 314px;
        height: 139px;
        background: url('/layouts/frontend/img/christmas-landing/menu-landing/menu_header_mobile.png');
      }
    }
  }

  &__recipe {
    position: relative;
    z-index: 12;
    border-style: solid;
    border-width: 8px;
    margin-bottom: 50px;
    //border-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg") 50 round;
    border-color: #CC9E3C;
    background: #FFF;

    @include media-breakpoint-down(xs) {
      margin-bottom: 25px;
    }

    //&:before,
    //&:after {
    //  content: '';
    //  position: absolute;
    //  top: -90px;
    //  left: 50%;
    //  margin-left: -880px;
    //  width: 275px;
    //  height: 500px;
    //  background-size: 275px 500px;
    //  background-image: url(/layouts/frontend/img/christmas-landing/glitter_vertical.png);
    //  transform: rotate(180deg);
    //
    //  @include media-breakpoint-down(sm) {
    //    display: none;
    //  }
    //}
    //
    //&:after {
    //  transform: none;
    //  left: auto;
    //  right: 50%;
    //  margin-right: -880px;
    //  top: auto;
    //  bottom: 150px;
    //}
    //
    //&:first-child {
    //  &:before {
    //    top: 100px;
    //  }
    //}

    .vue-recipe {
      overflow: hidden;

      .recipe__header {
        //font-family: 'Norican', sans-serif;
      }

      .recipe__type {
        position: relative;
        width: 205px;
        height: 46px;
        font-size: 18px;
        line-height: 46px;
        font-weight: 600;
        box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.25);
        background: #CC9E3C;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -46px;
          width: 0;
          height: 0;
          border: 23px solid transparent;
          border-left-color: #CC9E3C;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.135em;

        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
          position: absolute;
          left: 15px;
          top: 20px;
          z-index: 11;

          height: 51px;
          background-size: contain;
          width: 180px;
        }
      }

      .recipe-edit-link,
      .recipe__cookingMode {
        display: none;
      }

      .bg-light-grey {
        background: rgba(#EEDFC0, 0.2) !important;
        padding: 0 20px 30px;

        @include media-breakpoint-down(xs) {
          padding: 20px 5px;
          margin-top: 10px;

          .preparation {
            margin-top: 20px;
          }
        }
      }

      .recipe__firstSection {
        padding: 0 35px;

        @include media-breakpoint-down(xs) {
          padding: 15px 15px 0;
        }
      }

      .member {
        position: relative;

        .member__portions {
          z-index: 4;
          border-color: transparent;
          border-width: 3px;
        }

        &:after {
          position: absolute;
          height: 46px;
          width: 156px;
          top: -3px;
          right: -3px;
          left: -3px;
          bottom: -3px;
          content: '';
          border-radius: 100px;
          background: rgb(223, 183, 75);
          background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
          z-index: 3;
        }
      }

      .preparation__step {
        &-content-text {
          a {
            color: inherit;
          }
        }
      }

      .addToShoppingButton {

        .add-to-shopping-list {
          background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
          color: #111E07;
          font-size: 16px;
          text-decoration: none;
          transition: 100ms background, 100ms box-shadow;

          svg *,
          svg path {
            fill: #111E07 !important;
          }

          &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
            box-shadow: 0px 0px 25px #F6E26A, inset 0px 0px 12px rgba(255, 246, 31, 0.5);
          }
        }

        @media (max-width: 639px) {
          position: static;
          padding: 10px 0;
        }
      }
    }
  }
}