.planner_v2_topBanner {
  .header {
    background: none;
    box-shadow: none;
  }
}

@include media-breakpoint-down(md) {
  .page__cookbook__search .cookbook--withoutBreadcrumb {
    padding-top: 0 !important;
  }

  .page__shoplist__index .planner_v2_topBanner .page-top-margin,
  .page__shoplist__view .planner_v2_topBanner .page-top-margin,
  .page__planner__index .planner_v2_topBanner .top-margin {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .page__plan-landings__redirect .planner_v2_topBanner .breadcrumb__list,
  .page__cookbook__search .planner_v2_topBanner .breadcrumb__list,
  .page__tags__search .planner_v2_topBanner .breadcrumb__list,
  .page__recipe__search .planner_v2_topBanner .breadcrumb__list {
    padding-top: 0;
  }
  .page__plan-landings__redirect .planner_v2_topBanner .breadcrumb__list,
  .page__cookbook__search .planner_v2_topBanner .planner-v2-top-banner,
  .page__tags__search .planner_v2_topBanner .planner-v2-top-banner,
  .page__recipe__search .planner_v2_topBanner .planner-v2-top-banner {
    margin-top: 60px;
  }
  .planner_v2_topBanner {
    .breadcrumb__list {
      padding-top: 10px;
    }
    .breadcrumb__list:after {
      top: 5px;
    }
    .planLandings--first {
      padding-top: 30px;
    }
  }

  .page__index__index .planner_v2_topBanner {
    .planner-v2-top-banner {
      margin-top: 0;
      position: absolute;
      left: 0;
      top: 105px;
      right: 0;
      height: 62px;
      z-index: 9;
    }
  }
  .homepage-lidl__banner {
    margin-top: 62px !important;
  }

}



.planner-v2-top-banner {
  background: #348553;

  @media (max-width: 640px) {
    margin-top: 105px;
  }

  .container {
    position: relative;
    height: 46px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 30px;

    @include media-breakpoint-down(md) {
      display: grid;
      grid-template-columns: 2fr 1fr;
      height: auto;
    }

    p {
      display: inline-block;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 133.333% */

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }

      @include media-breakpoint-up(md) {
        position: relative;
        top: 2px;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
    }

    .btn-white {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 116.667% */
      text-transform: uppercase;
      text-align: center;

      color: #348553;
      background: #ffffff;
      border: 1px solid #ffffff;

      @include media-breakpoint-up(md) {
        margin-left: 14px;
      }

      @include media-breakpoint-down(md) {
        margin-top: 8px;
        padding: 8px;
        height: 32px;
      }

      &:hover {
        color: #ffffff;
        background: #348553;
      }
    }
  }

  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    @include media-breakpoint-down(md) {
      font-size: 16px;
    }
  }

  .close {
    z-index: 11; /* above hp slider */
    position: absolute;
    right: 0;
    top: 12px;
    color: #ffffff;
    cursor: pointer;

    height: 24px;
    width: 24px;

    @include media-breakpoint-down(md) {
      position: absolute;
      left: auto;
      top: 18px;
      right: 4px;
      width: 24px;
      height: 24px;
    }
  }

  + .page-top-margin {
    @media (max-width: 639px) {
      margin-top: 15px;
    }
  }
}

