.euro2024 {
  .header {
    background: none;
    box-shadow: none;
  }
  .top-margin {
    @media (max-width: 639px) {
      margin-top: 15px;
    }
  }
}

@include media-breakpoint-down(md) {
  .page__cookbook__search .euro2024 .breadcrumb__list,
  .page__tags__search .euro2024 .breadcrumb__list,
  .page__recipe__search .euro2024 .breadcrumb__list {
    padding-top: 0;
  }
  .page__cookbook__search .euro2024 .euro2024TopBar,
  .page__tags__search .euro2024 .euro2024TopBar,
  .page__recipe__search .euro2024 .euro2024TopBar {
    margin-top: 60px;
  }
  .euro2024 {
    .breadcrumb__list {
      padding-top: 10px;
    }
    .breadcrumb__list:after {
      top: 5px;
    }
  }
}

.euro2024TopBar {
  background: url('/layouts/frontend/img/euro-2024/bar-bg-desktop.png') repeat-x;
  background-size: cover;
  background-position: center;

  @media (max-width: 640px) {
    background: url('/layouts/frontend/img/euro-2024/bar-bg-mobile.png') repeat-x;
    margin-top: 105px;
  }

  .container {
    position: relative;
    height: 37px;
    display: flex;

    &:hover {
      text-decoration: none;
      color: #FFF;
    }

    @include media-breakpoint-down(md) {
      height: 32px;
    }
  }

  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    background-color: #0050A1;

    span {
      font-size: 23px;
      font-style: italic;
      padding: 0 14px;
    }

    a {
      background: #ffffff;
      color: #0050AA;
      border-radius: 4px;
      font-size: 12px;
      line-height: 14px;
      text-decoration: none;
      padding: 5px 8px;
    }

    @media (max-width: 639px) {
      background: transparent !important;
      padding-left: 60px;
      font-size: 14px;
    }
  }

  span.close {
    cursor: pointer;
  }

  .tagLink,
  .tagMobileLink {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 3px;

    @include media-breakpoint-down(md) {
      position: absolute;
      right: 12px;
    }
  }

  .tagLink {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .tagMobileLink {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      top: 6px;
      font-size: 12px;
    }
  }

  .close {
    z-index: 11; /* above hp slider */
    position: absolute;
    right: 15px;
    top: 3px;

    height: 24px;
    width: 24px;

    @include media-breakpoint-down(md) {
      top: 1px;
      right: 10px;
    }
  }

  + .page-top-margin {
    @media (max-width: 639px) {
      margin-top: 15px;
    }
  }

  + .top-margin {
    @media (max-width: 639px) {
      margin-top: 15px;
    }
  }
}

@include media-breakpoint-down(md) {
  .page__index__index .euro2024 {
    .euro2024TopBar {
      margin-top: 0;
      position: absolute;
      left: 0;
      top: 242px;
      right: 0;
      height: 32px;
      z-index: 9;
    }
  }
  .homepage-lidl__banner {
    margin-top: 32px;
  }
}

@media (max-width: 639px) {
  .page__index__index .euro2024 {
    .euro2024TopBar {
      top: 105px;
    }
  }
}
