.summary-box__avatar {

  #fakeFileInput, .avatar__add-avatar {
    position: absolute;
    left: 99px;
    text-decoration: underline;
    cursor: pointer;
  }
  #fakeFileInput {
    top: 40px;
  }
  .avatar__add-avatar {
    top: 15px;
  }

  .imgHolder {
    width: 70px;
    line-height: 70px;
    height: 70px;
    border: 2px solid black;
    border-radius: 50%;

    .avatar__preview {
      height: 100%;
      width: 100%;
      background-size: cover;
      border-radius: 50%;
    }
  }

  input[type=file] {
    display: none;
  }

  &.error {
    .imgHolder {
      border-color: $red;
    }
    .avatar__add-avatar,
    #fakeFileInput {
      color: $red;
    }
  }

  .progress-bar {
    margin-top: 10px;
    margin-left: 15px;
    max-width: 85px;
  }
}

.sex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.sex {
  display: inline-block;
  margin-top: 15px;
  position: relative;
  margin-right: 1.5em;
}

.sex__name {
  margin-top: 1em;
  font-size: 0.8em;
}

.sex__image {
  opacity: 0.3;
  cursor: pointer;
  -webkit-filter: #{ "brightness(0)" };
  filter: #{ "brightness(0)" };

  &.selected, &:hover {
    opacity: 1;
    filter: unset;
  }
}