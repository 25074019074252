.sallys-landing {

  &__section6 {
    padding: 56px 0;

    @include media-breakpoint-down(sm) {
      padding: 32px 0;
    }

    &.bgImageActive {
      .sallys-landing__section6--photo {
        &--one {
          background-image: url("/layouts/frontend/img/sally-landing/section6-photo1.png");
        }
        &--two {
          background-image: url("/layouts/frontend/img/sally-landing/section6-photo2.png");
        }

        &--three {
          background-image: url("/layouts/frontend/img/sally-landing/section6-photo3.png");
        }

        &--four {
          background-image: url("/layouts/frontend/img/sally-landing/section6-photo4.png");
        }
      }

      .single-item {

        &__name {
          @media (max-width: 1400px) {
            font-size: 1rem;
          }
        }

        span {
          background-image: url("/layouts/frontend/img/sally-landing/sally-tip.png");
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
    }

    &__box {
      p {
        max-width: 75%;
        margin-bottom: 32px;
        @include media-breakpoint-down(xs) {
          max-width: 100%;
        }
      }
    }

    &__button-box {
      display: flex;
      justify-content: center;

      a {
        width: 212px;

        @include media-breakpoint-down(xs) {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    &--photo {
      width: 100%;
      height: 250px;
      background-size: cover;
      background-position: center;
    }

    .single-item {
      position: relative;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);

      @include media-breakpoint-down(xs) {
        margin-bottom: 24px;
      }

      span {
        background-repeat: no-repeat;
        background-size: contain;
        width: 128px;
        height: 48px;
        position: absolute;
        right: 0;
        top: 24px;
      }
      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }
      @include media-breakpoint-down(xs) {
        max-width: 250px;
        min-width: 250px;
      }
    }


    &__scroll {
      width: 100%;

      @include media-breakpoint-down(sm) {
        width: 100vw;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        overflow-x: scroll;
        overflow-y: hidden;
        transform: translateZ(0);
        will-change: transform;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;

        margin-bottom: -16px;
        padding-bottom: 16px;

        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
      }
    }

    &__tips {
      display: flex;

      .homepageBanner {
        &__scroll {
          width: 100vw;
          margin-left: -20px;
          margin-right: -20px;
          padding-left: 20px;
          padding-right: 20px;

          overflow-x: scroll;
          overflow-y: hidden;
          transform: translateZ(0);
          will-change: transform;
          -ms-overflow-style: -ms-autohiding-scrollbar;
          -webkit-overflow-scrolling: touch;

          margin-bottom: -16px;
          padding-bottom: 16px;

          margin-left: -20px;
          margin-right: -20px;
          padding-left: 20px;
          padding-right: 20px;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      &__box {
        border: 1px solid;
        min-width: 230px;
      }

    }
  }
}