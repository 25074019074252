.promoLanding {
  width: 100%;

  .row {
    position: relative;
  }

  .arrow-left,
  .arrow-right {
    width: 50px;
    height: 50px;
    position: absolute;
    background-color: $lidlPrimaryColor;
    background-image: url(/layouts/frontend/img/homepage_de/slider_arrow_light.svg);
    background-position: center;
    background-repeat: no-repeat;
    z-index: 10;
    transition: 0.5s all;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s all;
  }

  .arrow-left {
    left: -25px;
    top: 50%;
  }

  .arrow-right {
    right: -25px;
    top: 60%;
    transform: rotate(-180deg) translate(0, 50%);
  }

  &__header {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
    font-family: 'Lidl Font'; font-weight: bold;

    @media (max-width: 599px) {
      font-size: 24px;
      line-height: 120%;
    }
  }

  p {
    font-size: 24px;
    line-height: 150%;
    color: #30303B;
    @media (max-width: 599px) {
      font-size: 16px;
      line-height: 156%;
      color: #212121;
    }
  }

  .flex {
    display: flex;
  }

  &__lawInfo {
    margin-top: 24px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 8px;

    span {
      font-size: 12px;
      line-height: 14px;
      color: #4E5761;
      font-weight: normal;
    }
  }

  &__banner {
    position: relative;
    margin-bottom: 72px;

    @media (max-width: 599px) {
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      @media (max-width: 599px) {
        display: none;
      }
    }

    p {
      font-family: 'Lidl Font'; font-weight: bold;
      font-style: normal;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      font-weight: bold;
      font-size: 48px;
      line-height: 140%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 2px 10px rgba(0, 0, 0, 0.3);

      @media (max-width: 1000px) {
        font-size: 32px;
        line-height: 38px;
      }
      @media (max-width: 470px) {
        white-space: normal;
        min-width: 236px;
        font-size: 29px;
        text-align: center;
      }
    }

    .mobile__banner {
      display: none;
      @media (max-width: 599px) {
        display: block;
      }
    }
  }

  &__mobile-photo {
    display: none;
    @media (max-width: 1000px) {
      display: block;
      margin-top: 24px;
    }

    @media (max-width: 350px) {
      max-width: 320px;
    }
  }

  &__mobile-button {
    display: none;
    @media (max-width: 1000px) {
      display: block;
    }
  }

  &__desktop-photo {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__recipe-of-the-week {
    background: #F1F2F3;
    padding: 89px 200px;

    @media (max-width: 1000px) {
      padding: 80px 23px 30px;
    }

    .box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
      }

      @media (max-width: 430px) {
        align-items: center;
      }
    }

    &__data {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 115px;
      justify-content: center;

      @media (max-width: 1150px) {
        margin-left: 40px;
      }

      @media (max-width: 1000px) {
        margin-left: 0;
      }

      ul {
        padding-left: 0;

        li {
          margin-bottom: 16px;
          white-space: nowrap;
          font-size: 18px;
          line-height: 130%;
          list-style: none;

          &::before {
            float: left;
            content: "\2022";
            color: $lidlPrimaryColor;;
            font-weight: bold;
            display: inline-block;
            margin-right: 10px;
          }

          @media (max-width: 1150px) {
            white-space: normal;
          }

          &:last-child {
            font-weight: 600;
          }

          @media (max-width: 1000px) {
            margin-bottom: 8px;
          }
        }
      }
    }

    &__header {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 120%;
      color: #212121;
      white-space: nowrap;
      margin-bottom: 24px;
      font-family: 'Lidl Font'; font-weight: bold;
      position: relative;

      @media (max-width: 1000px) {
        font-weight: bold;
        font-size: 24px;
        line-height: 120%;
        margin-bottom: 16px;
      }

      &:after {
        content: '';
        background-image: url("/layouts/frontend/img/landing-promo/recipe-label.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        height: 70px;
        width: 90px;
        top: -40px;

        @media (max-width: 1000px) {
          top: -60px;
          left: -8px;
        }
      }
    }
  }

  &__button {
    padding: 15px 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    background: $lidlPrimaryColor;
    border-radius: 4px;
    color: #FFFFFF;
    justify-content: center;
    display: inline-block;

    @media (max-width: 1000px) {
      margin: 0px 20px 24px 0;
    }
  }

  .plan-of-week {
    padding: 100px 0 100px 0;

    .button-lidl__blue {
      width: auto;
      padding: 0 20px;
    }

    @media (max-width: 1000px) {
      padding-top: 35px;
      padding-bottom: 35px;
    }

    &__box {
      margin-right: 16px;
      margin-top: 46px;

      min-width: 250px;
      margin-right: 0;
      margin-left: 5px;

      &--space {
        min-width: 50px;
      }
    }

    &__days {
      background: #EEEEEE;
      border-radius: 6px;
      display: inline-block;
      padding: 15px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 17px;
      line-height: 24px;
      text-transform: uppercase;
      color: #212121;
      white-space: nowrap;
    }

    .slick-arrow {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      margin-top: 40px;
      right: 0;
      background-color: $lidlPrimaryColor;
      background-image: url(/layouts/frontend/img/homepage_de/slider_arrow_light.svg);
      background-position: center;
      z-index: 10;
      transition: 0.5s all;
    }


    .single-item__image {
      border-radius: 0;
      height: 140px;
    }

    .single-item__name {
      overflow: auto;
    }

    &__desc {
      justify-content: space-between;
      align-items: center;

      h3 {
        @media (max-width: 1000px) {
          margin-bottom: 16px;
        }
      }

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
      }

      #createShoplist {
        margin-right: 0;
      }
    }

    .sliderOverflow {
      overflow: hidden;
      position: relative;

      .arrow-left {
        left: 0;
        opacity: 0;
        visibility: hidden;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      .arrow-right {
        right: 0;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      &.scrolled {
        .arrow-left {
          opacity: 1;
          visibility: visible;
        }
      }

      &.scrolledToEnd {
        .arrow-right {
          opacity: 0;
          visibility: hidden;
        }
      }

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: '';
        width: 145px;
        height: 100%;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 9;
        transition: 0.5s all;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 145px;
        height: 100%;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
        z-index: 9;
        transition: 0.5s all;
        opacity: 0;
        visibility: hidden;

        @media (max-width: 1000px) {
          display: none;
        }
      }

      &.scrolled {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }

      &.scrolledToEnd {
        //&:after {
        //  opacity: 0;
        //  visibility: hidden;
        //}
      }
    }

    .slider {
      overflow-x: scroll;
      overflow-y: hidden;
      transform: translateZ(0);
      will-change: transform;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      margin-bottom: -16px;
      padding-bottom: 16px;
    }

    &__photo {
      margin-top: 20px;
    }

    &__button {
      background: $lidlPrimaryColor;
      border-radius: 4px;
      padding: 16px 24px;

      a {
        font-family: Lidl Font;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #FFFFFF;
      }

      @media (max-width: 1000px) {
        margin-top: 32px;
      }
    }

    &__meal {
      width: 100%;
      height: 240px;
      border: 2px solid #F2F2F2;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        padding: 10px 10px 0 10px;
      }
    }

    &__recipe {
      &-desc {
        border: 1px solid #F2F2F2;
        box-sizing: border-box;

        span {
          font-size: 17px;
        }
      }
    }
  }

  .best-deal {
    position: relative;
    @media (max-width: 1000px) {
      flex-direction: column-reverse;
    }

    .arrow-right {
      top: 55%;
    }

    .flex {
      .photo {
        img {
          width: 100%;
          max-height: 401px;

          @media (max-width: 1000px) {
            width: 110%;
            min-height: 268px;
            max-height: initial;
          }
        }
      }
    }

    .slick-slide {
      margin-right: 100px;
    }


    .best-deal-slider-container {
      overflow: hidden;
      padding-top: 50px;

      @media (max-width: 1000px) {
        padding-top: 20px;
      }

      .arrow-left,
      .arrow-right {
        @media (max-width: 1000px) {
          display: none;
        }
      }

      .arrow-left {
        opacity: 0;
        visibility: hidden;
      }

      &.scrolled {
        .arrow-left {
          opacity: 1;
          visibility: visible;
        }
      }

      &.scrolledToEnd {
        .arrow-right {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .best-deal-slider {
      overflow-x: scroll;
      overflow-y: hidden;
      transform: translateZ(0);
      will-change: transform;
      -ms-overflow-style: -ms-autohiding-scrollbar;
      -webkit-overflow-scrolling: touch;
      margin-bottom: -16px;
      padding-bottom: 16px;
      min-height: 450px;

      @media (max-width: 1000px) {
        //display: block;
      }
    }

    .photo-container {
      min-width: 730px;

      border-right: 1px solid rgba(#000000, 0.05);
      margin-right: 20px;

      @media (max-width: 1000px) {
        border-right: 0;
        margin-right: 30px;
        min-width: 260px;
        margin-bottom: 20px;
      }
      @media (max-width: 350px) {
        margin-right: 15px;
      }

      &:first-child {
        @media (max-width: 1000px) {
          margin-left: 30px;
          margin-left: calc((100% - 260px) / 2);
        }
      }

      &:last-child {
        @media (max-width: 1000px) {
          margin-right: 30px;
          margin-right: calc((100% - 260px) / 2);
        }
      }

      .photo {
        width: 448px !important;
        position: relative;
        display: flex !important;
        align-items: flex-start;

        @media (max-width: 1000px) {
          flex-direction: column;
          width: auto !important;
          align-items: center;
        }

        img {

        }
      }

      .photo-last {
        width: 285px !important;
        position: relative;
        left: -38px;
        top: 16px;
        @media (max-width: 1000px) {
          display: none;
        }

        &--mobile {
          display: none;
          width: 221px !important;
          margin-top: 20px;

          @media (max-width: 1000px) {
            display: flex;
          }
        }
      }
    }

    .photo-container--space {
      @media (max-width: 1000px) {
        height: 1px;
        min-width: 30px;
        //min-width: calc((100% - 260px) / 4);
      }
      @media (max-width: 350px) {
        min-width: 15px;
      }
    }

    .slick-arrow {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 50%;
      margin-top: 40px;
      right: 0;
      background-color: $lidlPrimaryColor;
      background-image: url(/layouts/frontend/img/homepage_de/slider_arrow_light.svg);
      background-position: center;
      z-index: 10;
      transition: 0.5s all;
    }

    &__button {
      margin: 0 0 0 25px;
      width: 400px;

      @media (max-width: 1000px) {
        display: none;
      }

      &--mobile {
        display: none;
        margin: 0 0 0 17px;
        width: 300px;

        @media (max-width: 1000px) {
          display: flex;
          margin: 0;
          width: 100%;
        }
      }
    }

    .slick-prev,
    .slick-next {
      top: 50%;
    }

    .slick-prev {
      left: -65px;
    }

    .slick-next {
      right: -70px;
    }

    &-italiamo {
      background: #F5F8FC;
      padding: 30px 0;

      &__box {
        justify-content: space-between;

        @media (max-width: 1000px) {
          flex-direction: column-reverse;
        }

        &__head {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }

      &__photo {
        width: 396px;
        margin-left: 100px;

        @media (max-width: 1200px) {
          margin-left: 0;
        }
        @media (max-width: 1000px) {
          display: block;
          width: 300px;
          margin: 0 auto 20px;
        }
      }

      //&:after {
      //  position: absolute;
      //  top: 90px;
      //  right: 0;
      //  content: '';
      //  width: 80px;
      //  width: 232px;
      //  height: calc(100% - 90px);
      //  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      //  z-index: 9;
      //  transition: 0.5s all;
      //}
    }

    &-angebote {
      padding: 120px 0;

      @media (max-width: 1000px) {
        padding: 30px 0;
      }

      //&:after {
      //  position: absolute;
      //  top: 90px;
      //  right: 0;
      //  content: '';
      //  width: 80px;
      //  width: 232px;
      //  height: calc(100% - 90px);
      //  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      //  z-index: 9;
      //  transition: 0.5s all;
      //}
    }
  }

  .feeds {
    background: #F1F2F3;
    padding: 120px 0 80px 0;
    margin-bottom: -70px;

    @media (max-width: 1000px) {
      padding: 40px 0 20px;

      > .container {
        overflow: hidden;
      }

      .feedElement__header {
        padding-top: 35px;

        &__text {
          font-size: 18px;
        }
      }

    }

    .feedElement__moreLink {
      background: #FFF;
    }

    .single-item {
      background: #FFF;
      border-radius: 4px;

      &__name {
        border: 0;
      }
    }

    &__box {
      padding-bottom: 80px;
      border-bottom: 1px solid #E3E4E5;

      @media (max-width: 1000px) {
        padding-bottom: 30px;
      }
    }

    .feedElement:after {
      background: linear-gradient(270deg, #F1F2F3 0%, rgba(255, 255, 255, 0) 100%);
    }

    .feedElement:before {
      background: linear-gradient(90deg, #F1F2F3 0%, rgba(255, 255, 255, 0) 100%);
    }

    .ingredientPromotion__element {
      background: white;
      border: 2px solid white;
      border-top-color: #113770;
    }

    .ingredientPromotion__content {
      position: relative;
      top: 95px;
    }

    .ingredientPromotion__photo {
      max-width: 75%;
      margin-top: 10px;
    }

    .ingredientPromotion__name {
      margin-top: 24px;
      position: relative;
      top: 10px;
    }

    .ingredientPromotion__data-box {
      display: none;
    }

    .ingredientPromotion__priceInfo {
      display: none;
    }

    .ingredientPromotion__ml {
      max-width: 110px;
    }

    .ingredientPromotion__element--ingredient107 {
      .ingredientPromotion__content {
        top: 122px;
      }

      .ingredientPromotion__name {
        margin-top: 0;
        top: 40px;
      }
    }

    .ingredientPromotion__element--ingredient761 {
      .ingredientPromotion__name {
        margin-top: 10px;
      }
    }

    .ingredientPromotion__element--ingredient5747,
    .ingredientPromotion__element--ingredient665 {
      .ingredientPromotion__name {
        margin-top: 8px;
      }
    }

    .ingredientPromotion__element--ingredient761 {
      .ingredientPromotion__name {
        position: relative;
        top: 20px;
      }
    }
  }


  .slick-slide {
    margin: 0 10px;
  }

  .slick-slide img {
    display: block;
    width: 100%;
  }

  .slick-disabled {
    display: none !important;
  }

  .newsletter {
    background-image: url("/layouts/frontend/img/landing-promo/newsletter.png");
    padding: 105px 0;
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;

    @media (max-width: 1000px) {
      padding: 94px 0;
      background-image: url("/layouts/frontend/img/landing-promo/newsletter-mobile.png");
    }

    &__box {
      display: flex;
      margin-top: 40px;

      @media (max-width: 1000px) {
        flex-direction: column;
      }
    }

    input {
      padding: 13px 16px;
      width: 370px;
      border: 1px solid #E3E4E5;
      border-radius: 4px;

      @media (max-width: 1000px) {
        flex-direction: column;
      }

      @media (max-width: 400px) {
        width: 300px;
      }
    }

    button {
      border-radius: 4px;
      @media (max-width: 1000px) {
        margin-top: 16px;
      }
    }
  }
}





