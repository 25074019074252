.search-product-feed {
  display: block !important;

  &__headerBox {
    display: flex;
    margin-bottom: 27px;
    align-items: flex-end;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 15px;
    }

    a {
      margin-left: 25px;
      color: #0050AA !important;
      font-weight: normal;
      font-size: 18px;
      line-height: 140%;
      position: relative;

      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-top: 8px;
      }

      &:hover {
        text-decoration: none;
        text-underline: none;
      }

      &:before {
        content: '';
        display: block;
        width: 100%;
        position: absolute;
        height: 1px;
        left: 0;
        right: 0;
        bottom: 2.5px;
        background-color: #0050AA;
      }
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 0;

      @include media-breakpoint-down(xs) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    overflow: hidden;
    margin-left: -20px;
    margin-right: -30px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw;
  }


  &:after,
  &:before {
    top: 52px;
  }

  .scroll {
    margin-right: 0;
    margin-left: 0;
    @include media-breakpoint-down(xs) {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      width: 100vw;
    }
  }

  &__law {
    font-size: 12px;
    line-height: 140%;
    color: #4E5761;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  &__recipe {
    border: 2px solid #E3E4E5;
    border-radius: 4px;
    min-width: 270px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    width: 230px;
    border-top-color: #113770;
    position: relative;
    min-height: 402px;

    &:before {
      content: '';
      display: block;
      height: 2px;
      width: 100%;
      position: relative;
      top: -2px;
      background: $lidlPrimaryColor;
      border-radius: 4px;
    }

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &__action {
    padding: 9px 16px;
    background: $lidlPrimaryColor;
    position: absolute;
    right: 0;
    display: inline-block;
    color: white;
    align-self: flex-end;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    top: -5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
  }

  &__mg {
    position: absolute;
    left: 16px;
    bottom: 67px;
    font-size: 14px;
    color: #4E5761;
  }

  &__data {
    color: #E60A14;
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    padding: 4px 8px;
    border-radius: 2px;
    border: 1px solid #E60A14;
    display: inline-block;
    position: absolute;
    bottom: 12px;
    left: 16px;
  }

  &__name {
    position: absolute;
    bottom: 95px;
    min-height: 70px;
    left: 16px;
    right: 16px;
    p {
      line-height: 140%;
      &:first-child {
        font-weight: 600;
        font-size: 18px;
        color: #2F3A44;
        margin-bottom: 5px;
      }
      &:last-child {
        margin-bottom: 0;
        font-size: 14px;
        color: #2F3A44;
      }
    }
  }

  &__price-box {
    position: absolute;
    bottom: 39px;
    right: 11px;
    height: 58px;
  }

  &__price {
    padding: 7px 10px;
    text-align: center;
    box-shadow: 2px 2px 10px rgba(53, 59, 66, 0.25);

    &-info {
      color: #4E5761;
      font-size: 14px;
      line-height: 140%;
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
    }

    span {
      font-size: 22px;
      line-height: 120%;
      color: #353B42;

    }

    span, sup {
      font-weight: 600;
      font-family: 'Lidl Font Price';
      color: #353B42;
    }

    &__sup {
      top: 2px;
      left: 0px;
      font-size: 25px;
    }
  }

  &__list {
    padding: 0 16px 24px 16px;

    &__photo {
      text-align: center;
      margin-top: 10px;

      .custom-photo {
        max-height: 188px;
      }

      img {
        max-height: 200px;
        width: auto;
        max-width: initial;
      }
    }
  }

  .feedElement {

    &__scrollRight,
    &__scrollLeft {
      top: 44%;

      @include media-breakpoint-down(sm) {
        top: 50%;
      }
    }

  }

  .feedElement__recipes__recipe--lastSpace {
    width: 50px;
    @include media-breakpoint-down(xs) {
      display: block;
      width: initial;
      min-width: calc(50% - 135px);
    }
  }

  &__box {
    display: flex;
    padding-bottom: 30px;
    padding-left: 5px;

    @include media-breakpoint-down(xs) {
      padding-left: inherit;
    }

    li {
      list-style-type: none;
    }
  }
}