$gray_140: rgb(140, 140, 140);
$darkGray: $gray_140;
$gray_157: rgb(157, 157, 157);
$gray_180: rgb(180, 180, 180);
$gray_184: rgb(184, 184, 184);
$gray_229: rgb(229, 229, 229);
$gray_240: rgb(240, 240, 240);
$gray_242: rgb(242, 242, 242);
$gray_246: rgb(246, 246, 246);
$gray_225: rgb(225, 225, 225);

$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$blackAlpha: rgba(0, 0, 0, 0.8);
$dirty_white: rgb(247, 248, 249);
$lightGray: #d6d6d6;
$lightestGray: #e0e1e2;


$red: #df0000;
$blue: #1b77de;
$green: #ccde5b;
$darkGreen: #20b420;

// Navigation
$navBarBackground: rgb(255, 255, 255);
$navLinkColor: rgb(0, 0, 0);


// FOOTER
$footerBackground: rgb(255, 255, 255);
$footerLinkColor: rgb(0, 0, 0);

//Lidl
$lidlPrimaryColor: #0050AA;
$lidlPrimaryColor_08: rgba(14, 81, 124, 0.8);

$lidlYellow: rgb(251,239,53);
$lidlDarkBlue: rgb(0, 80, 170);
$lidlDarkBlue_01: rgba(27,81,125, 0.1);
$lidlVeryDarkBlue: rgb(48, 48, 59);
$lidlAzure: rgb(232, 238, 242);
$lidlGrey: rgb(255,255,255);

$borderColor: #BDBDBD;

$bannerColor: #FBCB03;

$sallysPink: #e5007e;