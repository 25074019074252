.promo-product {

  h5 {
    font-weight: 600;
    margin-top: 56px;
    margin-bottom: 24px;
    font-size: 26px;
    line-height: 120%;
    color: #353B42;

    @media (max-width: 780px) {
      margin-top: 0px;
    }
  }

  &__rightBox {
    display: flex;

    @media (max-width: 780px) {
      flex-wrap: nowrap;
      flex-direction: column;
    }

    .promo-product {

      &__name {
        font-size: 18px;
        line-height: 120%;
        font-family: 'Lidl Font';
        font-weight: 700;
        padding: 0 5px 10px 5px;
        margin-top: 0px;
        margin-bottom: 15px;
        white-space: initial;
        text-align: left;
        max-width: 90%;
        color: #353B42;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (max-width: 780px) {
          margin-bottom: 0;
          padding: 0px 16px;
          font-size: 16px;
        }

      }
      &__box {
        padding: 0;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      }

      &__desktop {
        display: block;
        @media (max-width: 780px) {
          display: none;
        }
      }

      &__mobile {
        display: none;
        @media (max-width: 780px) {
          display: block;
          height: auto;
        }
      }

      &__content {
        border: none;
        min-height: 327px;
        justify-content: flex-end;
        width: 203px;
        min-width: 215px;
        
        @media (max-width: 1400px) {
          min-width: 184px;
          width: 184px;
          min-height: 333px;
        }
        @include media-breakpoint-down(sm) {
          min-width: 215px;
          width: 203px;
          min-height: 333px;
        }

        @media (max-width: 780px) {
          flex-direction: row-reverse !important;
          width: 100%;
          min-height: initial;
          margin-bottom: 8px;
          padding: 16px 0;
        }

        a {
          width: 100%;
        }
      }

      &__photo {
        margin-top: 8px;

        @media (max-width: 780px) {
          max-width: 76px;
          margin-top: 0;
        }
      }

      &__content {
        a {
          margin-top: 30px;
        }
      }

      &__box {
        background-color: white;
      }

      &__ingredients {
        background: none;
        margin-top: 0;
        &:not(:last-child) {
          margin-right: 16px;

          @media (max-width: 780px) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__ingredients {
    background: white;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0;
  }

  &__photo {
    max-width: 195px;

    .wine-link {
      padding: 0;
      min-height: 195px;
      max-height: 195px;
      margin-bottom: 15px;

      @media (max-width: 780px){
        min-height: initial;
        margin-bottom: 0;
      }

    }

    img {
      width: 100%;
    }
  }

  &__box {
    text-align: center;
    padding: 0 0 30px 0;
    white-space: nowrap;

    &__name-top {
      font-size: 24px;
      line-height: 29px;
      font-family: 'Lidl Font';
      font-weight: bold;
      margin-bottom: 25px;
      margin-top: 40px;
      text-align: center;
    }
  }

  &__content {
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: center;
    width: 220px;
    min-height: 325px;
    margin: 0 auto;
    min-width: 230px;
    position: relative;
    cursor: pointer;
    border: 2px solid #E3E4E5;
    border-top-color: #0050AA;
    border-radius: 4px;

    span {
      background: #FFF103;
      padding: 8px 11px;
      box-shadow: 0px 2px 6px rgba(33, 33, 33, 0.08), 0px 0px 1px rgba(33, 33, 33, 0.04);
      border-radius: 30px;
      z-index: 4;
      font-size: 12px;
      line-height: 14px;
      font-family: 'Lidl Font'; font-weight: bold;
      position: absolute;
      left: 5px;
      top: 10px;
    }

    a {
      font-size: 16px;
      line-height: 19px;
      font-family: 'Lidl Font'; font-weight: bold;
      padding: 0 20px 10px 20px;
      color: black;
      margin-top: 40px;
      margin-bottom: 15px;
      white-space: initial;
      text-align: left;
      max-width: 90%;
    }
  }
}
