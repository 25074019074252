.lidl-container {
  width: 1200px;
  max-width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
  zoom: 1;
  max-width: 1216px;

  &--fluid {
    max-width: 1200px;
  }

  @include media-breakpoint-down(md){
    width: 100%;
  }
}


.lidl-section {
  width: 100vw;
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 50px 0;
  max-width: 100%;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm){
    padding-bottom: 0;
    min-height: 70vh;
  }
}

.lidl-header {
  font-family: 'Lidl Font'; font-weight: bold;
  line-height: normal;
  font-size: 2.8rem;
  margin-bottom: 2rem;

  h1 {
    font-size: inherit;
  }

  @include media-breakpoint-down(sm){
    color: white;
    margin-top: 1rem;
    font-size: 2.2rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.lidl-content-text {
  @include media-breakpoint-down(sm){
    color: white;
    text-align: center;
  }
}

.lidl-subheader {
  font-family: 'Lidl Font'; font-weight: bold;
  font-size: 2.5rem;
  position: relative;
  text-align: center;
  margin-top: 3rem;

  h2, h3 {
    font-size: 1em;
    line-height: 1.2em;
  }

  @include media-breakpoint-down(sm){
    font-size: 2rem;
    padding: 0 150px;
    line-height: normal;
  }

  @include media-breakpoint-down(xs){
    font-size: 1.7rem;
    padding: 0 70px;
  }
}

.lidl-subheader--ornament {
  //&::before {
  //  content: '';
  //  width: 150px;
  //  height: 1px;
  //  position: absolute;
  //  left: 0;
  //  background-color: $lidlPrimaryColor;
  //  top: 50%;
  //  transform: translateY(-50%);
  //}
  //
  //&::after {
  //  content: '';
  //  width: 150px;
  //  height: 1px;
  //  position: absolute;
  //  right: 0;
  //  background-color: $lidlPrimaryColor;
  //  top: 50%;
  //  transform: translateY(-50%);
  //}
  //
  //@include media-breakpoint-down(xs){
  //  &::before, &::after {
  //    width: 70px;
  //  }
  //}
  //
  //@media (max-width: 380px) {
  //  &::before, &::after {
  //    width: 65px;
  //  }
  //}
  //
  //&--smaller {
  //  &::after,
  //  &::before {
  //    width: 100px;
  //  }
  //}
}

.lidl-subheader-2 {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 4rem;

  @include media-breakpoint-down(sm){
    font-size: 1.2rem;
    margin-bottom: 0;
  }
}
