.page__profile__profiling,
.page__profile__profilingFastTrack { //body class
  .container.profiling {
    margin-top: 0;
    max-width: 100%;
    background: url('/layouts/frontend/img/tinder-profiling/welcome_desktop_2x.jpg') center no-repeat;
    background-size: cover;
    min-height: 600px;

    @media (max-width: 639px) {
      margin-top: 100px;
      background: url('/layouts/frontend/img/tinder-profiling/welcome_mobile_2x.png') top center no-repeat;
      background-size: cover;
    }
  }

  #lidl-footer .page__footer .footer {
    padding-top: 0;
  }
}

.profiling-container {
  max-width: 800px;
  width: 100%;
  margin: 0 0 50px;
  position: relative;

  @media (max-width: 600px) {
    .button-lidl__blue {
      margin: 0 auto;
    }
  }
}

.profilingStep {
  position: relative;


  &__title {
    font-size: 32px;
    line-height: 38px;
    padding: 10px 0;
    font-weight: bold;
    word-wrap: break-word;
    padding-right: 115px; /*space for skip button*/

    @media (max-width: 600px) {
      font-size: 24px;
      padding-right: 95px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .profilingStep__goBack {
      @media (max-width: 600px) {
        padding: 10px 20px;
      }
    }

    .button-lidl__blue {
      margin-right: 0;

      @media (max-width: 600px) {
        width: auto;
        padding: 0 35px;
      }
    }
  }

  &__goBack {
    display: inline-block;
    font-size: 18px;
    line-height: 18px;

    span {
      display: block;
      padding: 1px 0;
      color: #000000;
      border-bottom: 1px solid #000;
    }
  }

  &__skip {
    padding: 10px;
    position: absolute;
    top: 10px;
    right: -10px;
    cursor: pointer;

    span {
      display: block;
      padding: 1px 0;
      color: $lidlPrimaryColor;
      font-size: 12px;
      line-height: 14px;
      font-weight: bold;
      border-bottom: 1px solid $lidlPrimaryColor;
    }

    &:hover {
      span {
        border-bottom-width: 2px;
      }
    }
  }

  &__dishesInfo {
    margin-top: 30px;
    color: #6D757C;
    text-align: right;
  }

  &__dishes {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 5px;

    //height: 440px;
    //overflow: hidden;

    &__element {
      width: 32%;

      @media (max-width: 900px) {
        width: 49%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      .single-item {
        width: 100% !important;
        margin-bottom: 0 !important;
        transition: 0.25s all;

        &--selected {
          transform: scale(0.2);
          opacity: 0;
        }
      }
    }

    .single-item {
      width: 32%;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 100px;


      @media (max-width: 900px) {
        width: 49%;
      }

      @media (max-width: 600px) {
        width: 100%;
      }

      &--selected {
        //only if is not a edit Profile
        //display: none;
      }

      &__name {
        position: relative;
        height: 190px;
        padding: 20px 15px;
      }

      &__image {
        position: relative;
        pointer-events: none;

        &__stepNo {
          color: #FFF;
          position: absolute;
          right: 15px;
          bottom: 10px;
          font-weight: bold;
          text-shadow: 0 0 2px rgba(0,0,0, 0.5);
        }
      }

      &__buttons {
        bottom: 16px;
        left: 0;
        padding: 0 13px;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;

        &__button {
          width: 45%;
          height: 44px;

          font-size: 13px;
          line-height: 36px;

          padding-right: 10px;
          padding-left: 40px;
          text-align: center;

          border-radius: 4px;
          font-weight: bold;

          cursor: pointer;
          border: 0;
          outline: 0;

          box-sizing: border-box;

          background: url('/layouts/frontend/img/tinder-profiling/likeIt.svg') no-repeat 10px center;

          border: 3px solid #388455;
          background-color: #FFF;
          color: #388455;

          &:hover,
          &--selected {
            background-image: url('/layouts/frontend/img/tinder-profiling/likeIt--white.svg');
            background-color: #388455;
            border-color: #388455;
            color: #FFF;

            &.single-item__buttons__button--hateIt {
              background-image: url('/layouts/frontend/img/tinder-profiling/hateIt--white.svg');
              background-color: #F5A52A;
              border-color: #F5A52A;
              color: #FFF;
            }
          }

          &--hateIt {
            background-image: url('/layouts/frontend/img/tinder-profiling/hateIt.svg');

            border: 3px solid #f5a52a;
            color: #F5A52A;
            background-color: #FFF;
          }

          @media (max-width: 600px) {
            &:hover {
              background-image: url('/layouts/frontend/img/tinder-profiling/likeIt.svg');
              border-color: #388455;
              background-color: #FFF;
              color: #388455;

              &.single-item__buttons__button--hateIt {
                background-image: url('/layouts/frontend/img/tinder-profiling/hateIt.svg');
                border-color: #f5a52a;
                color: #F5A52A;
                background-color: #FFF;

              }
            }

            &--selected {
              &, &:hover {
                background-image: url('/layouts/frontend/img/tinder-profiling/likeIt--white.svg');
                background-color: #388455;
                border-color: #388455;
                color: #FFF;

                &.single-item__buttons__button--hateIt {
                  background-image: url('/layouts/frontend/img/tinder-profiling/hateIt--white.svg');
                  background-color: #F5A52A;
                  border-color: #F5A52A;
                  color: #FFF;
                }
              }
            }
          }
        }
      }
    }

    &--editMode {
      height: auto;
      justify-content: flex-start;

      .single-item {
        margin-bottom: 15px;
        margin-right: 2%;

        &:nth-child(3n) {
          margin-right: 0;
        }

        @media (max-width: 900px) {
          &:nth-child(3n) {
            margin-right: 2%;
          }
          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media (max-width: 600px) {
          margin-right: 0 !important;
        }
      }
    }
  }

  &__search {
    max-width: 280px;

    @media (max-width: 400px) {
      max-width: 100%;
    }

    .selectize-control.input-select {
      background-image: url('/layouts/frontend/img/tinder-profiling/search.svg');
      background-position: left 10px;
      background-repeat: no-repeat;

      .selectize-input input {
        padding-left: 16px !important;
      }
    }
  }

  &__errorBox {
    background: #FEF5F6;
    font-size: 14px;
    line-height: 22px;
    color: #E60A14;
    padding: 20px;
    margin: 20px -20px;
  }

  &--1 {
    min-height: 600px;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 639px) {
      padding-top: 100px;
      padding-bottom: 50px;
      justify-content: flex-end;
    }

    h2 {
      font-size: 48px;
      line-height: 58px;
      font-weight: bold;
      max-width: 390px;

      @media (max-width: 639px) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .desc {
      max-width: 390px;
      margin-top: 15px;
      margin-bottom: 25px;
    }

    .button-lidl__blue {
      display: inline-block;
      width: auto;
      padding: 0 40px;
      margin-right: 25px;
    }
  }

  &--mealTypes {
    #same-meals,
    #meals-per-day {
      display: flex;
      flex-wrap: wrap;
      max-width: 420px;

      .checkbox {
        width: 50%;

        &--disabled {
          display: none;
        }
      }

      input[type=checkbox] ~ label,
      input[type=radio] ~ label {
        margin-left: 0;
      }
    }
  }

  &--basicInfoStep {
    .dietCheckboxes {
      margin-top: 20px;
      justify-content: flex-start;

      @include media-breakpoint-down(xs) {
        margin-top: 5px;
        justify-content: space-between;
      }

      .dietCheckbox {
        min-height: 64px;
        max-width: 160px;
        margin-right: 20px;
        background-image: url('/layouts/frontend/img/tinder-profiling/sexWoman.svg');
        background-position: 20px center;

        @include media-breakpoint-down(xs) {
          max-width: 48%;
          margin-right: 0;
          padding: 15px 15px 15px 70px;
        }

        display: flex;
        justify-content: center;
        align-items: center;

        &--sex1 {
          background-image: url('/layouts/frontend/img/tinder-profiling/sexMan.svg');
        }

        &--selected {
          background-image: url('/layouts/frontend/img/tinder-profiling/sexWoman--checked.svg');

          &.dietCheckbox--sex1 {
            background-image: url('/layouts/frontend/img/tinder-profiling/sexMan--checked.svg');
          }
        }
      }

    }

    .lifeActivityBox {
      @include media-breakpoint-down(sm) {
        margin-top: 15px;
      }
    }

    .weightsBox {
      margin-top: 40px;

      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }
    }

    .calculator-result {
      color: #0076bf;

      &--visible {
        margin: 30px 0 0;
      }
    }

    .summary-box__header {
      text-transform: none;
    }

    .kcalBox {
      padding-top: 5px;
      position: relative;

      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }

      .box-activity__control {
        padding: 10px;
        margin: -10px;
      }

      &:after {
        content: 'kcal';
        position: absolute;
        top: 13px;
        right: 50%;
        margin-right: -40px;
        letter-spacing: 0.1px;
      }
    }

    .box-activity__input {
      background: transparent;
      width: 88%;
      padding-right: 25px;

      @include media-breakpoint-down(xs) {
        width: 85%;
      }
    }

    .validate-error {
      text-transform: none;
      font-size: 14px;
      background: #FEF5F6;
      padding: 15px 24px;
      letter-spacing: normal;
      margin-top: 15px;

      @include media-breakpoint-down(xs) {
        margin-top: 25px;
        margin-left: -15px;
        margin-right: -15px;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .calculator-select {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: 40px;
      position: relative;
      background: transparent;
    }
  }

  &--goalChoose {
    .dietCheckboxes .dietCheckbox {
      background-image: url('/layouts/frontend/img/tinder-profiling/goal-inspiration.svg');

      &--fit {
        background-image: url('/layouts/frontend/img/tinder-profiling/goal-fit.svg');
      }

      &--selected {
        background-image: url('/layouts/frontend/img/tinder-profiling/goal-inspiration--checked.svg');

        &.dietCheckbox--fit {
          background-image: url('/layouts/frontend/img/tinder-profiling/goal-fit--checked.svg');
        }
      }
    }
  }

  &__excludedIngredientsBox {
    background: #F5F8FC;
    border-radius: 4px;
    padding: 12px 15px;
    margin: 15px 0;

    &__title {
      font-family: 'Lidl Font';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
      cursor: pointer;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 7px;
        width: 13px;
        height: 9px;
        background: url('/layouts/frontend/img/svg/arrowBlueDown.svg') no-repeat;
        transition: 0.25s transform;
      }

      &--opened {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

    &__content {
      font-family: 'Lidl Font';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      max-height: 0;
      transition: 0.25s max-height;
      overflow: hidden;

      &--opened {
        max-height: 180px;

        @include media-breakpoint-down(xs) {
          max-height: 220px;
        }
      }

      ul {
        margin-top: 10px;
        padding-left: 20px;
        margin-bottom: 0;

        li {
          margin-top: 5px;
        }
      }
    }
  }

  &__excludedIngredientsInfo {
    display: flex;
    align-items: self-end;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: -12px;
    min-height: 24px;

    &__recipeCountInfo {
      width: 50%;
      min-height: 24px;
      font-family: 'Lidl Font';
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;

      @include media-breakpoint-down(xs) {
        width: 100%;
      }

      strong {
        font-weight: bold;
      }

      &--warning {
        strong {
          color: #D62631;
        }
      }
    }

    &__recipeCountMessage {
      width: 50%;
      font-family: Lidl Font;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #D62631;
      text-align: right;

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-top: 10px;
        text-align: left;
      }
    }
  }
}

.dietCheckboxes {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
  justify-content: space-between;

  .dietCheckbox {
    width: calc(50% - 10px);
    border: 2px solid #D6E3F1;
    border-radius: 6px;
    padding: 15px 25px 15px 100px;
    margin-bottom: 20px;
    background: url('/layouts/frontend/img/tinder-profiling/universalDiet.svg') no-repeat 20px 30px;
    cursor: pointer;
    min-height: 110px;

    @media (max-width: 639px) {
      width: 100%;
    }

    &--id2 {
      background-image: url('/layouts/frontend/img/tinder-profiling/vegetarian.svg')
    }

    &--id4 {
      background-image: url('/layouts/frontend/img/tinder-profiling/noMeatDiet.svg')
    }

    &--id5 {
      background-image: url('/layouts/frontend/img/tinder-profiling/vegetarianDiet.svg')
    }

    &--selected {
      border-color: $lidlPrimaryColor;
      background-color: $lidlPrimaryColor;
      color: #FFF;

      background-image: url('/layouts/frontend/img/tinder-profiling/universalDiet--checked.svg');

      &.dietCheckbox--id2 {
        background-image: url('/layouts/frontend/img/tinder-profiling/vegetarian-white.svg')
      }

      &.dietCheckbox--id4 {
        background-image: url('/layouts/frontend/img/tinder-profiling/noMeatDiet--checked.svg')
      }

      &.dietCheckbox--id5 {
        background-image: url('/layouts/frontend/img/tinder-profiling/vegetarianDiet--checked.svg')
      }
    }

    &__title {
      font-weight: bold;
      text-transform: capitalize;
    }

    &__desc {
      margin-top: 10px;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.ingredientsCheckboxes {
  $self: &;
  display: flex;
  margin: 25px 0;
  flex-wrap: wrap;

  &__element {
    position: relative;
    width: 24%;
    display: flex;
    font-size: 16px;
    line-height: 16px;

    cursor: pointer;
    margin-right: 1%;
    margin-bottom: 1%;

    border-radius: 4px;
    background: rgba(#E60A14, 0.1);
    text-align: center;
    height: 38px;
    justify-content: center;
    align-items: center;

    &:nth-child(4n) {
      margin-right: 0;
    }

    @media (max-width: 400px) {
      width: 49%;
      margin-right: 2%;
      margin-bottom: 2%;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    &:hover {
      background: rgba(#E60A14, 0.25);
    }

    &--checked {
      background: #E60A14;
      color: #FFF;

      &:hover {
        background: rgba(#E60A14, 0.75);
      }

      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: 97%;
        transform: rotate(9deg);
        height: 1.5px;
        background: #FFFFFF;
        transform-origin: 0 0;

      }
    }
  }

  &--favourites {
    #{$self}__element {

      background: rgba(#348553, 0.2);

      &:hover {
        background: rgba(#348553, 0.5);
      }

      &--checked {
        background: #348553;

        &:hover {
          background: rgba(#348553, 0.75);
        }

        &:after {
          display: none;
        }
      }
    }
  }
}

.profilingStep__ingredients--disabled {
  opacity: 0.2;
  cursor: not-allowed;
  pointer-events: none;
}

.caloriesBox {
  background: #F5F8FC;
  padding: 30px;

  @include media-breakpoint-down(xs) {
    margin-left: -15px;
    margin-right: -15px;
  }

  button {
    @include media-breakpoint-down(xs) {
      width: 100%;
    }
  }
}
