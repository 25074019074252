.lidl-step {
  display: flex;
  flex-direction: row;
  height: 80vh;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    height: auto;
    flex-direction: column;
  }
}


.lidl-step__background {
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%;

  &--cook-1 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section3_new.jpg');
    }
  }

  &--cook-2 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/zu-kochen/section4_new.jpg');
    }
    background-position: right;
  }

  &--cook-3 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/zu-kochen/section5_new.jpg');
    }
  }

  &--cook-4 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section6_new.jpg');
    }
    background-position: right;
  }

  &--diet-1 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section3_new.jpg');
    }
  }

  &--diet-2 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/zu-kochen/section5_new.jpg');
    }
    background-position: right;
  }

  &--diet-3 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section2_new.jpg');
    }

    @include media-breakpoint-down(sm) {
      background-position-y: 340px;
    }
  }

  &--diet-4 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/diet/section4.jpeg');
    }
    background-position: right;
  }

  &--diet-5 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section6_new.jpg');
    }

    @include media-breakpoint-down(sm) {
      background-position-y: -75px;
    }
  }

  &--planer-1 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section3_new.jpg');
    }
  }

  &--planer-2 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/zu-kochen/section4_new.jpg');
    }
    background-position: right;
  }

  &--planer-3 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/zu-kochen/section5_new.jpg');
    }
  }

  &--planer-4 {
    &.bgImageActive {
      background-image: url('/layouts/frontend/img/lidl-landing/common/section6_new.jpg');
    }
    background-position: right;
  }

  @include media-breakpoint-down(sm) {
    width: 100vw;
    max-width: 100%;
    height: 220px;
    position: absolute;
    bottom: 0;
  }
}

.lidl-step__content {
  width: 50%;
  height: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -4rem;

  @include media-breakpoint-down(sm) {
    position: static;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 260px;
    margin-top: 2rem;
  }

  @include media-breakpoint-down(xs) {
    height: 280px;
    &--diet-5 {
      height: 380px;
    }
  }
}

.step__text {
  font-size: 1.7rem;
  max-width: 41%;
  text-align: center;


  @include media-breakpoint-down(sm) {
    font-size: 1.1rem;
    max-width: 95%;
  }
}

.step__number {
  font-family: 'Lidl Font'; font-weight: bold;
  display: block;
  font-size: 4rem;
  text-align: center;
  color: $lidlPrimaryColor;

  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
}

.step__arrow {
  margin-right: 15rem;
  position: relative;
  top: -3rem;
  &--left {
    margin-right: -10rem;
  }

  &--iphone {
    margin-right: -19rem;
    top: -4rem;
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.lidl-step__laptop {
  position: absolute;
  max-width: 50vw;
  top: 50%;
  left: 13%;
  transform: translateY(-50%);
  min-height: 1px;
  height: auto;

  &--right {
    left: 34%;
  }

  @include media-breakpoint-down(sm) {
    position: static;
    width: 80%;
    max-width: 450px;
    margin-bottom: -4rem;
    height: 250px;
    margin-top: 10px;
  }

  @include media-breakpoint-down(xs) {
    max-width: 500px;
    width: 95%;
    height: auto;
    margin-top: 40px;
  }
}

.lidl-step__smartphone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 20vw;
  z-index: 1;
  min-height: 1px;

  @include media-breakpoint-down(md) {
    max-width: 22vw;
  }

  @include media-breakpoint-down(sm) {
    position: static;
    max-width: 65%;
    transform: initial;
    width: 35%;
    margin-top: -5rem;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(xs) {
    width: 50%;
  }
}
