.footer__row {
  display: flex;
}

// ROW WITH LOGO AND CONTACT DATA
.footer__row--contact {
  @extend .footer__row;
  justify-content: space-between;
  min-height: 100px;
  position: relative;

  @include media-breakpoint-down(sm) {
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  @include media-breakpoint-down(xs) {
    align-items: center;
    flex-direction: column;
  }
}

.footer__logo {
  display: block;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 1em;

  @include media-breakpoint-down(xs) {
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1em;
    margin-top: 1em;
  }
}

.footer__contact {

  &:first-of-type {
    margin-right: 150px;
  }

  @include media-breakpoint-down(sm) {
    &:first-of-type {
      margin-right: 0;
      padding-right: 25px;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-top: 15px;
    padding: 0;
    text-align: center;

    &:first-of-type {
      padding-right: 0;
    }
  }
}

// ROW WITH SOCIAL ICONS
.footer__row--socials {
  @extend .footer__row;
  justify-content: space-between;

  @include media-breakpoint-down(xs) {
    align-items: center;
    flex-direction: column;
  }
}

.footer__socials {
  display: flex;
  justify-content: center;
  width: 150px;

  a {
    display: inline-block;
    height: 30px;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 15px;
  }
}

.footer__producer {
}