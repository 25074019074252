.babiesLanding {

  .feedSection--banner {
    background-image: url("/layouts/frontend/img/babies-landing/banner.png");

    @include media-breakpoint-down(sm) {
      background-image: url("/layouts/frontend/img/babies-landing/banner-mobile.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .feedSection--section1 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/babies-landing/section1.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/babies-landing/section1-mobile.png");
      }
    }
  }


    .feedSection--section2 {
      ul {
        padding-top: 0;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 0;
      }
      
      &.bgImageActive:before {
        background-image: url("/layouts/frontend/img/babies-landing/section2.png");
        @media (max-width: 700px)  {
          background-image: url("/layouts/frontend/img/babies-landing/section2-mobile.png");
        }
      }
    }

  .feedSection--section3 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/babies-landing/section3.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/babies-landing/section3-mobile.png");
      }
    }
  }

  .feedSection--section4 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/babies-landing/section4.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/babies-landing/section4-mobile.png");
      }
    }
  }

  .feedSection--section5 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/babies-landing/section5.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/babies-landing/section5-mobile.png");
      }
    }
  }
  .feedSection--section6 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/babies-landing/section6.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/babies-landing/section6-mobile.png");
      }
    }
  }

  .feedSection--section7 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/babies-landing/section7.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/babies-landing/section7-mobile.png");
      }
    }
  }

}