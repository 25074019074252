.circle-container {
  padding: 0;

  @include media-breakpoint-down(md) {
    flex-wrap:wrap;
  }
}

.circle-step {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
  }
}

.circle-step__image {
  @include media-breakpoint-down(xs) {
    max-width: 90%;
  }
}

.circle-step__title {
  height: 3.5rem;
  max-width: 15.3rem;
  margin: 1rem auto 0 auto;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;

  @include media-breakpoint-down(xs) {
    font-size: 0.95rem;
    line-height: 1.1;
  }
}
