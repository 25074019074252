.viewList {
  &__feed {
    margin-top: 40px;

    @include media-breakpoint-down(xs) {
      .feedElement {
        margin-left: -15px;
        margin-right: -15px;
        overflow: hidden;
        padding: 0 15px;
      }
    }

    .feedElement__header {
      display: none;
    }
    .feedElement:before, .feedElement:after {
      height: calc(100% - 80px);
      top: 0px;
    }

    .feedElement__scrollLeft.slick-prev,
    .feedElement__scrollRight.slick-next {
      top: 32%;

      @include media-breakpoint-down(sm) {
        top: 40%;
      }
    }
    .feedElement__arrowText {
      top: 32%;
    }

    .single-item.main-page {
      max-width: 230px;
    }

    .single-item__labels__label--weekRecipe {
      font-size: 0.65em;
    }

    h4 {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 8px;

      @include media-breakpoint-down(xs) {
        font-size: 16px;
      }
    }

    p {
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 16px;

      @include media-breakpoint-down(xs) {
        font-size: 14px;
      }
    }

    &--christmasShoplist {
      h4 {
        color: #BC2C35;
      }
    }
  }
}