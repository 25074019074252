.shoplist-landing {
  .seo_content {
    color: #353B42;

    .lidl-landing-section {
      margin: 50px auto;
      @include media-breakpoint-down(xs) {
        margin: 25px auto;
      }

      .lidl-landing-section {

        &__heading {
          margin-bottom: 16px;
          font-size: 30px;
          line-height: 120%;

          @include media-breakpoint-down(xs) {
            margin-top: 25px;
            font-size: 28px;
          }
        }

        &__content-with-picture p {
          margin-bottom: 32px;
          font-size: 18px;
          line-height: 140%;

          @include media-breakpoint-down(xs) {
            font-size: 16px;
          }
        }

        &__content {
          &--bigger {
            margin-bottom: 32px;
          }
        }

      }

      &.section1,
      &.section5 {
        .blue-background {
          background: #D6E3F1;
        }
      }

      &.section1 {
        margin-top: 0;

        .lidl-landing-section {
          &__headeing {
            padding-top: 0;
          }
        }
      }

      &.section2,
      &.section5 {
        .lidl-landing-section__benefits {
          background: #DEEBE3;
          margin-bottom: 32px;
        }
      }

      &.section3 {
        .lidl-landing-section__heading {
          margin-bottom: 32px;
        }

        .section3__picture {
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child {
            img {
              width: 193px;
              @include media-breakpoint-down(xs) {
                width: 184px;
              }
            }
          }

          &:last-child {
            img {
              width: 120px;
              @include media-breakpoint-down(xs) {
                width: 80px;
              }
            }
          }
        }
      }

      &.section4 {
        .section4 {
          &__picture {
            &:first-child {
              img {
                width: 315px;
                @include media-breakpoint-down(xs) {
                  width: 240px;
                }
              }
            }

            &:last-child {
              img {
                width: 316px;
                @include media-breakpoint-down(xs) {
                  width: 240px;
                }
              }
            }
          }

          &__text {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }

        .lidl-landing-section {
          &__content-with-picture {
            @include media-breakpoint-down(xs) {
              margin: 0;
            }

            &.right {
              @include media-breakpoint-down(xs) {
                grid-gap: 40px;
              }
            }
          }
        }
      }


      &:nth-child(2) .lidl-landing-section__heading {
        border-top: 1px solid #EEEEEE;
      }

      &:nth-child(1) .lidl-landing-section__heading {
        border-top: none;
        @include media-breakpoint-down(xs) {
          margin-top: 0;
          padding-top: 0;
        }
      }
    }
  }
}
