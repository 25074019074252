.selected-meals {
  font-size: 0.8em;
  font-weight: 700;
}

.selected-meals__meal {
  color: $gray_140;
}

.selected-meals__edit {
  cursor: pointer;
}