#account-details {
  padding-top: 24px;

  iframe {
    width: 100%;
    height: 700px;

    @media (max-width: 681px) {
      height: 759px;
    }

    @media (max-width: 587px) {
      height: 1100px;
    }

    @media (max-width: 420px) {
      height: 1109px;
    }
  }

  .account-details__header {
    font-weight: bold;
    font-size: 44px;
  }

  .account-details__header2 {
    font-size: 22px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-top: 40px;
  }

  .account-details__newsletter {}

  #edit-username-link span {
    margin-left: 20px;
    position: relative;
  }

  #edit-username-link span::before {
    position: absolute;
    width: 16px;
    left: -16px;
    top: 2px;
    height: 16px;
    content: " ";
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMTg0NDRfMTQ5MDApIj48cGF0aCBkPSJNMTIuMzIyNSAwLjY4OTY5N0wxMi40MjY0IDAuNjkxMTM1QzEzLjM1MDggMC43MTgyNDEgMTQuMjIzMyAxLjEyNjg4IDE0Ljg5NDkgMS43NzA5MkwxNC45Njg3IDEuODQzNDRMMTUuNjk5NSAyLjU4MjU4QzE1LjkwNDYgMi43OTAyNyAxNS45MTU0IDMuMTIxNTMgMTUuNzMxOSAzLjM0MjIzTDE1LjY5OTUgMy4zNzc5NUw0LjkxOTM0IDE0LjI4MjZDNC44NTUwMiAxNC4zNDc2IDQuNzc2MzIgMTQuMzk2MiA0LjY5MDQ4IDE0LjQyMjlMNC42NDcwMSAxNC40MzQ0TDAuODE1NjE1IDE1LjI5NzJDMC40MzE1NjEgMTUuMzgxNCAwLjA4NjQzMjQgMTUuMDUwNyAwLjE0NDE2MyAxNC42NjU1TDAuMTUxNTg3IDE0LjYyNTVMMS4wMDQ1NSAxMC43NDk4QzEuMDIzNjEgMTAuNjYwNyAxLjA2NTEgMTAuNTc3OCAxLjEyMzgyIDEwLjUwODFMMS4xNTQ1OSAxMC40NzQ0TDkuNzY0ODYgMS43NjQ3M0MxMC40MjQ0IDEuMDk3NSAxMS4yOTExIDAuNzE3NzEyIDEyLjIxOTIgMC42OTExODdMMTIuMzIyNSAwLjY4OTY5N1pNMS42NjAyNSAxMS42NDA3TDEuMDYwMTEgMTQuMzc1M0wzLjc2MzQ4IDEzLjc2ODNMMS42NjAyNSAxMS42NDA3Wk0xMC4wNTkyIDIuNjU4NDdMMi4wMDc1NiAxMC44MDA0TDQuNTk0MjQgMTMuNDE3TDEyLjY0NiA1LjI3NTAyTDEwLjA1OTIgMi42NTg0N1pNMTAuNzUwNCAyLjAzMkwxMC42NzMzIDIuMDg3OTNMMTMuMjM0OSA0LjY3OTE5TDE0LjkxMzMgMi45ODE2N0wxNC4zNjU3IDIuNDI4MDJDMTMuODUxOCAxLjkwODA3IDEzLjE3NCAxLjU2ODAyIDEyLjQ0ODcgMS41MzcwOUMxMS44MzM2IDEuNTEwMzkgMTEuMjQzNyAxLjY4NjYyIDEwLjc1MDQgMi4wMzJaIiBmaWxsPSIjMDA3YmZmIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDBfMTg0NDRfMTQ5MDAiPjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=");
  }

  #edit-username-link span:hover::before {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMTg0NDRfMTQ5MDApIj48cGF0aCBkPSJNMTIuMzIyNSAwLjY4OTY5N0wxMi40MjY0IDAuNjkxMTM1QzEzLjM1MDggMC43MTgyNDEgMTQuMjIzMyAxLjEyNjg4IDE0Ljg5NDkgMS43NzA5MkwxNC45Njg3IDEuODQzNDRMMTUuNjk5NSAyLjU4MjU4QzE1LjkwNDYgMi43OTAyNyAxNS45MTU0IDMuMTIxNTMgMTUuNzMxOSAzLjM0MjIzTDE1LjY5OTUgMy4zNzc5NUw0LjkxOTM0IDE0LjI4MjZDNC44NTUwMiAxNC4zNDc2IDQuNzc2MzIgMTQuMzk2MiA0LjY5MDQ4IDE0LjQyMjlMNC42NDcwMSAxNC40MzQ0TDAuODE1NjE1IDE1LjI5NzJDMC40MzE1NjEgMTUuMzgxNCAwLjA4NjQzMjQgMTUuMDUwNyAwLjE0NDE2MyAxNC42NjU1TDAuMTUxNTg3IDE0LjYyNTVMMS4wMDQ1NSAxMC43NDk4QzEuMDIzNjEgMTAuNjYwNyAxLjA2NTEgMTAuNTc3OCAxLjEyMzgyIDEwLjUwODFMMS4xNTQ1OSAxMC40NzQ0TDkuNzY0ODYgMS43NjQ3M0MxMC40MjQ0IDEuMDk3NSAxMS4yOTExIDAuNzE3NzEyIDEyLjIxOTIgMC42OTExODdMMTIuMzIyNSAwLjY4OTY5N1pNMS42NjAyNSAxMS42NDA3TDEuMDYwMTEgMTQuMzc1M0wzLjc2MzQ4IDEzLjc2ODNMMS42NjAyNSAxMS42NDA3Wk0xMC4wNTkyIDIuNjU4NDdMMi4wMDc1NiAxMC44MDA0TDQuNTk0MjQgMTMuNDE3TDEyLjY0NiA1LjI3NTAyTDEwLjA1OTIgMi42NTg0N1pNMTAuNzUwNCAyLjAzMkwxMC42NzMzIDIuMDg3OTNMMTMuMjM0OSA0LjY3OTE5TDE0LjkxMzMgMi45ODE2N0wxNC4zNjU3IDIuNDI4MDJDMTMuODUxOCAxLjkwODA3IDEzLjE3NCAxLjU2ODAyIDEyLjQ0ODcgMS41MzcwOUMxMS44MzM2IDEuNTEwMzkgMTEuMjQzNyAxLjY4NjYyIDEwLjc1MDQgMi4wMzJaIiBmaWxsPSIjMDA1MEFBIi8+PC9nPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDBfMTg0NDRfMTQ5MDAiPjxyZWN0IHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=");
  }

  .account-details__navigation {
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .account-details__navigation-link {
    color: #000;
    cursor: pointer;
    font-size: 2em;
    text-decoration: none;

    &:hover {
      color: #000;
      text-decoration: underline;
    }

    &.active {
      text-decoration: underline;
    }

    @include media-breakpoint-down(xs) {
      text-align: center;
      width: 100%;
    }
  }

  .account-details__navigation-link-a {
    @extend .account-details__navigation-link;
  }

  .account-details__boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .account-details__boxes-box {
    background-color: white;
    font-size: 1.2em;

    .box__title {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  .account-details-panel {
    display: none;

    &.active {
      display: block;
    }
  }

  .box__inner-data {
    display: inline-block;
  }

  .box__inner-diets {
    display: inline-block;
    filter: brightness(0%);
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .box__inner-products {
    display: inline-block;

    &:not(:last-child):after {
      content: ",";
      margin-right: 5px;
      margin-left: -3px;
    }
  }

  .form-control {
    background-color: transparent;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 110px;
  }

  .account-details__lawInfo {
    font-size: 12px;
  }

  .account__save-btn {
    width: 120px;
  }

  .account__delete-link {
    width: 280px;
  }

  .remove-account {
    border-top: 1px solid #CBD1D6;
    padding: 40px 0;
    margin-top: 80px;

    @include media-breakpoint-down(sm) {
      padding: 24px 0;
      margin-top: 40px;
    }

    &__btn {
      cursor: pointer;
      width: fit-content;
      display: flex;
      align-items: center;
      gap: 6px;
      text-decoration: none;

      &__icon {
        width: 18px;
        height: 18px;
      }

      &__text {
        color: #e60a14;
        font-weight: 600;
      }
    }
  }
}