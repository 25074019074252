.healthScoreLp {

  &__header {
    text-align: center;
    height: 300px;
    background: url('/layouts/frontend/img/landing-health-score/header.jpg?v=3') center no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    color: #FFFFFF;

    @include media-breakpoint-down(xs) {
      height: 250px;
      background: url('/layouts/frontend/img/landing-health-score/header_mobile.jpg?v=3');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center -130px;

      padding: 0 15px;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);
    }

    @media (max-width: 480px) {
      background-position: center bottom;
    }

    h1 {
      font-weight: 700;
      font-size: 42px;
      line-height: 50px;
      margin-bottom: 15px;

      @include media-breakpoint-down(xs) {
        font-weight: 700;
        font-size: 34px;
        line-height: 41px;

      }
    }

    &__desc {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;

      @include media-breakpoint-down(xs) {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  &__blueBg {
    background: #F5F8FC;
  }

  &__textSection {
    display: flex;
    align-items: center;
    padding: 64px;
    border-bottom: 1px solid #D6E3F1;

    @include media-breakpoint-down(md) {
      padding: 32px 0;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    &:last-child {
      border-bottom: none;
    }

    &--reverse {
      flex-direction: row-reverse;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      .healthScoreLp__textSection__content {
        margin-left: 80px;
        margin-right: 0;

        @include media-breakpoint-down(md) {
          margin-left: 0;
        }
      }
    }

    &__content {
      margin-right: 40px;

      h3 {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
        color: #353B42;
        margin-bottom: 24px;

        @include media-breakpoint-down(xs) {
          font-size: 28px;
          line-height: 34px;
          color: #2F3A44;
        }
      }

      &__text {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #353B42;
        margin-bottom: 30px;

        p {
          margin-bottom: 25px;
        }
      }
    }

    &__img {
      @include media-breakpoint-down(md) {
        margin-top: 30px;

        img {
          max-width: 100%;
          height: auto;
          border-radius: 2px;
        }
      }


      &--onlyDesktop {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &--onlyMobile {
        display: none;

        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
  }


  &__section1 {
    &__mobileImage {
      display: none;

      @include media-breakpoint-down(xs) {
        display: block;

        img {
          margin-bottom: 20px;
        }
      }
    }

    &__desktopImage {
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }


  &__section5 {
    h3 {
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      color: #353B42;
      margin: 96px 0 48px;
      text-align: center;

      @include media-breakpoint-down(xs) {
        text-align: left;
        margin: 48px 0 0;
      }
    }

    &__boxes {
      display: flex;
      justify-content: space-between;
      width: 900px;
      margin: 0 auto;

      @media (max-width: 1000px) {
        flex-wrap: wrap;
        width: 100%;
      }

      @include media-breakpoint-down(xs) {
        flex-direction: column;
      }
    }

    &__box {
      width: calc(25% - 32px * 3 / 4);
      display: block;
      border-radius: 4px;
      min-width: 160px;
      position: relative;

      &__border {
        background: #FFF;
        margin: 2px 2px 16px 2px;
        padding: 12px 16px;

        @include media-breakpoint-down(xs) {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &:hover {
          background: rgba(#FFF, 0.8);
        }
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
      }

      &:hover {
        text-decoration: none;
        transition: 0.1s background;
      }

      &--1 {
        background: #4BAC30;
      }

      &--2 {
        background: #DFD701;
      }

      &--3 {
        background: #FCDA01;
      }

      &--4 {
        background: linear-gradient(90deg, #FBAF3D 0%, #E6440F 100%);
      }

      &__header {
        &__title {
          font-weight: 400;
          font-size: 18px;
          line-height: 25px;
          color: #000000;
          display: block;
        }

        &__rate {
          padding: 4px 0 2px;
          font-style: normal;
          font-weight: 700;
          font-size: 34px;
          line-height: 41px;
          color: #221111;
          display: block;
        }
      }

      &__link {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #0050AA;
        border-bottom: 1px solid #0050AA;
        position: relative;
        margin-right: 16px;

        &:after {
          content: '';
          position: absolute;
          right: -18px;
          top: -2px;
          width: 14px;
          height: 20px;
          background: url('/layouts/frontend/img/svg/arrow_link.svg');
        }
      }
    }
  }

  &__section6 {

    &__title {
      margin: 120px 0 15px;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      color: #353B42;
      text-align: center;

      @include media-breakpoint-down(xs) {
        margin-top: 48px;
        text-align: left;
        font-size: 32px;
        line-height: 38px;
      }
    }

    &__desc {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #353B42;
      text-align: center;
      margin-bottom: 32px;

      @include media-breakpoint-down(xs) {
        text-align: left;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 24px;
      }
    }

    &__faq {
      background: #FFFFFF;
      box-shadow: 0px 1.96621px 3.93242px rgba(0, 0, 0, 0.15);
      padding: 0 24px;
      position: relative;
      margin-bottom: 16px;

      @include media-breakpoint-down(xs) {
        padding: 0 12px;
        margin-bottom: 12px;
      }


      &__title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        cursor: pointer;
        padding: 16px 0;

        @include media-breakpoint-down(xs) {
          margin-right: 25px;
          padding: 12px 0;
        }

        &:after {
          content: '';
          top: 15px;
          right: 24px;
          background: url('/layouts/frontend/img/svg/arrow.svg');
          width: 24px;
          height: 24px;
          position: absolute;
          transition: 0.5s all;
          transform: rotate(180deg);
          cursor: pointer;

          @include media-breakpoint-down(xs) {
            right: 12px;
          }
        }
      }

      &__desc {
        max-height: 0;
        overflow: hidden;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        transition: all 0.5s;

        @include media-breakpoint-down(xs) {
          font-size: 16px;
          line-height: 22px;
        }

        p {
          margin-bottom: 15px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:hover {
        .healthScoreLp__section6__faq__desc {
          padding-top: 10px;
        }
      }

      &--open {
        .healthScoreLp__section6__faq__title {
          &:after {
            transform: rotate(0deg);
          }
        }

        .healthScoreLp__section6__faq__desc {
          max-height: 500px;
          padding: 16px 0 20px !important;
          border-top: 1px solid #E3E4E5;

          @include media-breakpoint-down(xs) {
            padding: 12px 0 !important;
            max-height: 1000px;
          }
        }
      }
    }
  }
}

.ratePopup {
  .popup__content {
    max-height: calc(100% - 200px);
    top: 100px;
    margin-top: 0;
    width: 850px;
    padding: 20px 30px 50px;

    @media (max-width: 900px) {
      width: 100%;
      top: 2%;
      max-height: 96%;
      padding: 20px 24px 20px;
    }

    .popup__header {
      margin-bottom: 30px;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      color: #353B42;

      @include media-breakpoint-down(xs) {
        font-size: 28px;
        line-height: 1em;
        margin-right: 30px;
      }
    }
  }

  &__box {
    text-align: left;
    margin-top: 34px;

    &--2 {
      .ratePopup__box__header:before {
        background: #DFD701;
      }
    }
    &--3 {
      .ratePopup__box__header:before {
        background: #FCDA01;
      }
    }
    &--4 {
      .ratePopup__box__header:before {
        background: #F19201;
      }
    }
    &--5 {
      .ratePopup__box__header:before {
        background: #E6440F;
      }
    }

    &__header {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #353B42;
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background: #4BAC30;
        border-radius: 4px;
        margin-right: 10px;
      }
    }

    &__desc {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #353B42;
    }
  }
}

.nutritionalPopup {
  .popup__content {
    max-height: calc(100% - 200px);
    top: 100px;
    margin-top: 0;
    width: 850px;
    padding: 20px 30px 50px;

    @media (max-width: 900px) {
      width: 100%;
      top: 2%;
      max-height: 96%;
      padding: 20px 24px 20px;
    }

    .popup__header {
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #353B42;

      @include media-breakpoint-down(xs) {
        font-size: 28px;
        line-height: 1em;
        margin-right: 30px;
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;

    text-align: left;
    padding-top: 32px;
    border-top: 1px solid #E3E4E5;
    margin-bottom: 20px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      padding-top: 22px;
      margin-bottom: 10px;
    }

    &:first-child {
      border-top: none;
    }

    &:last-child {
      margin-bottom: 0;
    }


    &__content {
      margin-right: 24px;
    }

    &__header {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #353B42;
      margin-bottom: 12px;
    }

    &__desc {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #353B42;
    }
  }
}