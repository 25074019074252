.otherRecipesOfTheWeek {
  margin-top: 20px;
  background: #ffffff;
  padding: 20px 24px;
  color: #000000;

  @include media-breakpoint-down(sm) {
    display: block;
    margin-left: -15px;
    margin-right: -15px;
  }

  &__header {
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 18px;

    @include media-breakpoint-down(sm) {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 0;
    }
  }

  &__list {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      justify-content: stretch;
      flex-wrap: wrap;
    }
  }

  &__box {
    width: 50%;
    width: calc(50% - 7.5px);
    display: block;

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 20px;
    }

    &, &:hover {
      color: #FFF;
      text-decoration: none;
    }

    &__header {
      min-height: 74px;
      padding: 12px 16px;
      display: flex;
      align-items: center;

      font-weight: 600;
      font-size: 18px;
      line-height: 25px;

      @include media-breakpoint-down(sm) {
        padding: 14px;

        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
      }

      background: #F7A606;

      &--sally {
        background: #E6007E;
      }

      &--timo {
        background: #65B4A2;
      }
    }

    &__image {
      position: relative;

      img {
        max-width: 100%;
      }

      &__label {
        position: absolute;
        top: -25px;
        right: 5px;
        width: 60px;
        height: 60px;
      }
    }
  }

  &--allRecipesMobileOnly {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }

    .otherRecipesOfTheWeek__list {
      display: none;
    }

    .otherRecipesOfTheWeek__header {
      position: relative;
      padding-right: 24px;
      padding-top: 10px;
      margin-bottom: 10px;

      &:after {
        position: absolute;
        right: 10px;
        top: 20px;

        content: '';
        width: 30px;
        height: 30px;
        background: no-repeat;
      }

      &:before {
        display: block;
        position: relative;
        top: -10px;

        content: '';
        width: 135px;
        height: 77.5px;
        background: no-repeat;
        background-size: 135px 77.5px;
      }
    }

    &.bgImageActive {
      .otherRecipesOfTheWeek__header {
        &:after {
          background-image: url('/layouts/frontend/img/recipe/arrow_down.svg');
        }
        &:before {
          background-image: url('/layouts/frontend/img/recipe/allRecipesMobileOnly.png');
        }
      }
    }
  }

  &--allRecipesMobileOnlyRecipesVisible {
    .otherRecipesOfTheWeek__header {
      &:after {
        transform: rotate(180deg);
      }
    }

    .otherRecipesOfTheWeek__list {
      display: block;
    }
  }


  // homepage
  &--homepageRecipesOfTheWeekPromotion {
    margin-top: 0;
    padding-top: 70px;
    padding-bottom: 80px;

    @include media-breakpoint-down(sm) {
      padding: 30px 4px;
      margin-left: 0;
      margin-right: 0;
    }

    .otherRecipesOfTheWeek__header {
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 40px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
      }
    }

    .otherRecipesOfTheWeek__box {
      width: 33%;
      width: calc(33% - 20px);

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }
}
