.popup__content {
  z-index: 103;
  background-color: white;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: -125px;
  padding: 70px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 50%;
  transform: scale(0);
  width: 800px;
  font-size: 1rem;
  max-width: 90%;

  max-height: 85vh;
  overflow: auto;

  @include transition(0.2s transform);

  @include media-breakpoint-down(sm) {
    padding: 30px;
  }

  .close-popup-btn {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close-popup-btn:hover {
    opacity: 1;
  }
  .close-popup-btn:before, .close-popup-btn:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close-popup-btn:before {
    transform: rotate(45deg);
  }
  .close-popup-btn:after {
    transform: rotate(-45deg);
  }
}

.popup--open .popup__content {
  transform: scale(1);
}

.popup__overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba(209, 209, 209, 0.9);
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -1;

  @include transition(0.2s opacity);
}

.popup--open .popup__overlay {
  opacity: 1;
  z-index: 102;
}

.popup__text {
  a {
    color: $blue;
  }
}

.popup__buttons {
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    align-items: center;

    button {
      max-width: 48%;
      //margin-bottom: 1rem;
    }
  }
}

.popup__content--share {
  width: 600px;
}

.popup__header {
  font-family: 'Lidl Font'; font-weight: bold;
  font-size: 26px;
  line-height: 31px;
  text-align: left;
}

.popup__desc {
  padding-top: 15px;
  font-size: 16px;
  line-height: 19px;
  padding-bottom: 20px;
  text-align: left;

  span {
    font-weight: bold;
  }
}

.sharingOptions {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  margin-top: -40px;
  padding: 5px 0;

  @media (max-width: 740px) {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 6px;
      font-size: 16px;

      i {
        font-size: 20px;
        margin-right: 8px;
      }
    }
  }
}

.popup--centered {
  .popup__content {
    margin-top: 0;
    top: 50%;
    transform: translateY(-50%) scale(0);
  }

  &.popup--open {
    .popup__content {
      transform: translateY(-50%) scale(1);
    }
  }
}