.christmas-section80 {
  //padding-bottom: 50px;

  @include media-breakpoint-down(xs) {
    padding-bottom: 0;

    .feedElement__header {
      margin-top: 0 !important;

      &__text {
        max-width: 180px;
      }
    }
  }

  &.christmasSection--50 {
    &:before {
      display: none;
    }
    .feeds {
      &:before {
        display: none;
      }
    }
  }

  .feedSection {
    &.feedSection--withBauble {
      &:after {
        right: 25%;
        left: initial;
      }
    }
  }
  &.bgImageActive {
    .feedSection {
      &--section8 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section80/section8-photo.jpg");
        }
      }
    }
  }
}
