.action-container {
  display: flex;
  align-items: center;
  margin-top: 30px;

  @media (max-width: 575px) {
    flex-wrap: wrap;
  }

  //@include media-breakpoint-down(md) {
  //  margin: 0 0 0 0.8rem
    //justify-content: space-evenly;
    //flex-wrap: wrap;
  //}

  .add-to-plan-btn {
    margin-right: 1em;
    color: #FFF !important;
    width: auto;
    padding: 0 12px;

    .calendar-icon {
      font-size: 20px;
      //margin-right: 7px;

      &:before {
        margin-left: 0;
        //margin-right: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &--withReportAction {
    @include media-breakpoint-down(xs) {
      padding-bottom: 35px;
    }
  }

  &__column {
    display: flex;
    align-items: center;

    @media (max-width: 575px) {
      flex-wrap: wrap;
    }

    @include media-breakpoint-down(xs) {
      justify-content: space-evenly;
      width: 100%;
    }
  }
}

.add-to-plan-btn-style {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: $lidlDarkBlue;
  color: white;
  padding: 12px 15px;
  border-radius: 4px;
  border: 3px solid $lidlDarkBlue;
  text-transform: uppercase;
  line-height: normal;

  @include transition(0.2s all);

  @include media-breakpoint-down(xs) {
    padding: 10px 13px;
  }

  &:hover {
    color: $lidlDarkBlue;
    background-color: white;

    path {
      fill: $lidlDarkBlue;
    }
  }
}

.recipe-action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-down(md) {
    margin-top: 15px;
    margin-bottom: 15px;
  }


  &__iconText {
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 14px;
    color: #212121;
    text-decoration: none;

    @include media-breakpoint-down(xs) {
      font-size: 16px;
    }

    &--noPadding {
      margin-left: 0;
    }
  }
}

.recipe-action--print,
.recipe-action--share,
.recipe-action--pinterest,
.recipe-action--favourite {
  &:hover {
    transition: transform .2s linear;
    transform: scale(1.1);
  }
}

.recipe-action i {
  cursor: pointer;
  height: 20px;
}

.recipe-action--report {
  color: black;
  font-size: 0.9rem;


  .recipe-action__iconText {
    display: block;
  }
}

.recipe-add-to-favourite__text {
  margin-bottom: 0;
  font-size: 0.9rem;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.recipe-action--favourite {
  cursor: pointer;
  margin-left: 0.65em;


  &:hover {
    .heart-clip {
      -webkit-animation: pulse .6s .3s infinite;
      animation: pulse .6s .3s infinite;
      &::before {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
      }
    }
  }
}

.buttonBubble--addToPlan {
  &.buttonBubble--visible {
    @include media-breakpoint-down(sm) {
      padding-bottom: 120px;
    }
  }
}

.buttonBubble--addToShoppingList {
  &.buttonBubble--visible {
    padding-bottom: 50px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 60px;
    }
  }
}

