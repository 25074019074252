.header {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15);
  flex-grow: 1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1004;
  background: #fff;

  @media (min-width: 640px) {
    position: relative;
    z-index: 31;
  }

  @media (max-width: 639px) {
    position: absolute;
  }

    &--visibleMobile {
      position: fixed;
      top: -110px;

      &.header--visibleMobileTranslate {
        transition: 0.5s top;
      }

      &.header--visibleMobileAnimated {
        top: 0;
      }
    }
  //}
}

.header--under-cookie-notice {
  //@media (max-width: 639px) {
    padding-top: 180px;
  //}
}


.header__mobile {
  position: relative;
  background-color: #fff;
  height: 60px;
  padding-top: 4px;

  @media (min-width: 640px) {
    height: 90px;
    padding-top: 6px;
  }
}

.header__claim {
  display: none;

  @media (min-width: 640px) {
    color: #06507f;
    display: block;
    float: left;
    font-size: 36px;
    padding: 26px 0
  }
}


.header__button {
  background: 0 0;
  border: none;
  color: #2f3a44;
  cursor: pointer;
  font-size: 15px;
  margin: 4px 0 0;
  padding: 0;
  width: 50px;

  @media (min-width: 640px) {
    //display: none
  }
}


.header__button--menu-toggle {
  margin-top: 3px;
  height: 38px;
  max-width: 640px;

  &.hamburger--opened {
    padding-top: 26px;
    position: relative;

    // x sign with css
    &:after, &:before {
      content: '';

      display: block;
      position: absolute;

      top: 12px;
      left: 10px;
      width: 28px;
      height: 1px;
      background-color: #2f3a44;
      transition: 0.5s;
      transform: rotate(45deg);
      transform-origin: center;

      @media (min-width: 639px) {
        width: 30px;
        top: 8px;
      }
    }

    &:before {
      transform: rotate(-45deg);
    }

    .menu-icon {
      display: none;
    }
    .header__label--menu {
      top: -4px;
    }
  }

  @media (min-width: 640px) {
    margin-top: 18px;
  }
}

.header__label {
  display: block
}

.header__label--menu {
  font-weight: 700;
  position: relative;
  text-transform: uppercase;
  top: -6px;
}

.header__label--customer-account, .header__label--my-plan {
  display: none
}

@media (min-width: 640px) {
  .header__label--customer-account, .header__label--my-plan {
    display: block;
    font-family: 'Lidl Font';
    font-size: 14px;
    font-weight: 400;
    line-height: 10px;
    color: #2f3a44;
  }
}

.header__nav-icons {
  margin: 0;
  padding-left: 0;
  float: right;
  padding-right: 0;
  display: flex;

  @media (min-width: 640px) {
    width: auto
  }
}

.hat {
  &:after {
    content: '';
    display: inline-block;
    width: 26px;
    height: 26px;
    background-image: url("/layouts/frontend/img/svg/hat.svg");
    position: absolute;
    top: -14px;
    right: 18px;
    @media (max-width: 640px) {
      right: 1px;
      right: 8px;
      top: -2px;
    }
  }
}

.top-nav__profile.hat {
  &:after {
    top: -13px;
    right: 2px;
    right: 18px;
    @media (max-width: 648px) {
      top: -5px;
      right: 1px;
    }
  }
}


.header__nav-icon {
  display: inline-block;
  list-style: none;
  margin: 9px 8px 0;
  max-height: 90px;

  @media (max-width: 360px) {
    margin: 9px 6px 0;
  }

  &--customer-account,
  &--my-plan {
    margin-left: 0;
  }

  &--favourites {
    i.icon-heart {
      transition: 1.5s transform ease-in-out, 0.5s color ease-in-out;

      &.animated {
        color: #df0000;
        transform: rotate(720deg);

        @media (min-width: 640px) {

          transform: translateY(-5px) rotate(720deg);
        }
      }
    }
  }

  &:last-child {
    @media (min-width: 640px) {
      margin-right: 0;
    }
  }

  @media (min-width: 640px) {
    float: left;
    margin-top: 5px;
    position: relative;
    width: 70px;
  }
}


.header__link {
  font-size: 23px;
  text-decoration: none;

  &, &:hover {
    color: #2f3a44;
  }

  @media (max-width: 640px) {
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  @media (min-width: 640px) {
    font-size: 30px;
    text-align: center
  }
}


.header__link-icon {
  width: 32px;
  max-height: 32px;
}

.header__link--customer-account:before {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.47 28h-8a15 15 0 1 0-14.93 0h-8C9.82 28 6 32.09 6 37.14V60h48V37.14c0-5.05-3.82-9.14-8.53-9.14zM18 15a12 12 0 1 1 12 12 12 12 0 0 1-12-12zm33 42h-7.5V43h-3v14h-20V43h-3v14H9V37.14C9 33.76 11.48 31 14.53 31h30.94c3.05 0 5.53 2.76 5.53 6.14z' fill='%231d1d1b'/%3E%3C/svg%3E")
}

.header__link--customer-account:before {
  display: inline-block;
  position: relative;
  height: 31px;
  width: 31px;

}

@media (min-width: 640px) {
  .header__link--customer-account:before {
    left: 27%;
    height: 32px;
    width: 32px;
  }

  .header__inner {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    zoom: 1;
    padding-left: 20px;
    padding-right: 20px
  }
}

@media (min-width: 1216px) {
  .header__inner {
    width: 1216px;
  }
}

.logo {
  float: left;
  margin: 0;
  padding-left: 4px
}

@media (min-width: 640px) {
  .logo {
    padding-left: 0
  }
}

.logo__link {
  text-indent: -9999px;
  overflow: hidden;
  white-space: nowrap;
  background-size: cover;
  display: block;
  height: 72px;
  margin: 2px 2px 0;
  width: 72px;
  background: url('/layouts/frontend/img/lidl/lidl-logo-svg.svg') no-repeat;
  background-size: cover;
  @media (max-width: 640px) {
    margin-left: 0;
    height: 40px;
    width: 40px;
    border: 1px solid #fff;
    margin-right: 0;
  }
}

.nav-main {
  background-color: transparent;
  display: block !important;
  font-size: 22px;
  position: fixed;
  transition: visibility 0s linear .4s, background-color .4s linear;
  visibility: hidden;
  width: 100%;
  height: calc(100% - 50px);
  left: 0;

  &.open {
    background-color: rgba(0, 0, 0, .5);
    overflow: auto;
    transition-delay: 0s;
    visibility: visible;
    left: 0;

    .frontend-nav--my-plan > .dropdown-menu {
      visibility: visible;
    }
    .nav-sub--main {
      opacity: 1;
      visibility: visible;
    }
  }

  //@media (max-width: 639px) {
    .frontend-nav--my-plan .dropdown-menu li a {
      text-transform: capitalize;
    }

    .frontend-nav--my-plan > .dropdown-menu {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 500ms;
      font-size: 20px;
      width: 70vw;
      flex-direction: column;
      margin: 0;
      list-style: none;
      font-family: 'Lidl Font';
      position: absolute;
      transform: translateX(100%);
      height: 90vh;
      height: calc(100vh - 102px);
      height: calc(var(--vh, 1vh) * 100 - 102px);
      background: #FFF;
      z-index: 8;
      display: none;

      @media (min-width: 640px) {
        width: 500px;
      }

      &.show {
        transform: translateX(0%);
        opacity: 1;
        display: flex;
      }
      &.show2 {
        transform: translateX(-100%);
        opacity: 0;
      }
      &.hide {
        transform: translateX(100%);
        opacity: 0;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: column;

        li {
          border-bottom: 1px solid #ccc;
          background: #FFF;

          &.dropdown:not(#menuMainTag) {
            .dropdown-menu {
              opacity: 0;
              visibility: hidden;
              position: absolute;
              top: 0;
              left: 0;
              transition: all 500ms;
              font-size: 20px;
              width: 70vw;
              margin: 0;
              flex-direction: column;
              margin: 0;
              display: flex;
              list-style: none;
              font-family: 'Lidl Font';
              position: absolute;
              transform: translateX(100%);
              height: 90vh;
              height: calc(100vh - 102px);
              height: calc(var(--vh, 1vh) * 100 - 102px);
              background: #FFF;
              z-index: 9;

              &.show {
                transform: translateX(0%);
                opacity: 1;
                visibility: visible;
              }
              &.show2 {
                transform: translateX(-100%);
                opacity: 0;
              }
              &.hide {
                transform: translateX(100%);
                opacity: 0;
              }
              ul {
                li {
                  a {
                    &:after {
                      display: none !important;
                    }
                  }
                }
              }
            }

            a {
              position: relative;

              &:after {
                cursor: pointer;
                position: absolute;
                right: 10px;
                top: 50%;
                color: #a5a5a5;
                transform: translateY(-50%);

                content: '\f006';
                font-family: "fontello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: 0.2em;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                margin-left: 0.2em;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
              }
            }
          }

          a {
            color: #000;
          }

          &#menuMoreElement {
            order: -1;
          }

          &#menuTagHeader2:nth-child(3) {
            display: none;
          }
        }

        #menuMainTag {
          border-top: 1px solid #CCCCCC;
          background: #F5F8FC;
          position: absolute;
          bottom: 10px;
          left: 0;
          width: 100%;
          padding: 15px;
          border-bottom: 0;

          > a {
            padding: 0;
            display: flex;
            background: transparent;

            img {
              max-width: 100%;
              max-height: 120px;
            }
          }
          .dropdown-menu {
            display: block;

            ul {
              margin-top: 15px;

              li {
                border: none;
                background: transparent;

                a {
                  display: inline-block;
                  background: transparent;
                  padding: 0;
                  border: 0;
                  text-align: left;
                }

                &#menuMainTag-name a {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 24px;
                  color: #353B42;
                }
                &#menuMainTag-more a {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  color: #0050AA;
                  text-decoration: none;
                  border-bottom: 1px solid #0050AA;
                }
              }
            }
          }
        }
      }
    }
  //}
}


.nav-main.open .nav-main__items {
  left: 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15) inset;
    z-index: 2;
  }

  @media (min-width: 640px) {
    position: fixed;
  }
}

.header.open {
  -webkit-overflow-scrolling: touch
}

//@media (min-width: 640px) {
//  .nav-main {
//    height: 90px;
//    border-top: none;
//    display: block;
//    padding-bottom: 36px;
//    padding-top: 8px;
//    position: relative;
//    transition: none;
//    visibility: visible
//  }
//}

.nav-main__items {
  padding-left: 0;
  background-color: #fff;
  left: -90%;
  list-style: none;
  margin: 0;
  overflow: auto;
  position: relative;
  transition: left .4s;
  width: 90%;
  z-index: 2
}

//@media (min-width: 640px) {
//  .nav-main__items {
//    border-right: none;
//    border-bottom: 1px solid #ccc;
//    margin-bottom: 16px;
//    position: static;
//    width: 100%;
//    overflow: hidden;
//    height: 39px;
//  }
//}

//@media (min-width: 640px) {
//  .nav-main__item {
//    border: 0;
//    display: inline-block
//  }
//}

.nav-main__item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

//@media (min-width: 640px) {
//  .nav-main__item:not(:last-child) {
//    border: 0;
//    display: inline-block
//  }
//}

.nav-main__link {
  display: inline-block;
  margin: 12px 0 12px 32px;
  text-decoration: none;
  color: #353B42;
}

//@media (min-width: 640px) {
//  .nav-main__link {
//    border-bottom: 4px solid transparent;
//    font-family: 'Lidl Font';
//    font-size: 22px;
//    line-height: 27px;
//    margin: 0 20px 0 0;
//    padding-bottom: 5px
//  }
//
//  .nav-main__link:hover {
//    border-bottom: 4px solid #646A70;
//  }
//}

.nav-main__link:hover {
  text-decoration: none;
  color: #2F3A44;
}


.nav-main__link--active {
  color: $lidlPrimaryColor;
}

.nav-main__item--nav--sale {
  color: #E60A14;
  &:hover {
    color: #C10811;
    border-bottom-color: #C10811;
  }
}

@media (min-width: 640px) {
  .nav-main__link--active {
    border-bottom: 4px solid $lidlPrimaryColor;
  }
}

@media (min-width: 640px) {
  .nav-main__link--active:hover {
    border-bottom: 4px solid #ccc
  }
}

.nav-meta {
  background: $lidlGrey;
  display: none;
  font-size: 17px;
  line-height: 17px;
  padding: 0;
  border-bottom: solid 1px #EAEBEC;

  @media (min-width: 640px) {
    display: block;
    height: 32px;
  }
}


.nav-meta__inner {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  zoom: 1;
  padding-left: 16px;
  padding-right: 16px
}

.nav-meta__inner:after,
.nav-meta__inner:before {
  content: "";
  display: table
}

.nav-meta__inner:after {
  clear: both
}

@media (min-width: 768px) {
  .nav-meta__inner {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    zoom: 1;
    padding-left: 24px;
    padding-right: 24px
  }
}


.nav-meta {

  &__inner {
    display: flex;
    max-width: 1216px;
    width: 100%;
    @media (max-width: 900px) {
      justify-content: flex-end;
    }
  }

  &__box {
    display: inline-flex;
  }
  &__country {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      margin-bottom: 0;
      list-style-type: none;
      padding-left: 0;
      li {
        padding: 8px 5px;
        font-size: 15px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
          background-color: #EAEBEC;
        }

        a {
          color: #2F3A44;
        }
      }
    }
  }
}

.nav-meta__items {
  float: right;
  margin: 0;
}

.nav-meta__item {
  display: inline-block;
  height: 32px;
  list-style: none;
  margin-right: 0;
  position: relative;

  &:hover {
    text-decoration: underline;
    background-color: #EAEBEC;

    a {
      color: #2F3A44;;
    }
  }
}

.nav-meta__link {
  display: block;
  padding: 0 2px;
  text-decoration: none;
  line-height: 32px;
  padding-left: 8px;
  padding-right: 8px;
}

.nav-meta__country-name {
  display: inline-block;
  line-height: 32px;
}

@media (max-width: 900px) {
  .nav-meta__country-name {
   display: none;
  }
}

.nav-meta__country-name,
.nav-meta__link {
  color: #2F3A44;
  font-size: 15px;
  font-weight: 400;
  vertical-align: sub;
  flex: 1;
}

.nav-sub {
  display: flex;
  list-style: none;
  font-family: 'Lidl Font';
  position: relative;

  //@media (min-width: 639px) {
  //  > li > a {
  //    display: block;
  //    padding-bottom: 8px;
  //    padding-right: 16px;
  //  }
  //
  //  > .dropdown {
  //
  //    &.event--plan {
  //      > .dropdown-menu {
  //        height: 245px;
  //
  //        > ul {
  //
  //          > li {
  //            &:first-child {
  //              padding-left: 53px;
  //            }
  //
  //            min-width: 110px;
  //            margin: 20px 25px 10px 0;
  //
  //            ul {
  //              display: flex;
  //              flex-wrap: wrap;
  //              flex-direction: column;
  //              margin-top: 10px;
  //              max-height: 100%;
  //
  //              a {
  //                color: #0050AA;
  //
  //                text-decoration: none;
  //                border-bottom: 1px solid #0050AA;
  //                display: inline-block;
  //                margin-bottom: 5px;
  //                line-height: 24px;
  //
  //                &:hover {
  //                  color: #003673;
  //                  border-color: #003673;
  //                }
  //              }
  //            }
  //
  //            > a {
  //              pointer-events: none;
  //              border: 0;
  //              color: #4E5761;
  //              pointer-events: none;
  //              min-height: 25px;
  //            }
  //          }
  //        }
  //      }
  //    }
  //
  //    > a.dropdown-toggle {
  //      position: relative;
  //      padding-right: 35px;
  //
  //      &:after {
  //        content: '';
  //        position: absolute;
  //        right: 11px;
  //        top: 6px;
  //        display: block;
  //        width: 18px;
  //        height: 18px;
  //        background: url('/layouts/frontend/img/lidl/menu_arrow.svg') center no-repeat;
  //        transform: rotate(180deg);
  //     }
  //    }
  //
  //    ul {
  //      list-style-type: none;
  //      margin: 0;
  //      padding: 0;
  //    }
  //
  //    &:hover {
  //      > a.dropdown-toggle {
  //        &:after {
  //          transform: rotate(0deg);
  //        }
  //      }
  //
  //      > .dropdown-menu {
  //        visibility: visible;
  //        opacity: 1;
  //      }
  //    }
  //
  //    > .dropdown-menu {
  //      visibility: hidden;
  //      opacity: 0;
  //      transition: 0.25s visiblity, 0.25s opacity;
  //
  //      height: 380px;
  //      position: absolute;
  //      width: 100vw;
  //      left: calc(-50vw + 580px);
  //      top: 35px;
  //      background: #FFF;
  //      border: 1px solid #E3E4E5;
  //      border-left: 0;
  //      border-right: 0;
  //
  //      > ul {
  //        width: 1174px;
  //        margin: 0 auto;
  //        display: flex;
  //        max-width: 100%;
  //
  //        > .dropdown {
  //
  //          &.flyout-menu-tag-header {
  //            min-width: 110px;
  //            margin: 20px 25px 10px 0;
  //
  //            > a {
  //              display: block;
  //              font-weight: 400;
  //              font-size: 18px;
  //              line-height: 25px;
  //              color: #4E5761;
  //              pointer-events: none;
  //              margin-bottom: 6px;
  //              min-height: 25px;
  //            }
  //
  //            .dropdown-menu {
  //              height: 220px;
  //              overflow: hidden;
  //
  //              ul li a {
  //                color: #0050AA;
  //                text-decoration: none;
  //                border-bottom: 1px solid #0050AA;
  //                display: inline-block;
  //                margin-bottom: 5px;
  //                text-transform: capitalize;
  //                line-height: 24px;
  //
  //                &:hover {
  //                  color: #003673;
  //                  border-color: #003673;
  //                }
  //              }
  //            }
  //          }
  //
  //          &#menuMainTag {
  //            position: relative;
  //            z-index: 11;
  //
  //            width: 350px;
  //            height: 378px;
  //            background: #F5F8FC;
  //            padding: 36px;
  //            margin-left: auto;
  //
  //            border: 1px solid #E3E4E5;
  //            border-top: 0;
  //            border-bottom: 0;
  //
  //            > a {
  //              display: block;
  //            }
  //
  //            img {
  //              width: 248px;
  //              display: block;
  //              margin: 0 auto;
  //              max-height: 230px;
  //            }
  //
  //            .flyout-menu-main-tag-element-name {
  //              font-weight: bold;
  //              margin-top: 15px;
  //
  //              a {
  //                color: #353B42;
  //              }
  //            }
  //
  //            .flyout-menu-main-tag-element-more {
  //              margin-top: 8px;
  //
  //              a {
  //                color: #0050AA;
  //                text-decoration: none;
  //                border-bottom: 1px solid #0050AA;
  //
  //                &:hover {
  //                  color: #003673;
  //                  border-color: #003673;
  //                }
  //              }
  //            }
  //          }
  //        }
  //
  //        #menuMoreElement {
  //          z-index: 10;
  //          position: absolute;
  //          bottom: 0;
  //          left: 0;
  //          width: calc(100vw - 700px);
  //          height: 80px;
  //          display: flex;
  //          align-items: center;
  //          border-top: 1px solid #E3E4E5;
  //
  //          a {
  //            display: block;
  //            font-size: 22px;
  //            margin-left: calc(50vw - (1174px / 2));
  //
  //            line-height: 28px;
  //            color: #0050AA;
  //            text-decoration: none;
  //            border-bottom: 1px solid #0050AA;
  //
  //            &:hover {
  //              color: #003673;
  //              border-color: #003673;
  //            }
  //          }
  //        }
  //      }
  //
  //
  //    }
  //  }
  //}

  //@media (max-width: 639px) {
    flex-direction: column;
    margin: 12px 0 12px 32px;
  //}
}

.frontend-nav--my-plan {
  //@media (max-width: 960px) {
    padding-right: 20px;
  //}
  a {
    color: #2f3a44;
  }
}

//@media (min-width: 640px) {
//  .nav-sub {
//    bottom: 0;
//    left: 0;
//    position: absolute;
//    width: 100%
//  }
//}

.nav-sub__link {
  display: inline-block;
  font-size: 20px;
  margin: 12px 0 12px 60px;
  text-decoration: none;
  color: #2f3a44;
}

//@media (min-width: 640px) {
//  .nav-sub__link {
//    margin: 0 32px 0 0
//  }
//}

.nav-sub__link:hover {
  color: #ccc;
}

//@media (min-width: 640px) {
//  .nav-sub__item {
    //float: left
  //}
//}

.menu-icon {
  width: 31px;
  height: 26px;
  margin: 0 auto 2px auto;

  @media (min-width: 639px) {
    margin: -4px auto 6px auto;
  }
}


 .navigation-back {
   display: none;
   text-decoration: none;
   font-weight: 300;
   color: $lidlGrey;
   background: #d5d8da;
   -webkit-transition: all .5s ease;
   transition: all .5s ease;
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   font-size: 20px;
   cursor: pointer;
   color: #868e96;
 }

//@media (max-width: 639px) {
  .navigation-back {
    display: block;

    &.hide {
      display: none;
    }
  }

  .frontend-nav li {
    background: #FFF;
  }

  .frontend-nav--my-plan {
    margin: 6px 0 12px 20px;

    &.dropdown {
      > a {
        position: relative;

        &:after {
          cursor: pointer;
          position: absolute;
          right: -10px;
          top: 50%;
          color: #a5a5a5;
          transform: translateY(-50%);

          content: '\f006';
          font-family: "fontello";
          font-style: normal;
          font-weight: normal;
          speak: none;
          display: inline-block;
          text-decoration: inherit;
          width: 1em;
          margin-right: 0.2em;
          text-align: center;
          font-variant: normal;
          text-transform: none;
          line-height: 1em;
          margin-left: 0.2em;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }

  #navigation-back2,
  #navigation-back3,
  #navigation-back4,
  #navigation-back5 {
    display: none;
  }

  .navigation-back .nav-main__link {
    margin: 10px 0 10px 20px;

    @media (max-width: 639px) {
      margin: 8px 0 8px 20px;
    }
  }

  .nav-sub--main {
    position: absolute;
    top: 50px;
    left: 0;
    transition: transform 500ms;
    font-size: 20px;
    width: 100%;
    margin: 0;

    @media (max-width: 639px) {
      top: 45px;
    }
  }

  .frontend-nav--my-plan {
    margin: 0;
  }

  .frontend-nav--my-plan:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }

  .frontend-nav--retterfilter a,
  .frontend-nav--my-plan a {
    padding: 12px 0 12px 32px;
    display: block;
    background: #FFF;
  }


  .nav-sub--main .nav-sub__link {
    margin: 12px 0 12px 30px;
  }

  .nav-main__item--nav-main {
    transition: transform 500ms;
    transform: translateX(-100%);
    font-size: 20px;
  }

  .nav-main__item--nav-main.show {
    opacity: 1;
    visibility: visible;
    transform: translateX(0) translateY(0px);
    z-index: 100;
    background-color: white;
    position: relative;
  }

  .nav-main__link--active.show {
    transform: translateX(0);
    transition: transform 300ms;
  }

  .nav-main__items {
    height: 100%;
    width: 70vw;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (min-width: 640px) {
      width: 500px;
      overflow-y: auto;
    }
  }
//}

.header {
  .calendar-icon {
    color: black;
    position: relative;
    display: flex;
    font-size: 32px;

    @media (min-width: 640px) {
      display: block;
      font-size: 30px;
      -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }
}


.to-my-menu-nav-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  color: #a5a5a5;
  transform: translateY(-50%);

  @media (min-width: 640px) {
    display: none;
  }
}

.header__nav-icon--prospekte .prospekte {
  display: block;

  @media (min-width: 640px) {
    width: 70px;
    height: 45px;
    -webkit-transform: translateY(3px);
    transform: translateY(3px);
  }

  &:before {
    display: block;
    margin: 0 auto;
    content: '';
    width: 32px;
    height: 32px;
    mask-image: url('/layouts/frontend/img/lidl/prospekte.svg');
    -webkit-mask-image: url('/layouts/frontend/img/lidl/prospekte.svg');
    mask-size: cover;
    -webkit-mask-size: cover;
    background-color: #353B42;

    @media (min-width: 640px) {
      width: 30px;
      height: 30px;
    }
  }
}

.header__nav-icon--newsletter {

  @media (max-width: 640px) {
    margin-right: 7px !important;
    margin-left: 0;
  }

  .newsletter {
    display: block;

    @media (min-width: 640px) {
      width: 70px;
      height: 45px;
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
    }

    &:before {
      display: block;
      margin: 0 auto;
      content: '';
      width: 32px;
      height: 32px;

      background: url('/layouts/frontend/img/lidl/newsletter.png') no-repeat;
      background-size: 32px 32px;

      @media (min-width: 640px) {
        width: 30px;
        height: 30px;
        background-size: 30px 30px;
      }
    }
  }
}

.header__nav-icon--search-recipes .search-recipes {
  display: block;

  @media (min-width: 640px) {
    width: 70px;
    height: 35px;
  }

  &:before {
    display: block;
    margin: 0 auto;
    content: '';
    width: 32px;
    height: 32px;
    mask-image: url('/layouts/frontend/img/lidl/rezeptwelt.svg');
    -webkit-mask-image: url('/layouts/frontend/img/lidl/rezeptwelt.svg');
    mask-size: cover;
    -webkit-mask-size: cover;
    background-color: #353B42;

    @media (min-width: 640px) {
      width: 30px;
      height: 30px;
      margin: 10px auto 0 auto;
    }
  }
}

.nav-main__item-ext,
.nav-main__item-ext li {
  list-style: none;
  padding: 0;
  margin: 0;

  a {
    font-size: 0.875rem;

    &:hover {
      background-color: #e3e4e5;
      text-decoration: underline;
    }
  }

  .nav-main__link-ext {
    display: inline-block;
    padding: 0.5rem;
    text-decoration: none;
    color: #353B42;
  }
}
