.recipeSearchBottomBar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0 20px;

  .cookbook__search__activeFilters {
    max-width: 100%;
  }

  .cookbook__search__sorting {
    margin-top: 6px;
  }
}