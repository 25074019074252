@mixin button-animation {
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    color: $lidlPrimaryColor;
    background-color: white;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 0;
    @include transition(0.2s all);
  }

  &:hover {
    text-decoration: none;

    &:after {
      opacity: 1;
    }
  }
}



.button {
  background: $lidlPrimaryColor;
  border: 3px solid $lidlPrimaryColor;
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  height: 50px;
  line-height: 46px;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 10px;



  span,
  svg {
    color: white;
    position: relative;
    z-index: 1;
  }

  &:hover {
    text-decoration: none;
    color: $lidlPrimaryColor;

    span,
    svg {
      color: $lidlPrimaryColor;
    }
  }

  @include button-animation;

  @include media-breakpoint-down(xs) {
    span {
      font-size: 0.9rem;
    }

    font-size: 0.9rem;
  }
}

.button--black {
  @extend .button;
  background-color: black;
  border: 1px solid black;
  color: white;

  &:hover {
    text-decoration: none;
    color: black;

    &:after {
      background-color: $dirty_white;
    }
  }
}

.button--gray {
  @extend .button;
  background-color: $lightGray;
  border: 1px solid $lightGray;
  color: white;
}

.button--profiling {
  @extend .button;
  padding: 0;
  width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.2em;
  display: inline-block;
}

.button--popup {
  @extend .button;
  padding: 0 30px;
  width: 300px;
}

.button--forms {
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
}

.button--login {
  @extend .button;
  width: 100%;
}

.button--cookbook {
  @extend .button;
  margin: 50px auto;
  width: 200px;
}

.button--category-add {
  width: 200px;
  max-width: 100%;
  margin: 0 2px;
}

.button--frisco {
  padding: 0 10px;
  width: 200px;
  font-weight: bold;
}

.button__recipe--add {
  width: 270px;

  @include media-breakpoint-down(xs) {
    margin-right: 0 !important;
  }
}

.button--filters {
  padding: 0 10px;
  width: 150px;
  max-width: 100%;
  font-weight: bold;
  height: 46px;
  line-height: 46px;
  border: 3px solid $lidlPrimaryColor;
  flex: 0.6;

  @include media-breakpoint-down(xs) {
    padding: 0 7px;
  }
}

.button-lidl {
  width: 185px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2em;
  color: black;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border: 0;
  font-size: 18px;
  text-transform: uppercase;

  &--autoWidth {
    width: auto;
  }

  &--fullWidth {
    width: 100%;
  }

  @include transition(0.2s all);

  @include media-breakpoint-down(sm) {
    margin-right: 1em;
  }

  &:hover {
    color: initial;
    text-decoration: underline;
  }
}

.button-lidl__custom {
  @extend .button-lidl;

  //  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, .3));
  //  color: #FFF;
  //
  &:hover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
    //    color: #FFF;
    //
    //    i {
    //      path {
    //        fill: #FFF !important;
    //      }
    //    }
  }
}

.button-lidl__custom--bordered {
  @extend .button-lidl;
  border: 3px solid $lidlYellow;
  color: white;


  &:hover {
    background: $lidlYellow;
    color: black;
  }
}

.button-lidl__yellow {
  @extend .button-lidl;
  background: $lidlYellow;
  border: 3px solid $lidlYellow;

  &:hover {
    background: transparent;
    color: white;
  }
}

.button-lidl__yellow--bordered {
  @extend .button-lidl;
  border: 3px solid $lidlYellow;
  color: white;

  &:hover {
    background: $lidlYellow;
    color: black;
  }
}

.button-lidl__blue {
  @extend .button-lidl;
  background: $lidlPrimaryColor;
  color: #FFF;

  &:hover {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
    color: #FFF;

    i {
      path {
        fill: #FFF !important;
      }
    }
  }
}

.button-lidl__green {
  @extend .button-lidl;
  background: #348553;
  color: #FFF;

  &:hover {
    background-image: darken(#348553, 15%);
    color: #FFF;
  }
}

.button-lidl__white {
  @extend .button-lidl__blue;
  color: $lidlPrimaryColor;
  background: #FFF;
  background-image: none;

  &:hover {
    color: $lidlPrimaryColor;
  }
}

.button-lidl__blueAutoWidth {
  @extend .button-lidl__blue;
  width: auto;
  padding: 0 15px;
  margin-right: 0;
}

a.button-lidl__blueAutoWidth {
  display: inline-block;
  line-height: 48px;
}

.button-lidl__blue--bordered {
  @extend .button-lidl;
  background: white;
  border: 2px solid $lidlPrimaryColor;

  &,
  span {
    color: $lidlPrimaryColor;
  }

  &:after {
    background: $lidlPrimaryColor;
  }

  &:hover {
    background: $lidlPrimaryColor;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
    border-color: darken($lidlPrimaryColor, 10%);

    &,
    span {
      color: white;
    }
  }
}

.button--withIcons {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    svg {
      path {
        fill: $lidlPrimaryColor;
      }
    }
  }
}

.button--autoHeightMobile {
  @include media-breakpoint-down(xs) {
    height: auto;
    line-height: 1.2em;
    padding: 5px 10px;
  }
}

.button--white {
  @extend .button-lidl;

  border: 0;
  background-color: white;
  border-radius: 2px;

  span {
    color: $lidlDarkBlue;
  }

  path {
    fill: $lidlDarkBlue;
  }


  @include transition(0.2s all);

  &:hover {

    &,
    &:after {
      background-color: white;
    }

    span {
      color: $lidlDarkBlue;
    }

    path {
      fill: $lidlDarkBlue;
    }
  }
}

.buttonBubble {
  $self: &;
  position: relative;

  .add-to-shopping-list {
    margin-top: 15px;
  }

  &__fixedBg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    translate: 0.5s all;
    z-index: 99;

    &--visible {
      display: block;
    }

    +.buttonBubble__element {
      z-index: 99;
    }
  }

  &__element {
    margin-top: 15px;
    position: absolute;
    padding: 15px 18px;
    background: #30303B;
    border-radius: 2px;
    color: #FFF;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    transition: 0.25s all;
    opacity: 0;
    visibility: hidden;
    z-index: 1;

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    &__tip {
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5em;
      font-family: 'Lidl Font';
      font-weight: bold;
      color: #FFF;

      .button-lidl__blue {
        width: auto;
        height: 35px;
        margin-top: 10px;
        padding: 0 12px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: #30303B;
      border-top: 0;
      margin-left: -10px;
      margin-top: -10px;
    }

    &__close {
      display: block;
      position: absolute;
      right: 5px;
      top: 5px;
      color: #fff;
      font-size: 24px;
      padding: 5px;
      text-decoration: none;
    }

    strong {
      display: block;
      padding-bottom: 5px;
      font-weight: bold;
    }

    p {
      margin-bottom: 0;
    }
  }

  &--wider {
    @include media-breakpoint-down(xs) {
      width: 100%;
      //max-width: 320px;
      //margin-bottom: 20px;
    }

    #{$self}__element {
      width: 300px;
      left: 50%;
      margin-left: -150px;

      @include media-breakpoint-down(sm) {
        margin-left: -100px;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-left: -50%;
      }
    }
  }

  &--featureBubble {
    #{$self}__element {
      background: #F7A606;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
      transition: 0.25s opacity, 0.25s visibility;

      &:after {
        border-bottom-color: #F7A606;
      }

      strong {
        display: block;
        font-size: 14px;
        padding-bottom: 8px;
      }
    }
  }
}


.buttoned {
  background: $lidlPrimaryColor;
  border: 3px solid $lidlPrimaryColor;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  height: 50px;
  line-height: 46px;
  text-align: center;
  text-decoration: none;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 10px;

  &:hover {
    background: #FFF;
    border-color: $lidlPrimaryColor;
    color: $lidlPrimaryColor;
  }
}

.radio-button {
  label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 0;
    padding-bottom: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
      &:after {
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #1B517D;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked~.checkmark {
        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.radio-button-blue {

  label {
    display: block;
    position: relative;
    padding-left: 65px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .checkmark {
      &:after {
        left: 16px;
        top: 10px;
        width: 8px;
        height: 14px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #348553;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked~.checkmark {
        &:after {
          display: block;
        }

        &:before {
          display: block;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    cursor: pointer;
    top: 2px;
    left: 0;
    height: 40px;
    width: 40px;
    background-color: $lidlPrimaryColor;
    border-radius: 50%;
    background-image: url("/layouts/frontend/img/svg/add.svg");
    background-repeat: no-repeat;
    background-position: center;


    &:after {
      content: "";
      position: absolute;
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      display: none;
    }
  }

  &--red {
    .checkmark {
      background-color: red;
    }
  }
}

.close-popup-btn-blue {
  width: 12px !important;
  height: 12px !important;
  background-repeat: no-repeat;
  background-size: cover;
  display: block !important;

  &:before,
  &:after {
    display: none;
  }
}

.popup--open .close-popup-btn-blue {
  background-image: url("/layouts/frontend/img/svg/closeModal.svg");
}

.arrow-back-mobile {
  display: none;

  @include media-breakpoint-down(xs) {
    background-image: url("/layouts/frontend/img/svg/arrowBack.svg");
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    display: block;
    cursor: pointer;
  }
}