// COMMON PART FOR FRONTEND NAV
.frontend-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    font-size: 18px;

    a {
      color: $navLinkColor;

      &:hover {
        text-decoration: none;
        color: $lidlPrimaryColor;
      }
    }

    &.active a {
      color: $lidlPrimaryColor;
    }
  }
}

// COMMON PART FOR USER PROFILE
.mobile-nav__profile,
.top-nav__profile {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 4px 8px 0;

  @media (max-width: 639px) {
    margin-top: 0;
    margin-left: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.nav__profile-img {
  height: 38px;
  width: 38px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  max-height: 40px;
  border: 1px solid;
  margin: 0 auto;

  @media (max-width: 639px) {
    height: 36px;
    width: 36px;
  }
}

.profile-nav {
  position: absolute;
  top: 65px;
  background-color: white;
  padding: 10px;
  text-align: center;
  list-style-type: none;
  transform-origin: 0 0;
  transform: scaleY(0);
  z-index: 100;
  width: 120px;
  border: 1px solid;
  font-size: 16px;

  @media (max-width: 639px) {
    left: -82px;
    top: 56px;
  }

  @include transition(0.2s transform ease-in-out);
}

.profile-nav__item {
  padding: 3px 0;

  a {
    color: black;

    &:hover {
      color: black;
    }
  }
}

.top-nav__profile {
  &:hover {
    .profile-nav {
      transform: scaleY(1);
    }
  }
}

// MOBILE NAVIGATION
.mobile-nav {
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  padding-top: 100px;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: transform .3s ease;
  width: 100%;
  z-index: 998;

  &.open {
    transform: translateY(0);
  }

  .frontend-nav {
    align-items: center;
    flex-direction: column;

    li {
      margin-bottom: 25px;
    }
  }

  .top-nav__logout, .top-nav__login {
    color: black;
    text-transform: uppercase;
    margin-top: 25px;
  }
}

// TOP NAVIGATION BAR
.top-nav {
  align-items: center;
  background-color: $navBarBackground;
  display: flex;
  height: 80px;
  justify-content: space-between;
  left: 0;
  padding: 0 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;

  @include media-breakpoint-down(sm) {
    height: 80px;
    position: relative;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 25px;
  }

  .frontend-nav {
    @include media-breakpoint-down(sm) {
      display: none;
    }

    li {
      &:not(:last-of-type) {
        margin-right: 60px;
      }
    }
  }

  .top-nav__logout {
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 60px;
    justify-content: center;
    padding: 0;
    position: fixed;
    right: -450px;
    top: 100px;
    width: 20vw;
    z-index: 999;

    @include transition(0.3s right ease-in-out);
    &.open {
      right: 0;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.top-nav__right-part {
  align-items: center;
  display: flex;
}

.top-nav__logo {
  margin-right: 80px;
  height: 50px;
}

.top-nav__logout, .top-nav__login {
  text-decoration: none;
  text-transform: uppercase;

  a {
    color: black;
    font-size: 1rem;
  }
}
