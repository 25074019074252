.shoplist-landing-main {
  &.section5 {
    &.bgImageActive {
      &:after {
        background-image: url("/layouts/frontend/img/shoplist-landing/section5-phone.png");
      }
      .shoplist-section {
        &__leftBox {
          background-image: url("/layouts/frontend/img/shoplist-landing/section5-background2.png");
          &:after {
            @include media-breakpoint-down(sm) {
              background-image: url("/layouts/frontend/img/shoplist-landing/section5-phone-mobile.png");
            }
          }
        }
      }
    }

    &:after {
      width: 320px !important;
      bottom: 0;
      top: initial;
    }
    .shoplist-section {
      &__leftBox {
        &:after {
          @include media-breakpoint-down(sm) {
            width: 237px !important;
            height: 288px !important;
            bottom: 0;
            top: initial !important;
          }
        }
      }
    }
  }
}
