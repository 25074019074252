.loader--ingredient {
  width: 42%;
  position: absolute;
  right: -15px;
  left: unset;
  height: 50px;
  top: 0;
  background: $white;

  .sk-folding-cube {
    margin: -10px auto 0 auto;
    width: 20px;
    height: 20px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
    top: 50px;
  }
}

.loader--ingredients {
  width: calc(100% + 20px);
  background: $white;

  .sk-folding-cube {
    margin: -10px auto 0 auto;
    width: 20px;
    height: 20px;
  }
}
