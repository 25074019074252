.diets-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  @media (max-width: 650px) {
    justify-content: space-between;
  }
}

.diet {
  width: 33.3%;
  margin: 15px 0 0;
  position: relative;
  order: 3;

  @media (max-width: 650px) {
    width: 50%;
  }

  &--id1 {
    order: 1;
  }
  &--id5 {
    order: 2;
  }
}

.diet__name {
  margin-top: 1em;
  font-size: 0.8em;
}

.diet__image {
  -webkit-filter: #{ "brightness(0)" };
  filter: #{ "brightness(0)" };
  opacity: 0.3;
  cursor: pointer;
  width: 70px;
  height: 70px;

  &.selected, &:hover {
    opacity: 1;
    filter: unset;
  }
}

.diet__tooltip {
  background-color: white;
  border: 1px solid black;
  display: none;
  font-size: 0.67em;
  font-weight: 700;
  left: 18px;
  letter-spacing: 1px;
  padding: 2px 14px;
  position: absolute;
  text-transform: uppercase;
  top: -40px;
  &:after {
    bottom: -17px;
    content: url('/layouts/frontend/img/triangle-down.png');
    left: 10px;
    position: absolute;
  }
}
