.fitnessLanding {
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }

  .feeds {
    background: #FFF;

    .feedElement__recipes__recipe .single-item__name {
      background: #FFF;
    }
  }

  &__banner {
    background: url('/layouts/frontend/img/fitness-landing/title_bg_desktop-v3.png') right top no-repeat;
    background-size: cover;
    color: #FFF;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      background: url('/layouts/frontend/img/fitness-landing/title_bg_mobile-v2.jpg') no-repeat center;
    }

    .feedSection__title {
      margin-top: 15px;
      white-space: normal !important;
      line-height: 50px !important;
      font-size: 42px !important;
      font-weight: 700 !important;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.8);

      @include media-breakpoint-down(sm) {
        margin-bottom: 0 !important;
        margin-top: 0;

        font-size: 34px !important;
        line-height: 41px !important;
      }
    }
  }

  .feeds__box {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .feedSection--popularPlansList {
    position: relative;
    padding: 60px 0;
    margin: 60px 0 0;

    @include media-breakpoint-down(sm) {
      padding: 30px 15px;
      margin: 15px -15px 20px;
    }

    .feedSection__title {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      max-width: 100%;
      color: #000;
    }

    .feedSection__desc {
      font-size: 18px;
      line-height: 25px;
      max-width: 100%;
      margin-top: 10px;
      color: #000;
    }
  }

  &__section3_1,
  &__section3 {
    position: relative;
    margin-top: 15px;
    height: 320px;
    border: 0;
    padding: 35px 48px;

    background-color: #FFF;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    &.bgImageActive {
      background-image: url('/layouts/frontend/img/fitness-landing/plan_desktop.jpg?v=2');
    }

    @include media-breakpoint-down(sm) {
      height: auto;
      padding: 240px 16px 20px;
      border: 1px solid #F2F2F2;

      &:before {
        content: '';
        position: absolute;
        top:0; left: 0;
        width: 100%;
        height: 225px;
        background-size: cover;
        background-position: top center;
        background-repeat: no-repeat;
      }

      &.bgImageActive {
        background-image: none;

        &:before {
          background-image: url('/layouts/frontend/img/fitness-landing/plan_mobile.jpg');
        }
      }
    }

    h3 {
      font-weight: 600;
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 10px;
    }

    &__content {
      padding: 15px 0 0;
      font-size: 18px;
      line-height: 25px;
      max-width: 400px;

      @include media-breakpoint-down(sm) {
        max-width: 100%;
        font-size: 16px;
        line-height: 22px;
      }

      ul {
        padding-left: 22px;

        li {
          margin-bottom: 5px;
        }
      }
    }

    &__button {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  &__section3_1 {
    margin: 30px 0;
    background-size: contain;
    background-position: center right;
    background-repeat: no-repeat;
    color: #FFF;
    height: 280px;

    &.bgImageActive {
      background-image: url('/layouts/frontend/img/fitness-landing/section_3_1_new.png');
    }

    @include media-breakpoint-down(sm) {
      height: auto;
      background-color: #73BDEA;

      &:before {
        content: '';
        position: absolute;
        top:0; left: 0;
        width: 100%;
        height: 214px;
        background-size: cover;
        background-position: center right;
        background-repeat: no-repeat;
      }

      &.bgImageActive {
        background-image: none;

        &:before {
          background-image: url('/layouts/frontend/img/fitness-landing/section_3_1_mobile_new.png');
        }
      }
    }
  }

  .feedSection--fitnessLanding {

    &__section6 {

      @include media-breakpoint-down(sm) {
        margin: 20px -10px;
        padding: 25px 10px !important;
      }

      &.bgImageActive:before {
        background: url('/layouts/frontend/img/fitness-landing/notes_image.jpg');
        background-size: cover;
      }

      .feedSection__desc {
        width: 100%;
      }

      &__columns {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          margin-top: 20px;
        }

        ol {
          list-style-type: none;
          width: 48%;
          padding: 0;
          margin: 20px 0 8px 0;

          @include media-breakpoint-down(sm) {
            width: 100%;
            margin: 0;
          }
        }
      }
    }

  }

  &__section12 {
    margin: 70px 0 100px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }

    h4 {
      text-align: center;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        text-align: left;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 10px;
      }
    }

    &__articles {
      display: flex;
      justify-content: center;
      align-items: stretch;

      @include media-breakpoint-down(sm) {
        overflow: scroll;
        justify-content: flex-start;
        margin-left: -16px;
        margin-right: -22px;
        padding: 16px;
      }

      &__article {
        margin: 0 8px;
        width: 305px;
        height: 325px;

        @include media-breakpoint-down(sm) {
          width: 250px;
          min-width: 250px;
          height: 270px;

          &:first-child {
            margin-left: 0;
          }
        }

        a {
          display: block;
          background: #FFFFFF;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
          color: #000;
          text-decoration: none;
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;

          @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 19px;
          }

          span {
            display: block;
            padding: 16px;
            min-height: 82px;

            @include media-breakpoint-down(sm) {
              min-height: 70px;
            }
          }

          img {
            max-width: 100%;
          }
        }
      }
    }

    &__buttons {
      display: flex;
      justify-content: center;
      margin: 35px 0 40px;

      @include media-breakpoint-down(sm) {
        margin-top: 10px;
      }

      .button-lidl__blue {
        margin: 0;

        @include media-breakpoint-down(sm) {
          width: 100%;

        }
      }
    }
  }

  &__seo {
    padding-top: 50px;

    h3 {
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      padding-bottom: 15px;

      @include media-breakpoint-down(sm) {
        font-size: 28px;
        line-height: 33px;
        padding-bottom: 10px;
      }
    }

    p {
      margin-bottom: 1.6rem;
    }

    &__hr {
      margin: 48px 0;
      border-top: 1px solid #EEEEEE;

      @include media-breakpoint-down(sm) {
        margin: 28px 0;
      }
    }

    &__section1 {
      display: flex;
      align-items: center;

        @include media-breakpoint-down(sm) {
          flex-direction: column-reverse;
        }

      img {
        width: 428px;
        margin-right: 70px;

          @include media-breakpoint-down(sm) {
            margin-right: 0;
            width: auto;
            max-width: 100%;
          }
      }
    }

    &__section2 {
      background: #DEEBE3;
      border-radius: 4px;
      padding: 30px 32px;

      font-size: 22px;
      line-height: 31px;

      @include media-breakpoint-down(sm) {
        padding: 16px;
        font-size: 16px;
        line-height: 22px;

        h3 {
          font-size: 20px;
          line-height: 24px;
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;

        list-style-type: none;
        max-width: 70%;
        margin-left: 0;
        padding-left: 0;
        margin-top: 30px;
        margin-bottom: 10px;

        @include media-breakpoint-down(sm) {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        li {
          display: flex;
          align-items: center;
          min-width: 190px;

          font-size: 18px;
          line-height: 25px;
          margin-bottom: 24px;

          @include media-breakpoint-down(sm) {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 14px;
          }

          &:before {
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background: url('/layouts/frontend/img/fitness-landing/check.svg');
            margin-right: 16px;
          }
        }
      }

      p:last-child {
        margin-bottom: 10px;
      }
    }

    &__section3 {
      &__container {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        img {
          width: 307px;
          margin-left: 70px;

          @include media-breakpoint-down(sm) {
            margin-left: 0;
            width: auto;
            max-width: 100%;
          }
        }
      }

      &__box {
        margin-top: 32px;
        padding: 30px 32px;
        background: #D6E3F1;
        border-radius: 4px;

        font-size: 22px;
        line-height: 31px;

        @include media-breakpoint-down(sm) {
          padding: 16px;
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    &__section4 {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column-reverse;
      }

      img {
        width: 447px;
        height: 309px;
        margin-right: 70px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
          width: auto;
          height: auto;
          max-width: 100%;
        }
      }
    }

    &__section5 {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }

      img {
        width: 374px;
        margin-left: 70px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
          width: auto;
          max-width: 100%;
        }
      }

      &:after {
        content: '';
        display: block;
      }
    }
  }
}
