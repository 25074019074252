.view-new .recipeImage__ribbon {
  display: flex;
  height: 88px;
  justify-content: space-between;
  //justify-content: flex-end;
  align-items: center;
  position: relative;

  @include media-breakpoint-down(sm) {
    height: 47px;
  }

  &, &:hover {
    color: #FFF;
    text-decoration: none;
  }

  &__title {
    margin-left: 190px;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
      font-size: 20px;
      line-height: 24px;
      margin-left: 110px;
    }
  }

  &__label.slider__item__promo {
    position: absolute;
    left: 18px;
    bottom: 18px;

    width: 140px;
    height: auto;

    z-index: 12; /* for video view*/

    @include media-breakpoint-down(sm) {
      .recipeVideoView & { /* body.recipeVideoView */
        display: none;
      }
    }

    &--sally, &--timo {
      left: 8px;
      bottom: 8px;
      width: 180px !important;
      height: auto !important;

      @include media-breakpoint-down(sm) {
        width: 90px !important;
        left: 10px;
        bottom: 5px;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 90px !important;
      left: 10px;
      bottom: 5px;
    }
  }

  &__moreRecipes {
    margin-right: 48px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }


}

.view-old .labelsRibbon {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 60px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__label.slider__item__promo {
    position: static;
  }
  &__label--11.slider__item__promo {
    width: 145px;
    height: 67px;
    max-width: 145px;
  }

  &__text {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    margin-left: 20px;
  }
}

.view-old .recipeViewLabelRibbon .slider__item__promo {
  @include media-breakpoint-up(md) {
    display: none;
  }
}