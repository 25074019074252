.slider-info {
  font-size: 0.8rem;

  @include media-breakpoint-down(sm) {
    &.info-max {
      position: absolute;
      bottom: 30px;
      left: 0;
    }

  }

}

.slider-selection {
  background: $lidlPrimaryColor !important;
  height: 2px !important;
}

.slider-track-high {
  //background: black !important;
}

.slider-left {
  display: inline-block;

  .slider-min-value {
    position: absolute;
    top: 0;
    left: 15px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;

    .slider-min-value {
      margin: 0;
    }

  }

}

.slider-horizontal * {
  @extend .no-select;
}
.slider-right {
  display: inline-block;

  .slider-max-value {
    position: absolute;
    top: 0;
    right: 15px;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.slider-value {
  text-align: center;
  position: absolute;
  top: 15px;
  left: -15px;
  right: 15px;
  font-weight: bold;
  font-size: 1.3rem;
  @extend .no-select;

  * {
    @extend .no-select;
  }
  @include media-breakpoint-down(sm) {
    top: 0;
  }
}

#slider-val {
  border: none;
  width: 45px;
  text-align: center;
  background: none;
  font-weight: bold;
  border-bottom: 1px solid black;

  &::selection {
    background: none;
  }
  &::-moz-selection {
    background: none;
  }
  &::-webkit-selection {
    background: none;
  }
}

.slider-track {
  background: $borderColor;
  height: 1px !important;
}

.slider-horizontal {
  width: 100% !important;
  top: -40px !important;

  @include media-breakpoint-down(sm) {
    top: 0 !important;
    margin-top: 40px;
    margin-bottom: 40px;
  }

}

.min-slider-handle,
.max-slider-handle {
  background-color: white !important;
  border: 2px solid $lidlPrimaryColor !important;
  height: 20px !important;
  width: 20px !important;
  top: -5px !important;
  background-image: none !important;
  cursor: pointer;

}

.slider-tick.round {
  left: 100%;
  width: 1px;
  background-image: none;
  background-color: black;
  opacity: 1;
  top: -5px;
  border-radius: 0;
  margin-left: 0 !important;
}

.slider-control {
  cursor: pointer;
}
