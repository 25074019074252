.recipe-tag {
  border: 1px solid black;
  padding: 5px 15px 5px 15px;
  margin-right: 10px;
  margin-bottom: 5px;
  background-color: black;
  color: white;
}

.recipe-tag__remove {
  position: relative;
  right: -3px;
  cursor: pointer;
}

.add-tag {
  position: relative;
}
.add-tag__input {
  position: absolute;
  top: 6px;
  right: -30px;
  cursor: pointer;
}