.textLanding {
  h3, h4, a {
    color: $lidlDarkBlue;
  }
  h3 {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  h4 {
    font-size: 1.25em;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .bold {
    font-weight: bold;
  }

  ol[data-type="none"] {
    list-style-type: none;
  }
}
