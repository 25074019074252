.shoplist-landing-main {
  &.section6 {

    &.bgImageActive {
      .shoplist-section {
        &__photoBox {
          p {
            &:first-child {
              background-image: url("/layouts/frontend/img/shoplist-landing/section6-photo2.png");
            }
            &:last-child {
              background-image: url("/layouts/frontend/img/shoplist-landing/section6-phone.png");
              @include media-breakpoint-down(sm) {
                background-image: url("/layouts/frontend/img/shoplist-landing/section6-phone-mobile.png");
              }
            }
          }
        }
        &__iconBox {
          span {
            &:first-child {
              background-image: url("/layouts/frontend/img/svg/shoplist/messenger-shoplist.svg");
            }
            &:nth-child(2) {
              background-image: url("/layouts/frontend/img/svg/shoplist/whatsapp-shoplist.svg");
            }
            &:last-child {
              background-image: url("/layouts/frontend/img/svg/shoplist/messaages-shoplist.svg");
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column !important;
      height: 627px !important;
    }

    .shoplist-section {

      &__photoBox {
        z-index: 5;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        p {
          position: relative;
          margin-bottom: 0;

          &:first-child {
            width: 356px;
            height: 463px;
            background-size: cover;
            left: 60px;
            position: absolute;
            bottom: 0;

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }

          &:last-child {
            width: 320px !important;
            height: 424px !important;
            background-repeat: no-repeat;
            background-size: contain;

            @include media-breakpoint-down(sm) {
              bottom: 0;
              top: initial !important;
              width: 260px !important;
              height: 280px !important;
            }
          }
        }
      }

      &__rightBox {
        position: initial;
        img {
          top: initial;
          bottom: 0;
        }
      }

      &__iconBox {
        display: flex;
        justify-content: center;
        margin-top: 48px;

        @include media-breakpoint-down(sm) {
          margin-top: 32px;
        }

        span {

          &:not(:last-child) {
            margin-right: 40px;
          }

          &:first-child,
          &:nth-child(2),
          &:last-child {
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            background-size: contain;

            @include media-breakpoint-down(sm) {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
      &__leftBox {
        background-image: url("/layouts/frontend/img/shoplist-landing/section6-background2.png");

        &:before {
          position: absolute;
          top: 0px;
          left: 0;
          content: "";
          width: 500px;
          height: 100%;
          background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0)) !important;
        }
      }
    }
  }
}
