.heart-container {
  position: relative;
  width: 40px;
  height: 40px;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}

.heart-container--small {
  -webkit-transform: scale(0.55);
  transform: scale(0.55);
  will-change: transform;
}

.heart-clip {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  -webkit-clip-path: url(#svgPath);
  clip-path: url(#svgPath);

  &:hover {
    -webkit-animation: pulse .6s .3s infinite;
    animation: pulse .6s .3s infinite;

    &::before {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #9aabb6;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: opacity .2s linear, -webkit-transform .2s linear;
    transition: transform .2s linear, opacity .2s linear;
    transition: transform .2s linear, opacity .2s linear, -webkit-transform .2s linear;
    -webkit-transform-origin: center 60%;
    transform-origin: center 60%;
  }
}

.heart-stroke {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  fill: $lidlPrimaryColor_08;
}

.add-to-favourites--full::before {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  background-color: #df0000 !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  60% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.single-item__likes .like-count {
  margin-right: -4px;
}