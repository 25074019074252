.newsletter-banner {
  opacity: 0;
  visibility: hidden;
  transition: 0.25s all;
  //position: relative;

  .newsletter-banner__close {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    background-color: #295179;
    border-radius: 50%;

    @media (max-width: 600px) {
      top: -44px;
      position: relative;
    }

    &:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &--open {
    opacity: 1;
    visibility: visible;

    .info-bar__right {
      background-image: url("/layouts/frontend/img/recipe/sticky-bar.png");
    }
    .newsletter-banner__close:before {
      background-image: url("/layouts/frontend/img/svg/close-newsletter-banner.svg");
    }
  }

  @media (max-width: 600px) {
    display: none;
  }

  &.mobileVisible {
    @media (max-width: 600px) {
      display: block;
    }
  }
}