.addToShoppingButton {
  &__desc {
    display: none;
  }

  &__popup {
    max-height: 0;
    overflow: hidden;
    transition: 0.5s all;
    position: relative;

    &__header {
      padding-top: 30px;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 15px;
    }

    &__close {
      position: absolute;
      top: 5px;
      right: 0;
      width: 14px;
      height: 14px;
    }

    .member__portions {
      width: 100%;
      justify-content: space-between;
      padding: 0 15px;
      border: 1.5px solid #F2F2F2;
      margin-bottom: 5px;

      .member-portions__btn {
        height: auto;
        font-size: 40px;
        line-height: 1em;
      }
    }
  }
  &--active {
    .addToShoppingButton__popup__close {
      background: url('/layouts/frontend/img/svg/close_24px.svg');
    }
  }
}

.testAB__hideLikesOnRecipeFeeds {
  .feedElement__recipes__recipe {
    .single-item__image--content {
      display: none;
    }
  }
}


@include media-breakpoint-down(sm) {
  .addToShoppingButton {
    .add-to-shopping-list {
      margin-top: 5px;
    }

    &--active {
      .add-to-shopping-list {
        margin-top: 20px;
      }
    }
  }

  .recipe-banner--shoppinglistBanner {
    display: flex !important;

    .bannerStyle__right {
      display: none;
    }

    .bannerStyle__text {
      &:after {
        content:'';
        display: block;
        margin: 15px auto;

        background-position: center;
        background-repeat: no-repeat;
        width: 50%;
        height: 280px;
        background-size: contain;
      }
    }

    &.bgImageActive {
      .bannerStyle__text {
        &:after {
          background-image: url('/layouts/frontend/img/homepage_de/phone-promotion.png');
        }
      }
    }
  }
}

.cookbook__wizard {
  //display: none;
}

.testAB__wizard__B .cookbook__wizard {
  display: block;

  @include media-breakpoint-down(sm) {
    .cookbook__quickFilters {
      display: none;
    }
  }

  .wizard__question__answer {
    span {
      display: inline;
    }
  }
}

.testAB__wizard__C {
  .cookbook__wizard {
    display: block;
  }

  @include media-breakpoint-down(sm) {
    .cookbook__quickFilters {
      display: none;
    }
  }
}

.testAB__signPost__B {
  .homepage .signPost {
    padding-top: 25px;
    min-height: 0 !important;

    .col-md-5 {
      max-width: 80%;
      flex: 1;
      margin: 0 auto;

      h2 {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    &__form {
      padding: 30px 0;
    }

    &__tags,
    &__popular {
      display: none;
    }
  }
}


.homepage .signPost__form__voiceButton {
  display: none;
}

.testAB__signPostVoice__B {
  .homepage .signPost__form__voiceButton {
    display: flex;
  }
}

.testAB__ingredientsLayer__B {
  @include media-breakpoint-up(sm) {
    .single-item {
      &--hovered {
        .single-item__ingredientsLayer {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.testB__searchTitle {
  .feedSection--searchSection {
    display: flex !important;
  }
}


.slider__videoButton {
  &__text {
    display: none;
  }
}

.testB__videoButton {
  .slider__videoButton {
    padding-left: 67px;
    background-position: 21px 12px;
    width: auto;
    padding-right: 27px;

    @include media-breakpoint-down(xs) {
      padding-left: 55px;
      background-position: 21px 12px;
      width: auto;
      padding-right: 21px;
    }

    &__text {
      display: block;
      font-size: 16px;
      line-height: 19px;

     @include media-breakpoint-down(xs) {
        font-size: 14px;
        white-space: nowrap;
        line-height: 17px;
      }
    }
  }
}

.testB__videoButton {
  .slider__videoButton {
    padding-left: 67px;
    background-position: 21px 12px;
    width: auto;
    padding-right: 27px;

    @include media-breakpoint-down(xs) {
      padding-left: 55px;
      background-position: 21px 12px;
      width: auto;
      padding-right: 21px;
    }

    &__text {
      display: block;
      font-size: 16px;
      line-height: 19px;

     @include media-breakpoint-down(xs) {
        font-size: 14px;
        white-space: nowrap;
        line-height: 17px;
      }
    }
  }
}

.info-bar__text--second {
  display: none;
}

.testB__newsletterBannerPopup {
  .info-bar__text--second {
    display: block;
    font-size: 16px;
  }
  .info-bar__text--first {
    display: none;
  }
}

.openRateBanner__main-text--second {
  display: none;
}

.testB__rateBannerText {

  .openRateBanner__main-text--second {
    display: block;
  }
  .openRateBanner__main-text--first {
    display: none;
  }
}

/*.event--promotion-landing {
  position: relative;
  color: #0050AA;
}

.event--promotion-landing a:after {
  content: 'NEU';
  background: #2E9489;
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  padding: 2px 3px;
  position: absolute;
  top: 0px;
  right: -13px;
  font-weight: bold;
}

@media (max-width: 639px) {
  .event--promotion-landing a:after {
    top: 4px;
    right: 2px;

    @media (max-width: 639px) {
      position: relative;
      right: -10px;
      top: -10px;
    }
  }
  .frontend-nav--my-plan a {
    padding: 12px 0 11px 20px;
  }
}*/

.testAB__homepageBanners__B {
  .homepage__hero__nav,
  .homepage__hero .slick-prev,
  .homepage__hero .slick-next,
  .homepage__hero .slick-dots {
    display: none !important;
  }

  .homepageBanners {
    display: block;
  }
}


