
.ui-datepicker {
  border-radius: 0 !important;
  border-color: $gray_140 !important;
  padding: 5px 10px !important;
  width: 302px !important;
  z-index: 9999 !important;
  th {
    color: black;
    font-weight: 300 !important;
  }
  td a, .ui-state-disabled span {
    height: 32px;
    text-align: center !important;
    line-height: 26px;
  }
  background: white !important;
  font-size: 14px !important;
  font-weight: 300;
  .ui-datepicker-header {
    background: white;
  }
  .ui-state-default {
    background: white !important;
    color: $black !important;
    border-color: $gray_140 !important;
    font-weight: 300 !important;

    &.ui-state-active, &.ui-state-hover {
      background-color: $black !important;
      border-color: $black !important;
      color: white !important;
    }
  }
  .ui-state-disabled {

  }
  .ui-datepicker-next, .ui-datepicker-prev {
    display: none;
  }
  .ui-datepicker-header {
    border: none;
    .ui-datepicker-title {
      margin: 0 !important;
      select {
        height: 50px;
        font-weight: 300 !important;
        background-color: white;
        border-color: $gray_140;
        color: $black;
        padding-left: 10px;

        &:first-child {
          width: 60%;
        }
        &:nth-child(2) {
          width: 40%;
        }
      }
    }
  }
}
