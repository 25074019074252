$christmasMainColor: #BC2C35;

.christmasSection--20 {
  background-color: #E1CAA1;

  .christmasMenuSection {

    &__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      padding-top: 55px;
      padding-bottom: 35px;

      @media (max-width: 800px) {
        padding-top: 15px;
        padding-bottom: 25px;
      }

      // ozdoby
      &.bgImageActive {

        // liść
        //&:before {
        //  content: '';
        //  position: absolute;
        //  top: -160px;
        //  right: -85px;
        //  width: 264px;
        //  height: 331.5px;
        //  background-image: url('/layouts/frontend/img/christmas-landing/section20/gold-blatt.png');
        //  background-size: 264px 331.5px;
        //  z-index: 5;
        //
        //  @media (max-width: 1400px) {
        //    right: -140px;
        //  }
        //  @media (max-width: 1100px) {
        //    display: none;
        //  }
        //}

        //&:after {
        //  content: '';
        //  position: absolute;
        //  top: -74px;
        //  right: -75px;
        //  width: 386px;
        //  height: 212px;
        //  background-image: url('/layouts/frontend/img/christmas-landing/section20/glitter.png');
        //  z-index: 3;
        //
        //  @media (max-width: 1400px) {
        //    right: -140px;
        //  }
        //  @media (max-width: 1100px) {
        //    display: none;
        //  }
        //}

        // mobile glitter
        .christmasMenu {
          &:before, &:after {
            background-image: url('/layouts/frontend/img/christmas-landing/section20/glitter_recipe.png');
          }
        }
      }
    }
  }

  .christmasMenu {
    position: relative;
    z-index: 4;
    width: calc(50% - 8px);

    @media (max-width: 1000px) {
      width: 100%;

      &:before,
      &:after {
        position: absolute;
        display: block;
        top: 30px;
        left: -115px;
        content: '';
        width: 133px;
        height: 298px;
        background-repeat: no-repeat;
        z-index: 3;
      }
      &:after {
        left: auto;
        right: -50px;
        top: 230px;
      }
    }

    &__content {
      background: #FFF;
      padding: 16px;
      margin-top: 16px;
      z-index: 4;
      position: relative;
    }

    &__header {
      font-size: 40px;
      font-family: 'Norican', cursive;
      color: $christmasMainColor;
      line-height: 56.37px;
    }

    &__scroll {
      overflow: hidden;

      @media (max-width: 600px) {
        margin-bottom: 16px;
        margin-right: -16px;
      }
    }

    &__recipes {
      margin: 16px 0;
      display: flex;
      justify-content: space-between;

      @media (max-width: 600px) {
        overflow-x: scroll;
        overflow-y: hidden;
        transform: translateZ(0);
        will-change: transform;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        margin-bottom: -16px;
        padding-bottom: 16px;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__recipe {
        width: 31%;
        display: block;
        text-decoration: none;

        @media (max-width: 600px) {
          min-width: 200px;
          margin-right: 16px;

          &:last-child {
            box-sizing: content-box;
            border-right: 16px solid #FFF;
          }
        }

        &__title {
          color: #000;
          height: 50px;
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
          font-family: 'Lidl Font'; font-weight: bold;
          margin-top: 8px;

          text-overflow: ellipsis;
          max-height: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &__image {
          position: relative;

          @include media-breakpoint-up(lg) {
            min-height: 168px;
          }
          @media (max-width: 600px) {
            min-height: 200px;
          }

          img {
            max-width: 100%;
          }

          &__label {
            position: absolute;
            top: 12px;

            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.135em;
            color: #000;
            max-width: 100px;
            display: flex;
            flex: auto;
            justify-content: center;
            align-items: center;
            height: 34px;
            width: auto;
            padding: 0 10px 0 20px;
            background: #CC9E3C;
            //background: url('/layouts/frontend/img/christmas-landing/section20/arrow.png');
            //background-size: 145px 34px;
            //background-position: center right;
            //background-repeat: no-repeat;
            box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.5);

            &:after {
              content: '';
              position: absolute;
              top: 0;
              right: -34px;
              width: 0;
              height: 0;
              border: 17px solid transparent;
              border-left-color: #CC9E3C;
            }

            @media (max-width: 1023px) and (min-width: 600px) {
              letter-spacing: 0.05em;
              padding: 4px 25px 0 5px;
              font-size: 12px;
            }
          }

        }
      }
    }
  }
}