.recipe .tag-container,
.recipe .hybrid-tag-container {
  margin-top: 10px;
  //max-width: 600px;
}

.recipe-tag-link,
.recipe-hybrid-tag-link {
  display: inline-block;
  color: $lidlPrimaryColor_08;
  background: $lidlAzure;
  border-radius: 4px;
  padding: 6px 10px;
  border: none;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.85rem;
  margin-right: 10px;
  margin-bottom: 12px;
  @include transition(0.2s all);

  .lidl-icon-x {
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
    font-size: 9px;
    margin-right: -5px;

    &:before {
      line-height: 20px;
    }
  }

  &:hover {
    color: #e8eef2;
    background: $lidlPrimaryColor;
    text-decoration: none;
  }
}

.recipe-tag-header,
.recipe-hybrid-tag-header {
  font-size: 1.6rem;
  font-weight: 600;
  color: #00b050;
  margin-top: 20px;
  margin-bottom: 20px;
}

.top-tags-container {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 30px;
  border-top: 1px solid #E3E4E5;
  border-bottom: 1px solid #E3E4E5;

  > label {
    font-size: 1.2rem;
    font-weight: 600;
    padding-right: 10px;
  }
}

.all-tags-container {
  margin-top: 20px;
}
