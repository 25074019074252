.single-plan {
  align-items: center;
  background-color: #dedfe0;
  display: flex;
  height: 85px;
  justify-content: space-between;
  margin-bottom: 15px;

  @include media-breakpoint-down(xs) {
    padding: 0 10px;
  }
}
