.christmas-section95 {
  .feedElement--dein-weinhachts-markt {
    .feedElement {

      &__scrollRight {
        &.slick-next {
          top: 57%;

          @include media-breakpoint-down(sm) {
            top: 70%;
          }
        }
      }

      &__arrowText {
        top: 318px;
      }
      &__header {
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;
          flex-direction: column;
          align-items: flex-start;
        }
        &__text {
          color: #BC2C35;

          @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
          }

          @media (max-width: 910px) {
            margin-top: 62px;
          }
          @media (max-width: 525px) {
            margin-top: 20px;
          }

          &:before {
            content: '';
            display: block;
            width: 182px;
            height: 149px;
            margin-right: 10px;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            top: -8px;

            @include media-breakpoint-down(xs) {
              width: 146px;
              height: 120px;
            }
          }
        }
      }
    }
  }

  &.bgImageActive {
    .feedElement--dein-weinhachts-markt {
      .feedElement {
        &__header {
          &__text {
            &:before {
              background-image: url("/layouts/frontend/img/christmas-landing/section3/dein-weihnachts-markt.png");
            }
          }
        }
      }
    }
  }
  .feedSection {
    &.feedSection--withBauble {
      &:after {
        right: 25%;
        left: initial;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .feedElement__header {
      margin-top: 0 !important;
    }
  }

  &.christmasSection--50 {
    &:before {
      display: none;
    }
    .feeds {
      .container {
        padding-top: 0;
        padding-bottom: 55px;

        @media (max-width: 575px) {
          padding-top: 40px;
        }
      }

      &:before {
        display: none;
      }

      //&:after {
      //  top: 280px;
      //  transform: rotate(245deg);
      //}
    }
  }
  &.bgImageActive {
    .feedSection {
      &--section100 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section95/section100-photo.jpg");
        }
      }
    }
    .feedSection {
      &--section3 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section3/section3-photo.jpg");
        }
      }
    }
  }
}
