.recipeViewComments {
  margin-bottom: 20px;

  &__header {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;

    display: flex;
    align-items: center;
    color: #353B42;

    display: flex;
    align-content: center;

    &:before {
      content: '';
      width: 32px;
      height: 32px;
      background: no-repeat url('/layouts/frontend/img/svg/commentForm.svg');
      margin-right: 12px;
    }
  }

  &__form {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-top: 40px;
    border-top: 1px solid #E3E4E5;

    @media (max-width: 800px) {
      margin-top: 0;
    }

    .recipeViewComments__header {
      margin-bottom: 20px;
    }

    label {
      width: 135px;

      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #353B42;
    }

    textarea, input[type="text"] {
      padding: 5px;

      &.error {
        border-color: red;
      }
    }

    &__nameField {
      display: flex;

      label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }

      input[type="text"] {
        width: 250px;
        border-style: solid;
        border-width: 1px;
      }
    }

    &__textField {
      position: relative;
      padding-bottom: 12px;

      textarea {
        width: 100%;
      }

      &__letterCount {
        position: absolute;
        bottom: -8px;
        right: 0;
        text-align: right;

        font-size: 14px;
        line-height: 20px;
        color: #353B42;
      }
    }

    &__rate {
      margin: 15px 0 10px;
      display: flex;

      label {
        display: flex;
        align-items: center;
      }

      .rating__box {
        direction: rtl;
        text-align: left;
      }
    }

    &__submitButton {
      display: flex;

      @media (max-width: 800px) {
        flex-direction: column;
        margin-top: 25px;
      }

      &__validationMsg {
        padding: 5px;
        color: red;
        display: flex;
        align-items: center;

        &.hidden {
          display: none;
        }
      }

      button {
        width: auto;
        padding: 0 15px;
        margin-right: 15px;

        @media (max-width: 800px) {
          margin-right: 0;
        }
      }
    }
  }

  &__list {
    margin-top: 50px;

    .recipeViewComments__header {
      &:before {
        background-image: url('/layouts/frontend/img/svg/commentList.svg');
      }
    }
  }

  &__comment {
    margin: 10px -15px;
    padding: 10px 15px;

    &--highlight {
      margin: 15px -15px;
      background: #F1F2F3;
      border-radius: 2px;
    }

    &__top {
      display: flex;
      align-items: flex-end;
    }

    &__author {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #353B42;
      margin-right: 8px;
    }

    &__adminLabel {
      background: #FBCB03;
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      color: #353B42;
      padding: 0 4px;
      margin-right: 8px;
    }

    &__timeago {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #4E5761;
    }

    &__rate {
      .rating__box {
        margin-top: 15px;
        direction: rtl;
        text-align: left;

        .rating__star {
          &, svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    &__text {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #353B42;
      margin: 5px 0 10px;
    }

    &__bottom {
      line-height: 14px;
      display: flex;
      align-items: center;
    }

    &__reply,
    &__edit,
    &__like {
      display: flex;
      align-items: center;

      font-size: 12px;
      color: #0050AA;
      cursor: pointer;
      margin-right: 10px;

      span {
        border-bottom: 1px solid #0050AA;
        display: inline-block;
        margin-left: 5px;
      }

      &:hover {
        color: darken(#0050AA, 10%);

        span {
          border-bottom-color: darken(#0050AA, 10%);
        }
      }

      &:before {
        content: '';
        width: 20px;
        height: 20px;
      }
    }

    &__reply {
      margin-right: 15px;

      &:before {
        background-image: url('/layouts/frontend/img/svg/corner-down-right.svg');
      }
    }

    &__edit {
      &:before {
        background-image: url('/layouts/frontend/img/svg/edit.svg');
        background-size: 16px 16px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &__like {
      &:before {
        background-image: url('/layouts/frontend/img/svg/thumbs-up.svg');
      }

      &--liked {
        &:before {
          background-image: url('/layouts/frontend/img/svg/thumbs-up-marked.svg');
        }
      }
    }

    &__report,
    &__delete {
      cursor: pointer;
      font-size: 12px;
      color: #4E5761;
      margin-left: auto;
    }

    &__answersList {
      margin-top: 20px;
      border-left: 3px solid #D9D9D9;
      padding-left: 20px;
      margin-left: 10px;
      margin-bottom: -10px;
      position: relative;
      padding-bottom: 5px;
      margin-bottom: 5px;

      &:before {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -10px;
        content: '';
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 5px solid #D9D9D9;
      }

      .recipeViewComments__comment__answer {
        margin-top: 20px;
      }
    }
  }

  &__loadMore {
    display: flex;
    justify-content: center;
    margin: 20px 0 20px;

    button {
      width: auto;
      padding: 0 20px;
    }
  }
}

.commentsReportOverlay {
  &__reportReason {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 30px 0 20px;

    label {
      font-weight: bold;
    }

    textarea {
      width: 300px;
      height: 100px;
      resize: vertical;
    }
  }
}

.commentsEditOverlay {
  .recipeViewComments__form__textField__letterCount {
    display: none;
  }

  .recipeViewComments__header {
    margin-bottom: 20px;
  }

  form {
    label {
      font-weight: bold;
      width: 135px;
    }
    textarea {
      height: 150px;
    }

    .error {
      border-color: red;
    }
  }

  .recipeViewComments__form__submitButton {
    display: flex;
    flex-direction: column-reverse;

    .recipeViewComments__form__submitButton__validationMsg {
      margin-bottom: 10px;
    }
  }
}

.commentsBadWordsOverlay {

  .popup__content {
    padding: 30px;
    width: 100% !important;

    @media (min-width: 700px) {
      width: 650px !important;
    }

    @media (max-width: 700px) {
      margin-top: 0 !important;
      top: auto;
    }
  }

  .content {
    text-align: left;

    .popup__title {
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      color: #000000;

      @media (max-width: 700px) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    p {
      padding: 20px 0 10px;
      margin-bottom: 0;
    }

    ul {
      padding: 0;
      margin: 0;
      margin-bottom: 15px;
      list-style-type: none;

      li {
        padding: 8px 0;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #353B42;

        &:before {
          content: '';
          width: 20px;
          height: 20px;
          margin-right: 8px;
          background-image: url('/layouts/frontend/img/recipe/comment/icon1.svg');
        }

        &.icon2:before {
          background-image: url('/layouts/frontend/img/recipe/comment/icon2.svg');
        }
        &.icon3:before {
          background-image: url('/layouts/frontend/img/recipe/comment/icon3.svg');
        }
        &.icon4:before {
          background-image: url('/layouts/frontend/img/recipe/comment/icon4.svg');
        }
        &.icon5:before {
          background-image: url('/layouts/frontend/img/recipe/comment/icon5.svg');
        }
        &.icon6:before {
          background-image: url('/layouts/frontend/img/recipe/comment/icon6.svg');
        }
      }
    }

    .bottomInfo {
      padding: 16px 30px;
      margin: 0 -30px -30px;
      background: #F1F2F3;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #000000;

      a {
        color: rgba(0, 80, 170, 1);
        text-decoration: underline;
      }
    }
  }
}

.commentsUsername {
  .popup__content {
    padding: 30px;
    width: 100% !important;

    @media (min-width: 700px) {
      width: 650px !important;
    }

    @media (max-width: 700px) {
      margin-top: 0 !important;
      top: auto;
    }
  }

  .content {
    text-align: left;

    .popup__title {
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      color: #000000;

      @media (max-width: 700px) {
        font-size: 30px;
        line-height: 38px;
      }
    }

    input[type="text"] {
      width: 100%;
      max-width: 250px;
    }

    p {
      margin-top: 10px;
    }
  }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

    .button-lidl__blue {
      margin-right: 0;
      width: auto;
      padding: 0 15px;
    }
  }

  &__validation {
    margin: 15px 0;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;

    .iconset {
      margin-right: 5px;
      min-width: 16px;
    }
  }
}