.mainLandings {
  width: 100%;
  margin-bottom: 100px;

  &__legalText {
    margin-top: 30px;
  }

  &__photo {

    &-desktop {
      @media (max-width: 700px)  {
        display: none;
      }
    }

    &-mobile {
      display: none;
      @media (max-width: 700px)  {
        display: block;
      }
    }
  }

  &__section-bottom {
    @media (max-width: 700px)  {
      background: #F5F8FC;
    }
  }
  &__box {
    display: flex;
    margin-top: 64px;
    border-radius: 6px;

    @media (max-width: 700px)  {
      flex-direction: column;
    }

    .content {
      background: #F5F8FC;
      padding: 12px 24px;

      &:first-child {
        margin-right: 18px;
        @media (max-width: 700px) {
          margin-right: 0;
          padding-bottom: 10px;
          border-bottom: 1px solid #EEEEEE;
        }
      }
      @media (max-width: 700px) {
        padding: 20px 10px 12px 10px;
      }

      h5 {
        font-weight: bold;
        font-size: 24px;
        line-height: 140%;
        margin-bottom: 30px;
        margin-top: 25px;
      }

      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 150%;

      }
      img {
        width: 288px;
        margin-bottom: 30px;
      }
      flex: 1;
    }
  }

  .feedSection--banner {

    @include media-breakpoint-down(sm) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.feedSection {
      @include media-breakpoint-down(md) {
        padding: 50px;
      }
      @include media-breakpoint-down(sm) {
        padding: 47px 15px;
        text-align: center;
      }
    }

    .feedSection__title {
      font-weight: bold;
      font-size: 48px;
      line-height: 58px;
      white-space: nowrap;


      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 36px;
      }
      @include media-breakpoint-down(sm) {
        white-space: initial;
        color: white;
        margin-bottom: 24px;
      }
    }

    .feedSection__desc {
      font-size: 22px;
      line-height: 150%;

      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .feedSection:not(:first-child) {
    position: relative;
    padding-left: 270px;
    background-color: #F6F6F6;
    border-radius: 4px;

    @media (max-width: 700px) {
      padding-left: 20px;
    }

    &.feedSection {
      padding: 30px 25px 25px 261px;

      @media (max-width: 700px) {
        padding: 0;
      }
    }

    &:before {
      content: '';
      background-repeat: no-repeat;
      display: inline-block;
      width: 206px;
      height: 206px;
      background-size: contain;
      position: absolute;
      left: 25px;

      @media (max-width: 700px) {
        position: initial;
        margin-bottom: 20px;
        width: 288px;
        height: 216px;
        background-size: cover;
      }
    }

    .feedSection__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 140%;
      white-space: initial;
      max-width: none;

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }

      @include media-breakpoint-down(sm) {
        color: black;
        line-height: 120%;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 16px;
      }
    }

    .feedSection__desc {
      display: inline-block;
      max-width: none;
    }
  }

  .feeds {
    @media (max-width: 700px) {
      background: #F6F6F6;
    }

    &__box {
      @media (max-width: 700px) {
        padding: 0 10px;
      }
    }

    .feedNavigation__header {
      margin-bottom: 30px;
      margin-top: 24px;
    }

    .section {
      margin-top: 64px;
    }



    .text-content {
      left: 37%;
      max-width: 630px;

      @include media-breakpoint-down(sm) {
        left: 50%;
        max-width: 630px;
        text-align: left;
        padding: 10px;
      }
    }

    p {
      &:first-child {
        font-size: 40px;
        line-height: 140%;
        white-space: initial;

        @include media-breakpoint-down(md) {
          font-size: 24px;
        }

        @include media-breakpoint-down(sm) {
          color: black;
          line-height: 120%;
        }
      }

      &:last-child {
        font-size: 16px;
        line-height: 150%;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }

        @include media-breakpoint-down(sm) {
          color: black;
        }
      }
    }
  }
}