@import 'partials/view/index';

.breadcrumb__list {
  list-style-type: none;
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  margin-top: 10px;

  @media (max-width: 639px) {
    margin-top: 0;
    padding-top: 100px;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 5px;

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      top: 110px;
      right: 5px;
      width: 40px;
      height: 30px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 60%);
    }
  }

  li {
    display: inline-block;
    margin-top: 2px;

    &.breadcrumbs--home {
      margin-top: 0;
      font-size: 18px;
    }

    a {
      display: flex;
      align-items: center;
      color: #2F3A44;
      text-decoration: none;

      @media (max-width: 639px) {
        white-space: nowrap;
      }

      &:hover {
        color: #6D757C;
      }

      &:after {
        font-family: lidl-dings;
        content: '\64';
        font-size: 10px;
        padding-left: 10px;
        padding-right: 8px;
        padding-top: 5px;
        color: #ACB0B4;
        width: 28px;
        height: 20px;

        @media (max-width: 450px) {
          padding-left: 0.35rem;
          padding-right: 0.35rem;
          width: 20px;
        }
      }

      span {
        padding-top: 4px;
      }
    }

    &:last-child {
      a {
        cursor: text;
        font-weight: 600;
        text-decoration: none;
        color: $lidlPrimaryColor;

        @media (max-width: 639px) {
          padding-right: 5px;
        }

        &:after {
          content: '';
        }
      }
    }

    &--current {
      color: #0076BF;
      pointer-events: none;
    }
  }

  &__recipeName {
    @media (max-width: 639px) {
      width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block !important;
    }

    @media (max-width: 450px) {
      width: 100px;
    }
    @media (max-width: 365px) {
      width: 75px;
    }
  }
}

.recipe__header {
  font-size: 2.6em;
  font-weight: bold;
  margin: 0 0 20px 0;
}

.recipe__h2 {
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 2rem;

  small {
    font-size: 1rem;
    color: $gray_140;
    margin-left: 0.8rem;
  }
}

.recipe__lidlplus {
  position: relative;
  &__button {
    position: absolute;
    cursor: pointer;
    width: 198px;
    height: 29px;
    background: transparent;
    left: 559px;
    top: 163px;

    @media (min-width: 1400px) {
      position: absolute;
      width: 235px;
      height: 36px;
      left: 673px;
      top: 197px;
    }

    @include media-breakpoint-down(sm) {
      width: 238px;
      height: 37px;
      left: 305px;
      top: 186px;
    }

    @media (max-width: 845px) {
      width: 29%;
      height: 37px;
      left: 36%;
      top: 30%;
    }

    @include media-breakpoint-down(xs) {
      height: 26px;
    }

    @media (max-width: 400px) {
      height: 19px;
    }
  }

  &__mobileImg {
    display: none;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__desktopImg {
    display: block;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  a {
    display: block;

    img {
      max-width: 100%;
    }
  }
}

.cookbook__title h1.recipe__h1 {
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 0;
}

.recipe__smallName {
  font-size: 12px;
  color: rgb(98, 106, 113);
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 7px;
  font-weight: bold;
}


.author-link {
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
}

@include media-breakpoint-down(sm) {
  .recipe__header {
    font-size: 2rem;
    margin: 30px 0 20px 0;
  }
}

@include media-breakpoint-down(xs) {
  .recipe__header {
    font-size: 1.8rem;
  }
}


.recipe {
  &__bannerPlaner {
    cursor: pointer;
    margin-top: 60px;

    .row {
      align-items: center;
      height: 280px;
      background: $lidlPrimaryColor;
      background-image: url('/layouts/frontend/img/recipe/bannerPlaner2.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: left top;
      border-radius: 7px;
      color: #000;
      margin: 0;


      @include media-breakpoint-down(sm) {
        background-size: cover;
        border-radius: 0;
        height: auto;
        padding: 30px 0;
      }

      @include media-breakpoint-down(xs) {
        margin: 0 -15px;
      }

    }

    &__title {
      font-size: 48px;
      line-height: 58px;
      //font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: 40px;
        line-height: 1em;
      }
    }

    &__desc {
      font-size: 26px;
      line-height: 31px;
      //font-weight: bold;

      @include media-breakpoint-down(md) {
        font-size: 22px;
        padding-top: 20px;
      }
    }

    &__title,
    &__desc {
      padding-left: 50px;

      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }

    &__buttonSection {
      display: flex;
      justify-content: center;

      @include media-breakpoint-down(md) {
        justify-content: flex-start;
        margin-top: 20px;
      }

      &__button {
        width: auto;
        padding: 0 20px;
        min-width: 180px;
      }
    }
  }

  &__firstSection {
    margin-bottom: 50px;
    margin-top: -20px;

    &__btn-back {
      display: inline-flex; 
      margin-bottom: 18px;
      margin-top: 18px;

      @media screen and (min-width: 640px) {
        margin-top: 0;
      }

      > span {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        color: #0050AA;
        margin-left: 6px;
      }
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }

    @include media-breakpoint-down(sm) {
      .row {
        position: relative;

        .col-md-6 {
          position: static;
        }
      }
    }
  }

  &__cookingMode {
    a {
      width: auto;
      margin-right: 0;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin: 10px 0;
      }
    }
    .iconset {
      margin-right: 10px;

      &--pan_blue {
        display: inline-block;
        width: 22px;
        height: 22px;
        background: no-repeat;
        background-size: cover;
      }
    }
  }
}

.recipe__labels {
  overflow: hidden;

  &--desktop {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .single-item__labels__label {
    box-shadow: none;
    float: left;
    margin-bottom: 40px;
    margin-left: 6px;

    &--blank {
      background: none;
      font-size: 16px;
      margin-left: 0;
    }
  }

  &--mobile {
    display: none;

    @include media-breakpoint-down(sm) {
      padding: 10px 0;
      overflow: visible;
      display: flex;

      .single-item__labels__label {
        margin-left: 0;
        margin-bottom: 0;
        float: none;
      }
    }
  }
}

.promotionBanner {
  margin-top: 40px;
}
.ingredients {
  border: 2px solid #EEEEEE;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;


  &__button {
    background: $lidlPrimaryColor;
    border-radius: 4px;
    font-family: Lidl Font;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }

  &__box {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    cursor: pointer;
  }

  &__name {
    display: flex;
  }

  &__ico {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }

  &__visible {
    display: flex;
  }

  &__list {
    list-style-type: none;
    font-size: 16px;
    margin-top: 10px;
  }
  &__data {
    display: none;
  }
  &__visible {
    display: block;
  }

  &__text {
    &:after {
      content: ', ';
    }
    &:last-child:after {
      content: '';
    }
  }


  &__icon {
    transition: 0.2s;
    &--transform {
      transform: rotate(180deg);
    }
  }
}

.buttonBubble {
  &__element {
    &--utensils {
      width: 300px;
      padding: 20px;
      left: -2px;
      margin-top: 32px;

      @media (max-width: 600px) {
        width: 283px;
        left: initial;
      }
    }

    &--promoIngredients {
      width: 100%;
      padding: 20px;
      left: 0px;
      margin-top: 15px;
    }
  }
}

.healthScore {
  font-size: 24px;
}

.healthScoreColor {
  font-weight: bold;

  &--1 {
    color: #f8696b;
  }
  &--2 {
    color: #ef846f;
  }
  &--3 {
    color: #f2a075;
  }
  &--4 {
    color: #f6be7a;
  }
  &--5 {
    color: #fbdc81;
  }
  &--6 {
    color: #eee783;
  }
  &--7 {
    color: #ccdd82;
  }
  &--8 {
    color: #a9d27e;
  }
  &--9 {
    color: #85c97c;
  }
  &--10 {
    color: #62be7b;
  }
}









