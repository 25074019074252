.seoRecipePromotion {
  font-size: 14px;
  color: #212121;
  padding-top: 15px;
  padding-bottom: 50px;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &__header {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }

  &__text {
    position: relative;
    margin: 25px 0;
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      margin: 15px 0;

      height: 40px;
      overflow: hidden;
    }
  }

  &__readMore,
  &__readLess {
    display: none;
    color: $lidlPrimaryColor;
  }

  &__readMore {
    position: absolute;
    bottom: 0;
    right: 3px;
    background: #FFF;
    padding-left: 5px;

    &:before {
      content: '...';
      color: #212121;
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  &__box {
    background: #ECF2F9;
    border-radius: 4px;
    padding: 25px;
    line-height: 20px;

    @include media-breakpoint-down(sm) {
      padding: 15px;

      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    &--opened {
      .seoRecipePromotion {
        &__text {
          height: auto;
        }

        &__box {
          display: block;
        }

        &__readMore {
          display: none !important;
        }

        &__readLess {
          float: right;
          margin-top: 10px;
          display: block !important;
        }
      }
    }
  }
}