.sallys-landing--newHeader {
  .sallys-landing__section2 {
    // this section is visible on mobile

    @include media-breakpoint-up(md) {
      display: none; // hidden on desktop
    }
  }
}

.sallys-landing {
  &__section2 {
    &.new-version {

      padding: 56px;
      position: relative;
      background-color: #F8EBF1;

      @include media-breakpoint-down(sm) {
        padding: 30px 0;
      }

      .sallys-landing {
        &__section2 {

          &__desc {
            margin-bottom: 30px;
          }

          &__box {
            position: relative;

            @include media-breakpoint-down(sm) {
              margin-left: -15px;
              margin-right: -15px;
            }
          }

          &__ytVideo {
            position: absolute;
            top: 0;
            right: 0;
            padding: 18px 16px;
            background-color: rgba(53, 59, 66, 0.75);

            &:before {
              content: '';
              background-image: url("/layouts/frontend/img/sally-landing/youtube-sally.png");
              background-repeat: no-repeat;
              width: 90px;
              height: 20px;
              display: block;
              background-size: contain;
            }

            @include media-breakpoint-down(sm) {
              top: initial;
              bottom: 0;
              padding: 12px 16px;
            }
          }

          &__sallyLogo {
            position: absolute;
            top: -39px;
            left: -34px;
            background-image: url("/layouts/frontend/img/sally-landing/Sally-logo.png");
            background-repeat: no-repeat;
            width: 104px;
            height: 104px;
            display: block;
            background-size: contain;

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }

          &__buttonVideo {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            background: url('/layouts/frontend/img/sally-landing/yt-sally.png');
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 6px;
            color: #0050AA !important;
            font-size: 16px;
            font-weight: 600;
            width: 60px;
            height: 60px;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.25s opacity;
            background-size: contain;

          }

          &__video {
            z-index: 11;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: 0.25s all;

            iframe {
              width: 100%;
              height: 100%;
            }

            &.openVideo {
              opacity: 1;
              visibility: visible;
            }
          }


          @include media-breakpoint-down(sm) {
            padding: 30px 0;
          }

          &__content {

            &--left {
              position: relative;
              flex: 1;
              @include media-breakpoint-down(sm) {
                margin-bottom: 0;
                order: 2;
              }
            }

            &--right {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: 1px solid #E5007D;
              border-right: none;
              flex: 1;
              background-color: white;

              @include media-breakpoint-down(sm) {
                order: 1;
              }

              .button-lidl__blue {
                @include media-breakpoint-down(sm) {
                  display: none;
                }
              }

              @include media-breakpoint-down(sm) {
                border-bottom: none;
                border-right: 1px solid #E5007D;
                align-items: flex-start;
              }

              h3 {
                font-size: 44px !important;
                line-height: 120% !important;
                color: #353B42 !important;

                @media (max-width: 1049px) {
                  font-size: 31px !important;
                }

                @include media-breakpoint-down(sm) {
                  margin-bottom: 0 !important;
                }
              }

              div {
                max-width: 75%;

                @include media-breakpoint-down(sm) {
                  max-width: 100%;
                  padding: 24px 15px;
                }
              }

              p {
                @include media-breakpoint-down(sm) {
                  display: none;
                }

                &:first-child {
                  margin-bottom: 20px;

                  @include media-breakpoint-down(sm) {
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }

          &__box {
            display: flex;
            flex-direction: row-reverse;

            @include media-breakpoint-down(sm) {
              flex-wrap: wrap;
              flex-direction: column;
            }

            &__imgLink {
              text-decoration: none;
            }

            &__img {
              background-repeat: no-repeat;
              width: 100%;
              height: 100%;
              display: block;
              background-size: cover;
              background-position: center;
              position: absolute;

              @include media-breakpoint-down(sm) {
                background-position: center;
              }

              &:hover {
                text-decoration: none;
              }

              &__photo {
                width: 100%;
                min-height: 380px;
                max-height: 390px;
                background-size: cover;
                background-position: center;
                position: relative;

                @include media-breakpoint-down(xs) {
                  min-height: 200px;
                  max-height: 200px;
                  height: 100%;
                }
              }

              &__text {
                background: #E6007E;
                padding: 16px;
                color: white !important;

                h4 {
                  font-size: 26px;
                  line-height: 120%;
                  font-weight: 600;

                  @include media-breakpoint-down(sm) {
                    max-width: 100%;
                    margin-bottom: 0 !important;
                  }

                  @include media-breakpoint-down(xs) {
                    line-height: 120%;
                    font-size: 20px;
                  }
                }

                p {
                  font-size: 17px;
                  line-height: 140%;
                  color: #FFFFFF !important;
                  margin-bottom: 0;

                  @include media-breakpoint-down(sm) {
                    margin-top: 15px;
                    margin-bottom: 12px;
                  }
                }

                .button-lidl__blue {
                  display: none;

                  @include media-breakpoint-down(sm) {
                    display: flex;
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &__section2 {
    &.old-version {

      padding: 56px 0;
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 30px 0;
      }

      .sallys-landing {
        &__section2 {

          &__desc {
            margin-bottom: 30px;
          }

          &__box {
            position: relative;

            //@include media-breakpoint-down(sm) {
            //  margin-left: -15px;
            //  margin-right: -15px;
            //}
          }

          &__ytVideo {
            position: absolute;
            top: 0;
            right: 0;
            padding: 18px 16px;
            background-color: rgba(53, 59, 66, 0.75);

            &:before {
              content: '';
              background-image: url("/layouts/frontend/img/sally-landing/youtube-sally.png");
              background-repeat: no-repeat;
              width: 90px;
              height: 20px;
              display: block;
              background-size: contain;
            }

            @include media-breakpoint-down(sm) {
              top: initial;
              bottom: 0;
              padding: 12px 16px;
            }
          }

          &__sallyLogo {
            position: absolute;
            top: -39px;
            left: -34px;
            background-image: url("/layouts/frontend/img/sally-landing/Sally-logo.png");
            background-repeat: no-repeat;
            width: 104px;
            height: 104px;
            display: block;
            background-size: contain;

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }

          &__buttonVideo {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 10;
            background: url('/layouts/frontend/img/sally-landing/yt-sally.png');
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 6px;
            color: #0050AA !important;
            font-size: 16px;
            font-weight: 600;
            width: 60px;
            height: 60px;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.25s opacity;
            background-size: contain;

          }

          &__video {
            z-index: 11;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: 0.25s all;

            iframe {
              width: 100%;
              height: 100%;
            }

            &.openVideo {
              opacity: 1;
              visibility: visible;
            }
          }


          @include media-breakpoint-down(sm) {
            padding: 30px 0;
          }

          &__content {

            &--left {
              position: relative;
              @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
              }
            }

            &--right {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              border: 1px solid #E5007D;
              border-left: none;
              width: 100%;

              @include media-breakpoint-down(sm) {
                border: none;
                align-items: flex-start;
              }

              h3 {

                @media (max-width: 1049px) {
                  font-size: 31px !important;
                }
              }

              div {
                max-width: 75%;

                @include media-breakpoint-down(sm) {
                  max-width: 100%;
                }
              }

              p {

                @include media-breakpoint-down(xs) {
                  font-size: 16px;
                  line-height: 140%;
                }

                &:first-child {
                  margin-bottom: 20px;

                  @include media-breakpoint-down(xs) {
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }

          &__box {
            display: flex;

            @include media-breakpoint-down(sm) {
              flex-wrap: wrap;
              flex-direction: column;
            }

            &__img {
              background-repeat: no-repeat;
              width: 519px;
              height: 389px;
              display: block;
              background-size: cover;

              h4 {
                padding: 16px;
                font-size: 26px;
                line-height: 120%;
                background: #E6007E;
                opacity: 0.9;
                color: white !important;
                font-weight: 600;

                @include media-breakpoint-down(sm) {
                  max-width: 100%;
                }

                @include media-breakpoint-down(xs) {
                  font-size: 24px;
                  line-height: 120%;
                  padding: 8px;
                }
              }

              @include media-breakpoint-down(sm) {
                max-width: 519px;
                width: 100%;
                background-position: center;
              }

              &:hover {
                text-decoration: none;
              }

              &__photo {
                width: 100%;
                min-height: 380px;
                max-height: 390px;
                background-size: cover;
                background-position: center;

                @include media-breakpoint-down(xs) {
                  min-height: 280px;
                  max-height: 280px;
                  height: 100%;
                }
              }

              &__text {
                background: #E6007E;
                padding: 16px;
                color: white !important;

                @include media-breakpoint-down(sm) {
                  top: 0;
                  padding: 8px;
                  left: 0;
                  right: 0;
                  position: absolute;
                }
              }
            }
          }
        }
      }
    }
  }
}
