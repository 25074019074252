.omuse-landing {

  &__bannerBottom {
    margin-top: 60px;
    background-color: #F3F3F3;
    position: relative;
    min-height: 330px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 30px 0 0 30px;
    justify-content: space-between;

    @media (max-width: 1095px) {
      flex-direction: column;
      padding: 30px 10px;
    }

    &__photo {

      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      @media (max-width: 1095px) {
        display: flex;
        justify-content: center;
      }

      &--desktop {
        width: 471px;
        @media (max-width: 1095px) {
          display: none;
        }

        img {
          width: 100%;
        }
      }

      &--mobile {
        display: none;
        width: 272px;
        height: 178px;
        @media (max-width: 1095px) {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    &--content {
      color: black;
      flex: 1;
      margin-right: 25px;


      @media (max-width: 1095px) {
        margin-bottom: 30px;
        margin-top: 0px;
        padding: 0 40px;
      }

      @media (max-width: 1095px) {
        padding: 0 15px;
        margin-right: 0;
      }

      h5 {
        font-size: 32px;
        line-height: 120%;
        margin-bottom: 15px;

        @media (max-width: 1095px) {
          font-size: 24px;
          line-height: 110%;
        }
      }

      a {
        margin-top: 30px;
      }

      div {
        margin-top: 15px;
        ul {
          padding-left: 20px;
          li {
            font-size: 16px;
            line-height: 160%;
            &:not(:last-child) {
              padding-bottom: 7px;
            }

            @media (max-width: 1095px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &.page-top-margin {
    margin-top: 0;

    @media (max-width: 640px) {
      margin-top: 35px;
    }
  }

  &__banner {
    background-image: url("/layouts/frontend/img/omuese/omuse-banner-desktop.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    @include media-breakpoint-down(xs) {
      background-image: url("/layouts/frontend/img/omuese/omuse-bannerTop-mobile.png");
      background-size: cover;
    }

    width: 100%;
    height: 360px;
    position: relative;

  }

  h4 {
    font-size: 32px;
    line-height: 38px;
    font-family: 'Lidl Font'; font-weight: bold;
    margin-top: 50px;

    @media (max-width: 700px) {
      font-size: 26px;
      line-height: 110%;
      margin-top: 35px;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, - 50%);
    background: #FFFFFF;
    opacity: 0.95;
    padding: 40px 50px;
    text-align: center;
    width: 654px;

    @media (max-width: 700px) {
      width: 382px;
      padding: 20px 0px;
    }

    @media (max-width: 640px) {
      top: 58%;
    }

    @media (max-width: 422px) {
      width: 282px;
    }

    h1 {
      font-weight: 600;
      font-size: 26px;
      line-height: 140%;
      margin-bottom: 10px;

      @media (max-width: 700px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 12px;
      }
    }

    span {
      color: #2C7046;
      font-size: 45px;
      line-height: 58px;
      font-family: 'Lidl Font'; font-weight: bold;

      @media (max-width: 700px) {
        font-size: 26px;
        line-height: 31px;
      }
    }

    p {
      color: #272A2D;
      font-size: 26px;
      line-height: 150%;
      margin-top: 20px;
      margin-bottom: 0;

      strong {
        font-weight: 600;
      }

      @media (max-width: 700px) {
        font-size: 20px;
        line-height: 160%;
      }
    }
  }

}