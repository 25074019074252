.cookingPopup {

  .popup__close {
    z-index: 2;
  }

  .iconset--pan,
  .iconset--carrot {
    background-image: none;
  }

  &.popup--open {
    .cookingPopup__welcome .cookingPopup__sectionImage .cookingPopup__image {
      background-image: url("/layouts/frontend/img/svg/chefHat.svg");
    }

    .cookingPopup__thankYou .cookingPopup__sectionImage .cookingPopup__image {
      background-image: url('/layouts/frontend/img/svg/tray.svg');
    }

    .iconset--carrot {
      background-image: url("/layouts/frontend/img/svg/carrot.svg");
    }

    .iconset--pan {
      background-image: url("/layouts/frontend/img/svg/pan.svg");
    }
  }

  .popup__content {
    width: 100%;
    max-width: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }

  .popup__text {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 70px;
    width: 100%;
    left: 0;

    @media (min-width: 820px) and (max-height: 920px) {
      bottom: 40px;
    }

    a,
    button {
      width: auto;
      padding: 0 20px;
    }

    @media (max-height: 380px) and (orientation: landscape) {
      .button-lidl__blue--bordered {
        display: none;
      }
    }

    @media (max-width: 820px) {
      bottom: 0;
      padding: 20px;
      flex-direction: column;
      background: #FFF;
      padding-top: 0;

      a,
      button {
        width: 100%;

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: 14px;
        }
      }
    }

    &__rightButton {
      &:last-of-type {
        margin-right: 70px;
      }

      @media (max-width: 820px) {
        margin: 0;
      }
    }

    &__rightMarginButton {
      margin-right: 70px;
    }

    &__backButton {
      float: left;
      color: $lidlPrimaryColor !important;
      background: none;
      border: 0;
      appearance: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 18px;
      display: flex;
      align-items: center;
      margin-left: 70px;
      margin-right: auto;
      padding: 0 !important;

      @media (max-width: 820px) {
        position: fixed;
        top: 20px;
        left: 20px;
        font-size: 14px;
        width: auto;
        margin: 0;
      }
    }
  }

  &__sectionImage {
    display: flex;
    align-items: center;
    max-width: 700px;

    @media (min-width: 1920px) {
      max-width: 1200px;
    }

    @media (max-width: 820px) and (orientation: landscape) {
      max-height: 50vh;
      overflow: auto;
    }
  }

  &__welcome {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 820px) {
      padding: 20px;
    }

    .popup__header {
      padding: 70px;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
      align-self: flex-start;

      @media (max-width: 820px) {
        padding: 20px;
        font-size: 26px;
        line-height: 31px;
        padding-right: 50px;
      }
    }

    .cookingPopup__sectionImage {
      @media (max-width: 1200px) {
        padding: 100px 0;
      }

      @media (max-width: 820px) {
        flex-wrap: wrap;
        flex-direction: row;
        padding: 30px 0 10px;
        margin: 10px 0 0;
        max-height: calc(100vh - 190px);
        max-height: calc(var(--vh, 1vh) * 100 - 190px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
      }

      .cookingPopup__image {
        margin-right: 50px;
        width: 160px;
        height: 160px;
        background: no-repeat;
        background-size: contain;

        @media (max-width: 820px) {
          width: 100%;
          height: 120px;
          background-position: center;
          margin-right: 0;
        }
      }
    }

    .popup__desc {
      margin-top: 30px;
      font-size: 22px;
      line-height: 26px;
      font-weight: bold;

      @media (min-width: 1920px) {
        font-size: 42px;
        line-height: 1em;
      }

      @media (max-width: 820px) {
        margin-top: 10px;
        font-size: 22px;
        line-height: 1.3em;
        padding-bottom: 0;
      }

      ul {
        padding-left: 20px;
        padding-top: 5px;

        li {
          font-weight: normal;
          font-size: 18px;
          line-height: 28px;
          padding-top: 10px;

          @media (min-width: 1920px) {
            line-height: 1em;
            font-size: 32px;
          }

          @media (max-width: 820px) {
            font-size: 20px;
            line-height: 1.2em;
          }

          @media (max-width: 350px) {
            font-size: 16px;
          }
        }

      }
    }
  }

  &__ingredients {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__portions {
      font-size: 24px;
      color: #8c8c8c;
      margin-left: 1rem;
      font-family: 'Lidl Font';
      font-weight: normal;

      @media (max-width: 820px) {
        font-size: 18px;
      }
    }

    .popup__header {
      padding: 70px;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
      align-self: flex-start;

      @media (max-width: 1024px) and (orientation: landscape) {
        padding: 20px 70px;
      }

      @media (min-width: 820px) {
        .iconset--carrot {
          width: 36px;
          height: 36px;
        }
      }

      @media (max-width: 820px) {
        padding: 20px;
        font-size: 26px;
        line-height: 31px;
      }
    }

    .popup__desc {
      width: 100%;

      @media (min-width: 820px) {
        padding: 70px;
      }

      @media (max-width: 1024px) and (orientation: landscape) {
        padding: 20px 70px;
      }

      @media (max-width: 820px) {
        padding: 20px;
        max-height: calc(100vh - 200px);
        max-height: calc(var(--vh, 1vh) * 100 - 200px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding-top: 0;
      }

      @media (max-width: 1024px) and (orientation: landscape) {
        padding: 20px;
        max-height: calc(100vh - 200px);
        max-height: calc(var(--vh, 1vh) * 100 - 200px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding-top: 0;
      }
    }

    &__table {
      min-height: 200px;
      line-height: normal;
      max-width: 450px;
      margin: 0 auto;

      &--advancedTable {
        max-width: 950px;
        column-count: 2;
        column-gap: 80px;
        column-fill: auto;

        @media (max-width: 820px) {
          column-gap: 40px;
        }

        @media (max-width: 500px) {
          column-count: 1;
        }
      }

      @media (max-width: 500px) {
        padding: 0;
      }

      &__row {
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        padding-top: 20px;

        @media (min-width: 1200px) {
          font-size: 22px;
        }

        @media (min-width: 1920px) {
          font-size: 24px;
        }

        @media (max-width: 820px) {
          padding: 5px 0;
          font-size: 18px;
        }

        &__name {}

        &__quantity {
          margin-left: 10px;
          font-weight: 600;
          white-space: pre;

          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__utensils {

    @media (max-width: 820px) {
      justify-content: flex-start;
    }

    .popup__header {
      @media (min-width: 820px) {
        .iconset--utensilPan {
          width: 36px;
          height: 36px;
          background-size: cover;
        }
      }

      @media (max-width: 820px) {
        padding-top: 55px;
      }

      @media (max-width: 1024px) and (orientation: landscape) {
        padding: 20px 90px;
      }

      @media (max-width: 820px) and (orientation: landscape) {
        padding: 15px 90px;
      }
    }

    .popup__desc {
      padding: 0 70px;
      font-size: 34px;
      line-height: 1.4em;
      max-height: calc(100vh - 285px);
      max-height: calc(var(--vh, 1vh) * 100 - 285px);
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      @media (min-width: 820px) and (max-height: 920px) {
        height: calc(100vh - 320px);
        height: calc(var(--vh, 1vh) * 100 - 320px);
      }

      @media (max-width: 820px) {
        font-size: 20px;
        padding: 10px 20px;
      }

      @media (max-height: 600px) {
        max-height: 90vh;
        max-height: calc(90vh - 100px);
        height: calc(var(--vh, 1vh) * 90 - 100px);
      }

      @media (max-width: 820px) and (orientation: portrait) {
        display: flex;
        flex-direction: column;
      }

    }

    .popup__desc {
      @media (max-width: 820px) {
        margin-top: 100px;
      }

      @media (max-width: 1024px) and (orientation: landscape) {
        margin-top: 45px;
      }

      ul {
        @media (max-width: 820px) {
          padding-left: 22px;
        }

        li {
          margin-top: 15px;

          @media (max-width: 820px) {
            margin-top: 0;
          }
        }
      }
    }
  }

  &__step {
    &__desktopImage {
      float: left;
      max-width: calc(50% - 30px);
      max-height: 90%;
      margin-right: 30px;
      margin-bottom: 15px;

      @media (max-width: 820px) and (orientation: portrait) {
        display: none;
      }
    }

    &__mobileImage {
      float: none;
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-top: 15px;
      margin-bottom: 0;
      display: none;

      @media (max-width: 820px) and (orientation: portrait) {
        display: block;
      }
    }

    .popup__desc {
      padding: 0 70px;
      font-size: 34px;
      line-height: 1.4em;
      max-height: calc(100vh - 285px);
      max-height: calc(var(--vh, 1vh) * 100 - 285px);
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      @media (min-width: 820px) and (max-height: 920px) {
        height: calc(100vh - 320px);
        height: calc(var(--vh, 1vh) * 100 - 320px);
      }

      @media (max-width: 820px) {
        font-size: 20px;
        padding: 10px 20px;
      }

      @media (max-height: 600px) {
        max-height: 48vh;
        height: calc(var(--vh, 1vh) * 48);
      }

      @media (max-width: 820px) and (orientation: portrait) {
        display: flex;
        flex-direction: column;
      }

      img {
        float: left;
        max-width: calc(50% - 30px);
        max-height: 90%;
        margin-right: 30px;
        margin-bottom: 15px;

        @media (max-width: 820px) and (orientation: portrait) {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-top: 15px;
          margin-bottom: 0;
        }
      }

      p {
        padding-bottom: 10px;
      }
    }
  }

  &__progressBar {
    padding: 70px;

    @media (max-width: 820px) {
      padding: 20px;
      padding-top: 60px;
    }

    @media (max-width: 1024px) and (orientation: landscape) {
      padding: 20px 70px;
    }

    @media (max-width: 820px) and (orientation: landscape) {
      padding: 15px;
    }

    .popup__header {
      display: flex;
      text-transform: uppercase;
      font-size: 24px;
      align-items: center;

      @media (max-width: 820px) {
        font-size: 18px;
      }

      @media (max-width: 820px) and (orientation: landscape) {
        padding-left: 80px;
      }

      .iconset--pan {
        margin-right: 10px;
      }
    }

    &__bar {
      background: #EEEEEE;
      border-radius: 5px;
      overflow: hidden;
      margin-top: 25px;

      @media (max-width: 820px) {
        margin-top: 15px;
      }

      span {
        display: block;
        height: 5px;
        background: #348553;
        border-radius: 6px;
        transition: 0.25s width;
      }
    }
  }

  &__thankYou {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .popup__desc {
      font-weight: normal;
      font-size: 34px;
      line-height: 1.5em;

      @media (max-width: 820px) {
        font-size: 20px;
      }

      @media (max-width: 820px) and (orientation: landscape) {
        margin: 0;
      }

      strong {
        display: block;
        padding-top: 10px;
        font-weight: 600;
        font-size: 34px;
        line-height: 150%;

        @media (max-width: 820px) {
          font-size: 20px;
        }
      }
    }

    .cookingPopup__sectionImage {
      max-width: 600px;

      .cookingPopup__image {
        width: 190px;
      }
    }
  }

  &__rate {

    .popup__header {
      @media (max-width: 820px) {
        padding: 80px 0;
        text-align: center;
        width: 100%;
      }
    }

    .rating__box__header--mobile {
      @media (max-width: 820px) {
        font-size: 20px;
        line-height: 32px;
        padding-bottom: 30px;
      }
    }

    .rating__string {
      font-weight: normal;
      font-size: 18px;
      min-height: 40px;
    }

    &__rightBackButton {
      color: $lidlPrimaryColor !important;
      text-transform: uppercase;
      font-weight: 600;
      // font-size: 18px;
      align-items: center;
      margin-right: 70px;
      margin-left: auto;
      line-height: 53px;
      cursor: pointer;

      @media (max-width: 820px) {
        position: absolute;
        bottom: 20px;
        // font-size: 14px;
        width: auto;
        margin: 0;
        text-align: center !important;
        left: 0;
        right: 0;
      }
    }

    &__rightButton {
      text-transform: uppercase;
      font-weight: 600;
      // font-size: 18px;
      display: flex;
      align-items: center;
      margin-right: 70px;

      @media (max-width: 820px) {
        position: relative;
        bottom: 50px;
        // font-size: 14px;
        width: auto;
        margin: 0;
      }
    }

    .rating__box {
      direction: rtl;

      .rating__star {
        content: '';
        cursor: pointer;
        width: 48px;
        height: 48px;
        margin: 6px;
        display: inline-block;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        @media (max-width: 820px) {
          margin: 2px;
        }
      }

      .rating__star path {
        stroke: #e3e4e5;
        fill: none;
      }

      .rating__star:hover path {
        stroke: #fbcb03;
        fill: #fbcb03;
      }

      .rating__star--active path {
        stroke: #f7a606;
        fill: #f7a606;
      }

      .rating__star--inactive path {
        stroke: #e3e4e5;
        fill: #e3e4e5;
      }

      @for $i from 1 through 5 {
        .rating__star_#{$i}:hover~i path {
          stroke: #fbcb03;
          fill: #fbcb03;
        }
      }
    }
  }
}