.diet-plans {
  margin-top: 80px;
  padding: 90px 0;
  background-color: #F1F5F7;
  margin-bottom: -100px;

  &__btn {
    display: flex;
    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      flex-direction: column;
      padding-bottom: 25px;
    }

    .button-lidl__blue--diat {
      background: white;
      border: 2px solid $lidlPrimaryColor;

      span {
        color: $lidlPrimaryColor;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 40px 0;
  }


  &__box {
    display: flex;
    margin-top: 24px;

    @media (max-width: 1400px) {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__content {
    background: white;
    border-radius: 12px;
    flex: 1;
    padding: 32px 40px 0 40px;

    @include media-breakpoint-down(xs) {
      padding: 16px;
    }
    &:last-child {
      margin-left: 20px;
      @media (max-width: 1400px) {
        margin-top: 20px;
        margin-left: 0;
      }
    }
  }

  &__photo {
    max-width: 530px;
    max-height: 318px;

    img {
      width: 100%;
    }
  }

  &__text {
    @media (max-width: 1400px) {
      max-width: 70%;
    }
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
    h3 {
      font-family: 'Lidl Font'; font-weight: bold;
      font-size: 26px;
      line-height: 120%;
      margin-bottom: 26px;
      @include media-breakpoint-down(xs) {
        font-size: 24px;
        margin-bottom: 18px;
      }
    }
    p {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 24px;

      strong {
        font-family: 'Lidl Font'; font-weight: bold;
      }

      @include media-breakpoint-down(xs) {
        font-size: 14px;
        margin-bottom: 18px;
      }
    }

    a {
      margin-bottom: 40px;

      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  &__header {
    max-width: 47%;
    margin-bottom: 25px;
    h2 {
      font-size: 40px;
      line-height: 120%;

      strong {
        font-family: 'Lidl Font'; font-weight: bold;
      }

      @media (max-width: 805px) {
        font-size: 30px;
      }
    }
    @media (max-width: 805px) {
      max-width: 90%;
      font-size: 30px;
    }
  }
  &__desc {

    ul {
      columns: 2;

      li {
        font-size: 20px;
        line-height: 140%;
        strong {
          font-family: 'Lidl Font'; font-weight: bold;
        }
      }
      @media (max-width: 805px) {
        columns: 1;
      }
    }
  }
}
