.newsletter-landing {
  @include media-breakpoint-down(sm) {
    margin-bottom: -28px;
  }
  .textLanding {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;

    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
      text-align: center;
      padding-top: 10px;
    }

    &:after {
      content: '';
      display: inline-block;
      background-repeat: no-repeat;
      width: 190px;
      height: 190px;
      background-size: contain;
      @include media-breakpoint-down(sm) {
        width: 120px;
        height: 120px;
      }
    }

    .text {
      max-width: 500px;
      margin-left: 75px;

      @include media-breakpoint-down(sm) {
        margin-top: 40px;
        margin-left: 0;
      }

      h2 {
        font-weight: 600;
        font-size: 30px;
        color: #000000;
        margin-bottom: 22px;
        @include media-breakpoint-down(sm) {
          font-size: 24px;
          margin-bottom: 16px;
        }
      }

      p {
        font-size: 18px;
        line-height: 150%;
        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }
      }
    }
  }
}