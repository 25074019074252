.lidl50Jahre {
  .header {
    background: none;
    box-shadow: none;
  }
}

@include media-breakpoint-down(md) {
  .page__cookbook__search .lidl50Jahre .breadcrumb__list,
  .page__tags__search .lidl50Jahre .breadcrumb__list,
  .page__recipe__search .lidl50Jahre .breadcrumb__list {
    padding-top: 0;
  }
  .page__cookbook__search .lidl50Jahre .lidl50JahreTopBar,
  .page__tags__search .lidl50Jahre .lidl50JahreTopBar,
  .page__recipe__search .lidl50Jahre .lidl50JahreTopBar {
    margin-top: 60px;
  }
  .lidl50Jahre {
    .breadcrumb__list {
      padding-top: 10px;
    }
    .breadcrumb__list:after {
      top: 5px;
    }
  }
}

.lidl50JahreTopBar {
  background: url('/layouts/frontend/img/50-jahre-lidl/spacer.jpg') no-repeat;
  background-size: cover;

  @media (max-width: 640px) {
    margin-top: 105px;
  }

  .container {
    position: relative;
    height: 37px;
    display: flex;

    &:hover {
      text-decoration: none;
      color: #FFF;
    }

    @include media-breakpoint-down(md) {
      height: 32px;
    }
  }

  p,
  .tagLink,
  .tagMobileLink {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }

  p {
    @include media-breakpoint-down(md) {
      padding-left: 60px;
      font-size: 14px;
    }
  }

  .tagLink,
  .tagMobileLink {
    margin-left: 8px;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    text-underline-offset: 3px;

    @include media-breakpoint-down(md) {
      position: absolute;
      right: 12px;
    }
  }

  .tagLink {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .tagMobileLink {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
      top: 6px;
      font-size: 12px;
    }
  }

  .logo {
    z-index: 11; /* above hp slider */
    position: absolute;
    right: 0;
    top: -25px;

    height: 86px;
    width: 152px;
    background: url('/layouts/frontend/img/50-jahre-lidl/logo_shadow.png?v=2') no-repeat;
    background-size: 152px 86px;

    @include media-breakpoint-down(md) {
      position: absolute;
      left: 12px;
      top: 4px;
      right: auto;
      width: 52px;
      height: 24px;
      background-size: 52px 24px;
      background-image: url('/layouts/frontend/img/50-jahre-lidl/logo_recipe.png');
    }
  }

  + .page-top-margin {
    @media (max-width: 639px) {
      margin-top: 15px;
    }
  }
}

@include media-breakpoint-down(md) {
  .page__index__index .lidl50Jahre {
    .lidl50JahreTopBar {
      margin-top: 0;
      position: absolute;
      left: 0;
      top: 105px;
      right: 0;
      height: 32px;
      z-index: 9;
    }
  }
  .homepage-lidl__banner {
    margin-top: 32px;
  }
}

.tagLanding--lidl50Jahre {
  background-image: url('/layouts/frontend/img/50-jahre-lidl/banner.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right top;
  color: #FFFFFF;
  padding: 25px 32px 10px;
  font-weight: 600;
  font-family: 'Lidl Font';
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  @include media-breakpoint-down(md) {
    background-image: url('/layouts/frontend/img/50-jahre-lidl/banner_mobile.jpg');
    background-size: cover;
    background-position: left top;
    padding: 145px 15px 15px;
  }

  h4 {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
    font-family: 'Lidl Font';

    @include media-breakpoint-down(md) {
      font-size: 26px;
      line-height: 31px;
    }
  }

  .tagLanding__content {
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    max-width: 470px;

    ul {
      padding-left: 10px;

      @include media-breakpoint-down(md) {
        padding-left: 20px;
      }
    }
  }
}

.recipesBanner.recipesBanner--50jahreLidl {
  background-image: url('/layouts/frontend/img/50-jahre-lidl/recipeBanner.jpg');
  background-size: cover;
  background-position: center top;

  p {
    margin-top: -20px;
    margin-bottom: 0;
  }
}

#lidl50JahrePopup {
  background: url('/layouts/frontend/img/50-jahre-lidl/popup-desktop-bg.png') no-repeat;
  background-size: 500px 100px;
  width: 500px;
  height: 100px;
  z-index: 101;

  position: fixed;
  right: 20px;
  bottom: 20px;

  @include media-breakpoint-down(sm) {
    background: url('/layouts/frontend/img/50-jahre-lidl/popup-mobile-bg.png') no-repeat;
    background-size: cover;
    width: 100%;
    height: 86px;

    right: 0;
    bottom: 0;
  }

  .lidl50JahrePopup__close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;

    background: url('/layouts/frontend/img/50-jahre-lidl/x_desktop.svg') no-repeat;
    background-size: cover;
    width: 28px;
    height: 28px;

    @include media-breakpoint-down(sm) {
      background: url('/layouts/frontend/img/50-jahre-lidl/x_mobile.svg') no-repeat;
      top: -10px;
    }
  }

  .lidl50JahrePopup__logo {
    position: absolute;
    top: 16px;
    right: 60px;
    cursor: pointer;

    background: url('/layouts/frontend/img/50-jahre-lidl/popup-logo-desktop.png') no-repeat;
    background-size: cover;

    width: 145px;
    height: 67px;

    @include media-breakpoint-down(sm) {
      top: 19px;
      right: 50px;
      background-image: url('/layouts/frontend/img/50-jahre-lidl/popup-logo-mobile.png');
      width: 101px;
      height: 47px;
    }
  }

  .lidl50JahrePopup__content {
    width: 220px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 14px;
    padding-top: 12px;

    @include media-breakpoint-down(sm) {
      padding-top: 10px;
      justify-content: flex-start;
    }

    .lidl50JahrePopup__title {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: #FFF;
      padding-bottom: 10px;
      text-align: center;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 17px;
        text-align: left;
        padding-bottom: 5px;
      }
    }

    .lidl50JahrePopup__button {
      width: 127px;
      padding: 0;
      height: 27px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      margin: 0 auto;

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }
  }
}