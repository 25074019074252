.contact__banner {
  align-items: center;
  background-image: url("/layouts/frontend/img/contact/background.png");
  background-position: center;
  background-size: cover;
  display: flex;
  height: 470px;
  justify-content: center;

  @include media-breakpoint-down(xs) {
    height: 300px;
  }
}

.contact__banner-text {
  color: $white;
  font-size: 3.4rem;
  font-weight: 700;
  text-align: center;

  @include media-breakpoint-down(xs) {
    font-size: 2rem;
  }
}

.contact__section {
  background-color: $dirty_white;
  padding-bottom: 80px;

  .contact__section-box {
    background-color: white;
    background-image: url('/layouts/frontend/img/contact/envelope-background.png');
    background-position: right;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-top: -70px;
    padding-bottom: 30px;
    padding-top: 60px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }
  }

  input {
    background-color: transparent;
    &:hover, &:focus, &:active {
      background-color: transparent;
    }
  }

  .input-text-select {
    @include media-breakpoint-down(xs) {
      margin-top: 50px;
    }
  }
}

#contact-form {
  input[type=file] {
    display: none;
  }

  .ajax-uploader {
    height: 100px;
    border-bottom: 1px solid black;
    width: 100%;
    text-align: left;
  }
  #fakeFileInput {
    color: $gray_140;
    cursor: pointer;
  }
  .imgHolder {
    width: 100%;
    height: 70px;
    text-align: center;
    display: block;
    top: 5px;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .buttons {
    position: relative;
    #progress {
      bottom: -14px;
    }
  }
}

textarea#content {
  background-color: transparent;
  resize: none;

  @include media-breakpoint-down(xs) {
    height: 150px !important;
  }
}
