.sallys-landing .sallys-landing__newHeader {
  background: #F8EBF1;
  padding-bottom: 40px;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &__bg {
    background: url('/layouts/frontend/img/sally-landing/new-header-bg-v2.jpg') no-repeat center;
    background-size: cover;
    height: 450px;
    display: flex;
    align-items: center;

    &__title {
      width: 50%;

      h2 {
        color: #FFF;
        font-weight: 600;
        font-size: 44px;
        line-height: 53px;
        padding-bottom: 15px;
      }

      p {
        color: #FFF;
        font-weight: normal;
        font-size: 18px;
        line-height: 140%;
        padding-top: 5px;
      }
    }
  }

  &__recipe {
    width: 800px;
    margin: -68px auto 0;
    border: 20px solid #FFF;
    //border-bottom: 0;

    &__videoBox {
      height: 428px;
      background-size: cover;
      position: relative;

      &__button {
        position: absolute;
        width: 100%; height: 100%;
        top: 0; left: 0;
        background: url('/layouts/frontend/img/sally-landing/new-header-yt.png') no-repeat center;
        background-size: 123px 86px;
        z-index: 11;
      }

      &__video {
        position: absolute;
        width: 100%; height: 100%;
        top: 0; left: 0;
        z-index: 10;

        iframe {
          width: 100%;
          height: 100%;
        }
      }

      &--videoVisible {
        .sallys-landing__newHeader__recipe__videoBox__button {
          display: none;
        }
      }
    }

    &__info {
      background: #E6007E;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      h4 {
        color: #FFF;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;
      }

      p {
        color: #FFF;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 0;
      }

      .button-lidl__blue {
        min-width: 162px;
        margin: 0 10px;
      }
    }
  }
}