.christmas-landing {
  margin-top: 0;
  overflow: hidden;

  &.bgImageActive {
    .feedElement--red-background {
      .feedElement {
        &__header {
          &:before {
            background-image: url("/layouts/frontend/img/christmas-landing/background-feed.png");
          }
        }
      }
    }
  }

  .single-item {
    &__image,
    &__likes,
    &__labels__label--video {
      border-radius: 0;
    }
  }

  @media (min-width: 1024px) {
    .container {
      max-width: 1158px;
    }
  }

  .christmasButton {
    background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
    height: 48px;
    width: 100%;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: 'Lidl Font'; font-weight: bold;
    text-decoration: none;
    transition: 100ms background, 100ms box-shadow;

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
      box-shadow: 0px 0px 25px #F6E26A, inset 0px 0px 12px rgba(255, 246, 31, 0.5);
    }
  }

  &__down-section {
    max-height: 10px;
    overflow: hidden;
    position: relative;
    z-index: 2;

    &:before {
      content: '';
      height: 10px;
      max-height: 100%;
      width: 100%;
      background-size: contain;
      background: #CC9E3C;
      display: block;
    }

  }

  .feeds {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
    .container {
      @include media-breakpoint-down(xs) {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
      }
    }
  }

  .feedSection--withBorder {

    @include media-breakpoint-down(xs) {
      position: relative !important;
    }

    &:after {
      @include media-breakpoint-down(xs) {
        content: '' !important;
        display: block !important;
        height: 10px !important;
        position: absolute !important;
        left: 0px !important;
        right: 0px !important;
        margin-left: -27px !important;
        margin-right: -20px !important;
        top: 0 !important;
        width: 1000px !important;
        background-size: inherit !important;
        background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg") !important;
      }
    }

  }

  .feedSection {
    position: relative;
    //border: 8px solid transparent;
    border-style: solid;
    border-width: 8px;
    padding: 30px 127px 25px 261px;
    //border-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg") 50 round;
    border-color: #CC9E3C;
    background: #FFF;
    border-radius: 0;

    @media (max-width: 910px) {
      padding: 30px 50px 25px 50px;
    }

    @include media-breakpoint-down(xs) {
      margin-right: -15px;
      margin-left: -15px;
      padding: 40px 20px 25px 20px;
      border-width: 0;
      border: none;
      margin-bottom: 0;
    }

    &:before {
      content: '';
      background-repeat: no-repeat;
      display: inline-block;
      width: 206px;
      height: 206px;
      background-size: contain;
      position: absolute;
      margin-bottom: 20px;
      left: 25px;

      @media (max-width: 910px) {
        position: initial !important;
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        max-width: 240px;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
        background-size: cover;
        height: 217px;
      }
    }

    &.bgImageActive {
      &.feedSection--withBauble:after {
        background-image: url('/layouts/frontend/img/christmas-landing/bauble.jpg');
      }
    }
    &.feedSection--withBauble {
      &:after {
        content: '';
        position: absolute;
        width: 50px;
        height: 50px;
        background-size: 50px 50px;
        margin-left: -25px;
        left: 50%;
        top: 15px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }


    &__title {
      color: #BC2C35;
      font-size: 32px !important;
      line-height: 140%;
      font-family: 'Norican', cursive;
      max-width: 100% !important;
      font-weight: normal !important;

      @include media-breakpoint-down(xs) {
        font-size: 24px !important;;
        line-height: 34px;
        margin-bottom: 20px;
        max-width: 100% !important;
      }
    }

    &__desc {
      font-size: 14px;
      line-height: 150%;
      max-width: 100%;

      p {
        font-size: 14px !important;
        line-height: 140%;
        max-width: 100% !important;
      }
    }
  }

  .feedElement {
    &__recipes__recipe--moreLink {
      background-color: white;
    }
    &__scroll {
      @include media-breakpoint-down(xs) {
        padding-bottom: 28px;
      }
    }
    &__arrowText {
      display: block;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &__scrollRight {
      &.slick-next {
        background-color: #871014;
        @include media-breakpoint-down(sm) {

        }
      }
    }

    &__header {
      &__text {
        font-family: 'Norican', cursive;
        display: flex;
        font-size: 48px;
        line-height: 68px;
        align-items: center;

        @include media-breakpoint-down(sm) {
          font-size: 32px;
          line-height: 45px;
        }
      }
      a {
        &:hover {
          text-decoration: none;
          border-bottom: 1px solid;
        }
      }

      &__link {
        color: #000000;
        font-size: 12px;
        border-bottom: 1px solid;
      }
    }
  }





  .feedElement--red-background {
    // left gradient after scrolling
    &:before {
      display: none !important;
    }

    .feedElement {
      &__header {
        margin-top: 50px;

        @include media-breakpoint-down(xs) {
          margin-top: 0px;
        }
        &:before {
          content: '';
          display: block;
          width: 676px;
          height: 270px;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          left: -80px;
          top: -20px;

          @include media-breakpoint-down(sm) {
            top: -30px;
            width: 549px;
          }

          @include media-breakpoint-down(xs) {
            width: 377px;
            top: -7px;
          }
        }

        &__text {
          position: relative;
          color: white;
        }

        &__link {
          color: white;
          z-index: 1;
        }
      }
    }
  }

  .feedElement {
    &__scrollLeft,
    &__scrollRight {
      background-color: #871014;

      @include media-breakpoint-down(sm) {
        top: 65%;
      }
    }

    &__header {
      &__text {
        font-family: 'Norican', cursive;
        display: flex;
        font-size: 48px;
        line-height: 68px;
        align-items: center;

        @include media-breakpoint-down(sm) {
          font-size: 32px;
          line-height: 45px;
        }
      }

      &__link {
        color: #000000;
      }
    }

    .single-item__name {
      background: white;
    }
  }
}

.christmasSection {
  &--withSnowBg {
    &.bgImageActive {

      .christmasSection__snowBg {
        position: relative;

        &:after {
          width: 100%;
          height: 100%;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;

          @include media-breakpoint-up(md) {
            background-image: url('/layouts/frontend/img/christmas-landing/section_snow.png');
            background-size: 1920px 2942px;
            background-repeat: repeat-y;
            background-position: top center;

            @include retina {
              background-image: url('/layouts/frontend/img/christmas-landing/section_snow_2x.png');
            }
          }
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 15;
  }
}
