.lidl-landing-section-1__background-img {
  position: absolute;
  left: 50%;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('/layouts/frontend/img/lidl-landing/zu-kochen/section1.jpg');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  z-index: 0;

  @include media-breakpoint-down(sm) {
    left: 0;
    background-position-x: -110px;
    background-position-y: 20px;
    background: rgba(0, 0, 0, .65) url('/layouts/frontend/img/lidl-landing/zu-kochen/section1.jpg');
    background-blend-mode: darken;
    background-size: cover;
    background-repeat: no-repeat;
  }

  @include media-breakpoint-down(xs) {
    top: 50px;
    left: -70px;
  }
}

.lidl-landing-section-1 {
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
  }
}

.lidl-landing-section-1__background-img--diet {
  background-image: url('/layouts/frontend/img/lidl-landing/diet/section1.jpeg');
}

.lidl-landing-section-1__background-img--planer {
  background-image: url('/layouts/frontend/img/lidl-landing/planer/section1_new.jpg');
}

.lidl-landing-section-1__iphone {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 83%;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.lidl-button-container {
  @include media-breakpoint-down(sm) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.lidl-landing-section-1__btn {
  margin-top: 1.5rem;

  //display: inline-block;
  @include media-breakpoint-down(sm) {
    margin: 1rem auto 0 auto;
  }
}

.lidl-landing-section-2 {
  background-color: $lidlPrimaryColor;
  color: white;
  padding: 5.7rem 0;
  height: auto;
  margin: 3rem 0 0 0;

  @include media-breakpoint-down(sm) {
    padding: 5.3rem 0;
  }
}