@import 'homepage/homepageBanners';
@import 'homepage/personalizedFeed';
@import 'homepage/inflationCampaign';
@import 'homepage/_lidlPlusIframe';

.homepage {
  background-color: $white;

  .label-promo {
    width: 30px;
    height: 30px;
    bottom: 75px;
    position: absolute;
    background-size: contain;
    left: 20px;
  }

  .wine-photo {
    max-width: 97px;
    max-height: 85px;
    min-width: 97px;
    min-height: 85px;
    margin-left: 10px;

    &__label {
      background: #FFF103;
      padding: 8px 12px;
      box-shadow: 0px 2px 6px rgba(33, 33, 33, 0.08), 0px 0px 1px rgba(33, 33, 33, 0.04);
      border-radius: 30px;
      z-index: 4;
      font-size: 12px;
      line-height: 14px;
      font-family: 'Lidl Font';
      font-weight: bold;
    }

    .wine-link {
      margin: 0;

      img {
        -webkit-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
      }

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 140px;
      max-height: 140px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .wine {

    &__recipe {
      &__two {
        display: inline-flex;
        flex-direction: column;

        img {
          @include media-breakpoint-down(xs) {
            margin-bottom: 10px !important;
          }

        }
      }
    }

    &__box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 11px;
      position: relative;

      &:before {
        @include media-breakpoint-down(xs) {
          margin-left: 20px;
        }
      }



      &--one {
        margin-left: 40px;

        @include media-breakpoint-down(sm) {
          margin-left: 0;
        }

        &:before {
          @include media-breakpoint-down(xs) {
            right: -10px !important;
          }
        }
      }

      &:before {
        @include media-breakpoint-down(sm) {
          content: '';
          display: inline-block;
          position: absolute;
          right: 0px;
          width: 67px;
          height: 97px;
          top: -80px;
          background: url('/layouts/frontend/img/svg/arrowWineMobile.svg') no-repeat;
        }

        @include media-breakpoint-down(xs) {
          top: -70px;
        }
      }

      p {
        font-family: 'Lidl Font';
        font-weight: bold;
        font-size: 16px;
        line-height: 120%;
        color: #000000;
        margin-bottom: 15px;
        margin-left: 10px;

        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 18px;
        }

        &:before {
          content: '';
          display: inline-block;
          width: 57px;
          height: 57px;
          position: absolute;
          top: -35px;
          left: 200px;
          background: url('/layouts/frontend/img/svg/arrowWine.svg') no-repeat;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 20px;

      &__data {
        padding-left: 20px;
      }

      a {
        font-family: 'Lidl Font';
        font-weight: bold;
        max-width: 50%;
        font-size: 16px;
        line-height: 120%;
        margin-top: 15px;
        color: $lidlPrimaryColor;

        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 18px;
        }
      }
    }
  }

  .homepage-lidl__banner {
    width: 100vw;
    position: relative;
    height: 450px;
    color: white;
    max-width: 100%;
    display: flex;

    @include media-breakpoint-down(sm) {
      height: 410px;
      overflow: hidden;
    }

    .col-sm-6 {
      @media (min-width: 600px) {
        max-width: 60%;
        flex: 0 0 60%;
      }
    }

    .banner__content {
      width: 100vw;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 100%;
      position: relative;

      .banner__content-text {
        font-size: 3rem;
        font-weight: 600;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        line-height: 1.2em;
        margin-top: -0.5em;

        @include media-breakpoint-down(sm) {
          font-size: 28px;
        }

        @include media-breakpoint-down(xs) {
          text-align: center;
          font-family: 'Lidl Font';
          font-weight: bold;

          br {
            display: none;
          }
        }
      }

      .content-small_text {
        font-size: 22px;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        margin-top: 16px;

        br {
          display: none;

          @include media-breakpoint-down(sm) {
            display: block;
          }
        }

        @include media-breakpoint-down(sm) {
          font-size: 16px;
        }

        @include media-breakpoint-down(xs) {
          text-align: center;
          margin-top: 5px;
        }
      }

      .banner__content-buttons {
        display: flex;
        margin-top: 32px;

        @include media-breakpoint-down(xs) {
          justify-content: center;
          flex-direction: column;
          align-items: center;

          .button-lidl__slide__primary,
          .button-lidl__slide__secondary {
            margin-right: 0;
          }
        }

        .button-lidl__slide__secondary {
          background: rgba(0, 0, 0, 0.3);
          color: #FFF;

          @include media-breakpoint-down(xs) {
            margin-top: 10px;
          }
        }

        .button-lidl__yellow--bordered {
          color: #000;

          @include media-breakpoint-down(sm) {
            color: #FFF;
          }
        }

        .button-lidl__yellow {
          &:hover {
            color: #000;
          }
        }

        @include media-breakpoint-down(sm) {
          margin-top: 10px;

          .button-lidl__yellow,
          .button-lidl__yellow--bordered,
          .button-lidl__sm-size {
            height: 36px;
            width: auto;
            padding: 0 15px;
          }
        }

        .button-lidl__sm-size:nth-child(2) {
          @include media-breakpoint-down(xs) {
            margin-right: 0;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        justify-content: flex-end;
      }
    }

    .banner__phone {
      position: absolute;
      bottom: 0;
      right: 10%;
      z-index: 2;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &.featureFlagHomepageSlider {
      .homepage__hero {
        .homepage__slide {
          z-index: 9;

          .homepage__slide__bg {
            z-index: 10;
            position: relative;
          }
        }
      }
    }
  }

  .homepage-lidl__banner-v2 {
    display: flex;
    max-width: 1216px;
    margin-top: 110px;
    margin-bottom: 32px;
    overflow: hidden;
    flex-direction: column-reverse;

    @media (min-width: 640px) {
      flex-direction: row;
      height: 500px;
      margin-top: 32px;
      margin-bottom: 56px;
    }

    &__content {
      z-index: 20;
      padding: 16px 16px 20px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 6px;
      margin: -40px 10px 0 10px;

      @media (min-width: 640px) {
        padding: 48px;
        margin: 0;
        flex: 0.3;
        border-radius: 6px 0 0 6px;
        min-width: 450px;
      }

      &__title {
        font-weight: 700;
        text-align: center;

        font-size: 30px;
        line-height: 1;

        @media (min-width: 640px) {
          font-size: 44px;
        }
      }

      &__description {
        font-weight: 400;

        font-size: 18px;
        line-height: 1.2;
        margin-top: 10px;

        @media (min-width: 640px) {
          font-size: 20px;
          margin-top: 16px;
          text-align: center;
        }
      }

      &__buttons {
        margin-top: 24px;
        display: flex;
        gap: 16px;

        >a {
          font-weight: 600;
          text-decoration: none;

          font-size: 12px;
          line-height: 14px;

          padding: 8px 12px;
          border: 1.5px solid;
          text-transform: uppercase;

          @media (min-width: 640px) {
            font-size: 16px;
            line-height: 20px;

            padding: 8px 16px;
          }

          &:hover {
            opacity: 0.9;
          }
        }
      }
    }

    &__image {
      z-index: 10;
      height: 300px;
      background-color: #ededed;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;
      overflow: hidden;

      @media (min-width: 640px) {
        height: 100%;
        flex: 1;
        border-radius: 0 6px 6px 0;
      }

      >img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-height: 300px;
      }

      &--desktop {
        display: none;
        object-position: center;

        @media (min-width: 640px) {
          display: block;
        }
      }

      &--mobile {
        display: block;
        object-position: bottom center;

        @media (min-width: 640px) {
          display: none;
        }
      }
    }
  }

  .homepage-lidl__section {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 0px 50px 0px;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    min-height: 800px;

    @media (max-height: 500px) {
      min-height: 500px;
    }

    @media (max-height: 800px) {
      min-height: 100vh;
    }

    .section__header {
      font-size: 2rem;
      max-width: 100%;
      font-family: 'Lidl Font';
      font-weight: bold;

      h2 {
        line-height: 1.5em;
      }

      h3 {
        font-size: 1em;
      }

      h4 {
        font-size: 1em;
        line-height: 1.2em;
      }

      b {
        font-family: 'Lidl Font';
        font-weight: bold;
      }
    }

    .section__text {
      font-size: 1rem;
      margin-top: 1em;
      max-width: 100%;
    }

    .section__buttons {
      margin-top: 2em;
    }

    .section__subheader {
      font-size: 1.9rem;
    }

    &.lidl__section-laptop {
      background: $gray_246;

      .section__photo {
        margin-top: 1.5em;
        text-align: center;
      }

      .section__laptop {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        img {
          width: 950px;
          min-height: 1px;

          @include media-breakpoint-up(md) {
            margin-right: -160px;
          }

          @include media-breakpoint-down(md) {
            max-width: 550px;
          }
        }

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &.lidl__section-tags {
      height: auto;
      min-height: auto;
    }

    &.promoPlansSection {
      min-height: 0;
      padding-bottom: 0;

      .lidl-container {
        width: 100%;

        .col-12 .col-12 {
          padding: 0;
          margin-left: -5px;
          margin-right: -10px;
        }
      }

      .popular-plans__plan-list {
        display: flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5em;
        justify-content: space-between;

        .single-item.main-page {
          margin-right: 0;
          margin-left: 0;
          width: 20%;
        }

        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        @include media-breakpoint-down(xs) {
          justify-content: center;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
      min-height: 50vh;
    }

    .section__hometags {
      display: flex;
      flex-wrap: wrap;
      width: 60%;
      margin: 0 auto;
      justify-content: center;
      margin-top: 1.5em;

      .single__tag {
        margin: 0px 10px 10px 0px;
        background: $lidlDarkBlue_01;
        border-radius: 4px;
        padding: 5px 10px;

        font-size: 0.8rem;
        text-align: center;
        text-transform: uppercase;
        color: $lidlPrimaryColor;
        font-weight: 600;
        @include transition(0.2s all);

        &:hover {
          background: $lidlDarkBlue;
          text-decoration: none;
          color: white;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .section__main-photo {
      height: 80vh;
      width: 60vw;
      position: relative;

      img {
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
          max-width: 100%;
          border-radius: 4px;
        }
      }
    }

    .section__main-photo--3 {
      @extend .section__main-photo;

      &.bgImageActive {
        background-image: url('/layouts/frontend/img/homepage_de/section_3.jpg'); //lazy-loading
      }

      background-size: cover;
      background-position: right;
      border-radius: 0px 4px 4px 0px;
      float: left;
      margin-left: -25vw;

      @include media-breakpoint-up(md) {
        margin-left: -37vw;
      }

      @include media-breakpoint-down(md) {
        margin-left: -12vw;
      }

      @include media-breakpoint-down(sm) {
        height: auto;
        position: relative;
        left: unset;
        right: unset;
        width: 100%;
        float: none;
        margin: 0;
      }
    }

    .section__main-photo--4 {
      @extend .section__main-photo;

      &.bgImageActive {
        background-image: url('/layouts/frontend/img/homepage_de/section_4.jpg');
      }

      background-size: cover;
      background-position: center;
      border-radius: 4px 0px 0px 4px;
      margin-right: -25vw;
      float: right;
      height: 60vh;

      @include media-breakpoint-up(md) {
        margin-right: -40vw;
        left: 15px;
      }

      @include media-breakpoint-down(md) {
        margin-right: -20vw;
      }

      @include media-breakpoint-down(sm) {
        height: auto;
        position: relative;
        left: unset;
        right: unset;
        width: 100%;
        float: none;
        margin: 0;
      }
    }

    .section__content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: baseline;

      @include media-breakpoint-down(sm) {
        padding: 30px 0;
      }

      &.section__content--padded {
        padding: 50px;

        @include media-breakpoint-down(sm) {
          padding: 30px 0;
        }
      }
    }

    &.lidl__section-bottom {
      min-height: 20vh;

      .section__content {
        flex-direction: row;
        flex-wrap: wrap;
      }

      .section-bottom__buttons {
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(md) {
          justify-content: space-between;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 2em;
        }
      }
    }

    &.lidl__section--promoPlans {
      background: $gray_246;
    }
  }

  &__sliders-container {
    background: #f6f6f6;
    width: 100%;
    padding-bottom: 50px;

    @media (max-width: 800px) {
      padding-bottom: 0;

      .lidl-container {
        padding: 0;

        .row {
          margin-left: 0;
          margin-right: 0;

          .col-12 {
            padding: 0;
          }
        }
      }

      .slick-list {
        padding: 0 10% 0 0;
      }
    }
  }

  &__sliders {
    margin-top: -160px;
    padding: 20px 10px;
    background: #FFF;
    border-radius: 6px;
    margin-bottom: -5px;
    margin-top: -100px;
    pointer-events: auto;

    .popular-plans__plan-list {
      .single-item {
        min-width: 190px;

        @media (max-width: 768px) {
          min-width: 200px;
        }
      }
    }

    //.slickSlider {
    display: flex;
    //}

    @media (max-width: 768px) {
      //overflow-x: scroll;
      //overflow-y: hidden;
      //transform: translateZ(0);
      //-webkit-transform: translateZ(0px);
      //will-change: transform;
      //-ms-overflow-style: -ms-autohiding-scrollbar;
      //-webkit-overflow-scrolling: touch;
      border-radius: 0;
      //pointer-events: auto;


      //&::-webkit-scrollbar {
      //  display: none;
      //}
      //
      //&::-webkit-scrollbar {
      //  display: none;
      //}
      //
      //&::-webkit-scrollbar {
      //  display: none;
      //}

      .popular-plans__plan-list {

        display: -webkit-flex;
        width: 100%;
        display: flex;

        .single-item {
          margin-right: 0;
        }
      }
    }

    .single-item {
      margin: 0;
      width: calc(100% / 6);
      padding: 0 10px;

      &__labelPromo {
        width: 30px;
        height: auto;
        bottom: 75px;
        position: absolute;
        background-size: contain;
        left: 20px;
      }

      @media (max-width: 768px) {
        width: 80%;
        max-width: 200px;
      }

      .single-item__name {
        padding: 12px;
        font-size: 16px;
        border-width: 1px;
        height: 65px;
        margin-bottom: 0;
        position: relative;
        overflow: hidden;

        a {
          text-overflow: ellipsis;
          height: auto;
          max-height: 100%;
        }

        &:after {
          content: "";
          text-align: right;
          position: absolute;
          bottom: 16px;
          right: 0;
          width: 20%;
          height: 1em;
          //background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
        }
      }

      .single-item__image {
        height: 140px;
        border-radius: 4px 4px 0 0;
      }
    }
  }

  &__hero {
    position: relative;

    &,
    .slickSlider--hero {
      height: 100%;
    }

    .slickSlider--hero {
      @media (min-width: 769px) {
        display: -webkit-flex;
        display: flex;
      }

      .slick-list,
      .slick-track,
      .slick-slide>div {
        height: 100%;
      }
    }

    .slick-dots {
      visibility: hidden;
      opacity: 0;
      bottom: 110px;

      @media (max-width: 800px) {
        opacity: 1;
        visibility: visible;
      }

      li {
        width: 10px;
        height: 10px;

        button {
          padding: 4px;
          background: rgba(255, 255, 255, 0.25);
          border: 0;
          width: 8px;
          height: 8px;

          &:hover {
            background: #FFF;
          }

          &:before {
            display: none;
          }
        }

        &.slick-active {
          button {
            width: 8px;
            height: 8px;
            background: #FFF;
          }
        }
      }


    }

    .slick-prev,
    .slick-next {
      @media (max-width: 1300px) {
        visibility: hidden;
        opacity: 0;
      }
    }

    &__nav {
      position: absolute;
      left: 0;
      top: 25px;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      z-index: 1;

      @media (max-width: 800px) {
        visibility: hidden;
        opacity: 0;
        display: none !important;
      }

      ul {
        list-style-type: none;
        padding: 5px;
        background: #FFFFFF;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        border-radius: 25px;
        display: -webkit-flex;
        display: flex;

        li {
          a {
            padding: 5px 16px;
            display: block;
            color: $lidlPrimaryColor;
            text-decoration: none;

            font-size: 16px;
            color: #212121;

            &:hover,
            &.active {
              background: rgba($lidlPrimaryColor, 0.05);
              border-radius: 25px;
              color: $lidlPrimaryColor;
            }

            &.active {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  &__slide {
    position: relative;
    width: 100%;

    &,
    .homepage__slide__bg {
      height: 100%;
      display: -webkit-flex;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--staticBg {
        justify-content: start;

        &,
        .row--staticBg {
          margin-top: 58px;
        }
      }
    }

    .homepage__slide__bg {
      width: 100vw;

      &--activeBg {
        //background: rgba(#000, 0.25);

        @include media-breakpoint-down(sm) {
          background: rgba(#000, 0.4);
        }
      }
    }
  }

  .signPost {
    background: $gray_246;

    &--modification {

      &-recipe {

        .section__hometags {
          max-width: 900px;
        }

        .signPost__weekRecipe {
          @include media-breakpoint-down(xs) {
            margin-right: 12px;
          }

          &__imagesDesktop {
            display: block;

            @include media-breakpoint-down(sm) {
              display: none;
            }
          }

          &__imagesMobile {
            display: none;

            @include media-breakpoint-down(sm) {
              display: block;
            }
          }

          img {
            max-width: 487px;
            width: 100%;

            @include media-breakpoint-down(xs) {
              display: block;
              margin-bottom: 40px;
              height: auto;
            }
          }


          &__footer {
            @include media-breakpoint-down(xs) {
              display: flex;
              justify-content: center;
            }

            img {
              display: none;
              max-width: 337px;
              width: 100%;

            }
          }

          &__header {
            @include media-breakpoint-down(xs) {
              padding-top: 32px;
              border-top: 1px solid #DDDDDD;
              display: flex;
              justify-content: center;
            }

            &--hide {
              &-mobile {
                @include media-breakpoint-down(xs) {
                  display: none;
                }
              }
            }

            justify-content: flex-start;
            margin-bottom: 20px;

            @include media-breakpoint-down(xs) {
              margin-left: 12px;
            }
          }
        }

        .signPost__tags {
          @include media-breakpoint-down(xs) {
            padding-bottom: 32px;
          }
        }

        .recipe__box {
          padding: 0 !important;
        }
      }
    }

    &--homepageRecipesOfTheWeekPromotion {
      min-height: 0;
    }

    .row {
      justify-content: space-between;
    }

    &__form {
      padding: 40px 0;
      display: flex;
      position: relative;

      @include media-breakpoint-down(sm) {
        padding: 15px 0;
      }

      input {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        border-right: 0;
        display: inline-block;
        color: #212121;
        width: 90%;
        width: calc(90% - 48px);
        height: 46px;
        box-sizing: border-box;
        padding: 0 10px;
        border-radius: 4px 0 0 4px;

        @include media-breakpoint-down(sm) {
          width: calc(100% - 48px);
        }
      }

      &__button {
        position: relative;
        left: -2px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 46px;
        background: $lidlPrimaryColor;
        border-radius: 4px;
      }

      &__voiceButton {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        background: #fff;

        right: 98px;
        top: 41px;
        width: 44px;

        @include media-breakpoint-down(sm) {
          width: 36px;
          right: 52px;
          top: 16px;
        }

        .iconset--voice {
          display: none;
        }

        &.active {
          .iconset--voice {
            display: block;
            animation: move 0.5s infinite alternate ease-in;
          }

          .iconset--voiceGray {
            display: none;
          }
        }

        @keyframes move {
          0% {
            transform: scale(1);
          }

          100% {
            transform: scale(1.5);
          }
        }
      }
    }

    &__tags {
      padding: 40px 0 65px;

      @include media-breakpoint-down(sm) {
        padding: 20px 0;
      }

      .section__hometags {
        width: auto;
        margin: 0;
        justify-content: flex-start;
      }
    }

    &__popular {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__popularHeader {
      font-weight: 500;
      font-size: 24px;
      line-height: 50px;

      span {
        font-family: 'Lidl Font';
        font-weight: bold;
      }
    }

    &__categories {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 20px;
    }

    &__cat {
      display: block;
      width: 178px;
      height: 170px;
      background: #FFF;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 16px;
      text-decoration: none;

      &__image {
        height: 122px;
      }

      &__name {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        color: #30303B;
        height: 48px;
        padding: 0 14px;
      }
    }

    &__weekRecipe {
      &__header {
        display: flex;
        justify-content: space-around;

        @include media-breakpoint-down(sm) {
          justify-content: flex-start;
        }

        span {
          display: block;
          padding-left: 20px;
          font-size: 32px;
          font-family: 'Lidl Font';
          font-weight: bold;

          @include media-breakpoint-down(sm) {
            padding-left: 0;
          }
        }

        &__button {
          width: auto;
          padding: 0 24px;
          margin-right: 20px;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }

      &__image {
        &__a {
          display: block;
        }

        &__b {
          display: none;
        }

        &__desktop,
        &__mobile {
          margin: 15px auto 0;
        }

        &__desktop {
          display: block;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        &__mobile {
          display: none;

          @include media-breakpoint-down(sm) {
            width: calc(100% + 20px);
            display: block;
            margin: 20px -10px
          }
        }
      }

      &__footer {
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
        }

        &__button {
          width: 100%;
        }
      }
    }
  }
}

// STEPS INFO SECTION
.homepage__steps-info {}

.steps-info__logo {
  display: block;
  height: 140px;
  margin: 0 auto 50px;

  @include media-breakpoint-down(xs) {
    height: 100px;
  }
}

.steps-info__steps {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    color: inherit;
    text-decoration: none;
  }

  @include media-breakpoint-down(xs) {
    justify-content: center;
  }
}

.steps-info__single-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  margin: 0 10px 25px;
  text-align: center;
  text-transform: uppercase;
  width: 170px;

  img {
    height: 75px;

  }

  @include media-breakpoint-down(xs) {
    margin: 0 5px 15px;
    width: 127px;
  }
}


.vegan-banner {
  width: 100%;

  a {
    cursor: pointer;
    margin: 50px auto;
    display: block;
    max-width: 1170px;
    height: 280px;
    background: url('/layouts/frontend/img/homepage/vegan-banner.png') no-repeat;
    color: #212528;
    text-decoration: none;
    position: relative;

    @media (max-width: 850px) {
      background-position: -100px 0;
    }

    @media (max-width: 750px) {
      background-position: -155px 0;
    }

    @media (max-width: 500px) {
      background-position: -230px 0;
    }

    @media (max-width: 380px) {
      background-position: -280px 0;
    }

    &:after {
      content: '';
      display: block;
      width: 170px;
      height: 96px;
      background: url('/layouts/frontend/img/homepage/vegan-logo.png') no-repeat;
      position: absolute;
      bottom: 10px;
      left: 50%;
      margin-left: -200px;

      @media (max-width: 1150px) {
        margin-left: -165px;
      }

      @media (max-width: 1050px) {
        margin-left: -115px;
      }

      @media (max-width: 950px) {
        bottom: -10px;
      }

      @media (max-width: 760px) {
        margin-left: -65px;
        bottom: -25px;
      }
    }

    h3,
    p {
      width: 50%;

      @media (max-width: 610px) {
        width: 65%;
      }

      @media (max-width: 380px) {
        width: 75%;
      }
    }

    h3 {
      font-size: 48px;
      padding-top: 50px;
      padding-left: 25px;

      @media (max-width: 750px) {
        padding-top: 20px;
      }

      @media (max-width: 460px) {
        font-size: 36px;
      }
    }

    p {
      font-size: 24px;
      padding-left: 25px;

      @media (max-width: 460px) {
        font-size: 20px;
      }
    }
  }
}

.monsieurCusineSection {
  margin-top: 30px;
  margin-bottom: 80px;

  h3 {
    font-weight: bold;
  }

  .section__buttons {
    margin-top: 0 !important;
  }

  &__img {
    margin-top: 30px;

    img {
      max-width: 100%;
    }
  }

  #monsieurCusine {
    margin-top: 30px;

  }
}

@media (min-width: 575px) {
  .homepage .homepage-lidl__banner .banner__content .homepage__slide {
    &--desktopSmallFont {
      .banner__content-text {
        font-size: 2.5rem;
        line-height: 1.2em;
      }
    }

    &--desktopNewUi {
      .lidl-container {
        padding: 0;
      }

      .banner__content-text,
      .content-small_text span {
        text-transform: uppercase;
        text-align: left;

        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }

      .banner__content-text {
        display: inline;
        border-radius: 4px;
        padding: 2px 10px;

        font-weight: 700;
        font-size: 42px;
        line-height: 50px;
      }

      .content-small_text {
        margin-top: 18px;
        display: block;
        text-align: left;
      }

      .content-small_text span {
        border-radius: 3px;
        padding: 5px 8px;

        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
      }

      &.homepage__slide--desktopSmallFont {
        .banner__content-text {
          font-size: 32px;
          line-height: 42px;
        }

        .content-small_text {
          margin-top: 6px;
        }

        .content-small_text span {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .homepage .homepage-lidl__banner .banner__content .homepage__hero .homepage__slide {

    &--mobileSmallFont {
      .banner__content-text {
        font-size: 22px;
      }
    }

    &--mobileNewUi {
      .lidl-container {
        padding: 0;
      }

      .banner__content-text,
      .content-small_text span {
        display: inline;
        text-transform: uppercase;
        text-align: left;

        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
      }

      .banner__content-text {
        border-radius: 0 4px 4px 0;
        padding: 2px 12px 2px 12px;

        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
      }

      .content-small_text {
        margin-top: 10px;
        display: block;
        text-align: left;
      }

      .content-small_text span {
        border-radius: 0 3px 3px 0;
        padding: 3px 6px 3px 12px;

        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
      }

      &.homepage__slide--mobileSmallFont {
        .banner__content-text {
          font-size: 20px;
          line-height: 28px;
        }

        .content-small_text {
          font-size: 10px;
          line-height: 12px;
        }
      }

      .banner__content-buttons {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0 10px;

        &--staticBg {
          position: absolute;
          top: 70px;
        }
      }
    }
  }
}

.leaflet {
  margin: 0 0 40px;

  .screen {
    margin-bottom: -15px;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    &:after {
      content: '';
      z-index: 10;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 67px;
      display: block;
      background: linear-gradient(270deg, #FFFFFF 5%, rgba(255, 255, 255, 0) 95%);

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .slider {
    display: flex;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;

    overflow-y: hidden;
    padding-bottom: 15px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
      padding-top: 15px;
    }
  }

  &__arrowLeft,
  &__arrowRight {
    position: absolute;
    cursor: pointer;
    z-index: 11;
    left: -8px;
    top: 50%;
    margin-top: -23px;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #1D51A4;
    background-image: url('/layouts/frontend/img/homepage_de/slider_arrow_light.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 9px 17px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &__arrowRight {
    left: auto;
    right: -8px;
    transform: rotate(180deg);
  }

  .oneLeaflet {
    scroll-snap-align: center;
    flex: 1 0 50%;
    display: flex;
    width: 750px;
    height: 318px;
    background: #D6E3F1;
    border-radius: 4px;
    margin-right: 30px;
    padding: 40px;
    position: relative;

    @include media-breakpoint-down(sm) {
      padding: 24px 16px;
      width: 85%;
      flex-direction: column;
      height: auto;
      margin-right: 15px;
    }

    &__img {
      max-width: 178px;
      position: absolute;
      bottom: 0;
      left: 40px;
      max-height: 278px;

      @include media-breakpoint-down(sm) {
        position: static;
        width: 100%;
        margin-bottom: 20px;
        margin-top: -40px;
      }
    }

    &__content {
      padding-left: 252px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        padding-left: 0;
        // @TODO mobile
      }
    }

    &__label {
      display: inline-block;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      background: #F5F8FC;
      border-radius: 3px;
      padding: 3px 7px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__title {
      display: block;
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;
      margin: 16px 0 24px;
      color: #353B42;
      text-decoration: none;
      min-height: 122px;

      @include media-breakpoint-down(sm) {
        font-size: 28px;
        line-height: 34px;
        margin: 12px 0 16px;
        min-height: 102px;
      }

      &:after {
        // full element link
        content: '';
        position: absolute;
        z-index: 9;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }

    .button-lidl__blue {
      position: relative;
      z-index: 10;
    }

  }
}