#favourites-banner {
  opacity: 0;
  visibility: hidden;
  transition: 0.25s all;

  &.visible {
    opacity: 1;
    visibility: visible;

    .info-bar__right {
      background-image: url("/layouts/frontend/img/recipe/sticky-bar.png");
    }
  }

  @media (max-width: 600px) {
    display: none;
  }

  &.mobileVisible {
    @media (max-width: 600px) {
      display: block;
    }
  }
}


/* copied from info-bar.component.scss */
.info-bar {
  color: white;
  position: fixed;
  bottom: 2%;
  right: 2%;
  width: 20vw;
  max-width: 100%;
  min-width: 500px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgb(27, 81, 125);
  z-index: 99;
  text-align: center;
  height: 100px;
}

.info-bar__text {
  margin: 0 0 12px 0;
  font-weight: bold;
  font-size: 1.1rem;
  line-height: 1.2;
}

.info-bar__btn {
  margin: 0;
  padding: 4px 10px;
  background-color: white;
  color: rgb(27, 81, 125);
  border: 2px solid rgb(27, 81, 125);
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color .15s ease-in;
}

.info-bar__btn:hover {
  background-color: rgb(27, 81, 125);
  color: white;
  border: 2px solid white;
}

.info-bar__left {
  width: 50%;
  padding: 0 10px;
}

.info-bar__right {
  width: 50%;
  background-size: cover;
  height: 100%;
  background-position-y: center;
  background-position-x: -19px;
}

@media (max-width: 599px) {
  .info-bar {
    height: 70px;
    bottom: 0;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
  }

  .info-bar__left {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .info-bar__text {
    margin-bottom: 0;
    margin-right: 0.3rem;
  }

  .info-bar__right {
    display: none;
  }

  .info-bar__text {
    width: 50%;
  }

}

@media (max-width: 400px) {
  .info-bar__text {
    width: 38%;
  }

  .info-bar__btn {
    padding: 4px 8px;
  }
}

/* end copied from info-bar.component.scss */

.info-bar {
  z-index: 9998;
}

.info-bar__text {
  font-size: 1rem;

  @media (max-width: 400px) {
    font-size: 0.9em;
  }
}