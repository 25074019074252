.themenLanding {
  h1 {
    font-size: 44px;
    font-weight: bold;
    line-height: 52px;
    margin-top: 12px;

    @media (max-width: 900px) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__navigation {
    margin-bottom: -16px;

    &__title {
      margin-top: 32px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: normal;
      line-height: 19px;
      color: #353B42;
    }

    ul {
      max-width: 750px;
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        margin: 0 8px 8px 0;
        border-radius: 4px;
        border: 1px solid #E3E4E5;
        padding: 0;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 6px 10px;
          color: #353B42;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }
  }

  &__tag {
    margin: 80px 0;
    padding: 80px 0;

    @media (max-width: 900px) {
      margin: 32px 0;
      padding: 16px 0;
    }

    &,a {
      color: #111;
    }

    &__title {
      font-weight: bold;
      font-size: 42px;
      margin-bottom: 24px;

      @media (max-width: 900px) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    &__scroll {
      @media (max-width: 900px) {
        overflow: hidden;
        margin-bottom: -16px;
        padding-bottom: 16px;
        margin-left: -16px;
        margin-right: -16px;
        scroll-snap-type: y mandatory; // @TODO not working
      }
    }

    &__list {
      display: flex;
      justify-content: flex-start;
      gap: 24px;
      flex-wrap: wrap;
      max-height: 456px;
      transition: 0.5s max-height ease-in-out;
      overflow: hidden;

      @media (max-width: 900px) {
        flex-wrap: nowrap;
        gap: 10px;
        overflow-x: scroll;
        overflow-y: hidden;
        transform: translateZ(0);
        will-change: transform;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__element {
        width: 175px;
        margin-bottom: 24px;

        @media (max-width: 900px) {
          margin-bottom: 0;
          scroll-snap-align: start;


          &:first-child {
            margin-left: 16px;
          }
        }

        &__title {
          font-size: 16px;
          line-height: 19px;
          margin-top: 8px;
          font-weight: 600;
        }
        &__img {
          width: 175px;
          height: 175px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;

          @media (max-width: 900px) {
            width: 115px;
            height: 115px;
          }
        }
      }
    }

    &__moreButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 72px;
      border-radius: 4px;
      background: rgba(#111, 0.15);
      margin-top: 24px;

      @media (max-width: 900px) {
        display: none;
      }

      span {
        display: flex;
        align-items: center;

        &:before {
          content: '';
          display: inline-block;
          margin-right: 6px;
          width: 20px;
          height: 20px;
          background: url('/layouts/frontend/img/themen-landing/arrow-down.svg') no-repeat;
        }
      }

      &--extended {
        span:before {
          transform: rotate(180deg);
        }
      }
    }

    &--white {
      &, a {
        color: #FFF;
      }

      .themenLanding__tag__moreButton span:before {
        background-image: url('/layouts/frontend/img/themen-landing/arrow-down-white.svg');
      }
    }
  }

  &__cloud {
    margin: 80px 0;

    @media (max-width: 900px) {
      margin: 32px 0;
    }

    &__title {
      font-size: 34px;
      font-weight: 600;
      line-height: 41px;
      margin-bottom: 24px;

      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      a {
        display: flex;
        border-radius: 4px;
        background: #E7EFF8;
        color: #0050AA;
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        padding: 6px 10px;
        margin: 8px 8px 0 0;
      }
    }
  }

  &__boxes {
    margin: 80px 0;

    @media (max-width: 900px) {
      margin: 32px 0;
    }

    &__title {
      font-size: 34px;
      font-weight: 600;
      line-height: 41px;
      margin-bottom: 24px;

      @media (max-width: 900px) {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 16px;
      }
    }

    &__list {
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      justify-content: space-between;

      &__element {
        color: #000;
        text-align: center;
        padding: 24px;
        padding-top: 152px;
        width: 274px;
        box-sizing: border-box;

        border-radius: 4px;
        border: 1px solid #D2D2D2;

        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        background-position: center 24px;
        background-repeat: no-repeat;

        @media (max-width: 900px) {
          width: calc(50% - 8px);
          font-size: 12px;
          line-height: 14px;
          background-size: 64px 64px;
          padding: 18px;
          padding-top: 100px;
        }

        &--fruehlingsrezepte {
          &.bgImageActive {
            background-image: url('/layouts/frontend/img/themen-landing/spring.svg');
          }
        }
        &--sommerrezepte {
          &.bgImageActive {
            background-image: url('/layouts/frontend/img/themen-landing/summer.svg');
          }
        }
        &--winterrezepte {
          &.bgImageActive {
            background-image: url('/layouts/frontend/img/themen-landing/winter.svg');
          }
        }
        &--herbstrezepte {
          &.bgImageActive {
            background-image: url('/layouts/frontend/img/themen-landing/autumn.svg');
          }
        }
      }
    }
  }
}
