.rating {
    margin: 0;
    padding-top: 50px;
    border-top: 1px solid #dddddd;
    position: relative;

    .buttonBubble__element {
        bottom: -163px;
        left: 87px;

        &--ratings {
            width: 300px;
            padding: 20px;
        }

        &:after {
            @media (max-width: 577px) {
                left: 102px;
            }
        }

        
        @media (max-width: 577px) {
            bottom: -108px;
            left: 1px;
            width: 100%;
        }

        @media (max-width: 390px) {
            bottom: -127px;
        }

        @media (max-width: 345px) {
            bottom: -130px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 30px 0 5px;
        margin-top: 10px !important;
    }

    .rating__header {
        font-size: 1.5em;
        padding-bottom: 24px;
        font-weight: bold;
    }

    .rating__rate {
        font-size: 16px;
        min-width: 100px;
        color: #212121;
        line-height: 32px;
        font-weight: bold;
    }

    .rating__string {
        line-height: 32px;
    }

    .rating__box {
        direction: rtl;

        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }
}

.rating__box {
    .rating__star {
        content: '';
        width: 32px;
        height: 32px;
        display: inline-block;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .rating__star path {
        stroke: #e3e4e5;
        fill: none;
    }
    .rating__star--active path {
        stroke: #f7a606;
        fill: #f7a606;
    }
    .rating__star--inactive path {
        stroke: #e3e4e5;
        fill: #e3e4e5;
    }

    &--hoverOption {
        .rating__star {
            cursor: pointer;
        }

        .rating__star:hover path {
            stroke: #fbcb03;
            fill: #fbcb03;
        }

        @for $i from 1 through 5 {
            .rating__star_#{$i}:hover ~ i path {
                stroke: #fbcb03;
                fill: #fbcb03;
            }
        }
    }
}



.rating-average {
    min-height: 80px;
    min-width: 1px;

    &.noRatings {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    &--improvedView {
        position: relative;
        margin-right: 10px;
        padding-right: 15px;
        min-height: 58px;
        width: 215px;
        cursor: pointer;

        margin-top: 25px;
        margin-bottom: 30px;

        .view-new & {
            margin-top: 0;
            margin-bottom: 0;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 17px;

            .view-old & .rating-average__stars,
            .view-new & .rating-average__stars {
                padding: 0;
            }
        }

        .rating-average__stars {
            display: flex;
            align-items: center;

            .rating-average__star {
                &, svg {
                    cursor: pointer;
                    width: 28px;
                    height: 28px;
                }
            }

            .rating-average__box--outer,
            .rating-average__box--inner {
                height: 25px;
            }

            .ratingRateScore {
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                display: flex;
                align-items: center;
                color: #353B42;
                margin-left: 5px;
            }
        }

        .ratingAndCommentText {
            color: #0050AA;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            border-bottom: 1px solid #0050AA;
            margin-top: 10px;
            display: inline-block;
        }

        .view-new & {
            &:after {
                content: '';
                width: 1px;
                height: 48px;
                background: #353B42;
                opacity: 0.25;

                position: absolute;
                top: 5px;
                right: 5px;

                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }

    .rating-average__rate {
        font-size: 16px;
        color: #212121;
        line-height: 40px;
        font-weight: bold;
    }

    .rating-average__star {
        content: '';
        cursor: default;
        width: 32px;
        height: 32px;
        display: inline-block;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .rating-average__box--outer {
        position: relative;

        .rating-average__star path {
            stroke: #e3e4e5;
            fill: #e3e4e5;
        }
    }
    .rating-average__box--inner {
        position: absolute;
        z-index: 2;
        top: 0;
        bottom: 0;
        left: 0;
        width: 0px;
        overflow: hidden;

        .rating-average__box--inner-stars {
            width: 200px;
        }

        .rating-average__star path {
            stroke: #f7a606;
            fill: #f7a606;
        }
    }

    .rating-average__string {
        line-height: 32px;
    }
}

.view-old .rating-average {
    &.noRatings {display: none;}
}