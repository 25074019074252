.shoplist-landing-main {
  &.section4 {

    &.bgImageActive {
      &:after {
        background-image: url("/layouts/frontend/img/shoplist-landing/section4-phone.png");

        @include media-breakpoint-down(sm) {
          background-image: url("/layouts/frontend/img/shoplist-landing/section4-phone-mobile.png");
        }
      }
      .shoplist-section {
        &__leftBox {
          background-image: url("/layouts/frontend/img/shoplist-landing/section4-background.png");

          &:after {
            @include media-breakpoint-down(sm) {
              background-image: url("/layouts/frontend/img/shoplist-landing/section4-phone-mobile.png");
            }
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column !important;
    }

    &:after {
      bottom: 0;
      top: initial;
      width: 480px !important;

      @include media-breakpoint-down(sm) {
        bottom: 0 !important;
        top: initial !important;
      }
    }
    .shoplist-section {

      &__rightBox {
        position: initial;
        img {
          top: initial;
          bottom: 0;
        }
      }
      &__leftBox {

        &:after {
          @include media-breakpoint-down(sm) {
            bottom: 0;
            top: initial !important;
          }
        }

        &:before {
          position: absolute;
          top: 0px;
          left: 0;
          content: "";
          width: 500px;
          height: 100%;
          background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0)) !important;
        }
      }
    }
  }
}