.christmas-section90 {
  &.christmas-landing {
    .feedSection {
      &:after {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }
  &.bgImageActive {
    .feedSection {
      &--section9 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section90/section9-photo.jpg");
        }
      }
    }
  }
}
