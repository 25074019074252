.sallys-landing__section21 {
  background: #F8EBF1;
  padding: 20px 0 70px;

  @include media-breakpoint-down(sm) {
    padding: 20px 0;
  }

  &__head {
    &__header {
      font-weight: 600;
      font-size: 34px;
      line-height: 41px;

      @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 38px;
      }
    }

    &__desc {
      font-size: 18px;
      line-height: 25px;
      padding: 10px 0 40px;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 22px;
        padding: 5px 0 20px;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__videoSection {
    width: 50%;
    position: relative;
    margin-right: 64px;
    background: #FFF;
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 10px;
      }
    }

    &__recipeImage {
      position: relative;
      background-size: cover;
      min-height: 311px;
      height: 311px;

      @include media-breakpoint-down(sm) {
        // https://css-tricks.com/aspect-ratio-boxes/
        height: calc(591.44 / 1127.34 * 100vw);
        min-height: calc(591.44 / 1127.34 * 100vw);
      }

      &--1 {
        background-image: url('/layouts/frontend/img/sally-landing/recipevideosSectionRecipe1.jpg');
      }
      &--2 {
        background-image: url('/layouts/frontend/img/sally-landing/recipevideosSectionRecipe2.jpg');
      }
      &--videoVisible {
        .sallys-landing__section21__videoSection__recipeImage__label {opacity: 0;}
        .sallys-landing__section21__videoSection__recipeImage__video {display: block;}
      }

      &__buttonVideo {
        width: 100%;
        height: 100%;
        display: block;
        background: url('/layouts/frontend/img/sally-landing/yt.svg') no-repeat center;
        background-size: 96px 96px;

        @include media-breakpoint-down(sm) {
          background-size: 64px 64px;
        }
      }

      &__video {
        display: none;
        position: absolute;
        top: 0;
        left: 0;

        &, iframe {
          width: 100%;
          height: 100%;
        }
      }

      &__label {
        position: absolute;
        top: 16px;
        right: -12px;
        background: #E60A14;
        padding: 6px 12px;
        border-radius: 2px 2px 0 2px;

        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        color: #FFFFFF;

        z-index: 2;
        transition: 0.5s opacity;

        @include media-breakpoint-down(sm) {
          top: 8px;
          right: -8px;
          padding: 6px 8px;
          font-size: 14px;
          line-height: 20px;
        }

        &:after {
          position: absolute;
          right: -12px;
          bottom: -12px;

          content: '';
          width: 0; height: 0;
          border-right: 12px solid transparent;
          border-bottom: 12px solid transparent;
          border-left: 12px solid #9C070E;
          z-index: 1;

          @include media-breakpoint-down(sm) {
            right: -8px;
            bottom: -8px;
            border-right-width: 8px;
            border-bottom-width: 8px;
            border-left-width: 8px;
          }
        }
      }
    }

    &__content {
      position: relative;
      border: 1px solid #D4307D;
      border-top: 0;
      padding: 20px 24px 24px;
      height: 100%;

      @include media-breakpoint-down(sm) {
        padding: 8px;
      }

      &__header {
        font-weight: 600;
        font-size: 30px;
        line-height: 36px;

        @include media-breakpoint-down(sm) {
          padding-bottom: 50px;
          font-size: 20px;
          line-height: 24px;
        }
      }

      &__desc {
        font-size: 18px;
        line-height: 25px;
        padding-bottom: 65px;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }

    &__button {
      position: absolute;
      bottom: 24px;
      left: 24px;

      @include media-breakpoint-down(sm) {
        width: calc(100% - 16px);
        left: 8px;
        right: 8px;
        bottom: 8px;
      }
    }
  }
}