.actions-woche {

  .related-recipes {
    color: black;
  }

  position: relative;
  background-repeat: no-repeat;
  height: 280px;
  background-size: 1170px 280px;
  display: flex;
  margin-top: 30px;
  padding: 0 50px;

  @media (max-width: 560px) {
    flex-direction: column;
    padding: 230px 20px 20px;
    background-size: cover;
    height: initial;
    margin-bottom: 100px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;

    &--right {
      position: absolute;
      right: 190px;
      top: 113px;
      @include media-breakpoint-down(sm) {
        padding-left: 30px;
        position: initial;
        right: 0;
        top: 0;
      }
    }

    @media (max-width: 560px) {
      padding-left: 0;
    }

    h3, p {
      color: #FFFFFF;
    }

    h3 {
      font-size: 48px;
      line-height: 120%;
      margin-bottom: 24px;

      @media (max-width: 800px) {
        font-size: 32px;
      }
    }

    p {
      font-size: 20px;
      line-height: 160%;

      @media (max-width: 800px) {
        font-size: 14px;
        max-width: 100%;
      }

      max-width: 77%;

    }

    a {
      padding: 15px 40px;
      color: #2875BF !important;
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      background: #FFFFFF;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
      border: none;
      white-space: nowrap;
      cursor: pointer;
      text-align: center;
      text-transform: uppercase;

      @media (max-width: 560px) {
        width: 100%;
        padding: 12px 40px;
      }
    }

  }
  &--italiamo {
    background-image: url("/layouts/frontend/img/actions-woche/italiamo.jpg");

    @media (max-width: 560px) {
      background-image: url("/layouts/frontend/img/actions-woche/italiamo-mobile.jpg");
    }
  }
  &--balkan {
    background-image: url("/layouts/frontend/img/actions-woche/balkan.jpg");

    @media (max-width: 560px) {
      background-image: url("/layouts/frontend/img/actions-woche/balkan-mobile.jpg");
    }
  }
  &--french {
    background-image: url("/layouts/frontend/img/actions-woche/french.jpg");

    @media (max-width: 560px) {
      background-image: url("/layouts/frontend/img/actions-woche/french-mobile.jpg");
    }
  }
  &--alpine {
    background-image: url("/layouts/frontend/img/actions-woche/alpine.jpg");

    @media (max-width: 560px) {
      background-image: url("/layouts/frontend/img/actions-woche/alpine-mobile.jpg");
    }
  }
  &--greek {
    background-image: url("/layouts/frontend/img/actions-woche/greek.jpg");

    @media (max-width: 560px) {
      background-image: url("/layouts/frontend/img/actions-woche/greek-mobile.jpg");
    }
  }
}
