
.editable-control {
  width: 100%;
  display: none;
}

.tooltip {
  display: none !important;
}

.note-popover.popover {
  display: none;
}

.btn-group > .btn {
  line-height: 10px;
}

.edit-mode {
  .editable-container {
    background-color: rgba(0, 194, 255, 0.15);
  }
}

.editable-container {
  p {
    margin-bottom: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
}