.homepage {

  &--promotionTime {
    .homepage-lidl__banner {
      @media (min-width: 600px) {
        background-position: 0 120px;
        min-height: 750px;
      }

      @media (max-width: 600px) {
        height: calc(100vh - 60px);
        background-position: -230px 230px;
      }
      @media (max-width: 600px) and (max-height: 600px) {
        height: calc(100vh + 70px);
      }
    }

    .banner__promotion {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 120px;

      background-color: #127840;
      background-image: url('/layouts/frontend/img/promotions/christmas/background.png');
      background-size: 1920px 990px;

      display: flex;
      align-items: center;

      z-index: 3;

      @media (max-width: 600px) {
        height: auto;
        padding: 15px 0;
        top: 45px;
      }

      @include media-breakpoint-down(sm) {

      }


      &__header {
        font-size: 36px;
        line-height: 44px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        font-family: 'Lidl Font'; font-weight: bold;


        @media (max-width: 1024px) {
          font-size: 24px;
          line-height: 30px;
          margin-bottom: 10px;
        }
      }

      &__subheader {
        font-size: 20px;
        font-weight: bold;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

        @media (max-width: 1024px) {
          font-size: 16px;
          line-height: 19px;
        }
      }

      &__button {
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
          margin-top: 20px;
        }

        .button-lidl__yellow {
          width: 100%;
        }
      }
    }
  }

  & &-lidl__section {
    &--promotion {
      background-color: #127840;
      background-image: url('/layouts/frontend/img/promotions/christmas/background.png');
      background-size: 1920px 990px;
      color: #FFF;

      .section__header {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

        span {
          color: #FBEF35;
          font-family: 'Lidl Font'; font-weight: bold;
        }
      }

      .section__text {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        padding-bottom: 20px;
      }


      .categoryName {
        font-size: 24px;
        line-height: 29px;
        margin-top: 40px;
        font-weight: bold;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
      }


      .popular-plans__plan-list {
        margin-top: 20px;
        justify-content: space-between;

        .single-item {
          width: 23%;
          margin-right: 0;
          margin-left: 0;

          @include media-breakpoint-down(sm) {
            width: 48%;
          }

          @include media-breakpoint-down(xs) {
            width: 100%;
          }

          &__name {
            background: #FFF;
            border-radius: 0 0 4px 4px;
          }
        }
      }

      .button-lidl__yellow {
        min-width: 220px;
        margin: 0 auto;
      }


    }
  }
}

#promoPlans {
  margin-top: 50px;
}

.promoPlansItem {

  .single-item__image {
    height: 180px;

    &--content {
      background: none;
    }
  }

  .single-item__name {
    height: auto;

    a {
      color: #30303B
    }
  }


  &__author {
    color: darken(#BDBDBD, 25%);
    font-size: 12px;
    padding-top: 5px;
  }

  &__dots {
    list-style-stype: none;
    margin: 0;

    border-top: 1px solid #F2F2F2;
    padding: 5px 0 0;
    margin-top: 10px;

    li {
      display: inline-block;
      font-size: 12px;
      color: #212121;

      &:after {
        content: '•';
        padding-left: 4px;
      }

      &:last-child {
        &:after {
          content: '';
          padding-left: 0;
        }
      }
    }
  }
}