#accountMembers {
  .account__member-photo {
    width: 150px;
    height: 150px;
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    border: 2px solid black;
    margin: 0 auto;
    cursor: pointer;
  }

  .account__member--control {
    display: none;
  }

  .slick-slide {
    min-width: 190px;
  }
  .account__member {
    position: relative;
    width: 190px;
    margin: 0 auto;
    max-width: 100%;
  }

  .slick-current {
    .account__member {
      .account__member--control {
        width: 50px;
        height: 50px;
        line-height: 48px;
        display: block;
        background: black;
        color: white;
        font-size: 1.5rem;
        text-align: center;
        border-radius: 50%;
        position: absolute;

        cursor: pointer;
        border: 2px solid black;
        @include transition(0.2s all);

        &:hover {
          text-decoration: none;
          background-color: white;
          color: black;
          polygon, line {
            stroke: black;
          }
        }
      }
    }
  }

  .account__member--placeholder {
    @extend .account__member;
    .account__member-photo {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .account__member-control--add {
    @extend .account__member--control;
    bottom: 0;
    right: 10px;
    left: 72%;
  }

  .account__member-control--edit {
    @extend .account__member--control;
    bottom: 0;
    left: 5%;
  }

  .account__member-control--remove {
    @extend .account__member--control;
    top: 0;
    right: 10px;
    left: 72%;
  }

  .slick-slide {
    transform: scale(0.6);
  }

  .slick-slide.slick-cloned {
    transform: scale(0.6);
  }

  .slick-slide.slick-active {
    transform: scale(0.6);
  }

  .slick-slide.slick-current.slick-active.slick-center {
    transform: scale(1);
    transition: all;
    transition-duration: 0.3s;
  }

  svg {
    width: auto;
  }
}