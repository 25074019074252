#newsletterLeftBar {
  position: fixed;
  top: 50%;
  margin-top: -21px;
  width: 43px;
  height: 219px;
  background-size: 86px 219px;
  background-repeat: no-repeat;
  background-image: url("/layouts/frontend/img/newsletterLeftBar.png");
  left: 0;
  z-index: 99;
  transition: 250ms left;

  @media (max-width: 600px) {
    display: none; // hide on mobile
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    overflow: hidden;
  }

  &:hover {
    background-position: -43px 0;
  }
}