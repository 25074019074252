.popup__avatars {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.popup__avatar {
  width: 85px;
  line-height: 85px;
  height: 85px;
  border: 2px solid black;
  border-radius: 50%;
  background-size: cover;
  margin-bottom: 10px;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 10px;
  }
}