.landingTemplate {
  color: #212121;
  padding: 50px 0 20px;

  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }

  &__header {
    font-size: 32px;
    line-height: 50px;
    padding-bottom: 20px;
    font-family: 'Lidl Font'; font-weight: bold;

    @include media-breakpoint-down(sm) {
      font-size: 22px;
      padding-bottom: 0;
      line-height: 26px;
    }
  }

  &__subHeader {
    font-weight: 600;
    font-size: 22px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
      line-height: 1.1em;
    }
  }

  &__desc {
    font-size: 22px;
    line-height: 34px;
    padding-bottom: 20px;
    padding-top: 20px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 25px;
    }

    &--beforeBlueBox {
      padding-top: 0;
      padding-bottom: 20px;
    }
  }

  &__section {
    padding: 50px 0 70px;
    border-bottom: 1px solid #EEEEEE;

    @include media-breakpoint-down(sm) {
      padding: 20px 0 30px;
      line-height: 1em;
    }

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }

    p {
      font-size: 16px;
      line-height: 25px;
      margin-top: 16px;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }

  &__image {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__blueBox {
    background: #ECF2F9;
    border-radius: 4px;
    padding: 30px 32px;

    @include media-breakpoint-down(sm) {
      padding: 24px 16px;
    }

    p {
      font-size: 20px;
      line-height: 31px;
      margin: 0;
      padding: 0;

      @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &__greenBox {
    background: rgba(#81D25B, 0.08);
    border-radius: 4px;
    padding: 30px 32px;

    @include media-breakpoint-down(sm) {
      padding: 14px 16px;
    }
  }

  &__bullets {
    margin: 25px 0;

    @include media-breakpoint-down(sm) {
      margin: 10px 0 20px;
    }

    ul {
      flex-wrap: wrap;
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        padding-bottom: 15px;
        font-size: 16px;
        line-height: 22px;
        width: 33%;
        box-sizing: border-box;
        padding-right: 3%;

        @include media-breakpoint-down(sm) {
          font-size: 14px;
          line-height: 22px;
          width: 50%;
          align-items: center;
          width: 100%;
          padding-right: 0;
        }

        &:before {
          display: flex;
          content: '';
          width: 25px;
          height: 25px;
          min-width: 25px;
          margin-right: 15px;
          background: no-repeat;
          background-size: 100% 100%;

          @include media-breakpoint-down(sm) {
            width: 16px;
            height: 16px;
            min-width: 16px;
            margin-right: 15px;
          }
        }
      }

    }
  }

  .bgImageActive {
    .landingTemplate__bullets ul li:before {
      background-image: url("/layouts/frontend/img/lidl-landing/planer/check_2x.png");
    }
  }

  &__mobileReverse {
    @include media-breakpoint-down(sm) {
      flex-direction: column-reverse;
    }
  }
}

.landingTemplate--searchSeoText {
  margin-top: 50px;

  @include media-breakpoint-down(sm) {
    margin-top: 75px;
  }


  .landingTemplate__section {
    p:last-child {
      margin-bottom: 0;
    }
  }

  .landingTemplate__section--2,
  .landingTemplate__section--3 {
    .landingTemplate__bullets ul li {
      @include media-breakpoint-down(sm) {
        width: 100%;
        padding-right: 0;
      }

      @include media-breakpoint-up(sm) {
        width: 25%;
        padding-right: 0;

        &:nth-child(2n) {
          margin-right: 50%;
        }
      }
    }
  }

  .landingTemplate__section--3 {
    @include media-breakpoint-down(sm) {
      .landingTemplate__image {
        margin-top: 30px;
      }
    }
  }
}

.landingTemplate--cookbookSeoText {
  @include media-breakpoint-down(sm) {
    margin-top: 50px;
    margin-bottom: -50px;

    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
    }
  }

  p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 30px;
  }

  a {
    color: #0050AA;
    border-bottom: 1px solid #0050AA;
    text-decoration: none;
  }

  .d-xs-none {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .landingTemplate__section {
    padding: 40px 0 40px;

    @include media-breakpoint-down(sm) {
      padding-bottom: 20px;
    }
  }

  .landingTemplate__section--1 {
    .landingTemplate__header {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    p {
      strong {
        font-size: 22px;
        line-height: 31px;
      }

      &:first-child {
        @include media-breakpoint-down(sm) {
          margin-top: 10px;
        }
      }
    }

    .landingTemplate__bullets ul {
      display: block;

      li {
        padding-right: 0;
        width: 100%;
      }
    }
  }

  .landingTemplate__section--2 {
    p {
      &:first-child {
        margin-top: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      .landingTemplate__image {
        margin-top: 20px;

        img {
          max-width: 120px;
        }
      }
    }
  }

  .landingTemplate__section--3 {

    .landingTemplate__bullets ul {
      display: block;

      li {
        padding-right: 0;
        width: 100%;
      }
    }

    .landingTemplate__greenBox {
      @include media-breakpoint-down(sm) {
        margin-top: 20px;
      }

      p {
        margin-top: 0;

        strong {
          font-weight: 600;
          font-size: 22px;
          line-height: 31px;
        }
      }

      .landingTemplate__bullets {
        margin-bottom: 0;

        ul li {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .landingTemplate__blueBox {
      p {
        font-size: 16px;
        line-height: 25px;
        margin-top: 0;

        &:first-child {
          margin-bottom: 15px;
        }

        a {
          font-size: 18px;
        }
      }
    }
  }
}