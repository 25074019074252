.activity {
  position: relative;
  height: 140px;
}

.activity__chart {
  position: absolute;
  cursor: pointer;
}

.activity__input {
  display: none;
}

.box-activity__input {
  width: 150px;
  text-align: center;
  display: inline;
}

.box-activity__control {
  font-size: 1.4rem;
  cursor: pointer;
}

#activity-chart-1 {
  bottom: 0;
  left: 0;
}

#activity-chart-2 {
  left: 27px;
  bottom: 46px;
}

#activity-chart-3 {
  bottom: 72px;
  left: 97px;
}

#activity-chart-4 {
  left: 165px;
  bottom: 47px;
}

#activity-chart-5 {
  left: 207px;
  bottom: -1px;
}

#indicator {
  position: absolute;
  bottom: -17px;
  left: 92px;
  transform-origin: 58px 50%;
  transform: rotate(0deg);
  @include transition(0.2s transform ease-in-out);

  &.activity-1 {
    transform: rotate(19deg);
  }
  &.activity-2 {
    transform: rotate(53deg);
  }
  &.activity-3 {
    transform: rotate(90deg);
  }
  &.activity-4 {
    transform: rotate(126deg);
  }
  &.activity-5 {
    transform: rotate(160deg);
  }
}

path.clickable {
  cursor: pointer;
}
