.homepageBanners {
  padding: 60px 0 20px;
  overflow: hidden;
}

.homepageBanner:last-child {
  margin-bottom: 0;
}

.homepageBanner {
  display: flex;
  margin-bottom: 70px;

  @include media-breakpoint-down(sm) {
    margin-bottom: 35px;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__wrapper {
    display: flex;
    padding: 80px 0 60px 0;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 16px 0;
    }
  }

  &__header {
    position: relative;
    height: 556px;
    width: 366px;
    min-width: 374px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    overflow: hidden;
    background-size: cover;
    display: flex;

    &:hover {
      text-decoration: none;
    }

    @include media-breakpoint-down(sm) {
      height: 288px;
      width: 100%;
      margin-bottom: 24px;
      min-width: 320px;
      width: 100%;
    }

    @include media-breakpoint-down(xs) {
      height: 200px;
    }

    &__text {
      font-family: 'Lidl Font';
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      color: #000;
      display: inline;
      font-size: 35px;
      text-transform: uppercase;
      border-radius: 0px 4px 4px 0px;
      position: absolute;
      top: 50px;
      left: 0;


      @include media-breakpoint-down(sm) {
        top: 22px;
      }

      span {
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;

        @include media-breakpoint-down(sm) {
          padding-left: 0;
        }

        &:first-child {
          display: inline;

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

      }

      @include media-breakpoint-down(xs) {
        font-size: 28px;
        position: absolute;
        text-align: left;
      }

      &__desktop {
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }

      &__mobile {
        @include media-breakpoint-down(sm) {
          display: inline !important;
        }
      }
    }
  }

  &__recipes {
    margin-left: 36px;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      overflow-x: auto;
    }

    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }

    &__header {
      font-family: 'Lidl Font';
      font-style: normal;
      text-align: left;
      margin-bottom: 24px;
      color: #FFF;
      font-size: 28px;
      font-weight: 600;
      line-height: 120%;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    @include media-breakpoint-down(xs) {
      &__container {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;
        width: calc(100vw - 10px);
      }

      &__scroll {
        width: 100vw;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        overflow-x: scroll;
        overflow-y: hidden;
        transform: translateZ(0);
        will-change: transform;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;

        margin-bottom: -16px;
        padding-bottom: 16px;

        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-wrap: nowrap;
      }

      &__button {
        width: 24%;
        width: calc(25% - 15px);
        margin: 0;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
          width: 100%;
        }
      }

      .single-item {
        width: 24%;
        width: calc(25% - 15px);
        margin: 0;
        margin-bottom: 20px;

        @include media-breakpoint-down(sm) {
          width: 100%;
          max-width: 115px;
          min-width: 115px;
        }
      }

      .single-item {
        @include media-breakpoint-down(sm) {
          margin-right: 10px;
        }

        &__image {
          height: 174px;
          width: 174px;

          @include media-breakpoint-down(md) {
            height: 125px;
            width: 125px;
          }
          @include media-breakpoint-down(sm) {
            height: 115px;
            width: 115px;
          }
        }

        &__name {
          height: 56px;
          padding: 8px 0;
          border: none;

          a {
            color: #FFF;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
          }
        }

        &__labelPromo {
          width: 45px;
          height: auto;
          bottom: 65px;
        }
      }

      @include media-breakpoint-down(xs) {
        margin: 0;
      }

      &__button {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 195px;
        font-family: 'Lidl Font';
        font-style: normal;
        text-align: center;
        color: #FFF;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        text-transform: uppercase;
        color: #FFF;
        padding: 10px;

        &--desktop {
          display: flex;
          @include media-breakpoint-down(sm) {
            display: none !important;
          }
        }

        &--mobile {
          display: none;
          @include media-breakpoint-down(sm) {
            display: flex !important;
          }
        }

        @include media-breakpoint-down(sm) {
          display: flex;
          flex-basis: 100%;
          width: 100%;
          border-radius: 3px;
          border: 1.5px solid #FFF;
          min-height: initial;
          padding: 6px 15px;
        }
      }

      &__arrow {
        background-image: url("/layouts/frontend/img/homepage_de/homepage-arrow-right.svg");
        background-repeat: no-repeat;
        min-width: 20px;
        min-height: 20px;
        background-size: contain;
        margin-left: 6px;
      }

      &__arrow-black {
        background-image: url("/layouts/frontend/img/homepage_de/homepage-arrow-right-black.svg");
        background-repeat: no-repeat;
        min-width: 20px;
        min-height: 20px;
        background-size: contain;
        margin-left: 6px;
      }
    }
  }

  .button-lidl__blue--bordered {
    width: 100%;
    margin: 0;

    @include media-breakpoint-down(xs) {
      display: flex !important;
    }
  }
}
