.mail-registration-landing {
  .textLanding {
    p:first-child {
      margin-bottom: 0;
      font-size: 100px;
    }
    &:after {
      background-image: url("/layouts/frontend/img/newsletter-landing/mail-registration-landing.png");
    }
  }
}
