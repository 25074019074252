.silvester-landing {

  .snow {
    position: relative;
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      width: 239px;
      height: 435px;
      left: -192px;
      top: -65px;
      z-index: -1;
      background-image: url("/layouts/frontend/img/silvester-landing/snow2.png");

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }

  &__banner {
    position: relative;
    min-height: 330px;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border: 8px solid transparent;
    display: flex;
    flex-direction: row-reverse;
    padding: 30px 0 0 30px;
    justify-content: space-between;
    align-items: center;
    background-image: url('/layouts/frontend/img/christmas-landing/section100/banner-background.jpg');
    border-image: url('/layouts/frontend/img/christmas-landing/gold-layer.jpg') 50 round;
    margin-bottom: 70px;

    &:before {
      content: '';
      display: block;
      width: 468px;
      height: 270px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 162px;
      left: -279px;
      z-index: -1;
      background-image: url("/layouts/frontend/img/silvester-landing/snow-banner-left.png");
      
      @media (max-width: 1100px) {
        top: 262px;
      }

      @include media-breakpoint-down(xs) {
        top: 274px;
        left: -63px;
        z-index: -1;
        transform: rotate(120deg);
      }

    }

    &:after {
      content: '';
      display: block;
      width: 239px;
      height: 435px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: -202px;
      right: -264px;
      z-index: -1;
      transform: rotate(81deg);
      background-image: url("/layouts/frontend/img/silvester-landing/snow2.png");

      @include media-breakpoint-down(xs) {
        top: -183px;
        left: -298px;
        transform: rotate(151deg);
      }
    }

    @media (max-width: 1095px) {
      flex-direction: column;
      padding: 0;
    }
  }

  &__photo {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 165px;
      height: 130px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 175px;
      right: 536px;
      z-index: 2;
      background-image: url("/layouts/frontend/img/silvester-landing/silvester-cone.png");
      @media (max-width: 1095px) {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 319px;
      height: 25px;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      left: -500px;
      z-index: 2;
      background-image: url("/layouts/frontend/img/silvester-landing/snow-banner.png");
      @media (max-width: 1095px) {
        display: none;
      }
    }

    @media (max-width: 1095px) {
      display: flex;
      justify-content: center;
    }
    &--desktop {
      width: 500px;
      height: 320px;


      @media (max-width: 1095px) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      width: 240px;
      height: 205px;
      @media (max-width: 1095px) {
        display: block;
        margin-top: 20px;
      }
    }
  }

  &__banner__content {
    padding: 0 40px 40px 40px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: end;

    h4 {
      font-family: Norican;
      font-size: 41px;
      line-height: 68px;
      user-select: none;
      text-shadow: 0px 0px 12.4719px rgba(0, 0, 0, 0.5);

      span {
        color: white;
        background: url('/layouts/frontend/img/christmas-landing/gold-layer.jpg') no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: cover;
        -webkit-filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
        text-shadow: none;
      }

      @include media-breakpoint-down(sm) {
        font-size: 32px;
        line-height: 45px;
        white-space: initial;
        margin-top: 0;
      }
    }

    div {
      margin-top: 15px;
      font-size: 20px;
      line-height: 24px;
      color: white;
      font-family: 'Lidl Font'; font-weight: bold;
    }

    a {
      padding: 24px 32px;
      margin-top: 30px;
      background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
      border: none;
      font-size: 14px;
      line-height: 17px;
      font-weight: bold;
      display: block;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      font-family: 'Lidl Font'; font-weight: bold;
      color: black;

      &:before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: 176px;
        left: 400px;
        z-index: 2;
        background-image: url("/layouts/frontend/img/silvester-landing/big-snow.png");
        @media (max-width: 1095px) {
          display: none;
        }
      }

      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 15px 32px;
      }
    }

  }

  &__banner {
    margin-top: 70px;
  }

  &.page-top-margin {
    margin-top: 0;
    overflow: hidden;
    margin-bottom: -70px;

    @media (max-width: 639px) {
      margin-top: 120px;
    }
  }

  .christmasSection--withSnowBg.bgImageActive .christmasSection__snowBg:after {
    background-image: url('/layouts/frontend/img/silvester-landing/silvester-snowflake.png');
    background-size: 1920px 2942px;
    background-repeat: repeat-y;
  }

  .container {
    z-index: 15;
    position: relative;
  }

  .section1 {
    position: relative;
    background: url('/layouts/frontend/img/silvester-landing/silvester-background.jpg') no-repeat;
    background-size: cover;
    height: 425px;
    text-align: center;
    background-position: center;

    @media (max-width: 636px) {
      max-width: 100%;
      background-image: url('/layouts/frontend/img/silvester-landing/silvester-background-mobile.jpg');
    }
  }

  &__header {
    img {
      width: 100%;
    }
  }

  &__content {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    font-family: 'Lidl Font'; font-weight: bold;

    h1 {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #FFFFFF;
      user-select: none;
      text-shadow: 0px 0px 12.4719px rgba(0, 0, 0, 0.8);

      @media (max-width: 640px) {
        font-size: 20px;
        line-height: 160%;
        margin-bottom: 0;
      }

      span {
        color: white;
        background: url('/layouts/frontend/img/silvester-landing/golden-background.jpg') no-repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'Norican', cursive;
        font-style: normal;
        font-weight: normal;
        font-size: 90px;
        display: block;
        line-height: 102px;
        background-size: cover;
        user-select: none;

        -webkit-filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 1)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
        text-shadow: none;


        @media (max-width: 640px)  {
          font-size: 56px;
          line-height: 130%;
          margin-bottom: 0;
        }

        &.text {
          background-clip: text;
          -webkit-background-clip: text;
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .feeds {
    background: linear-gradient(90deg, #E1CAA1 0%, #FFECCA 49.79%, #E1CAA1 100%);
    padding: 70px 0 !important;
    position: relative;



    .feedElement {

      &:last-child {
        .feedElement__scrollbarHidden {
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            width: 239px;
            height: 435px;
            left: -206px;
            top: -785px;
            z-index: -1;
            background-image: url("/layouts/frontend/img/silvester-landing/snow2.png");
          }
        }
      }

      &:after {
        background: linear-gradient(270deg, #ECDCBA 0%, rgba(255, 255, 255, 0) 100%);
      }

      &:before {
        display: none;
      }

      &--visible {
        margin-bottom: 70px;
      }

      &__header {
        &__text {
          color: white;
          background: url('/layouts/frontend/img/silvester-landing/gold-text.jpg') no-repeat;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: 'Norican', cursive;
          font-style: normal;
          font-weight: normal;
          display: block;
          background-size: cover;
          user-select: none;

          -webkit-filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
          text-shadow: none;
        }

        &__link {
          position: relative;
          color: white;
        }
      }

      &--black-background {
        .feedElement__header {

          > div {
            position: relative;
            color: white;
          }
          &:before {
            content: '';
            display: block;
            width: 676px;
            height: 270px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            left: -80px;
            top: -8px;
            background-image: url('/layouts/frontend/img/silvester-landing/black-layer.png');

            @include media-breakpoint-down(sm) {
              top: -30px;
              width: 549px;
            }

            @include media-breakpoint-down(xs) {
              width: 377px;
              top: -6px;
              left: -39px;
            }
          }

          &:after {
            display: none;
            @include media-breakpoint-down(xs) {
              content: '';
              display: block;
              width: 140px;
              height: 90px;
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              right: 100px;
              top: 20px;
              background-image: url('/layouts/frontend/img/silvester-landing/snow-mobile.png');
            }

            @media (max-width: 514px) {
              right: 0px;
            }
            @media (max-width: 420px) {
              right: -100px;
            }
            @media (max-width: 420px) {
              right: -60px;
              top: 24px;
            }
          }
        }
      }
    }

    .feedSection {
      margin-bottom: 80px;
      margin-left: 0;
      margin-right: 0;
      border-radius: initial;

      @include media-breakpoint-down(xs) {
        border: none;
      }


      &:after {
        content: '';
        display: inline-block;
        width: 450px;
        height: 314px;
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &--section4 {
        position: relative;
        &:before {
          background-image: url("/layouts/frontend/img/silvester-landing/section4-photo.jpg");
        }
        &:after {
          right: -422px;
          top: -211px;
          background-image: url("/layouts/frontend/img/silvester-landing/snow.png");
        }
      }

      &--section7 {
        &:before {
          background-image: url("/layouts/frontend/img/silvester-landing/section7-photo.jpg");
        }
        &:after {
          right: -462px;
          top: -30px;
          transform: rotate(22deg);
          background-image: url("/layouts/frontend/img/silvester-landing/snow.png");
        }
      }

      &--section10 {
        &:before {
          background-image: url("/layouts/frontend/img/silvester-landing/section10-photo.jpg");
        }
        &:after {
          right: -456px;
          top: 28px;
          transform: rotate(220deg);
          background-image: url("/layouts/frontend/img/silvester-landing/snow.png");
        }
      }

      &--section13 {
        &:before {
          background-image: url("/layouts/frontend/img/silvester-landing/section13-photo.jpg");
        }
        &:after {
          width: 239px;
          height: 435px;
          left: -206px;
          top: -2380px;
          z-index: -1;
          transform: rotate(87deg);
          background-image: url("/layouts/frontend/img/silvester-landing/snow2.png");
        }
      }

      &__title {
        color: black;
      }
    }
  }
}
