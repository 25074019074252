.sallys-landing {

  &__section1 {
    background-color: #F8EBF1;
    padding: 56px 0;

    @include media-breakpoint-down(sm) {
      padding: 50px 0;
    }

    @include media-breakpoint-down(xs) {
      padding: 20px 0 30px;
    }

    &__box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }

    &__content {
      flex: 1;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      &:first-child {
        margin-right: 30px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;
        }
      }

      &--left {
        h3 {

          @include media-breakpoint-down(xs) {
            font-size: 32px;
            line-height: 120%;
            margin-bottom: 16px;
          }
        }

        div {
          max-width: 75%;

          @include media-breakpoint-down(sm) {
            max-width: 100%;
          }
        }

        p {

          @include media-breakpoint-down(xs) {
            font-size: 16px;
            line-height: 140%;
          }

          &:first-child {
            margin-bottom: 20px;

            @include media-breakpoint-down(xs) {
              margin-bottom: 8px;
            }
          }
        }
      }

      &--right {

        width: 400px;
        height: 320px;
        background-repeat: no-repeat;
        position: relative;

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-bottom: 20px;
          position: relative;
          padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
          height: 0;
          overflow: hidden;
        }


        &__video {
          background-image: url('/layouts/frontend/img/sally-landing/sally-thumbnail.png');
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
          overflow:hidden;
          cursor:pointer;
          height: 100%;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          right: 0;
        }


        iframe {
          max-width: 610px;
          width: 100%;
          height: 320px;

          @include media-breakpoint-down(sm) {
            max-width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
