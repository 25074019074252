#add-recipe {
  padding: 50px 0;

  .button-lidl__blue--sticky {
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 2;
    border: 5px solid white;
    width: 200px;
  }

  .add-recipe__title {
    font-size: 2.5em;
    font-weight: bold;
  }

  .add-recipe__caption {
    font-size: 1.3em;
  }

  .add-recipe__steps {
    &__button {
      width: auto;
    }
  }

  .additional-info {
    font-size: 1.3em;
  }

  #preparation_time,
  #preparation_total_time,
  #number_of_portion,
  #like_count {
    max-width: 50px;
    text-align: center;
  }

  #pinterest_description,
  #description {
    min-height: 50px;
  }

  .step-number {
    font-size: 1em;
    font-weight: bold;
  }

  .step-picture {
    a {
      color: black;
      text-decoration: underline;
    }

    span {
      color: $gray_184;
    }

  }
  .step__remove {
    position: relative;
    left: 5px;
    top: -2px;
    cursor: pointer;
  }

  .autocomplete-items {
    background-color: white;
    position: absolute;
    top: 100%;
    z-index: 1;
    width: calc(100% - 30px);
  }

  .autocomplete-item {
    cursor: pointer;
    font-size: 0.75rem;
    letter-spacing: 1px;
    padding: 6px;
    text-transform: uppercase;

    &.selected {
      background-color: #ccde5b;
      color: white;
    }
  }

  .form-group {
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }

  }
  .js-maxlength {
    position: absolute;
    right: 15px;
  }

  .checkbox {
    .form-control-feedback {
      padding-left: 15px;
    }
  }

  #go_live_date {
    width: 200px;
  }

  .checkbox-wrapper .col-form-label {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 40px;
  }

  .newUxContainer {

    .form-group {
      margin-bottom: 10px;
    }
    a {
      font-size: 16px;
      margin-right: 10px;
    }
  }
}

.addRecipe {

  &__buttons {
    display: flex;
    justify-content: center;


    button {
      margin-left: 2em;
      margin-right: 2em;
    }
  }
}

.recipe__photo-thumb-container {
  margin: 0 9px;
}

.recipe__photo-thumb {
  background-position: center center;
  background-size: cover;
  position: relative;
  border: 1px solid black;
  height: 65px;
  width: 65px;
  text-align: center;
  line-height: 65px;
  background-color: black;
}

.recipe__photo-remove {
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: none;
}

.recipe__photo-main {
  flex-grow: 0;

  @include media-breakpoint-down(sm) {
    margin-top: 30px;
  }
}

.recipe__photo-uploader {
  ._file {
    display: none;
  }

  #fakeFileInput {
    color: white;
    text-decoration: underline;
    cursor: pointer;
  }
  .ajax-uploader {
    width: 100%;
    height: 100%;
    position: relative;

    .imgHolder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
}

.recipe__photo-uploader--with-file {
  .recipe__photo-remove {
    display: block;
  }
  #fakeFileInput {
    display: none;
  }
}

.recipe__photo-add {
  height: 150px;
  width: 150px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: cover;
  line-height: 150px;
  text-align: center;
  position: relative;
}

.recipe__photo-add--disabled {
  opacity: 0.5;
  pointer-events: none;

  #fakeFileInput {
    color: rgba(255, 255, 255, 0.5);
  }

}

.step-picture {
  ._file {
    display: none;
  }
  #fakeFileInput {
    cursor: pointer;
  }
  .imgHolder {
    max-width: 100%;
    height: 100px;
    display: inline-block;
    position: relative;
    margin-top: 3px;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
  .ajax-uploader {
    background: #eeeeee;
    width: 249px;
    position: relative;
    padding: 5px;
    margin-bottom: 5px;

    form {
      text-align: center;
    }
  }
}

.step-picture__remove {
  position: absolute;
  right: 0;
  top: 0;
  background-color: $blackAlpha;
  cursor: pointer;
}

.selectize-input {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0 !important;
  border-color: black !important;
  height: 40px !important;
  padding: 8px !important;
}

.selectize-dropdown {
  top: 37px !important;;
  box-shadow: none !important;
  border: 1px solid black !important;;
  border-radius: 0 !important;

  .active {
    color: white !important;
    background-color: $lidlGrey !important;
  }
}

.selectize-control {
  background-color: white !important;
}

.selectize-input {
  .item {
    &.readonly {
      color: red !important;
    }

    &.isPromo {
      color: #0076BF !important;
    }
  }
}

.selectize-dropdown {
  .option[data-selectable] {
    &.readonly {
      color: red !important;
    }

    &.isPromo {
      color: #0076BF !important;
    }

    .highlight {
      background-color: transparent;
    }

    &.active {
      background-color: $lidlPrimaryColor !important;
      color: #FFF;
    }
  }
}

.progress {
  height: 5px;
  position: absolute;
  bottom: -5px;
  width: 100%;
  left: 0;

  .progress-bar {
    background-color: $green;
    height: 100%;
    width: 0;
    @include transition(0.2s width ease-out);
  }
}

.ingredient {


  &--readyForSorting {
    cursor: move;
  }

  &__unit-value {
    &:disabled {
      background: transparent;
      opacity: 0.5;
    }
  }

  &__actionsCell {
    display: flex;
  }
}

.ingredients {

  &__content {
    //width: 100%;
    padding: 20px 0 12px 0;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  &__buttons {
    display: flex;
    align-items: flex-end;

    &__link {

      &--add {
        @include media-breakpoint-down(xs) {
          width: 100%;
        }
      }

      &--order {
        display: flex;
        justify-content: center;
        align-items: center;

        color: black;
        font-size: 0.9rem;
        font-weight: normal;

        @include media-breakpoint-down(xs) {
          margin-top: 1em;
        }

        svg {
          margin-right: 0.2em
        }
      }

      &--report {
        display: flex;
        justify-content: center;
        align-items: center;

        color: black;
        font-size: 0.9rem;
        font-weight: normal;

        @include media-breakpoint-down(sm) {
          margin-top: 1em;
        }

        svg {
          margin-right: 0.6em
        }
      }
    }
  }
}


.utensils,
.tags {
  .input-label {
    cursor: pointer;
    width: 100%;
  }
}

.tags {
  .arrow-down {
    &:before {
      top: 6px;
    }
  }
}



.arrow-down {
  &:before {
    position: absolute;
    left: auto;
    top: 10px;

    color: #999;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    content: "";
    margin-left: 10px;
  }

  &.rotated {
    &:before {
      border-width: 0 6px 5px;
      border-color: #999 transparent #999;
    }
  }
}

.croppingPopup {
  &.popup--open {
    .popup__content {
      transform: translateY(-50%);
      margin-top: 0;
      max-height: 95vh;
      padding-bottom: 20px;
    }
  }

  &__header {
    margin-top: -30px;
    text-align: left;

    &__mobile {
      display: none;
    }

    &__desktop {

    }

    h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }

  &__instruction {
    font-size: 13px;
  }

  &[rel="mobile"] {
    .add-to-favourites,
    .button-print {
      display: none !important;
    }
  }

  &__imageContainer {
    position: relative;

    .button-print {
      z-index: 2;
      right: 25px !important;
      bottom: 55px !important;
    }
    .single-item__likes.slider-item-likes {
      right: 25px !important;
    }
  }

  &__zoom {
    display: none;
  }

  &__buttons {
    display: flex;
    justify-content: center;
  }

  .popup__content {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 1325px;
  }

  .imagePreviews {
    display: none;
  }

  .imagePreview {
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    background-position: center;

    //&--desktop {
    //  max-width: 1170px;
    //  height: 460px;
    //}

    &--wideMobile {
      max-width: 545px;
      height: 280px;
    }

    &--iphoneX {
      max-width: 345px;
      height: 280px;
    }

    &--iphone8 {
      max-width: 384px;
      height: 280px;
    }

    &--iphone5 {
      max-width: 290px;
      height: 280px;
    }
  }

  &--mobile {
    .imagePreviews {
      display: block;
    }

    .croppingPopup__header__mobile {
      display: block;
    }

    .croppingPopup__header__desktop {
      display: none;
    }

    .cr-viewport {
      &:before, &:after {
        content:'';
        width: 2px;
        height: calc(100% + 4px);
        top: -2px;
        left: 126px;
        background: aqua;
        position: absolute;
      }

      &:after {
        left: 418px;
      }
    }
  }
}
