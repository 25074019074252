.homepage-euro-2024 {

  .homepage-euro-2024-iframe {
    border: none;
    width: 100%;
    overflow: hidden;
  }

  #homepage-euro-2024-iframe {
    display: block !important;
  }

  @media (min-width: 640px) {
    margin: 0 auto;
  }
}
