.feeds {
  &.tabs {
    padding: 0;

    .feedElement {
      min-height: 671px;

      @include media-breakpoint-down(sm) {
        display: block !important;
        min-height: 460px;
      }

      margin-bottom: 40px;

      &__recipes {
        flex-wrap: wrap;
        //justify-content: space-between;
        justify-content: flex-start;


        @include media-breakpoint-down(sm) {
          flex-wrap: nowrap;
          justify-content: initial;
        }
      }

      &__scrollbarHidden {
        @include media-breakpoint-down(sm) {
        //  margin-left: 0;
          margin-right: 0;
        }
      }

      &__arrowText {
        display: none;
      }

      &:after,
      &:before {
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
          top: 130px;
          height: calc(86% - 90px);
        }
      }

      &:after {
        @include media-breakpoint-down(sm) {
          right: 0;
        }
      }



      &__scrollLeft,
      &__scrollRight {
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
          top: 58%;
        }

        @include media-breakpoint-down(xs) {
          display: none;
        }
      }

      &__header {
        display: none;

        @include media-breakpoint-down(sm) {
          display: block;
        }
      }

      &__recipes__recipe {
        min-width: initial;
        margin-left: 0;
        margin-bottom: 20px !important;
        margin-right: 16px !important;
        width: calc(25% - 16px) !important;

        &--moreLink {
          background-color: white;
          border-radius: initial;

          button {
            background-color: #0050AA;
            color: white;
          }

          span {
            &:before {
              background-image: url("/layouts/frontend/img/sally-landing/more-recipes2.png");
              width: 124px;
              height: 86px;
              background-size: contain;
            }
          }
        }



        @include media-breakpoint-down(sm) {
          width: 21%;
          margin-right: 15px;
          min-width: 230px;

          &--lastSpace {
            min-width: 30px;
            width: 30px !important;
          }
        }
      }
    }

    [data-id="mittagessen"] {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }


    .feedNavigation {

      margin-top: 50px;
      white-space: nowrap;


      @include media-breakpoint-down(sm) {
        display: none !important;
      }

      ul {
        justify-content: flex-start;
        border-bottom: 1px solid rgba(53, 59, 66, 0.1);
        padding-bottom: 8px;
        margin-bottom: 24px;
        overflow: hidden;

        li {

          cursor: pointer;
          padding-right: 24px;
          font-size: 22px;
          line-height: 140%;
          color: #353B42;

          &:hover, {
            color: #0050AA;
            font-weight: 600;
          }

          &.active {
            color: #0050AA;
            font-weight: 600;
          }
        }
      }
    }

    .single-item {
      &__name {
        background-color: white;
      }

      &__likes {
        border-radius: initial;

        a {
          font-weight: 600;
          font-size: 18px;
          line-height: 140%;
          color: #2F3A44;
          display: -webkit-box;
          -webkit-line-clamp: 2;
        }
      }
    }
  }
}
