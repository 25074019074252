.homepage {
  $self: &;

  &--christmas {
    overflow: hidden;

    #{$self}__sliders-container .lidl-container {
      position: relative;
      z-index: 2;
    }

    #{$self}__sliders {
      position: relative;

      @media (max-width: 800px) {
        padding-bottom: 28px;
        border-radius: 0;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
      }

      &::before {
        display: none;

        @media (max-width: 800px) {
          display: block;
          left: 0;
          width: 100%;
          height: 8px;
          background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
        }
      }

      &::after {
        display: block;
        width: 300px;
        height: 300px;
        right: 0;
        transform: translate(35%, 54%);
        z-index: -1;
        background-image: url("/layouts/frontend/img/christmas-landing/cone.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @media (max-width: 800px) {
          display: block;
          right: unset;
          left: 0;
          width: 140px;
          height: 140px;
          transform: translate(8%, 43%);
        }
      }
    }

    .signPost {
      overflow: visible;
      padding-bottom: 85px;

      @media (max-width: 800px) {
        padding-top: 55px;
      }

      @media (max-width: 575px) {
        padding-bottom: 30px;
      }

      &::before,
      &::after {
        content: "";
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
      }

      &::before {
        height: calc(100% + 170px);
        background-image: url("/layouts/frontend/img/christmas-landing/section_snow.png");
        background-position: top center;
        top: -170px;
        z-index: 1;

        @media (max-width: 700px) {
          background-size: 1250px;
          background-position: top right -70px;
          top: -115px;
        }
      }

      &::after {
        height: 8px;
        background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
        bottom: 0;
        z-index: 2;
      }

      .row:nth-child(1) {
        position: relative;
        z-index: 2;
      }

      .recipe__box {
        position: relative;
        z-index: 2;
      }
    }

    #feeds {
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        display: block;
        background-image: url("/layouts/frontend/img/christmas-landing/section_snow.png");
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -2;

        @media (max-width: 700px) {
          background-size: 1250px;
        }
      }

      & > .row,
      & > .row .col-12 {
        &::after,
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 560px;
          height: 280px;
          background-image: url("/layouts/frontend/img/christmas-landing/glitter_horizontal.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          z-index: -1;
        }
      }

      & > .row {
        &::after {
          top: 530px;
          left: -430px;
          width: 450px;
          height: 225px;

          @media (max-width: 1300px) {
            top: 880px;
            left: unset;
            right: 0;
            transform: scaleX(-1) translateX(-38%);
          }

          @media (max-width: 700px) {
            width: 250px;
            height: 125px;
          }
        }

        &::before {
          top: 1330px;
          right: -530px;
          transform: scaleX(-1);

          @media (max-width: 1300px) {
            top: 1760px;
            right: 0;
            transform: translateX(50%);
          }

          @media (max-width: 700px) {
            width: 250px;
            height: 125px;
          }
        }
      }

      & > .row .col-12 {
        &::before {
          transform: rotate(90deg) scaleX(-1);
          top: 2340px;
          left: -440px;

          @media (max-width: 700px) {
            display: none;
          }
        }

        &::after {
          display: none;
        }
      }
    }

    .promoPlansSection {
      position: relative;
      overflow: visible;
      z-index: 1;
      margin-top: 60px;

      &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #f5f5f5;
        background-image: url("/layouts/frontend/img/christmas-landing/section_snow.png");

        @media (max-width: 700px) {
          background-size: 1250px;
        }
      }

      &::after {
        content: "";
        display: block;
        width: 100vw;
        height: 8px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
      }

      .lidl-container {
        &::before {
          content: "";
          position: absolute;
          display: block;
          width: 560px;
          height: 280px;
          background-image: url("/layouts/frontend/img/christmas-landing/glitter_horizontal.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          right: 0;
          bottom: 0;
          transform: translateX(95%);

          @media (max-width: 1200px) {
            display: none;
          }
        }
      }

      .section__header {
        position: relative;

        @media (max-width: 440px) {
          margin-top: 20px;
        }

        &::before {
          @media (max-width: 440px) {
            content: "";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(0, -40%);
            width: 110px;
            height: 110px;
            background-image: url("/layouts/frontend/img/christmas-landing/cone.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
          }
        }
      }
    }

    #{$self}__popular-plans {
      padding-bottom: 35px;

      &::after {
        content: "";
        display: block;
        width: 100vw;
        height: 8px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
      }
    }

    .promoPlansItem {
      position: relative;
      z-index: 1;

      &::after {
        content: "";
        display: block;
        width: calc(100% - 20px);
        height: 100%;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }

    .christmas-section100 {
      margin-top: 40px;
    }
  }
}
body.christmasTime {
  .homepage .homepage-lidl__banner .banner__content .banner__content-buttons {
    .button-lidl__slide__primary__1 {
      border-width: 0;
      background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
      transition: 100ms background, 100ms box-shadow;
      color: #000;

      &:hover {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
        box-shadow: 0px 0px 25px #F6E26A, inset 0px 0px 12px rgba(255, 246, 31, 0.5);
      }
    }

    .button-lidl__slide__secondary__1 {
      border-image: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%) 1 round;
      transition: 100ms background, 100ms box-shadow;
      color: #000;

      &:hover {
        background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
        box-shadow: 0px 0px 25px #F6E26A, inset 0px 0px 12px rgba(255, 246, 31, 0.5);
      }
    }
  }

  .footer__secondary {
    position: relative;
    padding-top: 8px;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      width: 100vw;
      height: 8px;
      position: absolute;
      left: 0;
      top: 0;
      //background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
      background: #CC9E3C;
    }
  }
}