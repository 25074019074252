.viewList {
  $self: &;
  margin-top: 50px;

  &__return-to-planner-btn {
    color: #0050AA;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 17px;
    align-items: center;
    padding: 0 16px;
    margin-left: 17px;
    border-left: 1px solid #CBD1D6;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }

    &:before {
      content: "\e805";
      margin: 0;
      margin-right: 10px;
    }
  }

  &__all-shoplist {
    color: #0050AA;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 17px;
    align-items: center;
    margin-left: 15px;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }

    &:before {
      content: '';
      background-image: url("/layouts/frontend/img/shoplist/all-shoplist.svg");
      background-repeat: no-repeat;
      width: 17px;
      height: 16px;
      display: block;
      margin-right: 13px;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-top: 110px;
  }

  .single-item__likes {
    display: none;
  }

  .single-item__prep-time {
    position: absolute;
    bottom: 15px;
  }

  .feedElement:after {
    top: 67px;

    @media (max-width: 1023px) {
      top: 43px;
    }
  }

  .view-list__header {

    .button--cookbook {
      margin-left: 1em;
      margin-right: 1em;

      img {
        margin-left: 1em;
        margin-right: 1em;
      }

      @include media-breakpoint-down(xs) {
        margin: 1em 0;
        width: 100%;
      }
    }
  }

  .view-list__title {
    position: relative;

    .view-list {

      &__all-shoplist {
        color: #0050AA;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        text-transform: uppercase;
        display: flex;
        margin-bottom: 17px;

        &:before {
          content: '';
          background-image: url("/layouts/frontend/img/shoplist/all-shoplist.svg");
          background-repeat: no-repeat;
          width: 17px;
          height: 16px;
          display: block;
          margin-right: 13px;
        }
      }

      &__subtitle {
        font-size: 32px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > div {
          width: 100%;

          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }
        }
      }

      &__input {
        border: none;
        padding-bottom: 8px;
        border-bottom: 1px solid black;
        font-weight: 600;
        font-size: 32px;
        width: 100%;

        @include media-breakpoint-down(xs) {
          font-size: 18px;
          line-height: 130%;
        }
      }

      &__save-single-list,
      &__cancel-single-list {
        display: none;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;

        @include media-breakpoint-down(xs) {
          width: 16px;
          height: 16px;
          background-size: contain;
          padding: 5px;
        }
      }

      &__save-single-list {
        position: absolute;
        right: 55px;
        top: 14px;
        padding: 0 10px;
        background-image: url("/layouts/frontend/img/svg/cancel-shoplist.svg");

        @include media-breakpoint-down(md) {
          top: initial;
        }

        @include media-breakpoint-down(xs) {
          right: 40px;
        }
      }

      &__rename-single-list {
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;
        background-image: url("/layouts/frontend/img/svg/shoplist-pencil.svg");
        background-size: contain;

        @include media-breakpoint-down(xs) {
          padding: 5px;
          width: 16px;
          height: 16px;
        }
      }

      &__cancel-single-list {
        position: absolute;
        right: 15px;
        top: 14px;
        padding: 0 10px;
        background-image: url("/layouts/frontend/img/svg/save-shoplist.svg");

        @include media-breakpoint-down(md) {
          top: initial;
        }
      }
    }

    .view-list__plan-name {
      font-size: 2em;
      font-weight: bold;

      span {
        display: block;
        font-size: 0.7em;
        font-weight: 100;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-top: 0.5em;
      position: static;
    }
  }

  .view-list__filters {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    justify-content: space-between;

    .filters-category {
      display: flex;
    }

    .filters-category {
      flex: 1;

      .filter-header {
        margin-right: 1em;
      }

      .filter-select {
        cursor: pointer;

        .arrow-down {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 9px solid black;
          display: inline-block;
          height: 0;
          width: 0;
        }
      }
    }
  }

  &__iconButtonsArea {
    margin-top: 25px;
    display: flex;
    justify-content: center;

    &--christmasLanding {
      margin-top: 50px;
    }

    a {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #30303B;
      text-decoration: none;
      line-height: 12px;

      @media (max-width: 600px) {
        font-size: 15px;
      }

      i,
      img {
        margin-right: 10px;
      }

      &:hover {
        transition: transform .2s linear;
        transform: scale(1.2);
      }
    }
  }

  &__additionalRecipesList {
    display: none;

    overflow: hidden;
    background: #FAFAFA;

    margin-top: 20px;

    width: calc(100vw);
    padding: 15px 20px;

    &__header {
      display: flex;
      align-content: center;
      justify-content: center;
      position: relative;

      @include media-breakpoint-down(xs) {
        justify-content: space-between;
      }

      color: #1B517D;
      font-size: 18px;
      line-height: 19px;
      font-weight: bold;

      cursor: pointer;

      &.open {
        &:after {
          transform: rotate(180deg);
        }
      }

      &:after {
        content: '';
        width: 12px;
        height: 19px;
        position: absolute;
        top: 5px;
        right: 0;
        background: url('/layouts/frontend/img/shoplist/expand_more_24px.svg') no-repeat;
        transition: 0.5s all;
        transform-origin: 6px 4px;
      }
    }

    &__content {
      display: none;

      .shoplist__recipes-wrapper {
        margin-top: 15px;

        .shoplist__recipe {
          text-align: left;
          font-size: 15px;
          padding: 7px 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .filter-tab {
    height: 40px;
    padding: 10px;
    font-weight: 700;
    background-color: black;
    border-top: 1px solid black;
    border-right: 1px solid $dirty_white;
    color: white;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: 0.2s color;

    &.active {
      background-color: #f7f8f9;
      border-color: black;
      color: black;
    }

    &:first-child {
      border-left: 1px solid black;
    }
  }

  .view-list__editMode {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;

    .editMode__deleteSelected {
      text-transform: lowercase;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .view-list__container {
    position: relative;
  }

  .view-list__products {
    .category-container:not(:first-child) {
      margin-top: 3em;
    }

    .category-container:first-child {
      margin-top: 1em;
    }
  }

  .products-category__name {
    color: $gray_157;
    font-size: 1.3em;
    text-transform: uppercase;

    img {
      margin-right: 1em;
    }
  }

  .category-container[data-id="19"] {
    .products-category__name {
      padding-bottom: 8px;
    }
  }

  .products-category__list {
    .products__product {
      border-bottom: 1px solid;
    }
  }

  .products-category__delete {
    display: none;
    margin-left: 10px;
    position: relative;
    top: -2px;
    cursor: pointer;
  }

  .view-list__slider {
    .slider-title {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    .slider-container {
      background-color: $gray_240;
      padding-bottom: 50px;
      padding-top: 50px;

      .slick-slide {
        text-align: center;

        img {
          margin: 0 auto;
          height: 150px;
        }
      }

      .slick-prev {
        left: 0px;
      }

      .slick-next {
        right: 0px;
      }
    }
  }

  .products__product {
    border-bottom: 1px solid;
    padding: 10px 0;
    transition: 750ms;

    &.products__product-custom {
      //background-color: $gray_240;
    }

    &.products__product-custom.products__product-edit {
      //background-color: $dirty_white;
    }

    &-edit {
      border-bottom: 0 !important;
    }

    &-withoutUnits {
      position: relative;

      .secondRow {
        display: none;
      }

      .product__actions {
        position: absolute;
        right: 0;
        bottom: 30px;
        z-index: 99;
      }

      .products__edit {
        margin: 10px 0;
      }
    }

    label {
      width: auto;
      margin: 0;
      min-width: 85%; //fix for ie11

      @include media-breakpoint-down(sm) {
        min-width: 50%;
      }

      .product__info-count {
        width: 100%;
        color: $gray_140;

        input[type='number'] {
          width: 60px;
          padding-left: 0.5em;

          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }

        select {
          margin-left: -5px;

          @include media-breakpoint-down(xs) {
            width: 100%;
            margin: 0;
            margin-top: 0.5em;
          }
        }
      }
    }
  }

  .product__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .add-products-popup {
    display: flex;
    align-items: center;
    justify-content: space-between;


    input {
      width: 100%;
      margin-left: 20px;
      border: none;
      padding-bottom: 10px;
      border-bottom: 1px solid;
      cursor: pointer;
      caret-color: transparent;
      -webkit-appearance: none;
      border-radius: 0;
    }


    span {
      min-width: 40px;
      height: 40px;
      background: $lidlPrimaryColor;
      border-radius: 50%;
      position: relative;
      margin-bottom: 7px;
      cursor: pointer;
      background-image: url("/layouts/frontend/img/svg/add.svg");
      background-repeat: no-repeat;
      background-position: center;

      .selectize-input {
        margin-left: 20px !important;
      }
    }
  }

  .product__info-details {
    flex: 0 0 calc(100% - 100px);
  }

  .product__info-name {
    line-height: 1.2rem;
    > span {
      > svg {
        fill: rgb(140, 140, 140);
        height: 1rem;
        vertical-align: bottom;
      }
    }
  }

  .product__icons {
    flex: 0 0 35px;
    line-height: 10px;
    text-align: right;
  }

  .product__actions {
    flex: 0 0 70px;
    text-align: right;
    padding-right: 5px;

    img {
      cursor: pointer;

      margin-right: 10px;
    }
  }

  .action__save,
  .action__cancel {
    display: none;
  }

  .product__actions--edit {

    .action__save,
    .action__cancel {
      display: inline-block;
    }

    .action__delete,
    .action__edit {
      display: none;
    }
  }

  .products__edit {
    display: none;
  }

  .products__add {
    margin-top: 10px;

    &__shoppingListEmpty {
      padding: 26px 32px;
      display: flex;
      justify-content: space-between;
      background-color: #F2F5F8;
      margin-top: 32px;
      margin-left: 15px;
      align-items: center;
      width: 100%;


      @include media-breakpoint-down(xs) {
        margin-right: 15px;
        padding: 15px 16px;
      }

      &--text {
        flex: 1;
        margin-right: 10px;

        h4 {
          font-size: 17px;
          line-height: 150%;
          max-width: 70%;
          font-weight: 700;

          @include media-breakpoint-down(xs) {
            max-width: 100%;
            font-size: 14px;
          }
        }
      }

      &--icon {
        background-image: url("/layouts/frontend/img/shoplist/empty-shoppinglist-icon.png");
        height: 72px;
        width: 72px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    @include media-breakpoint-down(sm) {
      .button--category-add {
        margin-bottom: 20px;
        width: 49%;
        margin: 0;
      }
    }
  }

  .products-category__actions {
    display: flex;

    @include media-breakpoint-down(sm) {
      .button-lidl__blue--bordered {
        margin: 0 5px;
        width: 135px;
        font-size: 13px;
      }
    }
  }

  .products-category__actions--add {


    .products-category__add {
      display: none;
    }


    @include media-breakpoint-down(xs) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .button--category-add {
        margin-bottom: 20px;


        &.products-category__cancel {
          margin-bottom: 0;
        }
      }
    }
  }

  .products-category__add {
    color: #1B517D !important;
    line-height: 22px;

    display: flex;
    align-items: center;
    font-weight: bold;

    span {
      border-bottom: 2px solid #1B517D;
    }

    img {
      margin-right: 8px;
    }
  }

  .products-category__save,
  .products-category__cancel {
    display: inline-block;
  }

  .add-product__name {
    padding: 0 10px;
  }

  input[type=checkbox] ~ .checkbox-label--shoplist {

    &:before,
    &:after {
      font-size: 1rem;
      top: calc(50% - 10px);
    }
  }

  input[type=checkbox]:checked ~ .checkbox-label--shoplist {
    &:after {
      background-color: $lidlPrimaryColor;
      color: white;
    }
  }

  .view-list__sublist {
    background-color: #f0f0f0;
    margin-bottom: 2em;
    padding: 10px;
  }

  .sublist__name {
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;

    span {
      font-size: 0.7em;
      font-weight: 100;
    }

    &:after {
      color: black;
      content: '\002B';
      float: right;
      font-weight: bold;
      margin-left: 5px;
    }

    &.active:after {
      content: "\2212";
    }
  }

  .sublist__draggable-area {
    display: none;
  }

  .sublist__products {
    overflow: hidden;
    padding: 0 18px;
    transition: max-height 0.2s ease-out;
  }

  .ui-sortable-handle {
    cursor: move;
  }

  .product__buy {
    cursor: pointer;
  }

  .icons__dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 3px;
  }

  .blue-dot {
    @extend .icons__dot;
    background-color: #18afda;
  }

  .orange-dot {
    @extend .icons__dot;
    background-color: #e6a72f;
  }

  .green-dot {
    @extend .icons__dot;
    background-color: #00c73d;
  }

  .button--bordered {
    @include media-breakpoint-down(xs) {
      width: 160px;
      height: auto;
      line-height: normal;
      padding: 8px 5px;
    }
  }

  .button--black {
    @include media-breakpoint-down(xs) {
      width: 160px;
      height: auto;
      line-height: normal;
    }
  }

  &--christmasShoplist {
    margin-top: 0;
    background: url('/layouts/frontend/img/shoplist/christmasShoplistBg.jpg?v=2') center top repeat-x;
    background-size: 1440px 230px;

    @include media-breakpoint-down(sm) {
      margin-top: 110px;
      background-image: url('/layouts/frontend/img/shoplist/christmasShoplistMobileBg.jpg?v=2');
      background-size: 100% 300px;
    }

    @media (max-width: 530px) {
      background-size: 100% 330px;
    }

    @media (max-width: 400px) {
      background-size: 100% 320px;
    }

    @media (max-width: 370px) {
      background-size: 100% 380px;
    }

    @media (min-width: 1400px) {
      background-image: url('/layouts/frontend/img/shoplist/christmasShoplistWideBg.jpg?v=2');
      background-size: 1920px 270px;
      background-position: center -60px;
    }

    @media (min-width: 1920px) {
      background-image: url('/layouts/frontend/img/shoplist/christmasShoplist2560.jpg');
      background-size: 2560px 270px;
    }

    @media (min-width: 2560px) {
      background-image: url('/layouts/frontend/img/shoplist/christmasShoplist3000.jpg');
      background-size: 3000px 270px;
    }

    > .container {
      position: relative;

      @include media-breakpoint-down(sm) {
        padding-top: 100px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 393px;
        height: 202px;
        background: url('/layouts/frontend/img/shoplist/christmasShoplistLogo.png') no-repeat;
        background-size: cover;

        @include media-breakpoint-down(sm) {
          width: 251px;
          height: 140px;
          right: auto;
          left: 15px;
          background-image: url('/layouts/frontend/img/shoplist/christmasShoplistLogoMobile.png');
          background-size: 251px 140px;
        }
      }
    }

    .viewList__all-shoplist,
    .view-list__rename-single-list {
      display: none;
    }

    .view-list__title .view-list__subtitle {
      padding-top: 22px;
      margin-bottom: 50px;
      display: block;

      @media (max-width: 400px) {
        margin-bottom: 25px;
      }

      .make-input {
        font-weight: 600;
        font-size: 36px;
        line-height: 48px;
        color: #FFFFFF;

        @media (max-width: 1400px) {
          font-size: 30px;
          line-height: 34px;
        }

        @include media-breakpoint-down(sm) {
          font-size: 26px;
          line-height: 34px;
        }

        @media (max-width: 500px) {
          font-size: 22px;
          line-height: 34px;
        }
      }

      .shoplistChristmasText0 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        max-width: 150px;
        display: inline-block;
      }

      .shoplistChristmasText1 {
        padding-top: 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;

        @include media-breakpoint-down(sm) {
          padding-top: 12px;
        }
      }

      .shoplistChristmasText2 {
        margin-top: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        color: #FFFFFF;
        padding-left: 40px;
        background: url(/layouts/frontend/img/plan-landings/basket_white.svg) no-repeat;
        background-position: left center;

        @media (max-width: 400px) {
          margin-top: 13px;
        }

        span {
          font-weight: bold;
        }
      }
    }


    .christmasLandingPortions {
      padding: 12px 0 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: rgba(#111111, 0.5);
      display: flex;
      align-items: center;

      &:before {
        content: '';
        width: 18px;
        height: 18px;
        display: inline-block;
        background-image: url('/layouts/frontend/img/shoplist/portionsIcon.svg');
        margin-right: 5px;
      }
    }

    .shoplist-recipes-container {
      padding-top: 230px;

      .shoplist-recipes-container__header {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #BC2C35;
      }

      .shoplist__recipes-wrapper.christmasLandingRecipes {
        margin-top: 0;
        display: flex;
        justify-content: space-between;

        .shoplist__recipe {
          border-bottom: 0;
          width: calc(100% / 3 - 32px / 3);
          align-items: flex-start;

          a {
            display: block;
            width: 100%;
          }

          &__img {
            width: 100%;
            height: 168px;
            position: relative;
            background-size: cover;
            background-position: center;

            &__label {
              position: absolute;
              left: 0;
              top: 12px;
              padding: 5px 20px 15px 10px;
              background-image: url('/layouts/frontend/img/shoplist/christmasLabel.png');
              background-size: 122px 26px;
              background-repeat: no-repeat;
              background-position: top right;

              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              display: flex;
              align-items: center;
              letter-spacing: 0.135em;
              text-transform: uppercase;
              color: #000000;
            }
          }

          &__name {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            margin-top: 10px;
          }
        }
      }
    }

    .viewList__additionalRecipesList {
      background: rgba(188, 44, 53, 0.06);

      .viewList__additionalRecipesList__header {
        color: #BC2C35;

        &:after {
          background-image: url('/layouts/frontend/img/shoplist/expand_more_christmas.svg');
        }
      }

      .christmasLandingRecipes {
        margin-top: 5px;

        .shoplist__recipe {
          border-bottom: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          margin-bottom: 0;

          a {
            display: flex;
          }

          &__img {
            width: 64px;
            min-width: 64px;
            height: 64px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            &__label {
              display: none;
            }
          }

          &__name {
            padding-left: 10px;
            display: flex;
            align-items: center;
          }

          .shoplist__recipe-delete {
            display: none;
          }
        }
      }
    }
  }

  &--christmasLanding {

    #products-add,
    #newProductButtons,
    .product__actions,
    .checkbox-wrapper input[type=checkbox] ~ label:before,
    .checkbox-wrapper input[type=checkbox] ~ label:after {
      display: none;
    }

    .products__product {
      pointer-events: none;
    }

    .checkbox-wrapper input[type=checkbox] ~ label {
      margin-left: 0;
      padding-left: 0;
    }

    .christmasLandingBanner {
      background: url(/layouts/frontend/img/shoplist/christmasLandingBannerBg.png) no-repeat #F0D563;
      background-size: 561px 82px;
      padding: 10px 12px;
      border-radius: 2px;
      display: flex;
      height: 82px;
      align-items: center;
      margin-bottom: 30px;

      @media (max-width: 600px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        z-index: 2;
      }

      &__left {
        &__header {
          font-size: 20px;
          line-height: 24px;
          font-weight: 600;
          color: #111111;
        }

        &__desc {
          margin-top: 6px;

          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #111111;
          max-width: 280px;

          @media (max-width: 600px) {
            display: none;
          }
        }
      }

      &__right {
        width: 200px;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 15px;
        justify-content: flex-end;

        @media (max-width: 600px) {
          margin-right: 0;
          margin-left: 0;
          margin-top: 10px;
          justify-content: center;
        }

        button {
          width: auto;
          padding: 0 20px;
          background: #BC2C35;
          border-radius: 2px;
          color: #FFF;
          cursor: pointer;
          border: 0;

          height: 40px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
        }
      }
    }
  }
}

.shoplist__buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;

  @include media-breakpoint-up(sm) {
    margin-top: 20px;
  }
}

.shoplist__instruction {
  display: flex;
  font-style: italic;
  font-size: 1.2em;
  margin: 20px 20px 0 20px;

  img {
    margin-right: 1em;
  }

  @include media-breakpoint-down(xs) {
    text-align: left;
    margin-bottom: 10px;
  }
}

.fallDown {
  transform: translateY(70px);
  opacity: 0;
}

.fallUp {
  transform: translateY(-70px);
  opacity: 0;
}

.shoplist__recipe {
  border-bottom: 1px solid;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  .shoplist__recipe-delete {
    cursor: pointer;
  }
}

.checkbox-label--shoplist-category {
  margin: 0 15px !important;
  padding-left: 0 !important;

  &:before {
    display: none;
  }

  &:after {
    display: none;
  }
}

.checkbox-label--kitchen {
  font-size: 0.8em;
}

.shoplist-recipes-container {
  &__header {
    font-size: 20px;
    padding-top: 8px;
    font-weight: bold;
  }
}

#products-add {
  padding-top: 30px;

  .selectize-control,
  .profiling__ingredient-input {
    width: 100%;
    margin-left: 20px;
  }

  .selectize-dropdown.input-select.profiling__ingredient-input.selectized {
    display: none !important;
    opacity: 0;
  }

  .selectize-dropdown.input-select.profiling__ingredient-input.selectized {
    display: none !important;
    opacity: 0;
  }

  .product__actions {
    display: none;
    position: absolute;
    right: 15px;
    z-index: 9;

    &.visible {
      display: flex;
    }

    .action__save {
      margin-right: 10px;
    }

    .action__cancel,
    .action__save {
      display: block;
    }
  }
}

.shopping-list--sort-select-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    flex: 0 1 auto;
    text-align: center;
  }
}

.shopping-list--sort-select {
  display: block;
  min-width: 150px;
  position: relative;
}

.shopping-list--sort-select select {
  appearance: none;
  padding: 0.5em 3em 0.5em 1em;
  background-color: #fff;
  border: none;
  cursor: pointer;
}

.shopping-list--sort-select::before,
.shopping-list--sort-select::after {
  --size: 0.3rem;
  content: "";
  position: absolute;
  right: 1rem;
  pointer-events: none;
}

.shopping-list--sort-select::before {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-bottom: var(--size) solid black;
  top: 40%;
}

.shopping-list--sort-select::after {
  border-left: var(--size) solid transparent;
  border-right: var(--size) solid transparent;
  border-top: var(--size) solid black;
  top: 55%;
}

.products-category__image {
  max-height: 60px;
}