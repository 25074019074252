.sitemap-html {

    ul {
        margin-top: 5px;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            padding: 8px 0;
        }
    }
    
    h1 {
        margin-top: 10px;
    }
    
    @media (min-width: 640px) {
        ul {
            margin-top: 10px;
            -moz-column-count: 2;
            -moz-column-gap: 20px;
            -webkit-column-count: 2;
            -webkit-column-gap: 20px;
            column-count: 2;
            column-gap: 20px;
        }

        h1 {
            margin-top: 20px;
        }
    }
    
    @media (min-width: 1280px) {
        ul {
            -moz-column-count: 4;
            -moz-column-gap: 20px;
            -webkit-column-count: 4;
            -webkit-column-gap: 20px;
            column-count: 4;
            column-gap: 20px;
        }
    }
}

