.recipe-detail-container {
  max-width: 600px;
  margin-top: 2rem;

  @include media-breakpoint-down(xs) {
    padding: 0 15px;
  }

}

.recipe-detail {
  display: flex;
  height: 50px;
  align-items: center;

  &-mobile {
    display: none !important;

    @include media-breakpoint-down(xs) {
      display: flex !important;
    }
  }
}

.recipe-detail__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin-right: 0.5rem;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);

  @include media-breakpoint-down(xs) {
    width: auto;
    -webkit-transform: scale(1);
    transform: scale(1);
    margin-right: 0.3rem;
  }
}

.recipe-detail__value {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0;

  @include media-breakpoint-down(xs) {
    font-size: 1.2rem;
  }
}

.recipe-detail__value--title {
  margin-bottom: 0;
  font-size: 0.8rem;
  display: block;
  font-weight: normal;

  @include media-breakpoint-down(xs) {
    font-size: 0.7rem;
  }
}

.recipe-detail-healthscore,
.recipe-detail-zerowastescore {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 244px;
  padding-right: 24px;
  margin-right: 15px;
  position: relative;

  @include media-breakpoint-down(sm) {
    justify-content: flex-start;
    padding-right: 0;
    width: 100%;
    border-top: 1px solid #E3E4E5;
    padding-top: 10px;
    margin-top: 5px;
    margin-bottom: -5px;

    img {
      margin-right: 15px;
    }
  }


  &:before,
  &:after {
    content: '';
    width: 1px;
    height: 48px;
    background: #353B42;
    opacity: 0.25;

    position: absolute;
    top: 2px;
    right: 0;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &:before {
    display: none;
    right: auto;
    left: 0;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    color: #0050AA;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    span {
      display: inline-block;
      border-bottom: 1px solid #0050AA;
    }

    &:before {
      content: '';
      display: inline-block;
      margin-right: 5px;
      width: 20px;
      height: 20px;
      background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/help-circle.svg');
      background-size: cover;
    }
  }
}

.recipe-oldux-info-row {
  display: flex;
  margin: 45px 0 40px;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    margin: 20px 0 20px;
  }

  .rating-average--improvedView {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .recipe-detail-healthscore {
    padding-right: 0;
    padding-left: 25px;

    @include media-breakpoint-down(sm) {
      padding-left: 0;

      margin-top: 10px;
      padding-top: 15px;
      border-top: 0;
    }

    &:before {
      display: block;
      top: 5px;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
    &:after {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    //display: none;
  }
}

.view-old .action-container {
  margin-top: 40px;

  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }
}
