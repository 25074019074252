.sallys-landing {
  &__section4 {
    overflow: hidden;
    padding: 56px 0;

    &__main-desc {
      max-width: 63%;
      margin-bottom: 24px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }

    &__price {
      font-family: "Lidl Font Price";
      font-size: 24px;
      line-height: 29px;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
    }

    .feedElement {
      display: block !important;

      &__scroll {
        padding: 0 15px;
        margin-left: -12px;
      }

      &:after,
      &:before {
        top: 0;
      }

      &__scrollRight,
      &__scrollLeft {
        top: 32%;
      }

    }

    &__legalText {
      font-size: 14px;
      line-height: 140%;
      color: #353B42;
      margin-top: 8px;
    }

    &__description {
      font-size: 18px;
      line-height: 120%;
      display: flex;
      font-weight: 600;
      align-items: center;
      color: #353B42;
      margin-top: 22px;
      margin-bottom: 20px;
      min-height: 40px;
      word-break: break-word;
    }

    .contain {
      position: relative;
      @include media-breakpoint-down(sm) {
        overflow: hidden;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100vw;
      }
    }

    .scroll {
      @include media-breakpoint-down(sm) {
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100vw;
        verflow-x: scroll;
        overflow-y: hidden;
        transform: translateZ(0);
        will-change: transform;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
        margin-bottom: -16px;
        padding-bottom: 16px;
      }
    }

    &__photo {
      &--box {
        text-align: center;
        min-height: 150px;
        margin-bottom: 15px;

        img {
          width: 100%;
        }
      }

      &--desc {
        color: #353B42;
        margin-bottom: 30px;
        min-height: 70px;
        max-height: 170px;
        font-size: 14px;
      }
    }

    &__button-box {
      display: flex;
      justify-content: center;
      margin-top: 20px;

      a {
        width: 222px;
      }
    }

    &__price {

      &-box {
        position: absolute;
        bottom: 8px;
        right: 16px;
        padding: 5px;
        border: 1px solid #ACB0B4;
        font-size: 32px;
        line-height: 38px;
        font-weight: bold;
        z-index: 2;
        background-color: white;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        min-width: 93px;

      }


      &--high {
        font-size: 12px !important;
        line-height: 100% !important;
        color: #353B42 !important;
        margin-bottom: 2px;
        background-color: white;

        &:before {
          content: '';
          display: block;
          width: 39px;
          height: 1px;
          background-color: #E60A14;
          position: absolute;
          right: 17px;
          transform: rotate(165deg);
          top: 10px;
        }
      }

      &--discount {
        width: 91px;
        background-color: #E60A14;
        padding: 5px 0 10px 0;
        color: white !important;
        font-size: 16px !important;
        line-height: 100%;
        font-weight: 600;
        position: absolute;
        right: 8px;
        bottom: 39px;
        font-family: "Lidl Font Price";
        text-align: center;
      }

    }

    &__list-box {
      display: flex;
      margin: 0;
      padding: 0;
      margin-bottom: 30px;

      ul {
        padding-left: 0;

        li {
          list-style-type: none;
        }
      }

    }

    &__list {
      list-style-type: none;
      margin: 0;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
      margin-right: 9px;
      margin-left: 9px;
      margin-top: 4px;
      min-width: 250px;
      padding: 8px;
      position: relative;
      min-height: 505px;

      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}