.cookbook__search {
  $self: &;

  &__topBar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--withoutButton {
      #{$self}__activeFilters {
        max-width: 100%;
      }
    }

    .cookbook__search__sorting {
      margin-top: 6px;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }
  }



  &__activeFilters {
    max-width: 65%;
    display: flex;
    flex-wrap: wrap;

    &--fullWidth {
      max-width: 100%;
    }

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }

    &__header {
      line-height: 32px;
      font-size: 18px;
      margin-right: 10px;
      display: inline-block;

      @include media-breakpoint-down(sm) {
          display:none;
      }
    }

    .tag-container,
    .hybrid-tag-container {
      list-style-type: none;
      margin: 0;
      padding: 0;

      .recipe-tag-link,
      .recipe-hybrid-tag-link {
        margin-bottom: 4px;
      }

      .recipe-tag-header,
      .recipe-hybrid-tag-header {
        font-size: 1.6rem;
        color: #00b050;
      }
    }

    &__clearAll {
      display: inline-block;
    }
  }

  &__sorting {
    &__container {
      display: flex;
      align-items: center;
    }

    @include media-breakpoint-down(xs) {
      height: 40px;
    }

    &__title {
      min-width: 60px;
      text-align: right;
      font-size: 14px;
    }

    .multiselect {
       cursor: pointer;
        z-index: 11;
       
      .multiselect__tags {
        border: 0;
        padding-top: 9px;
        padding-right: 15px;
      }

      .multiselect__select {
        width: auto;
        padding: 4px 0;
        z-index: 2;
      }

      &__content-wrapper {
        min-width: 180px;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
        border: 0;
        z-index: 10 !important;
      }

      &__content {
        padding: 5px 0;
        font-size: 14px;
      }

      &__single {
        color: #353B42;
        font-weight: 600;
        white-space: nowrap;
        line-height: 24px !important;
      }

      .multiselect__option {
        padding: 10px 20px;

        &--selected,
        &--highlight,
        &:hover {
          color: $lidlPrimaryColor !important;
        }

        &--selected {
          font-weight: bold !important;
        }

        &:before,
        &:after {
          display: none;
        }
      }
    }
  }

  &-left {
    .filter-button__wrapper {
      .cookbook__search__sorting {
        display: none;
        max-width: 50%;

        .multiselect__single {
          color: $lidlPrimaryColor;
          font-weight: 600;
          text-overflow: ellipsis;
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
        }

        @include media-breakpoint-down(xs) {
          display: block;
          min-width: 41%;

          .cookbook__search__sorting__title {
            display: none;
          }
        }
      }
    }
  }

  &__topButtonArea {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    align-items: center;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    &--addRecipeButton {
      justify-content: flex-start;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .button-lidl__blue:last-child {
      margin-right: 0;
    }

    &__arrowContent {
      margin-left: 40px;
      font-size: 14px;
      line-height: 19px;
      position: relative;

      span {
        font-weight: bold;
      }

      &:before {
        content: '';
        position: absolute;
        width: 82px;
        height: 23px;
        background: url('/layouts/frontend/img/search/addRecipeButtonArrow.png');
        background-size: 82px 23px;
        top: -30px;
        left: -60px;
      }
    }

  }

  &-preparationTime,
  &-calories {
    .slider__description {
      font-size: 13px;
      font-weight: 600;
    }
  }
}

.cookbook__search-left {
  width: 30%;
  justify-content: flex-start;
  padding-right: 60px;

  @include media-breakpoint-up(md) {
    min-height: 800px;

    .cookbook--emptyList & {
      min-height: auto;
    }
  }
  @include media-breakpoint-down(md) {
    width: 35%;
  }
  @include media-breakpoint-down(sm) {
    width: 100%;
    padding-right: 0;
  }
}

.cookbook__search-right {
  width: 70%;

  @include media-breakpoint-down(md) {
    width: 65%;
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.cookbook__title,
.cookbook__search__activeFilters {
  padding-top: 8px;
}

.cookbook__title {
  //display: flex;
  margin-bottom: 25px;
  //justify-content: space-between;

  h2 {
    display: inline;
    margin-bottom: 0;
  }
  h1 {
    font-family: 'Lidl Font', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    padding-top: 2px;
  }

}

.cookbook__search-clearFilters {
  display: inline-block;
  padding-left: 10px;
  color: $lidlDarkBlue !important;
  font-size: 14px;
  font-weight: bold;
}

.cookbook__search-form {
  // display: flex;
  width: 100%;
  margin-bottom: 20px;
  display: none;
}

.cookbook__search-field {
  border: 1px solid $borderColor;
  border-right: 0;
  width: 100%;
  padding-left: 12px;
  color: #6d757c;
  line-height: 22px;
  font-size: 16px;
  height: 36px;

  //hide ios border
  -webkit-appearance: none;
  -webkit-border-radius: 0;

  &--active {
    color: #000;
  }
}

.cookbook__search-button {
    color: #FFF;
    background-repeat: no-repeat;
    text-align:center;
    cursor: pointer;

    height: 36px;
    background: $lidlDarkBlue;
    width: 46px;
}

.cookbook__search-addRecipeButton {
  min-width: 275px;
  margin-right: 0;
}

.cookbook__search-infoBox {
  margin-top: 20px;
}

.cookbook__search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .search__category-name {
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
    overflow: hidden;
  }

  .search__category-name,
  .multiselect {
    max-width: 291px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  .checkbox-wrapper input[type=checkbox] ~ label:before,
  .checkbox-wrapper input[type=radio] ~ label:before {
    border-color: $borderColor;
  }

  .checkbox-label {
    font-size: 15px;
  }

  .filter-button__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 10px;

    button {
      margin-bottom: 1em;
    }

    .show-filters {
      background: transparent;
      border: 0;


      span {
        color: $lidlPrimaryColor;
        line-height: 22px;
        font-size: 14px !important;
        font-weight: bold;
        text-transform: uppercase;
      }

      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 10px;
        background: url('/layouts/frontend/img/svg/filter.svg');
        background-size: cover;
        display: inline-block;
      }
    }

    &--withTooltip {
      z-index: 9999;
      background: #FFF;

      .buttonBubble__element {
        width: 100%;
        z-index: 999;
        top: 30px;
        max-width: 400px;

        &:after {
          left: 30%;
        }

        img {
          display: block;
          max-width: 320px;
          margin: 10px auto 0;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .button {
        width: 45%;
        max-width: 200px;
        margin-bottom: 0;
      }
    }

    @media (max-width: 440px) {
      .button {
        border: 0;
        height: 40px;
        line-height: 40px;

        span {
          font-size: 0.7rem;
        }
      }
    }
    @media (max-width: 350px) {
      .button {
        padding: 0;

        span {
          font-size: 0.6rem;
        }
      }
    }
  }

  .cookbook__recipes-filters {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .cookbook__search-toggles {
      margin-bottom: 2em;
      width: 100%;

      .cookbook__search-toggle {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .search-toggle__switch {
          display: inline-block;
          height: 24px;
          position: relative;
          width: 50px;
        }

        .search-toggle__switch input {display:none;}

        .switch__slider {
          background-color: #ccc;
          border-radius: 34px;
          bottom: 0;
          cursor: pointer;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          transition: .4s;
          -webkit-transition: .4s;
        }

        .switch__slider:before {
          background-color: white;
          border-radius: 50%;
          bottom: 4px;
          content: "";
          height: 16px;
          left: 4px;
          position: absolute;
          transition: .4s;
          -webkit-transition: .4s;
          width: 16px;
        }

        input:checked + .switch__slider {
          background-color: $lidlDarkBlue;
        }

        input:focus + .switch__slider {
          box-shadow: 0 0 1px $lidlDarkBlue;
        }

        input:checked + .switch__slider:before {
          transform: translateX(26px);
          -ms-transform: translateX(26px);
          -webkit-transform: translateX(26px);
        }
      }
    }

    .cookbook__search {
      width: 100%;

      .checkbox {
        width: 50%;
      }

      &-admin {
        .checkbox {
          width: 100%;
        }
      }
    }

    .cookbook__search {
      margin-bottom: 24px;

      .filtersShowHideButton {
        color: #212529;
        font-size: 15px;
      }
    }

    .cookbook__search-difficulty,
    .cookbook__search-preparationTime,
    .cookbook__search-calories {
      max-width: 300px;
    }

    .cookbook__search-excluded {
      margin-top: 20px;
      margin-bottom: 30px;

      .selectize-control {
        background: transparent !important;
      }
      .desc {
        font-size: 16px;
      }
      .ingredientsCheckboxes {
        margin: 10px 0;

        .ingredientsCheckboxes__element {
          margin-right: 5px;
          width: auto;
          padding: 10px;

          i {
            padding-left: 8px;
            font-size: 12px;
          }
        }
      }
      .profiling__ingredient-input {
        margin-top: 10px;
      }
    }

    .cookbook__search-tags {

      .multiselect__tag {
        margin-left: 0;
        margin-right: 4px;

        &:last-child {
          &:after {
            content: '';
          }
        }

        &:after {
          content: 'oder';
        }
      }

      &--dietType {
        .multiselect__tag {
          &:last-child {
            &:after {
              content: '';
            }
          }

          &:after {
            content: 'und';
          }
        }
      }
    }

    .cookbook__search-calories,
    .cookbook__search-preparationTime{
      margin-bottom: 2em;
      width: 100%;
      text-align: center;

      .slider-horizontal {
        top: 0 !important;
        width: 90% !important;
      }
    }

    .cookbook__search-mealTypes {
      .checkbox {
        max-width: 160px;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }
    }

    .cookbook__update-filters {
      align-self: center;
    }

    @include media-breakpoint-down(sm) {
      width: 100vw;
      max-width: 100%;
      max-height: 100vh;
      max-height: calc(var(--vh, 1vh) * 100);
      background-color: $dirty_white;
      position: fixed;
      top: 105vh;
      left: 0;
      z-index: 1000;
      padding: 0;
      -webkit-overflow-scrolling: touch;

      @include transition(0.3s top ease-in-out);

      .cookbook__recipes-scrollableArea {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;

        .cookbook__recipes-paddingArea {
          padding: 8em 20px 4em;
        }
      }
    }

    &.open {
      top: 0;
    }
  }

  .hide-filters__wrapper {
    display: none;

    &-open {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      background-color: $dirty_white;
      height: 3em;
      z-index: 1001;
      right: 0;
      margin: 0 auto;
      -webkit-transform: translate3d(0, 0, 0);
      transform : translate3d(0, 0, 0);

      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      img {
        position: absolute;
        cursor: pointer;
        right: 1em;
        top: 0.8em;
        width: 25px;
      }
    }
  }

  .update-filters__wrapper {
    display: none;

    &-open {
      width: 100%;
      background: $dirty_white;
      position: fixed;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      height: 4em;
      z-index: 1001;
      align-items: center;

      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
  }

  .cookbook__recipes-list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 30px;

    @include media-breakpoint-down(sm) {
      margin-top: 15px;
      min-height: 350px;
    }

    &__emptyInfo{
      width: 100%;
      position: relative;

      svg + h2 {
        padding-top: 150px;
      }

      .heart-stroke {
        top: 0;
        height: 120px;
      }

      a {
        margin: 0 auto;
        max-width: 250px;
      }
    }
  }

  .recipes__pages {
    //font-size: 1.3em;
    height: 55px;
    text-align: center;
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-count {
      font-size: 2em;
      flex: 1;

      @include media-breakpoint-down(sm) {
        font-size: 17px;
      }

      input {
        width: 65px;
        text-align: center;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      @media (max-width: 350px) {
          display: none;
      }
    }
    .button-lidl__blue {
      margin-right: 0;

      @include media-breakpoint-down(sm) {
        width: auto;
        min-width: 100px;
      }
    }
  }

  .tagLanding__veganeRezepteOptimalization {
    margin-top: 100px;

    .single-item {
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        margin-right: 10px;
      }

      &__name {
        height: auto;
      }

      .sallys-landing__section6--photo {background-repeat: none; background-size: cover; height: 190px;}
      .sallys-landing__section6--photo--one {background-image: url('https://www.lidl-kochen.de/kochideen/wp-content/uploads/2021/10/vegane_proteinquellen_nu%CC%88sse_soja_co.jpg'); }
      .sallys-landing__section6--photo--two {background-image: url('https://www.lidl-kochen.de/kochideen/wp-content/uploads/2021/09/na%CC%88hrstoffe-und-mineralstoffe-bei-veganer-erna%CC%88hrung.jpg'); }
      .sallys-landing__section6--photo--three {background-image: url('https://www.lidl-kochen.de/kochideen/wp-content/uploads/2021/07/veganer_fleischesser_so_klappt_das_gemeinsame_kochen_ohne_stress.jpg'); }
      .sallys-landing__section6--photo--four {background-image: url('https://www.lidl-kochen.de/kochideen/wp-content/uploads/2021/10/wie_gesund_ist_eigentlich_vegan.jpg'); }
    }
  }
}

.multiselect {
  &__tag {
    &:first-child {
      margin-left: 4px;
    }
  }

  & &__tags {
    border-color: $borderColor;
    border-radius: 0;
    min-height: 36px;
    max-height: 36px;
    padding-top: 7px;
  }

  & &__single {
    line-height: 22px;
    color: #6D757C;
    font-size: 14px;

    .cookbook__search-tags--notDefault & {
      //fix for single mutliselect
      color: #000;
    }

  }

  & &__content-wrapper {
    border-color: $borderColor;
    border-radius: 0;
  }


}

.page__cookbook__search,
.page__tags__search,
.page__recipe__search {
  @media (max-width: 639px) {
    // .header .search-form {
    //   display: none;
    // }
    .breadcrumb__list {
      margin-bottom: 0;
      padding-top: 120px;
    }
  }
}


.tagLanding {

  &__vegan {
    background-color: #F5F8FC;
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    padding: 56px;

    @media (max-width: 700px) {
      padding: 20px;
      align-items: center;
    }

    h3 {
      font-size: 26px;
      line-height: 120%;
      font-weight: 600;
      margin-bottom: 56px;

      @media (max-width: 700px) {
        margin-bottom: 25px;
      }
    }

    img {
      width: 100%;
      max-width: 707px;
      height: 120px;

      @media (max-width: 700px) {
        display: none;
      }

      &:last-child {
        display: none;
        max-width: 240px;
        height: 340px;
        @media (max-width: 700px) {
          display: block;
        }
      }
    }
  }

  &__photo {
    position: relative;
    background-image: url("/layouts/frontend/img/search/veganuary-photo.jpg");
    background-repeat: no-repeat;
    width: 100%;
    height: 280px;
    background-size: cover;
    padding: 20px 30px;
    margin: 30px 0 40px 0;
    overflow: hidden;

    @media (max-width: 770px) {
      padding: 230px 20px 20px;
      width: 100%;
      height: auto;
      background-color: #F6F6F6;
    }

    &:before {
      @media (max-width: 770px) {
        content: '';
        display: block;
        width: 100%;
        height: 214px;
        background-image: url("/layouts/frontend/img/search/veganuary-planner.png");
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &--newVeganuary {
      position: relative;
      background-image: linear-gradient(rgba(#F6F6F6, 0.5), rgba(#F6F6F6, 0.5)), url("/layouts/frontend/img/search/veganuary-photo.jpg");
      background-position: top right, top right;

      @media (max-width: 770px) {
        background-image: none;
      }

      &:after {
        position: absolute;
        top: 15px;
        right: 15px;
        content: '';
        width: 300px;
        height: 60px;
        background: url('/layouts/frontend/img/search/veganuaryBannerNew.png') no-repeat center;
        background-size: cover;

        @media (max-width: 770px) {
          top: 150px;
          left: 50%;
          width: 210px;
          height: 46px;
          margin-left: -105px;
        }
      }
    }

    &--veganeRezepteOptimalization {
      background: #51B3BA;
      background-image: url("/layouts/frontend/img/search/veganeRezepteOptimalization.jpg?v=2");
      background-repeat: no-repeat;
      background-size: cover;

      @media (max-width: 770px) {
        background-image: url("/layouts/frontend/img/search/veganeRezepteOptimalizationMobile.jpg");
        padding-top: 270px;
      }

      &:before {
        @media (max-width: 770px) {
          display: none;
        }
      }

      h4,
      .tagLanding__photo__banner--content ul {
        color: #FFF;
      }

      h4 {
        padding-top: 20px;
      }

      .tagLanding__photo__box img {
        bottom: 7px;
        right: -45px;
      }
    }

    &--vemondo {
      background-color: #00BCAD;
      padding: 30px;
      position: relative;
      margin-bottom: 24px;
      overflow: hidden;

      @include media-breakpoint-down(xs) {
        padding: 130px 24px 24px 24px;
      }

      .tagLanding__photo__banner--content {
        max-width: 100%;
        color: #FFF;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        > div {
          &:first-child {
            margin-right: 25px;

            @include media-breakpoint-down(xs) {
              margin-right: 0;
            }
          }
        }

        .tagLanding__photo--vemondo__bottomText {
          color: #FFF;
          margin-top: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }

      h4 {
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
      }

      img {
        width: 250px;
        height: 125px;

        @include media-breakpoint-down(xs) {
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--milbona {
      background-color: #C80128;
      padding: 30px;
      position: relative;
      margin-bottom: 24px;
      overflow: hidden;

      @include media-breakpoint-down(xs) {
        padding: 159px 24px 24px 24px;
      }

      .tagLanding__photo__banner--content {
        max-width: 100%;
        color: #FFF;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        > div {
          &:first-child {
            margin-right: 25px;

            @include media-breakpoint-down(xs) {
              margin-right: 0;
            }
          }
        }

        .tagLanding__photo--milbona__bottomText {
          color: #FFF;
          margin-top: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }

      h4 {
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
      }

      img {
        width: 220px;
        height: 131px;

        @include media-breakpoint-down(xs) {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--metzgerfrisch {
      background-color: #000100;
      padding: 30px;
      position: relative;
      margin-bottom: 24px;
      overflow: hidden;

      @include media-breakpoint-down(xs) {
        padding: 159px 24px 24px 24px;
      }

      .tagLanding__photo__banner--content {
        max-width: 100%;
        color: #FFF;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        > div {
          &:first-child {
            margin-right: 25px;

            @include media-breakpoint-down(xs) {
              margin-right: 0;
            }
          }
        }

        .tagLanding__photo--metzgerfrisch__bottomText {
          color: #FFF;
          margin-top: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }

      h4 {
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
      }

      img {
        width: 220px;
        height: 131px;

        @include media-breakpoint-down(xs) {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--brot {
      background-color: #000100;
      padding: 30px;
      position: relative;
      margin-bottom: 24px;
      overflow: hidden;

      @include media-breakpoint-down(xs) {
        padding: 159px 24px 24px 24px;
      }

      .tagLanding__photo__banner--content {
        max-width: 100%;
        color: #FFF;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        > div {
          &:first-child {
            margin-right: 25px;

            @include media-breakpoint-down(xs) {
              margin-right: 0;
            }
          }
        }

        .tagLanding__photo--brot__bottomText {
          color: #FFF;
          margin-top: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }

      h4 {
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
      }

      img {
        width: 145px;
        height: 145px;

        @include media-breakpoint-down(xs) {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--bonGelati {
      background-color: #75BEEB;
      padding: 30px;
      position: relative;
      margin-bottom: 24px;
      overflow: hidden;

      @include media-breakpoint-down(xs) {
        padding: 179px 24px 24px 24px;
      }

      .tagLanding__photo__wrapper {
        justify-content: space-between;
      }

      .tagLanding__photo__banner--content {
        max-width: 100%;
        color: #FFF;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        > div {
          &:first-child {
            margin-right: 25px;

            @include media-breakpoint-down(xs) {
              margin-right: 0;
            }
          }
        }

        .tagLanding__photo--bonGelati__bottomText {
          color: #FFF;
          margin-top: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }

      h4 {
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
      }

      img {
        width: 140px;
        height: 141px;

        @include media-breakpoint-down(xs) {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--saskiaFreeway {
      background-color: #D1E8F0;
      padding: 30px;
      position: relative;
      margin-bottom: 24px;
      overflow: hidden;

      @include media-breakpoint-down(xs) {
        padding: 179px 24px 24px 24px;
      }

      .tagLanding__photo__wrapper {
        justify-content: space-between;
      }

      .tagLanding__photo__banner--content {
        max-width: 100%;
        color: inherit;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 156%;

        h4 {
          color: inherit;
        }

        > div {
          &:first-child {
            margin-right: 25px;

            @include media-breakpoint-down(xs) {
              margin-right: 0;
            }
          }
        }

        .tagLanding__photo--saskiaFreeway__bottomText {
          color: inherit;
          margin-top: 10px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 156%;
        }
      }

      h4 {
        color: #FFF;
        font-size: 26px;
        font-weight: 600;
      }

      img {
        width: 140px;
        height: 141px;

        @include media-breakpoint-down(xs) {
          position: absolute;
          top: 15px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    &--monsieurCuisine {
      //background: linear-gradient(90deg, darken(#B55568, 15%) 0%, #B55568 80%);
      background-image: url("/layouts/frontend/img/search/monsieurCuisine-desktop.jpg");
      background-repeat: no-repeat;
      background-size: cover;

      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media (max-width: 770px) {
        padding-top: 260px;
        background-image: url("/layouts/frontend/img/search/monsieurCuisine-mobile.jpg");
      }

      &:before {
        @media (max-width: 770px) {
          display: none;
        }
      }

      h4,
      .tagLanding__photo__banner--content {
        color: #FFF;
      }

      h4 {
        padding-top: 10px;
      }

      .button-lidl__white {
        margin-top: 10px;

          @media (max-width: 770px) {
            margin-top: 20px;
          }
      }

      .button-lidl__white {
        color: #B55568;
      }

      .tagLanding__photo__box {
        position: static;

        @media (max-width: 770px) {
          display: block;
          width: auto;
          height: auto;
        }

        img {
          position: absolute;
          bottom: 20px;
          right: 20px;

          width: 359px;
          height: 191px;

          @media (max-width: 770px) {
            display: block;
            width: 100%;
            top: 0;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    &--obst-und-gemuese {
      margin-bottom: 24px;
      overflow: hidden;

      .tagLanding__photo__banner--content-mobile img,
      .tagLanding__photo__banner--content-desktop img{
        max-width: 100%;
        height: auto;
      }

      .tagLanding__photo__banner--content-mobile {
        display: none;
      }

      @include media-breakpoint-down(xs) {
        .tagLanding__photo__banner--content-mobile {
          display: block;
          margin: 0 auto;
        }
        .tagLanding__photo__banner--content-desktop {
          display: none;
        }
      }
    }

    &--aus-lidl-zum-spiel {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:before {
        @media (max-width: 770px) {
          display: none;
        }
      }


      .tagLanding__photo__banner--content {
        position: static;
        display: block;
        max-width: 100% !important;

        img {
          display: block;
          margin: 0 auto 20px auto;
          max-width: 100%;

          @media (max-width: 770px) {
            max-width: 100%;
          }
        }

        @media (max-width: 770px) {
          .img-desktop {
            display: none;
          }
        }

        @media (min-width: 771px) {
          .img-mobile {
            display: none;
          }
        }
      }

    }

    &--fussball-sommer {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:before {
        @media (max-width: 770px) {
          display: none;
        }
      }


      .tagLanding__photo__banner--content {
        position: static;
        display: block;
        max-width: 100% !important;

        img {
          display: block;
          margin: 0 auto 20px auto;
          max-width: 100%;

          @media (max-width: 770px) {
            max-width: 100%;
          }
        }

        @media (max-width: 770px) {
          .img-desktop {
            display: none;
          }
        }

        @media (min-width: 771px) {
          .img-mobile {
            display: none;
          }
        }
      }

    }

    &--bio {
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;

      &:before {
        @media (max-width: 770px) {
          display: none;
        }
      }


      .tagLanding__photo__banner--content {
        position: static;
        display: block;
        max-width: 100% !important;

        img {
          display: block;
          margin: 0 auto 20px auto;
          max-width: 100%;

          @media (max-width: 770px) {
            max-width: 100%;
          }
        }

        @media (max-width: 770px) {
          .img-desktop {
            display: none;
          }
        }

        @media (min-width: 771px) {
          .img-mobile {
            display: none;
          }
        }
      }

    }

    h4 {
      font-size: 26px;
      line-height: 120%;
      margin-bottom: 25px;

      strong {
        font-family: 'Lidl Font'; font-weight: bold;
      }
    }

    &__wrapper {
      display: flex;
      @media (max-width: 770px) {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }

    &__box {
      width: 359px;
      height: 191px;
      position: relative;
      @media (max-width: 770px) {
        display: none;
      }


      img {
        position: relative;
        right: -170px;
        bottom: 40px;

        @media (max-width: 770px) {
          display: none;
        }
      }
    }

    &__banner {
      &--content {
        max-width: 50%;

        @media (max-width: 770px) {
          max-width: 100%;
        }

        ul {
          padding: 0;
          margin-left: 20px;
          li {
            &:not(:last-child) {
              padding-bottom: 8px;
            }
            font-size: 16px;
            line-height: 140%;

            strong {
              font-family: 'Lidl Font'; font-weight: bold;
            }
          }
        }
      }
    }

    &--vemondoContest {
      background-image: none;
      background-color: rgb(240,240,240);

      .tagLanding__photo__banner--content {
        a {
          margin-top: 15px;
        }
      }

      @include media-breakpoint-down(sm) {
        &:before {
          background-image: url('/layouts/frontend/img/search/veganuary-recipe-contest-mobile.png');
          height: 214px;
          background-size: contain;
          background-position: center;
          margin-top: 25px;
        }
        h4 {
          margin-top: 40px;
        }
        .tagLanding__photo__banner--content {
          a {
            margin-top: 15px;
          }
        }
      }

      @include media-breakpoint-up(md) {
        h4,
        .tagLanding__photo__banner--content {
          max-width: 33%;
          margin-bottom: 10px;
        }

        .tagLanding__photo__box {
          position: absolute;
          right: 15px;
          top: -13px;
          width: auto;
          height: 100%;
        }
      }
      @media (min-width: 1024px) and (max-width: 1400px) {
        h4,
        .tagLanding__photo__banner--content {
          max-width: 50%;
          margin-bottom: 10px;
        }

        .tagLanding__photo__box {
          position: absolute;
          right: 35px;
          top: 50%;
          width: auto;
          height: 55%;
          margin-top: -90px;
        }
      }

      @media (min-width: 771px) and (max-width: 1024px) {
        h4,
        .tagLanding__photo__banner--content {
          max-width: 50%;
          margin-top: 0;
        }

        h4 {
          margin-top: 5px;
        }

        .tagLanding__photo__box {
          position: absolute;
          right: 40px;
          top: 40px;
        }
      }
    }
  }
  &__header {
    margin-bottom: 35px;

    @media (max-width: 600px) {
      margin-bottom: 15px;
    }

    h1 {
      text-align: center;
      padding-bottom: 20px;

      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }

    &__bg {
      display: flex;
      color: #FFF;
      min-height: 250px;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
      font-weight: bold;
      position: relative;
      margin-top: -10px;
      margin-bottom: 10px;

      position: relative;
      overflow: hidden;

      .tagLanding__header__bg__mobileImg,
      .tagLanding__header__bg__desktopImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.1);

        min-height: 1000%;
        min-width: 1000%;
      }

      .tagLanding__header__bg__desktopImg {
        @media (max-width: 600px) {
          display: none;
        }
      }

      .tagLanding__header__bg__mobileImg {
        display: none;

        @media (max-width: 600px) {
          display: block;
        }
      }


      @media (max-width: 600px) {
        height: 120px;
        margin-left: -15px;
        margin-right: -15px;
      }

      h1 {
        position: relative;
        z-index: 2;
        padding: 0 20px;
        margin-bottom: 0;
        font-family: 'Lidl Font'; font-weight: bold;
      }

      &--tagLandingsHeaderNewUi {
        display: block;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 64px;

        h1 {
          display: inline;
          background: #0050AA;
          border-radius: 0px 6px 6px 0px;
          padding: 6px 12px 6px 24px;

          text-transform: uppercase;
          font-weight: 700;
          font-size: 30px;
          line-height: 36px;
          text-align: left;

          @include media-breakpoint-down(sm) {
            padding: 4px 8px 4px 16px;
            font-size: 24px;
            line-height: 29px;

            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;
          }
        }
      }
    }

    &__desc,
    &__descMobile {
      font-weight: 400;
      font-size: 22px;
      line-height: 31px;
      color: #000;
      text-shadow: none;
      margin-bottom: 0;
      margin-top: 10px;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    &__desc {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    &__descMobile {
      display: none;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;

      @include media-breakpoint-down(sm) {
        display: block;
        width: 50%;
      }
    }

    &--rettertuteCampaign {

      .tagLanding__header__bg {
        height: 250px;
        background: url('/layouts/frontend/img/homepage/rettertuteCampaign/bagSearch.png') #AED4D9 no-repeat;
        background-size: 227px 250px;
        background-position: 95% bottom;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 60px;

        @include media-breakpoint-down(sm) {
          height: 300px;
          padding-left: 16px;
          background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/bagSearchMobile.png');
          background-size: 125px 178px;
          background-position: 90% center;
        }

        h1 {
          font-weight: 600;
          font-size: 40px;
          line-height: 48px;
          padding-left: 0;

          color: #376633;
          text-shadow: none;

          @include media-breakpoint-down(sm) {
            font-size: 28px;
            line-height: 34px;
            width: 50%;
            text-align: left;
            word-break: break-word;
          }
        }

        .tagLanding__header__desc {
          span {
            font-weight: bold;
          }
        }
      }

      .tagLanding__header__modifyIngredients {
        text-shadow: none;
        margin-top: 20px;
        margin-bottom: 0;

        a {
          width: auto;
          padding: 0 20px 0 15px;
          font-weight: 600;
          font-size: 18px;
          line-height: 20px;
          color: #FFF;
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
            background: url('/layouts/frontend/img/svg/search/edit.svg?v=2') no-repeat;
            background-size: cover;
            width: 16px;
            height: 16px;
            margin-right: 12px;
          }
        }
      }
    }
  }

  &__topText {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 639px) {
      margin-top: 10px;
    }

    &__hiddenContent {
      display: none;
    }

    &__readMore {
      display: inline-block;
    }
  }

  &__bottomText {
    margin: 50px 0 10px;
  }

  &__childrenTags {
    padding-top: 75px;

    h4 {
      font-family: 'Lidl Font'; font-weight: bold;
      padding-bottom: 25px;
    }
  }

  &__lebensmittelrettungBanner {
    background: linear-gradient(0deg, #A2C124, #A2C124), linear-gradient(90deg, #A2C124 33%, #BBF0A2 99%);

    .tagLanding__photo {
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0) 48%);
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      color: #FFFFFF;

      @media (max-width: 770px) {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.63) 0%, rgba(255, 255, 255, 0) 35%);
      }

      &:before {
        @media (max-width: 770px) {
          display: none;
        }
      }

      h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .tagLanding__photo__banner--content {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        max-width: 75%;

        @media (max-width: 770px) {
          max-width: 100%;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .button-lidl {
        margin-top: 20px;

        background: #FFFFFF;
        color: rgba(162, 193, 36, 1);
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-shadow: none;
      }

      .tagLanding__photo__box {
        @media (max-width: 770px) {
          display: block;
          position: static;
          width: auto;
          height: auto;
          margin-bottom: 10px;
        }

        img {
          width: 247px;
          height: 247px;
          bottom: 60px;
          right: -30px;

          @media (max-width: 770px) {
            position: absolute;
            display: block;
            top: 20px;
            left: -10px;

            width: 200px;
            height: 200px;
          }
        }
      }
    }
  }

  &__guenstigBanner {
    background: #D22F2D;
    height: 370px;
    margin-bottom: 30px;

    @media (max-width: 770px) {
      height: auto;
    }

    .tagLanding__photo {
      background-color: #D22F2D;
      background-image: url('/layouts/frontend/img/search/guenstigDesktop.jpg');
      background-size: 819px 394px;
      background-position: 20px 0;
      text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      color: #FFFFFF;
      height: 100%;

      &:before {
        @media (max-width: 770px) {
          background-image: url('/layouts/frontend/img/search/guenstigMobile.jpg');
        }
      }

      h4 {
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .tagLanding__photo__banner--content {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        max-width: 660px;

        span {
          font-weight: bold;
        }

        ul {
          margin-top: 10px;
        }

        @media (max-width: 770px) {
          max-width: 100%;
          font-size: 16px;
          line-height: 19px;
        }
      }

      .button-lidl {
        margin-top: 20px;

        background: #FFFFFF;
        color: #D22F2D;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        text-shadow: none;
      }


    }
  }

  &--timoFrankeBanner {
    .tagLanding__photo {
      background: #4EB8A0;
      color: #FFF;
      height: auto;
      padding: 30px;


      &:before {
        background-image: none;
        display: none;
      }

      h4 {
        font-size: 26px;
        line-height: 31px;
        font-weight: 600;
        margin-bottom: 15px;
      }

      .tagLanding__photo__banner--content {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        max-width: 100%;
      }

      .tagLanding__photo__box {
        min-width: 160px;
        height: auto;

        @include media-breakpoint-down(xs) {
          display: block;
        }

        img {
          width: 136px;
          height: 136px;
          position: static;

          @include media-breakpoint-down(xs) {
            display: block;
            width: 100px;
            height: 100px;
            position: relative;
            top: -10px;
            left: -10px;
            bottom: auto;
            right: auto;
          }
        }
      }
    }
  }
}

.recipesBanner {
  background: #2775c0;
  font-family: 'Lidl Font'; font-weight: bold;
  font-size: 32px;
  margin-bottom: 50px;
  text-align: center;
  line-height: 1em;
  border-radius: 4px;

  .button-lidl__blue--bordered {
    min-width: 183px;
    width: auto;
  }

  &--vemondo {
    background: #00BCAD;
    padding: 24px;

    a {
      padding: 0 !important;
    }

    &:hover {
      .button__vemondo {
        background: #19a69b;
        color: white;
      }
    }

    .button__vemondo {
      color: #00BCAD;
      width: 100%;
      margin: 70px 0 0 0;
    }
  }

  &--vegan {
    background-color: #4EB8A0;

    a {
      font-size: 32px;
      line-height: 105%;
      font-family: 'Lidl Font'; font-weight: bold;

      &:hover {
        button {
          background: white !important;
          border-color: white !important;
          color: initial !important;
        }
      }

    }
    .button-lidl__blue--bordered {
      border: none;
      position: relative;

      &:hover {
        background: white !important;
        background-image: initial !important;
        border-color: white !important;

        span {
          color: #0076BF;
        }
      }

      span {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        margin-left: 11px;

        &:before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("/layouts/frontend/img/svg/fb.svg");
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          left: 18px;
          top: 18px;
        }
      }
    }
  }

  &--veganTimo {
    background-color: #4EB8A0;
    background-image: url('/media/img/recipe/label/timo2.png');
    background-position: center 35px;
    background-repeat: no-repeat;
    background-size: 200px;

    @include media-breakpoint-down(xs) {
      background-size: 200px;
      background-position: center 20px;
    }

    a {
      &:hover {
        button {
          background: white !important;
          border-color: white !important;
          color: #4EB8A0 !important;
        }
      }
    }

    .button-lidl__blue--bordered {
      border: none;
      position: relative;
      color: #4EB8A0;
      margin-top: 230px;

      @include media-breakpoint-down(xs) {
        margin-top: 200px;
      }

      &:hover {
        background: white !important;
        background-image: initial !important;
        border-color: white !important;
        color: #4EB8A0 !important;
      }
    }
  }

  &--lebensmittelrettung {
    background: rgba(162, 193, 36, 1);

    p {
      margin-bottom: 0;
      margin-top: 20px;
    }

    button {
      background: #FFF;
      color: rgba(162, 193, 36, 1);
      padding: 0 15px;
      width: auto;
    }

    &:hover button {
      background: darken(rgba(162, 193, 36, 1), 15%) !important;
      color: #FFF !important;
    }

    button {
      margin-top: 0;
    }
  }

  &--guenstig {
    background: #D22F2D;

    p {
      margin-bottom: 0;
      margin-top: 20px;
    }

    button {
      background: #FFF;
      color: #D22F2D;
      padding: 0 15px;
      width: 100%;
    }

    &:hover button {
      background: darken(#D22F2D, 15%) !important;
      color: #FFF !important;
    }

    button {
      margin-top: 0;
    }
  }

  &--monsieurCuisine {
    background: #B55568;
    text-shadow: none;

    p {
      font-weight: normal;
      font-size: 28px;

      span {
        font-weight: bold;
      }
    }

    img {
      border-radius: 5px;
    }

    &:hover {
      .button-lidl__white {
        background: darken(#B55568, 15%);
        color: #FFF;
      }
    }

    .button-lidl__white {
      color: #B55568;

      &:hover {
        background: darken(#B55568, 15%);
        color: #FFF;
      }
    }
  }

  &--center {
    justify-content: center;
  }

  .recipesBanner {
    &--top {
      justify-content: flex-start;
      margin-top: 10px;
    }

    &--center {
      justify-content: center;
    }

    &--bottom {
      justify-content: flex-end;
        margin-bottom: 20px;
    }
  }

  &--actionsWoche {
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__weihnachtsmarkt {
    font-size: 23px;
  }

  a {
    padding: 15px;
    color: #FFF;
    text-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    word-break: break-word;
    min-height: 250px;


    button {
      margin: 30px auto 0;
      font-size: 16px;

      &:hover {
        text-decoration: none;
      }
    }

    &:hover {
      button {
        background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) rgb(0, 118, 191);
        border-color: rgb(0, 86, 140);
        color: #FFF;
      }
    }
  }

  &--excludes {
    border-radius: 3px;
    border: 1.5px solid #C7E2F4;
    background: url('/layouts/frontend/img/svg/searchBannerExcludes.svg') #FFF center 120px no-repeat;

    @include media-breakpoint-down(xs) {
      background-position: center 85px;

      a {
        padding-bottom: 210px;
      }
    }

    a {
      justify-content: normal;
      position: relative;
      font-family: 'Lidl Font', sans-serif;
      text-align: left;
      text-shadow: none;

      .recipesBanner__head {
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
      }

      .recipesBanner__desc {
        position: absolute;
        bottom: 85px;
        left: 20px;
        right: 20px;

        font-size: 16px;
        line-height: 19px;
        color: #000000;
        margin-top: 100px;
        margin-bottom: 0;
        font-weight: normal;
      }

      button {
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: calc(100% - 40px);
      }
    }
  }
}


.filters {
  &__includeProfile {
    margin-top: 10px;
    position: relative;
    margin-bottom: 5px;
    width: 100%;

    .iconset {
      position: relative;
      top: 2px;
    }

    .buttonBubble__element {
      margin-top: 5px;
      left: 10px;
      width: 100%;
      z-index: 999;
      max-width: 400px;
      transition: 0.25s opacity, 0.25s visibility;

      @include media-breakpoint-down(xs) {
        width: calc(100% - 30px);
        bottom: -150px;

        &--visible {
          bottom: auto;
        }
      }

      img {
        display: none;
      }
    }

    .buttonBubble__element__profileInfo {
      padding-top: 20px;

      p {
        padding-bottom: 5px;
      }
    }

    &--withTooltip {
      background: #FFF;
      z-index: 999;
      padding-bottom: 10px;
      border-radius: 5px;

      .buttonBubble__element {
        position: relative;
        top: 0;
        width: calc(100% - 20px);

        @include media-breakpoint-down(xs) {
          bottom: auto;
        }
      }

      .checkbox-label {
        z-index: 999;
        //background: #FFF;
        padding: 10px 15px;
        padding-left: 67px !important;
        left: -15px;
        top: -10px;
        //border-radius: 5px;
        padding-bottom: 0;
        margin-top: 10px;
        margin-bottom: 0;
        padding-right: 15px;
        width: 100%;

        i {
          top: 10px !important;
          left: 15px !important;
        }
      }
    }
  }
}

.single-item {
  &__likes {
    position: relative;
    z-index: 10;
  }

  &__ingredientsLayer {
    transition: 0.5s all;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    overflow: hidden;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    cursor: pointer;

    &__bg {
      width: 100%;
      height: 100%;
      background: rgba(33, 33, 33, 0.75);
      box-sizing: border-box;
      padding: 16px;
      padding-top: 20px;
    }

    strong {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;

      .iconset--cart {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 15px;
      max-height: 195px;
      overflow: auto;

      li {
        margin: 0;
        padding: 0;
        display: inline;

        font-weight: normal;
        font-size: 14px;
        line-height: 1em;

        color: #FFFFFF;
      }
    }

    a {
      position: absolute;
      bottom: 16px;
      left: 16px;
      right: 16px;
      width: auto;
      margin-right: 0;
    }
  }
}

.pagination {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin: 40px 0;

  @include media-breakpoint-down(xs) {
    position: relative;
    margin: 20px 0;
    padding-bottom: 65px;
  }

  &__button {
    width: 48px;
    min-width: 48px;

    .button-lidl__blue {
      margin-right: 0;
      min-height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  &__list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 15px;

    @include media-breakpoint-down(xs) {
      width: 100%;
      margin: 0;
    }

    &__element {
      margin: 4px;

      &--dots {
        margin-left: 0;
        margin-right: 0;
      }

      &__number,
      &__dots {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000000;

        &, &:hover {
          text-decoration: none;
        }
      }

      &__number {
        display: block;
        min-width: 28px;
        height: 25px;

        &--current {
          font-weight: 600;
        }

        background: #FFFFFF;
        border: 1px solid #C6C9CC;
      }
    }

    .pagination__button {
      display: none;

      min-width: 25px;
      height: 25px;
      width: 25px;

      margin-left: 5px;
      margin-right: 5px;

      @include media-breakpoint-down(xs) {
        display: block;
      }

      a {
        height: 100%;

        .iconset--arrowLeft,
        .iconset--arrowRight {
          width: 10px;
          height: 10px;
          background-size: cover;
        }
      }
    }
  }
}

.planner-info-banner {
  position: relative;
  display: flex;
  width: 100%;
  background: #D6E3F1;
  border-radius: 4px;
  padding: 15px 25px;
  overflow: hidden;

  @media screen and (min-width: 640px) {
      overflow: visible;
  }

  &__message {
    flex: 1;
    z-index: 2;
    @media screen and (min-width: 640px) {
        flex: 0.75;
    }
  }

  &__message {
    h4 {
      font-weight: bold;
      // color: #23651d;
    }
    p {
      margin-top: 5px;
      margin-bottom: 0;

      strong {
        color: #0050AA;
        text-wrap: nowrap;
      }
    }
  }

  &__icon {
    position: absolute;
    height: 140%;
    right: 10px;
    bottom: -75%;
    opacity: 0.15;
    z-index: 0;
    display: none;
  
    @media screen and (min-width: 640px) {
      display: block;
      opacity: 1;
      right: 30px;
      bottom: 0;
    }
  }
}
