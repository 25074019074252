.raffleLp {
  .raffleLp__banner {

    &__desktopImg {
      width: 100%;

      @media screen and (max-width: 640px) {
        display: none;
      }
    }

    &__mobileImg {
      display: none;
      width: 100%;
      margin-top: 20px;

      @media screen and (max-width: 640px) {
        display: block;
      }
    }
  }

  .raffleLp__section {
    display: flex;
    flex-direction: row;

    &__left {
      max-width: 100%;
      margin: 24px 20px;
    }

    @media screen and (max-width: 900px) {
      flex-direction: column;
    }

    &__left,
    &__right {
      width: 50%;

      @media screen and (max-width: 900px) {
        width: 100%;
      }
    }
    &__left {
      margin-top: 104px;

      @media screen and (max-width: 900px) {
        margin-top: 72px;
      }

      @media screen and (max-width: 640px) {
        margin: 24px 0;
      }

      h1, h2, h3 {
        font-weight: bold;
        line-height: 120%;
      }

      h1 {
        font-size: 44px;
        margin-bottom: 25px;

        @media screen and (max-width: 900px) {
          font-size: 32px;
          line-height: 38px;
        }
      }

      h2 {
        font-size: 30px;
        margin-bottom: 10px;

        @media screen and (max-width: 900px) {
          font-size: 24px;
          line-height: 29px;
          margin-bottom: 20px;
        }
      }

      h3 {
        font-size: 30px;
        margin-top: 32px;

        @media screen and (max-width: 640px) {
          margin-top: 32px;
          font-size: 24px;
          line-height: 29px;
        }
      }

      ul,
      p {
        font-size: 18px;
        line-height: 140%;
        max-width: 85%;

        @media screen and (max-width: 640px) {
          font-size: 16px;
          line-height: 22px;
          max-width: 100%;
        }
      }
    }

    &__right {
      padding: 42px 32px;
      margin-bottom: 30px;
      margin-top: 80px;
      background: #F8F8F9;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);

      @media screen and (min-width: 901px) and (max-width: 1258px) {
        padding: 10px 10px;
      }

      @media screen and (max-width: 640px) {
        margin: 0 -15px;
        padding: 10px;
        width: 100vw;
      }
    }

    iframe {
      width: 100%;
      min-height: 970px;

      @media screen and (max-width: 1400px) {
        min-height: 1070px;
      }
      @media screen and (max-width: 1258px) {
        min-height: 1140px;
      }
      @media screen and (max-width: 890px) {
        min-height: 840px;
      }
      @media screen and (max-width: 700px) {
        min-height: 900px;
      }
      @media screen and (max-width: 600px) {
        min-height: 960px;
      }
      @media screen and (max-width: 500px) {
        min-height: 1050px;
      }
      @media screen and (max-width: 450px) {
        min-height: 1110px;
      }
    }
  }

  .raffleLp__dataProtection {
    &__title {
      background-color: #4E5761;

      h3 {
        color: #FFF;
        font-weight: bold;
        font-size: 26px;
        padding: 25px 0;
        margin: 0;

        @media screen and (max-width: 900px) {
          font-size: 24px;
        }

        @media screen and (min-width: 641px) and (max-width: 900px) {
          margin: 16px 24px;
        }
      }
    }

    &__boxes {
      padding: 40px 0;

      @media screen and (max-width: 900px) {
        padding: 20px 0;
      }

      .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media screen and (max-width: 900px) {
          flex-direction: column;
        }
      }

      &__left,
      &__right {
        width: 50%;
        width: calc(50% - 20px);

        @media screen and (max-width: 900px) {
          width: 100%;
        }
      }

      h4 {
        font-weight: bold;
        font-size: 22px;
        line-height: 120%;

        @media screen and (max-width: 900px) {
          font-size: 20px;
          line-height: 24px;
        }
      }

      p {
        font-size: 18px;
        line-height: 140%;

        @media screen and (max-width: 900px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .raffleLp__mobileButton {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    background: #FFF;

    @media screen and (max-width: 640px) {
      display: block;
    }

    .button-lidl__blue {
      width: 100%;
    }
  }
}