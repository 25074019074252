.nutritional-values-box {
  background-color: #F1EDE4;
  border-radius: 4px;
  padding: 40px 30px;
  margin: 3rem auto 0;

  @include media-breakpoint-down(xs) {
    padding: 30px 15px;
  }

  .nutritional-values-box__title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      text-align: center;
    }
  }

  .nutritional-values-box__member {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .nutritional-values {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .nutritional-values-item {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    margin-bottom: 10px;
    min-height: 50px;

    @include media-breakpoint-down(md) {
      padding-right: 0;
      width: 33.3%;
      align-items: center;
      margin-bottom: 20px;
    }

    @include media-breakpoint-down(xs) {
      padding-right: 0;
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .nutritional-values-item__title {
    font-size: 0.7rem;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .nutritional-values-item__number {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0;
  }

  .member__avatar {
    cursor: pointer;
    margin-right: 1rem;
  }

  .member__avatar:hover, .member__avatar--active {
    border: 2px solid $lidlDarkBlue;
  }
}

.nutritional-values-member {
  margin-top: 20px;

  p {
    margin-bottom: 5px;

    span {
      font-weight: bold;
    }
  }
}

.recipe--healthScore {
  .nutritional-values-container {
    display: flex;
    background: rgba(#0050AA, 0.1);
    border-radius: 2px;
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }

    .nutritional-values-health-score {
      padding: 16px 0;

      min-width: 195px;
      border-left: 1px solid rgba(#353B42, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include media-breakpoint-down(md) {
        padding-top: 25px;
        flex-direction: row;
        border-left: 0;
        min-width: 0;
        border-bottom: 1px solid rgba(#353B42, 0.1);
      }

      img {
        margin-bottom: 10px;
        height: 80px;

        @include media-breakpoint-down(md) {
          margin-right: 30px;
        }
      }

      a {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
        color: #0050AA;
        text-decoration: none;

        span {
          display: inline-block;
          border-bottom: 1px solid #0050AA;
        }

        &:before {
          content: '';
          display: inline-block;
          margin-right: 5px;
          width: 20px;
          height: 20px;
          background-image: url('/layouts/frontend/img/homepage/rettertuteCampaign/help-circle.svg');
          background-size: cover;
        }
      }
    }

    .nutritional-values-box {
      background: none;
      padding: 25px 24px 16px;
      margin: 0;
      min-width: 455px;

      @include media-breakpoint-down(md) {
        min-width: 0;
      }

      .nutritional-values-box__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 26px;
        color: #353B42;
        margin-bottom: 15px;

        @include media-breakpoint-down(md) {
          padding: 5px 0 14px;
          text-align: center;
        }
      }

      .nutritional-values-item {
        padding-right: 25px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          margin-bottom: 30px;
          padding-right: 0;

          &:last-child {
            margin-bottom: 20px;
          }
        }

        .nutritional-values-item__title {
          text-transform: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        .nutritional-values-item__number {
          font-weight: 400;
        }
      }
    }
  }
}