.wizard {
  background: #E5EDF7;
  border-radius: 6px;
  padding: 16px;
  padding-bottom: 10px;
  margin: 10px 0;
  overflow: hidden;
  position: relative;

  @include media-breakpoint-down(xs) {
    margin: 5px -15px 10px;
    padding: 10px 15px 5px 15px;
    border-radius: 0;
    height: 89px;
  }

  &--easter {
    background: #EAF8E5;
  }

  &--personalizedHeader {
    height: 114px;

    .wizard__header {
      display: flex;
      align-items: center;
      height: 100%;

      @include media-breakpoint-down(xs) {
        font-size: 18px;
      }
    }
  }

  &__header {
    font-weight: 600;
    font-size: 18px;
    color: #000000;

    p {
      display: inline;

      strong {
        font-family: 'Lidl Font'; font-weight: bold;
      }
    }

    &__multiple {
      color: rgba(#000, 0.5);
      font-weight: normal;
      margin-left: 10px;

      @include media-breakpoint-down(sm) {
        display: block;
        margin-left: 0;
        padding-top: 5px;
      }
    }

    @include media-breakpoint-down(xs) {
      font-size: 14px;
    }
  }

  &__question {
    padding-top: 12px;
    display: flex;

    @include media-breakpoint-up(sm) {
      overflow: visible;
    }

    .swiper-wrapper {
      flex-wrap: wrap;

      @include media-breakpoint-up(sm) {
        overflow: visible;
      }
    }

    @include media-breakpoint-down(sm) {
      .swiper-wrapper {
        flex-wrap: nowrap;
      }

      margin-bottom: -16px;
      padding-bottom: 16px;
      margin-left: -16px;
      margin-right: -16px !important;
      //padding-left: 16px;
      //padding-right: 16px;
    }

    &__space {
      @include media-breakpoint-down(sm) {
        min-height: 1px;
        min-width: 16px;
        width: 16px !important;

        &:last-child {
          width: 6px !important;
          min-width: 6px;
        }
      }
    }

    &__answer {
      width: auto;
      background: $lidlPrimaryColor;
      border-radius: 23px;
      color: #FFFFFF;
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 8px 16px;
      font-weight: 600;
      cursor: pointer;
      white-space: nowrap;
      display: flex;
      align-items: center;

      .wizard--easter & {
        background: #68D000;
      }

      .iconset--check {
        margin-right: 10px;

        @include media-breakpoint-down(sm) {
          margin-right : 5px;
        }
      }

      p {
        margin-bottom: 0;
      }

      span {
        display: none;
      }

      @include media-breakpoint-down(xs) {
        font-size: 13px;
        padding: 7px 12px;
        margin-right: 10px;
      }

      @include media-breakpoint-up(sm) {
        &--checked,
        &:hover {
          background: #266AB7;
          box-shadow: 0px 10px 25px rgba(38, 106, 183, 0.2);

          .wizard--easter & {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), #68D000;
          }
        }
      }
    }
  }

  &__multipleButton {
    position: absolute;
    bottom: 24px;
    right: 24px;
    width: auto;
    padding: 0 15px;
    margin-right: 0;
    z-index: 2;

    @include media-breakpoint-down(sm) {
      position: static;
      width: 100%;
      margin: 15px 0;
    }
  }
}