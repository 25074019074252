.christmasSection--30 {
  margin-bottom: 50px;
  overflow: hidden;
  background-color: white;

  &.feeds {
    @include media-breakpoint-down(xs) {
     padding: 0;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 0;
  }

  &.bgImageActive {
    .decoration {
      &.decoration1 {
        //&:after {
        //  background-image: url("/layouts/frontend/img/christmas-landing/section3-golden-circle.png")
        //}

        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/christmas-ball.png");
        }
      }

      &.decoration2 {
        &:after,
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section3-snow-small.png");
        }

        span {

          &:after {
            background-image: url("/layouts/frontend/img/christmas-landing/section3-snow-big.png");
          }

          //&:before {
          //  background-image: url("/layouts/frontend/img/christmas-landing/section3-golden-circle-bottom.png");
          //}
        }
      }
    }

    &:after {
      background-image: url("/layouts/frontend/img/christmas-landing/christmas-twig.png");

    }
  }

  .feedSection {

    &.feedSection--withBauble {
      &:after {
        right: 25%;
        left: initial;
      }
    }

    &__title {
      max-width: 85% !important;
    }
    &--section3 {
      background-color: white !important;
      margin-top: 95px;
      @include media-breakpoint-down(xs) {
        margin-top: 20px;
      }
    }

    &--section4 {
      background-color: white !important;
      @include media-breakpoint-down(xs) {
        margin-bottom: 0;
      }
    }
  }

  .decoration {
    @media (max-width: 1340px) {
      display: none;
    }

    &:after,
    &:before {
      content: '';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.decoration1 {
      position: relative;

      //&:after {
      //  width: 230px;
      //  height: 580px;
      //  top: 1083px;
      //  right: 0px;
      //}

      &:before {
        width: 62px;
        height: 55px;
        top: 100px;
        right: 50px;
      }
    }

    &.decoration2 {

      &:after,
      &:before {
        width: 45px;
        height: 42px;
      }

      &:after {
        top: 840px;
        right: 108px;
      }

      &:before {
        top: 1450px;
        left: 108px;
      }

      span {

        &:after {
        //&:before {
          content: '';
          display: block;
          position: absolute;
          background-repeat: no-repeat;
          background-size: contain;
        }

        &:after {
          width: 100px;
          height: 95px;
          top: 1550px;
          left: 52px;
        }

        //&:before {
        //  width: 240px;
        //  height: 559px;
        //  top: 230px;
        //  left: 0px;
        //}
      }
    }
  }

  &:after {
    content: '';
    display: block;
    width: 400px;
    height: 300px;
    position: absolute;
    top: 0;
    right: 34px;
    z-index: 16;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 1260px) {
      right: 0;
    }

    @media (max-width: 1170px) {
      right: -134px;
    }

    @media (max-width: 910px) {
      width: 322px;
      right: -30px;
      z-index: 1;
      top: -27px;
      display: none;
    }

    @media (max-width: 520px) {
      width: 235px;
      right: -80px;
      z-index: 1;
      top: -58px;
    }

    @media (max-width: 330px) {
      right: -90px;
    }

    @media (min-width: 1750px) {
      right: 150px;
    }
    @media (min-width: 2050px) {
      right: 350px;
    }
  }

  .feedSection.feedSection--section95.feedSection--withBauble:after {
    right: 25%;
    left: auto;
  }

  &.bgImageActive {
    .feedSection {
      &--section95 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section95/section95-photo.jpg");
        }
      }

      &--section4 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section3/section3-photo2.jpg");
        }
      }
    }
  }
}
