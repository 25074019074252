.unsubscribe-landing {
  .textLanding {
    text-align: left;
    &:after {
      background-image: url("/layouts/frontend/img/newsletter-landing/newsletter-versmissen.png");
    }
    .text {
      p {
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
