// mobile-first

.sallys-landing {
  &__widget {
    display: none;

    @include media-breakpoint-down(xs) {
      height: 130px !important;
    }

    &.shopping-list {

      .sallys-landing {
        &__widget {

          &__box {
            background: white;
            position: relative;
          }
          &__face {
            background: url('/layouts/frontend/img/sally-landing/sallys-landing-widget-phone.png');
            background-size: cover;
            width: 117px;
            height: 171px;
          }

          &__text {
            position: absolute;
            font-weight: 600;
            font-size: 26px;
            line-height: 115%;
            color: #353B42;
            max-width: 42%;
            text-align: center;
            right: 33px;
            top: 16px;
            background: none !important;
            text-indent: initial;

            @include media-breakpoint-down(xs) {
              max-width: 100%;
              font-size: 20px;
              right: 43px;
            }
          }

          &__button {
            color: #0050AA;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;

            @include media-breakpoint-down(xs) {
              width: 98px;
              padding: 10px 12px;
              left: 70%;
            }

            &:before {
              content: '';
              background-image: url("/layouts/frontend/img/svg/sally-landing/arrow-widget-sally.svg");
              width: 51px;
              height: 51px;
              background-repeat: no-repeat;
              background-size: contain;
              display: block;
              position: absolute;
              right: -56px;
              top: -8px;

              @include media-breakpoint-down(xs) {
                right: -78px;
              }
            }
          }
        }
      }
    }

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 115px;
    background: #FFF;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
    z-index: 999;

    @include media-breakpoint-up(sm) {
      display: block;
      top: 50%;
      right: -250px;
      left: auto;
      margin-top: -125px;
      width: 250px;
      height: 200px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      transition: 500ms right;
    }

    &--visible {
      display: block;

      @include media-breakpoint-up(sm) {
        right: 0;

        .sallys-landing__widget__sidebar .iconset--arrowRight {
          transform: rotate(90deg);
        }
      }
    }

    &__sidebar {
      display: none;

      @include media-breakpoint-up(sm) {
        background: $lidlPrimaryColor;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        padding: 0;
        transform: rotate(-90deg);
        position: absolute;
        bottom: 0;
        right: 50px;
        width: 200px;
        height: 56px;
        transform-origin: 0 100%;
        border-radius: 4px 4px 0 0;

        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;

        .iconset--arrowRight {
          display: block;
          transform: rotate(-90deg);
          margin-left: 10px;
        }
      }

    }

    &__box {
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba(#E5007D, 0.5) -10%, rgba(255, 255, 255, 0.5) 100%);

      @include media-breakpoint-up(sm) {
        background: linear-gradient(360deg, rgba(#E5007D, 0.5) -10%, rgba(255, 255, 255, 0.5) 100%);
      }
    }

    &__face {
      position: absolute;
      left: 0;
      top: -30px;
      display: block;
      width: 144px;
      height: 142px;
      background: url('/layouts/frontend/img/sally-landing/sally-widget-face.png');

      @include retina {
        background-size: 144px 142px;
        background-image: url('/layouts/frontend/img/sally-landing/sally-widget-face@2x.png');
      }

      @include media-breakpoint-up(sm) {
        background: url('/layouts/frontend/img/sally-landing/sally-widget-face-desktop.png');
        width: 172px;
        height: 186px;
        top: auto;
        bottom: 0;

        @include retina {
          background-size: 172px 186px;
          background-image: url('/layouts/frontend/img/sally-landing/sally-widget-face-desktop@2x.png');
        }
      }
    }

    &__text {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 201px;
      height: 86px;
      background: url('/layouts/frontend/img/sally-landing/sally-widget-text.png');
      background-size: cover;
      text-indent: -9999px;

      @include media-breakpoint-up(sm) {
        width: 153px;
        height: 133px;
        background: url('/layouts/frontend/img/sally-landing/sally-widget-text-desktop.png');
        right: 0;
        bottom: 40px;
        top: auto;

        @include retina {
          background-size: 153px 133px;
          background-image: url('/layouts/frontend/img/sally-landing/sally-widget-text-desktop@2x.png');
        }
      }
    }

    &__button {
      position: absolute;
      bottom: 8px;
      left: 50%;
      margin-left: -72px;
      padding: 10px;
      width: 144px;
      display: flex;
      justify-content: center;

      background: #FFFFFF;

      border: 1px solid $lidlPrimaryColor;
      box-sizing: border-box;
      border-radius: 2px;

      img {
        height: 18px;
      }

      @include media-breakpoint-up(sm) {
        width: 128px;
        margin-left: -64px;
      }
    }

    &__close {
      position: absolute;
      right: 2px;
      top: 2px;
      padding: 10px;
      cursor: pointer;

      @include media-breakpoint-up(sm) {
        display: none;
      }

      .iconset--cross {
        width: 14px;
        height: 14px;
      }
    }
  }
}