.openRateBanner {

  &.popup--open {
    @include media-breakpoint-down(xs) {
      display: block !important;
    }
  }


  .close-popup-btn {
    @include media-breakpoint-down(xs) {
      display: block !important;
    }
  }

  &__header {
    margin-top: 25px;

    h3 {
      @include media-breakpoint-down(xs) {
        font-size: 22px;
        line-height: 120%;
      }
    }
  }

  .close-popup-btn {
    background-image: url("/layouts/frontend/img/svg/closeModal.svg");
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: cover;
    &:before,
    &:after {
      display: none;
    }

    @include media-breakpoint-down(xs) {
      display: block !important;
    }
  }

  .popup__content {
    width: 600px;
    @include media-breakpoint-down(xs) {
      min-width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      max-height: 80vh;
      bottom: 0;
      margin-top: 200px !important;
      padding: 32px 31px !important;
    }
  }

  &__yourRate {
    @include media-breakpoint-down(xs) {
      display: none;

    }
  }

  .inputRate {
    border: none;
    border-bottom: 1px solid;
    font-size: 18px;
    line-height: 150%;
    padding-bottom: 5px;
    padding-left: 10px;
    width: 100%;
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      margin-bottom: 30px;
    }
  }

  .openRateBanner__header {
    h3 {
      @include media-breakpoint-down(xs) {
        font-size: 26px;
        margin-top: 20px;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    min-height: 0;
    margin-top: -187px !important;
    display: none;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: #0050AA;
    }

    button {
      font-size: 14px;
      line-height: 17px;
      margin-top:  0 !important;
      font-family: 'Lidl Font'; font-weight: bold;
      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-down(xs) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}