.sallys-landing {
  &__section3 {

    &.bgImageActive {

      .sallys-landing__section3 {
        &__content--right {
          div {
            span {
              background-image: url("/layouts/frontend/img/sally-landing/sally-section3.png");
            }
          }
        }
        &__banner {
          background-image: url("/layouts/frontend/img/sally-landing/newsletter-banner.png");
          @include media-breakpoint-down(sm) {
            background-image: url("/layouts/frontend/img/sally-landing/newsletter-mobile.png");
          }

          &--planner {
            background-image: url("/layouts/frontend/img/sally-landing/planner-banner-sally_new.png");
            @include media-breakpoint-down(sm) {
              background-image: url("/layouts/frontend/img/sally-landing/planner-banner-sally-mobile_new.png");
            }
          }

          &--rezeptkarten {
            margin-top: 30px;
            display: flex;
            align-items: center;
            background-image: url("/layouts/frontend/img/sally-landing/rezeptkarten_desktop.png");

            @include media-breakpoint-down(sm) {
              background-image: url("/layouts/frontend/img/sally-landing/rezeptkarten_mobile.png");
            }
          }
        }
      }
    }

    padding: 56px 0;
    background-color: #F8EBF1;

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
    }
    &__box {
      display: flex;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex-direction: column;
      }
    }
    &__content {

      &--left {
        width: 56%;
        margin-right: 100px;

        @media (max-width: 1400px) {
          margin-right: 50px;
        }

        @include media-breakpoint-down(sm) {
          width: 100%;
          margin-right: 0;
        }
      }
      &--right {

        h3 {
          @include media-breakpoint-down(xs) {
            font-size: 32px;
            margin-bottom: 24px;
          }
        }


        .text {
          display: flex;
          flex-direction: column;
          max-width: 56%;
          align-items: flex-start;

          p {
            margin-bottom: 0;
          }
        }
        div {

          span {
            width: 211px;
            height: 240px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            right: -30px;

            @include media-breakpoint-down(sm) {
              right: 0;
              width: 120px;
              height: 136px;
            }
          }

          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: flex-end;

          @include media-breakpoint-down(xs) {
            margin-bottom: 30px;
          }

        }
      }
    }

    &__banner {
      display: flex;
      min-height: 330px;
      background-color: white;
      border: 1px solid $sallysPink;
      background-repeat: no-repeat;
      background-size: 1268px 330px;
      background-position-x: 80%;

      @include media-breakpoint-down(sm) {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding: 230px 20px 20px;
        background-size: cover;
        background-position-x: center;
      }

      &--planner {
        margin-bottom: 32px;
        .sallys-landing__section3__banner__text {
          ul {
            padding-left: 20px;
          }
        }
      }


      &__text {
        max-width: 44%;
        padding: 24px 40px;

        @media (max-width: 1400px) {
          padding: 15px 40px;
        }

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }

        @include media-breakpoint-down(xs) {
          padding: 0;
        }

        h3 {
          font-weight: 600;
          font-size: 30px;
          line-height: 120%;
          margin-bottom: 16px;

          @media (max-width: 1400px) {
            font-size: 28px !important;
          }

          @include media-breakpoint-down(xs) {
            font-size: 28px;
          }
        }

        p {
          font-size: 18px;
          line-height: 140%;
          margin-bottom: 24px;
          @include media-breakpoint-down(xs) {
            font-size: 16px;
          }
        }

        a {
          white-space: nowrap;
          width: 220px;
          @include media-breakpoint-down(xs) {
            width: 100%;
          }
        }
      }
    }

    &__shoppingListDesktopBanner {
      margin-bottom: 20px;
      margin-right: 16px;
      width: 50%;
      width: calc(50% - 16px);

      order: 2;
      display: none;
      color: #FFF;
      padding: 20px 275px 20px 25px;

      background-color: $lidlPrimaryColor;
      background-image: url("/layouts/frontend/img/sally-landing/shoppingListDesktopBanner_new.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 280px 311px;

      min-height: 300px;

      @include media-breakpoint-up(sm) {
        display: block;
      }

      &__head {
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      &__text {
        font-size: 16px;
        line-height: 23px;
        padding-left: 20px;

        li {
          margin-bottom: 5px;
        }
      }

      &__button {
        margin-top: 10px;

        a {
          width: auto;
          float: left;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      .feedElement__recipes {
        &__recipe[data-index="6"],
        &__recipe[data-index="7"],
        &__recipe[data-index="8"],
        .feedElement__moreLink,
        &__recipe--lastSpace {
          order: 3;
        }
      }
    }

    &__shoppingListMobileBanner {
      display: none;

      margin-bottom: 32px;

      background-color: $lidlPrimaryColor;
      background-image: url("/layouts/frontend/img/sally-landing/shoppingListMobileBanner_new.png");
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 304px 180px;

      padding: 16px;

      @include media-breakpoint-down(sm) {
        display: block;
      }

      &__head {
        padding-top: 180px;
        font-size: 24px;
        color: #FFF;
        font-weight: bold;
        line-height: 28px;
      }

      &__button {
        margin-top: 15px;

        a {
          width: 100%;
          color: #353B42;
        }
      }
    }
  }

}
