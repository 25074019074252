#userNamePopup {
  .popup__content {
    padding: 0;
    text-align: left;
    width: 760px;
    padding-right: 361px;

    background-image: url('/layouts/frontend/img/username-popup/usernamePopupDesktop@2x.jpg');
    background-repeat: no-repeat;
    background-size: 361px 434px;
    background-position: top right;

    @media (max-width: 800px) {
      top: 50px;
      margin-top: 0;

      width: 320px;
      padding-right: 0;
      padding-top: 150px;
      background-image: url('/layouts/frontend/img/username-popup/usernamePopupMobile.jpg');
      background-size: 320px 150px;
      background-position: top left;
    }
  }

  .popup__close {
    background: url('/layouts/frontend/img/username-popup/x.svg') no-repeat;
    background-position: center;
    width: 31px;
    height: 31px;
    opacity: 1;
    top: 12px;
    right: 12px;

    @media (max-width: 800px) {
      top: 0;
      right: 0;
      background-size: 14px 14px;
    }

    &:before, &:after {display: none;}
  }

  .popup__text {
    padding: 30px;

    @media (max-width: 800px) {
      padding: 24px;
    }
  }

  .userNamePopup__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;

    @media (max-width: 800px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .userNamePopup__content {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    margin-top: 16px;

    p {
      margin: 12px 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: rgba(0, 80, 170, 1);
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
}