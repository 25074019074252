.person {
  height: 40px;
  line-height: 40px;
  width: 185px;
  display: inline-block;
  padding-left: 25px;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-right: 20px;
  position: relative;
  background-color: $lightGray;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:not(:last-child) {
    margin-righ: 15px;
  }
}

.person--completed {
  @extend .person;
  background-color: $green;
}

.person--active {
  @extend .person;
  &:after {
    border-color: black;
  }
}

.person__progress {
  background-color: $blue;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}

.person__text {
  position: relative;
  z-index: 1;
}

.person__ico {
  position: absolute;
  top: 0;
  cursor: pointer;
  z-index: 5;
}

.person__ico--edit {
  @extend .person__ico;
  right: 32px;

}

.person__ico--remove {
  @extend .person__ico;
  right: 10px;
}