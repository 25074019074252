.promo-banner {
  background: #f6f6f6;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  &__thumbnail {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    background: #0050AA;
    position: relative;
    overflow: hidden;
    max-width: 740px;

    @media (max-width: 833px) {
      width: 100%;
    }
  }
  &__videoBox {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100%;
    padding: 20px 20px 0 20px;
    position: relative;

    @media (max-width: 833px) {
      min-height: 358px;
      padding: 8px 8px 0 8px;
    }

    @media (max-width: 540px) {
      min-height: 144px;
    }
  }

  &__video {
    width: 100%;
    z-index: 1001;

    > div {
      position: relative;
      padding-bottom: 54.25%;

      @media (max-width: 833px) {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-family: 'Lidl Font';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 120%;
    margin-bottom: 0;
    margin-right: 20px;

    @media (max-width: 833px) {
      margin: 10px 0 20px 0;
    }
  }

  &__desc {
    min-width: 740px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    z-index: 1001;

    @media (max-width: 833px) {
      padding: 8px;
    }

    p {
      font-weight: 600;
      font-size: 30px;
      line-height: 120%;

      @media (max-width: 833px) {
        font-size: 20px;
        line-height: 120%;
      }
    }

    @media (max-width: 833px) {
      flex-direction: column;
      min-width: 100%;
    }
  }

  &__btn {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    color: #0050AA;
    background-color: #FFFFFF;
    padding: 13px 16px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;

    @media (max-width: 833px) {
      width: 100%;
    }
  }

  &__yt {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 37px;
    left: 0;
    background: url('/layouts/frontend/img/sally-landing/new-header-yt.png') no-repeat center;
    background-size: 123px 86px;
    z-index: 1002;

    &.hide {
      display: none;
    }

    @media (max-width: 833px) {
      background: url('/layouts/frontend/img/sally-landing/yt-sally.png') no-repeat center;
      border-radius: 6px;
      width: 64px;
      height: 45px;
      background-size: contain;
      left: 50%;
      top: 62%;
      transform: translate(-50%, -50%);
    }
  }

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider__videoButton {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 700px;
    width: 100%;
    transition: 0.25s all;
    margin: 20px;

    @media (max-width: 833px) {
      left: 0;
      transform: translateX(0);
    }

    &--visible {
      visibility: visible;
      opacity: 1;
    }
  }
}
