.search-form {
  display: flex;
  position: absolute;
  left: 133px;
  top: 50%;
  transform: translateY(-50%);

  @media (max-width: 1150px) {
    left: 15%;
  }

  @media (max-width: 900px) {
    left: 18vw;
  }

  @media (max-width: 639px) {
    top: 60px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 5px;
    transform: none;
    background-color: white;
  }
}

.search-form.active {
  .search-form__btn {
    transition: 0.15s;
    // background: $lidlPrimaryColor_08;
    background-color: #0050AA;
    cursor: pointer;

    .search-form__icon-search {
      fill: #ffffff;
    }
  }

  .search-form__btn-close {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in 0.6s;
  }
}

.search-form__input {
  height: 40px;
  border: 1px solid #CBD1D6;
  background: white;
  width: 500px;
  font-size: 16px;
  line-height: 1rem;
  padding: 4px 40px 4px 14px;
  border-radius: 100px;

  &:hover,
  &:active,
  &:focus {
    border-color: #0050AA;
  }

  &::placeholder {
    color: #616E79;
    font-weight: 400;
  }

  @media (max-width: 1100px) {
    width: 400px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
}

.search-form__icon-search {
  fill: #212121;
}

.search-form__btn {
  width: 40px;
  height: 40px;
  padding: 10px;
  border: none;
  margin-left: -40px;
  // background-color: $lidlPrimaryColor;
  background-color: transparent;
  transition: box-shadow 200ms linear;
  border-radius: 100px;
  transition: 0.15s;
  cursor: default;
}

.search-form__btn-close {
  visibility: hidden;
  width: 24px;
  height: 24px;
  background-color: #E4E7E9;
  cursor: pointer;
  border: none;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -12px;
  margin-right: 48px;

  opacity: 0;
  transition: 0.15s ease-out 0s;

  &__icon {
    width: 11px;
    height: 11px;
    fill: #3F444B;
  }
}

.autocomplete-suggestions {
  overflow: auto;
  min-width: 160px;
  margin-top: 6px;
  text-align: left;
  background-color: #ffffff;
  font-size: 16px;
  border: 0;
  box-shadow: 0px 2px 10px 0px #0000001A;
  background-clip: padding-box;
  border-radius: 8px;

}

.autocomplete-suggestion {
  cursor: pointer;
  padding: 6px 13px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #212121;
  transition: 0.05s;
  white-space-collapse: preserve;
}

.autocomplete-suggestion span.normal-text {
  font-weight: 400;
}

.autocomplete-suggestion strong {
  color: #212121;
  font-weight: 400;
}

.autocomplete-suggestion img.label {
  margin-left: 5px;
}

.autocomplete-suggestion img.recipe-image {
  width: 30px;
  height: 24px;
  border-radius: 2px;
  background-color: #ACB0B4;
  margin-right: 6px;
}

.autocomplete-suggestion .recipe-name {
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.autocomplete-selected {
  color: #FFFFFF;
  background: #0050AA;
}

.autocomplete-selected strong {
  color: #FFFFFF;
}

.autocomplete-group {
  padding: 6px 13px 3px 13px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #7C8994;
  border-top: 1px solid #E4E7E9;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}