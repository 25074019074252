@import "sally-landing/sally-section1";
@import "sally-landing/new-header";
@import "sally-landing/sally-section2";
@import "sally-landing/sally-section21";
@import "sally-landing/sally-section3";
@import "sally-landing/sally-section4";
@import "sally-landing/sally-section5";
@import "sally-landing/sally-section6";
@import "sally-landing/sally-section7";
@import "sally-landing/sally-widget";

.sallys-landing {
  margin-bottom: -70px;

  @media (max-width: 640px) {
    overflow: hidden;
  }

  .container {
    @media (min-width: 1200px) and (max-width: 1400px) {
      max-width: 1200px // (1216 - 2*20)px;
    }
    @media (min-width: 1000px) and (max-width: 1200px) {
      max-width: 95%;
      padding: 0 20px;
    }
  }


  &.page-top-margin {
    margin-top: 0;
    margin-bottom: -70px;

    @media (max-width: 640px) {
      margin-top: 110px;
    }
  }


  .breadcrumb__list {

    &:after {
      @media (max-width: 639px) {
        display: none;
      }
    }
    @media (max-width: 639px) {
      padding-top: 0;}
  }

  h3, p, span {
    color: #353B42;
  }

  h3 {
    font-weight: 600;
    font-size: 34px;
    line-height: 120%;
    margin-bottom: 30px;

    @include media-breakpoint-down(xs) {
      font-size: 28px !important;
      line-height: 120% !important;;
      margin-bottom: 16px !important;
    }
  }

  p {
    font-size: 18px;
    line-height: 140%;
  }

}
