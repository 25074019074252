.profiling__ingredient {
  min-height: 30px;
  color: white;
  font-size: 0.67em;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px 6px 10px;
  text-transform: uppercase;
  margin-right: 15px;
  margin-bottom: 15px;
  position: relative;
  background-color: $lidlDarkBlue;
}

.profiling__ingredient-remove {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}

.profiling__ingredient-input {
  background-color: white;
}

.selectize-control.single .selectize-input:after {
  display: none !important;
}

.selectize-control .selectize-input input {
  min-width: 100%;
}

.profiling__excludedIngredientsWarning {
  color: #D62631;
  text-align: left;
  margin-top: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
