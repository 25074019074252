.shoplist-landing-main {
  &.section1 {

    display: flex;
    flex-direction: row;
    height: 480px;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;
      margin-top: 30px;
      height: 560px;
    }

    @media (max-width: 639px) {
      margin-top: 120px;
    }


    &:after {
      content: '';
      width: 320px;
      height: 464px;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-image: url("/layouts/frontend/img/shoplist-landing/section1-phone.png");
      background-size: contain;
      background-repeat: no-repeat;

      @include media-breakpoint-down(sm) {
        background-image: url("/layouts/frontend/img/shoplist-landing/section1-phone-mobile.png");
        width: 236px;
        bottom: 0;
        height: 272px;
        top: initial;
        transform: translate(-50%, 0);
      }

    }

    .shoplist-section1 {

      &__rightBox-photo {
        background-image: url("/layouts/frontend/img/shoplist-landing/section1-background2.png");

        background-size: cover;
        background-repeat: no-repeat;
        width: 50%;
        height: 100%;
        position: absolute;
        left: 50%;
        right: 0;
        top: 0;
        bottom: 0;
        background-position-y: top;
        z-index: 0;

        @include media-breakpoint-down(sm) {
          background-position: 100%;
          left: 0;
          width: 100%;
          background-image: url("/layouts/frontend/img/shoplist-landing/section1-background2-mobile.png");
        }


        &:after {
          position: absolute;
          top: 0px;
          left: 0;
          content: "";
          width: 500px;
          height: 100%;
          background: linear-gradient(90deg, #fff, rgba(255, 255, 255, 0));

          @include media-breakpoint-down(sm) {
            display: none;
          }
        }
      }


      &__box {
        max-width: 36%;

        a {
          width: 201px;
          margin-right: 0;
        }

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }

        h3 {
          font-weight: 600;
          margin-bottom: 40px;
          font-size: 48px;
          line-height: 120%;

          @include media-breakpoint-down(sm) {
            font-size: 40px;
            line-height: 120%;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}
