.single-item {
  margin-bottom: 50px;
  position: relative;
  width: 23%;

  &__labelPromo {
    position: absolute;
    width: 55px;
    height: auto;
    left: 10px;
    bottom: 10px;
  }

  &:not(:nth-child(4n)) {
    margin-right: 2.66%
  }

  &__box {
    display: flex;
    flex-direction: row-reverse;
  }

  &__go-live-data {
    font-size: 1.2em;
    margin-right: 30px;
    text-shadow: 0px 0px 10px #000;
  }

  @include media-breakpoint-down(sm) {
    width: 48%;

    &:not(:nth-child(4n)) {
      margin-right: 0%
    }
    &:not(:nth-child(2n)) {
      margin-right: 2%;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    &:not(:nth-child(2n)) {
      margin-right: 0;
    }
  }
}

.single-item__top-wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
}

.item__owner {
  font-weight: 700;
  margin-bottom: 15px;

  white-space: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item__owner-link {
  color: black;
  &:hover {
    text-decoration: none;
    color: black;
  }
}

.item__owner-avatar {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 20px;
  max-height: 40px;
  border: 1px solid;
  float: left;
  position: relative;
}

.single-item__image {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}

.single-item__image--content {
  background: -webkit-linear-gradient(127deg, rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 32.9%);
  background: -o-linear-gradient(127deg, rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 32.9%);
  background: linear-gradient(323deg, rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 32.9%);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 15px;
  color: white;
  position: relative;
}

.single-item__likes {
  cursor: pointer;
  background: $white;
  border-radius: 4px;
  color: $lidlVeryDarkBlue;
  padding: 0 0 0 13px;
  font-size: 0.8rem;
  font-weight: bold;
  min-width: 65px;
  height: 35px;
  text-align: center;
  font-family: 'Lidl Font'; font-weight: bold;
  display: flex;
  justify-content: inherit;
  align-items: center;

  &:hover {
    .heart-clip {
        -webkit-animation: pulse .6s .3s infinite;
        animation: pulse .6s .3s infinite;
        &::before {
          -webkit-transform: scale(1);
          transform: scale(1);
          opacity: 1;
        }
      }
  }
}

.single-item__likes--plan {
  padding: 0;
  min-width: 40px;
}

.single-item__prep-time {
  font-size: 1.2em;
}

.single-item__name {
  font-weight: 600;
  border: 1.5px solid $gray_242;
  padding: 15px 16px;
  height: 80px;
  line-height: normal;
  border-top: none;
  font-size: 1.1rem;
  overflow: hidden;

  a {
    /*! autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
    overflow: hidden;
  }
}

.single-item__description {
  max-height: 3em;
  overflow: hidden;
}

.single-item.search-item {
  width: 30%;

  &:not(:nth-child(4n)) {
    margin-right: 0%;
  }
  &:not(:nth-child(3n)) {
    margin-right: 5%;
  }

  @include media-breakpoint-down(md) {
    width: 48%;

    &:not(:nth-child(3n)) {
      margin-right: 0%;
    }
    &:not(:nth-child(2n)) {
      margin-right: 4%;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    &:not(:nth-child(2n)) {
      margin-right: 0;
    }
  }
}

.single-item.main-page {
  margin-bottom: 25px;
  margin-right: 15px;
  margin-left: 15px;
  width: 21%;

  @include media-breakpoint-down(sm) {
    width: 48%;

    &:not(:nth-child(4n)) {
      margin-right: 0%
    }
    &:not(:nth-child(2n)) {
      margin-right: 2%;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    margin-left: 0;

    &:not(:nth-child(2n)) {
      margin-right: 0;
    }
  }
}


.single-item.single-item--recipe {
  margin: 0 0 25px 0;
  width: 25%;

  @include media-breakpoint-down(sm) {
    width: 48%;

    &:not(:nth-child(4n)) {
      margin-right: 0%
    }
    &:not(:nth-child(2n)) {
      margin-right: 2%;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
    &:not(:nth-child(2n)) {
      margin-right: 0;
    }
  }
}

.single-item__prep-icon {
  content: '';
  background-image: url('/layouts/frontend/img/homepage_de/time_de_icon.svg');
  width: 20px;
  height: 17px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.single-item__likes-icon {
  content: '';
  background-image: url('/layouts/frontend/img/homepage_de/likes_de_icon.svg');
  width: 13px;
  height: 11px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
}

.single-item {
  &__labels {
    position: absolute;
    left: 15px;

    &__label {
      padding: 0 16px;
      line-height: 14px;
      text-transform: uppercase;
      color: #000;
      font-size: 0.8rem;
      font-weight: 600;
      height: 35px;
      text-align: center;
      display: flex;
      align-items: center;
      background: #DEEBE3;
      border-radius: 18px;

      @include media-breakpoint-down(xs) {
        height: 32px;
        border-radius: 16px;
      }

      &--video {

      }
      &--weekRecipe {
        background: #FFEA4F;
        box-shadow: 0px 0px 1px rgba(33, 33, 33, 0.04), 0px 2px 6px rgba(33, 33, 33, 0.08), 0px 16px 24px rgba(33, 33, 33, 0.1), 0px 24px 32px rgba(33, 33, 33, 0.14);
        padding: 0 11px;
        font-size: 12px;
        font-family: 'Lidl Font'; font-weight: bold;
        position: relative;
        left: -5px;
        min-height: 32px;
      }
    }
  }
}






.single-item--recipeSlider {
  margin: 0;
  //width: calc(100% / 6) !important;
  padding: 0 10px;

  @media (max-width: 768px) {
    width: 80%;
    max-width: 200px;
  }

  .single-item__name {
    padding: 12px;
    font-size: 16px;
    border-width: 1px;
    height: 65px;
    margin-bottom: 0;
    position: relative;
    overflow: hidden;

    a {
      text-overflow: ellipsis;
      height: auto;
      max-height: 100%;
    }

    &:after {
      content: "";
      text-align: right;
      position: absolute;
      bottom: 16px;
      right: 0;
      width: 20%;
      height: 1em;
      //background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
    }
  }

  .single-item__image {
    height: 140px;
    border-radius: 4px 4px 0 0;
  }
}

#vue-search {
  .single-item__image--content {
    overflow: hidden;

    .single-item__imageElement {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      min-height: 100%;
      min-width: 100%;

      object-fit: cover;
    }

    a {
      display: block;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: -webkit-linear-gradient(127deg, rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 32.9%);
        background: -o-linear-gradient(127deg, rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 32.9%);
        background: linear-gradient(323deg, rgba(0, 0, 0, 0.5) 7%, rgba(0, 0, 0, 0) 32.9%);
      }
    }

    .single-item__labels {
      z-index: 30;
    }

    .single-item__likes {
      position: absolute;
      top: 15px;
    }
  }

  .single-item__info {
    position: relative;

    .single-item__name {
      a {
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
