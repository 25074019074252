.christmas-view {
  .recipe__header {
    font-weight: normal;
  }

  .iconset {
    &--arrow {
      background: url('/layouts/frontend/img/svg/chevron_left_black.svg') no-repeat;

    }
  }

  &.christmas-landing__down-section--last {
    margin-bottom: -70px;
  }

  .weekRecipe {
    &__sidebar {
      background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
      color: black;
    }
    &__box {
      &__button {
        border: solid 3px #F5D862 !important;
      }
    }
  }

  .recipe__bannerPlaner {
    display: none;
  }


  &.christmas-background {
    .christmasSection__snowBg {
      &:after {
        background-image: url('/layouts/frontend/img/christmas-landing/recipe-snow2x-2.png') !important;
        background-size: 1920px 508px !important;

        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }

  .container {
    position: relative;
    z-index: 15;
  }

  .row.breadcrumb {
    display: none;
    @include media-breakpoint-down(xs) {
      display: block;
    }
  }

  .heart-clip {
    &:before {
      background-color: #F5DF73;
    }
    &:hover {
      &:before {
        background-color: #F5DF73;
      }
    }
  }

  .christmasSection__snowBg:after {
    background-image: url('/layouts/frontend/img/christmas-landing/desktop-background.png') !important;
    background-size: 1920px 2335px !important;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .iconset--pan_blue {
    background: url(/layouts/frontend/img/svg/pan_black.svg) no-repeat;
    height: 29px;
  }

  .recipe__cookingMode {
    color: #FFF;
    background-clip: padding-box;
    border: solid 5px transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px 11px;
      background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);

      @include media-breakpoint-down(sm) {
        margin: 6px 11px;
      }
    }
  }

  .single-item__name {
    background-color: white;
  }

  .related-recipes-list {
    @include media-breakpoint-down(xs) {
      position: relative;
      padding-bottom: 20px;
    }
    &:before {
      @include media-breakpoint-down(xs) {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 86px;
        height: 77px;
        left: -14px;
        bottom: 11px;
        background-image: url("/layouts/frontend/img/christmas-landing/cone.png");
      }
    }
  }

  .promotionBanner {

    .recipe-banner--shoppinglistBanner {
      border-width: 5px;
      border-radius: initial;
      border-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg") 50 round;
    }
  }


  .member {
    position: relative;

    .member__portions {
      z-index: 4;
      border-color: transparent;
      border-width: 3px;
    }

    &:after {
      position: absolute;
      height: 46px;
      width: 156px;
      top: -3px;
      right: -3px;
      left: -3px;
      bottom: -3px;
      content: '';
      border-radius: 100px;
      background: rgb(223, 183, 75);
      background-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg");
      z-index: 3;
    }
  }

  .christmas {
    &-banner {

      margin-top: 100px;

      &__box {
        background-image: url("/layouts/frontend/img/christmas-landing/recipe-banner.jpg");
        background-repeat: no-repeat;
        height: 100%;
        min-height: 280px;
        width: 100%;
        background-size: cover;
        padding: 38px 50px;
        background-position: top;
        display: flex;
        border-radius: 10px;

        @include media-breakpoint-down(sm) {
          background-image: url("/layouts/frontend/img/christmas-landing/recipe-banner-mobile.jpg");
          flex-wrap: wrap;
          padding: 230px 20px 20px;
          flex-direction: column;
        }

        @include media-breakpoint-down(xs) {
          padding: 230px 20px 20px;
        }
      }

      &__data {
        display: flex;
        flex-direction: column;
        flex: 1;

        &--left {

          @media (max-width: 1400px) {
            padding-top: 20px;
          }


          h2 {
            font-size: 48px;
            line-height: 120%;
            max-width: 73%;

            @include media-breakpoint-down(xs) {
              font-size: 32px;
              line-height: 120%;
              max-width: 100%;
            }
          }

          p {
            font-size: 20px;
            line-height: 160%;
            margin-top: 20px;
            max-width: 88%;

            @include media-breakpoint-down(xs) {
              font-size: 14px;
              line-height: 160%;
              max-width: 100%;
            }

          }
        }

        &--right {
          align-items: center;
          justify-content: center;

          button {
            border: 2px solid #FFFFFF;
          }
        }
      }
    }
  }

  #reng-prod {
    color: white;
  }

  .page-top-margin--with-stash.recipe {
    margin-top: 0;
  }

  .recipe {

    &-tag-link {
      color: #CDB341;
      background: rgba(244, 192, 38, 0.1);
      font-family: 'Lidl Font'; font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      transition: none;
    }


    &__pdfFirstPage {
      background: url("/layouts/frontend/img/christmas-landing/background-photo.jpg") no-repeat;
      background-size: cover;
      background-position-x: 65%;
      padding: 40px 0;
      position: relative;

      @include media-breakpoint-down(sm) {
        background-position-x: left;
      }

      @include media-breakpoint-down(xs) {
        background: url("/layouts/frontend/img/christmas-landing/background-photo-mobile.jpg") no-repeat;
        background-size: cover;
      }

      .recipe-action {
        &__iconText {
          color: white;
        }
      }

      .rating-average__rate {
        color: white;
      }

      .action-container {
        display: flex;
        margin-top: 30px;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;

        &__column {
          a, div {
            i {
              svg {
                path {
                  fill: #F5DF73;
                }
              }
            }
          }
          div {
            div {
              svg {
                path {
                  fill: #F5DF73;
                }
              }
            }
          }
        }
      }
    }

    .recipe__smallName {
      @media (max-width: 400px) {
        max-width: 88%;
      }
    }

    &__firstSection {
      color: white;
      margin-bottom: 0;
    }

    &__header {
      font-family: 'Norican', cursive;
      font-size: 48px;
      line-height: 115%;

      @include media-breakpoint-down(sm) {
        font-size: 32px !important;
        margin-top: 25px !important;
      }

      @include media-breakpoint-down(xs) {
        margin-top: 30px !important;
        line-height: 115%;
      }
    }
  }

  .christmasSection--withSnowBg {
    &.bgImageActive {
      .ingredient-section {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }
        &:after {
          @include media-breakpoint-down(xs) {
            width: 79px;
            height: 76px;
            top: -38px;
            right: 14px;
            background-image: url('/layouts/frontend/img/christmas-landing/snows.png');
          }
        }
        &:before {
          width: 190px;
          height: 152px;
          background-image: url('/layouts/frontend/img/christmas-landing/cone.png');
          background-repeat: no-repeat;
          background-size: contain;
          left: -53px;
          bottom: -55px;

          @include media-breakpoint-down(xs) {
            width: 20px;
            height: 20px;
            top: 86px;
            right: 18px;
            left: initial;
            bottom: initial;
            background-image: url('/layouts/frontend/img/christmas-landing/recipe-section1-snow.png');
          }
        }
      }

      .table-wrapper {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }

        &:after {
          @include media-breakpoint-down(xs) {
            width: 62px;
            height: 55px;
            top: -13px;
            right: 75px;
            background-image: url('/layouts/frontend/img/christmas-landing/christmas-ball.png');
          }
        }
      }

      .preparation {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }

        &:after {
          @include media-breakpoint-down(xs) {
            width: 240px;
            height: 67px;
            top: -72px;
            right: 31px;
            background-image: url('/layouts/frontend/img/christmas-landing/recipe2-snow.png');
          }
        }
      }

      .recipe-banner--shoppinglistBanner {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }

        &:after {
          @include media-breakpoint-down(xs) {
            width: 240px;
            height: 67px;
            top: -33px;
            left: 35px;
            background-image: url('/layouts/frontend/img/christmas-landing/recipe2-snow.png');
            transform: rotate(185deg);
          }
        }
      }

      .nutritional-values-box {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }

        &:after {
          @include media-breakpoint-down(xs) {
            width: 62px;
            height: 55px;
            top: -16px;
            left: 75px;
            background-image: url('/layouts/frontend/img/christmas-landing/christmas-ball.png');
          }
        }

        &:before {
          @include media-breakpoint-down(xs) {
            width: 20px;
            height: 20px;
            top: -34px;
            right: 75px;
            background-image: url('/layouts/frontend/img/christmas-landing/recipe-section1-snow.png');
          }
        }
      }

      .tag-container {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }

        &:after {
          @include media-breakpoint-down(xs) {
            width: 240px;
            height: 67px;
            top: -72px;
            right: 31px;
            transform: rotate(-1deg);
            background-image: url('/layouts/frontend/img/christmas-landing/recipe2-snow.png');
          }
        }
      }

      .christmas-banner {
        position: relative;
        &:after,
        &:before {
          content: '';
          display: block;
          position: absolute;
          background-size: cover;
        }

        &:after {
          @include media-breakpoint-down(xs) {
            width: 91px;
            height: 98px;
            bottom: -130px;
            right: 31px;
            background-image: url('/layouts/frontend/img/christmas-landing/recpie-snow-last.png');
          }
        }
      }
    }
  }
  .christmas-landing__down-section {
    &--withoutBorder {
      &:before {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }

  }

  .padding-top-50 {

    padding-top: initial;

    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }
  }

  .tag-container {
    a {
      &:hover {
        background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%) !important;
        color: white;
      }
    }
  }

  .button-lidl__blue {

    &--bordered {
      color: black;
      border: none;
      &:hover {
        background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%) !important;
      }
    }

    background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%) !important;
    color: #111E07 !important;
    font-family: 'Lidl Font'; font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    white-space: nowrap;
    width: 230px;
    margin-bottom: 20px;
    transition: 100ms background, 100ms box-shadow;

    @include media-breakpoint-down(xs) {
      width: 100%;
    }

    i {
      svg {
        path {
          fill: black !important;
        }
        polygon {
          fill: black !important;
        }
        rect {
          fill: black !important;
        }
      }
    }

    &:hover {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
      box-shadow: 0px 0px 25px #F6E26A, inset 0px 0px 12px rgba(255, 246, 31, 0.5);
    }
  }

  .slider__item {
    position: relative;
    border-style: solid;
    border-width: 8px;
    border-image: url(/layouts/frontend/img/christmas-landing/gold-layer.jpg) 50 round;
  }

}

.view-old {
  .oldUxMobileOnly {
     display: none;

    @include media-breakpoint-down(sm) {
      display: block;

      .recipe__header {
        margin-top: 0;
      }
    }
  }
  .oldUxDesktopOnly {

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .recipe-action__iconText {
    @media (max-width: 430px) {
      font-size: 12px;
    }
  }

  .recipe-detail {
    position: relative;
    flex: 1;
    max-width: 165px;

    &:nth-child(2) {
      margin-right: 10px;
      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      flex: 1;
      margin-right: 0;
      padding-right: 25px;
      justify-content: center;
    }

    @media (max-width: 430px) {
      padding-right: 5px;
    }
  }

  .recipe-detail-container {
    flex: 1;
    display: flex;
    max-width: initial;
    align-items: center;
    margin-top: 30px;
    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    @include media-breakpoint-down(sm) {
      padding-right: 0;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 15px;
      max-width: 500px;
    }

    .recipe-detail-data {
      display: flex;
      max-width: 520px;
      min-width: 520px;
      align-items: center;

      @include media-breakpoint-down(md) {
        margin-top: 20px;
      }
      @include media-breakpoint-down(xs) {
        margin-top: 10px;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: initial;
      }

      .recipe-detail {
        position: relative;
        flex: 1;
        max-width: 160px;

        @include media-breakpoint-down(sm) {
          flex: 1;
          margin-right: 0;
          padding-right: 25px;
          justify-content: center;
        }

        @media (max-width: 430px) {
         padding: 0 15px;
        }
      }
    }
  }

  .recipe-detail__icon {
    margin-right: 18px;
    @media (max-width: 430px) {
      margin-right: 10px;
    }
  }

  @media (max-width: 490px) {
    &:last-child {
      margin-right: 0;
    }
  }


  .rating-average {
    @media (max-width: 430px) {
      margin-right: 0;
    }
  }

  .recipe-detail__value {
    white-space: nowrap;
    @media (max-width: 430px) {
      font-size: 14px;
      line-height: 156%;
    }

    &--title {
      @media (max-width: 430px) {
        font-size: 9px;
      }
    }

  }

  .rating-average__string {

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
    @media (max-width: 490px) {
      padding: 0 15px !important;
    }
  }

  .rating-average .rating-average__star {
    @include media-breakpoint-down(sm) {
      width: 26px;
    }
  }

  .recipe__header {
    @include media-breakpoint-down(sm) {
      font-size: 26px;
      margin: 20px 0 0 0;
    }
  }

  .rating-average__stars {
    @include media-breakpoint-down(xs) {
      padding: 0 0 0 15px;
    }
    svg {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .detail-container {
    display: flex;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .recipe-detail-container {
      flex: 1;
      display: flex;
      max-width: initial;
      align-items: center;
      margin-top: 0;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }

      @media (max-width: 575px) {
        padding-right: 0;
        padding-left: 0;
      }

      .recipe-detail-data {
        display: flex;
        max-width: 520px;
        min-width: 520px;
        align-items: center;

        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 10px;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          min-width: initial;
        }

        .recipe-detail {
          position: relative;
          flex: 1;
          max-width: 160px;

          @include media-breakpoint-down(sm) {
            flex: 1;
            margin-right: 0;
            padding-right: 25px;
            justify-content: center;
          }

          @media (max-width: 430px) {
            padding-right: 5px;
          }
        }
      }
    }
  }
  .add-to-plan {
    align-self: center;
    display: inline-block;
    color: #FFF !important;
    a {
      margin-right: 0;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
    @media (max-width: 690px) {
      display: none;
    }
    button {
      margin-right: 0;
      @media (max-width: 690px) {
        width: 100%;
      }
    }

    &--mobile {
      display: none;
      color: #FFF !important;
      width: 100%;
      a {
        width: 100%;
        margin-right: 0;
      }
      @media (max-width: 690px) {
        display: block;
      }
    }
  }


  .slider__item__promo {
    max-width: 95px;
    position: absolute;
    background-size: contain;
    left: 20px;
    bottom: 20px;

    @include media-breakpoint-down(xs) {
      max-width: 55px;
      left: 10px;
      bottom: 10px;
    }

    &--sally {
      max-width: 94px;

      @include media-breakpoint-down(xs) {
        max-width: 55px;
      }
    }

    &--timo {
      @include media-breakpoint-down(xs) {
        width: 90px;
        height: 90px;
        left: 0;
        bottom: 10px;
      }
    }
  }

  .slider-icons {
    .button-print {
      position: absolute;
      top: 395px;
      right: 50px;
      padding: 0px 20px;
      background: white;
      opacity: 0.9;
      border-radius: 8px;
      height: 40px;
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        top: 395px;
        right: 35px;
        padding: 0px 10px 0 0;
      }

      @media (max-width: 575px) {
        top: 212px;
        right: 24px;
        padding: 0 13px 0 0;
      }
    }

    .single-item__likes.add-to-favourites {
      //right: 280px;
      height: 40px;
      background: white;
      opacity: 0.9;
      border-radius: 8px;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        //right: 207px;
        top: 15px;
        z-index: 2;
      }
      @media (max-width: 490px) {
        top: 12px;
        //right: 198px;
      }
    }
    .recipe-action__iconText {
      @include media-breakpoint-down(sm) {
        font-size: 10px;
      }
    }
  }
  .recipe-detail__icon {
    margin-right: 15px;
    @include media-breakpoint-down(xs) {
      margin-right: 5px;
    }
  }

  .recipe-detail {
    @include media-breakpoint-down(xs) {
      padding-right: 5px;
    }
  }
}



