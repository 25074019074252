.newsletter-registration {

  iframe {
    background-image: url('/layouts/frontend/img/svg/loading.svg');
    background-repeat: no-repeat;
    background-position: center 100px;
    background-color: #f8f8f9;

    &.subscribe {
      width: 100%;
      height: 996px;

      @media (min-width: 1690px) {
        height: 1006px;
      }

      @media (max-width: 1400px) {
        height: 1035px;
      }

      @media (max-width: 1048px) {
        height: 740px;
      }

      @media (max-width: 681px) {
        height: 797px;
      }
      @media (max-width: 587px) {
        height: 1100px;
      }
      @media (max-width: 420px) {
        height: 1130px;
      }
    }

    &.unsubscribe {
      width: 100%;
      min-height: 785px;

      @media (min-width: 1690px) {
        min-height: 1006px;
      }

      @media (max-width: 1400px) {
        min-height: 1035px;
      }

      @media (max-width: 1048px) {
        min-height: 500px;
      }
    }
  }

  &__background {
    margin-bottom: -70px; /* footer */
    background-color: #f8f8f9;
    background-image: url("/layouts/frontend/img/newsletter-landing/newsletter-image4.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    position: relative;

    &--unsubscribe {
      background-image: url("/layouts/frontend/img/newsletter-landing/newsletter-image4.jpg");

      .newsletter__left-side {
        @media (max-width: 628px) {
          margin-top: 120px !important;
        }
      }
    }


    @media (min-width: 1950px) {
      background-size: contain;
      background-position: 50vw center;
    }

    @media (max-width: 1650px) {
      background-size: 720px auto;
    }

    @media (max-width: 1350px) {
      background-position: 120% top;
    }

    @media (max-width: 1200px) {
      padding: 20px 0;
      background-size: cover;
      margin-bottom: -110px;
      background-position: center;
    }
  }

  &__bottom-text {
    margin-top: 100px;
    margin-bottom: -50px;
    @media (max-width: 1200px) {
      margin-top: 130px;
      margin-bottom: -30px;
    }
    @media (max-width: 800px) {
      margin-top: 150px;
      margin-bottom: 0px;
    }
  }

  &__header {
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #353B42;
    margin-left: 20px;

    @media (max-width: 1650px) {
      font-size: 30px;
    }
    @media (max-width: 1200px) {
      font-size: 24px;
      line-height: 29px;
      padding-top: 30px;
    }
  }


  .newsletter__left-side {
    background-color: #f8f8f9;
    width: 50%;
    padding: 30px 107px 30px 0;

    @media (min-width: 1400px) {
      margin-left: -15px;
      padding-left: 5px;
    }

    @media (max-width: 1690px) {
      padding: 3px 50px 5px 0;
    }
    @media (max-width: 1200px) {
      padding: 0px 16px 0px 16px;
      margin-bottom: 40px;
      margin-top: 60px;
      width: 100%;
    }
    @media (max-width: 640px) {
      margin-top: 30px;
    }
  }

  .newsletter__right-side {
    background-color: transparent;
    width: 60%;
    position: relative;
    left: 130px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    @media (min-width: 1900px) {
      left: 170px;
    }

    @media (max-width: 1650px) {
      left: 190px;
    }
    @media (max-width: 1590px) {
      left: 152px;
    }
    @media (max-width: 1500px) {
      left: 121px;
    }
    @media (max-width: 1497px) {
      left: 93px;
    }
    @media (max-width: 1420px) {
      left: 70px;
    }

    @media (max-width: 1400px) {
      left: 130px;
      top: -70px;
    }

    @media (max-width: 1351px) {
      left: 157px;
    }
    @media (max-width: 1315px) {
      left: 80px;
    }

    @include media-breakpoint-down(sm) {
      height: 300px;
      margin: 30px 0;
      left: 0;
      top: 0px;
    }

    @media (max-width: 640px) {
      margin-top: 82px;
      margin-bottom: 64px;
    }

    img {
      width: 160px;
      height: 183px;

      @include media-breakpoint-down(sm) {
        width: 87px;
        height: 100px;
      }
    }

    .box {
      background: rgba(255, 255, 255, 0.9);
      border-radius: 2px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 448px;
      padding: 0 20px 30px 20px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        position: initial;
        left: initial;
        top: initial;
        transform: initial;
        padding-bottom: 5px !important;
      }

      &__content {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-left: 15px;

        //&:before {
        //  content: '';
        //  display: block;
        //  width: 32px;
        //  height: 27px;
        //  background-repeat: no-repeat;
        //  background-size: contain;
        //  margin-right: 20px;
        //  min-width: 32px;
        //}
        //
        //&--1 {
        //  &:before {
        //    background-image: url("/layouts/frontend/img/svg/newsletter1.svg");
        //  }
        //}
        //
        //&--2 {
        //  &:before {
        //    background-image: url("/layouts/frontend/img/svg/discount.svg");
        //  }
        //}
        //
        //&--3 {
        //  &:before {
        //    background-image: url("/layouts/frontend/img/svg/idea.svg");
        //  }
        //}
        //
        //&--4 {
        //  &:before {
        //    background-image: url("/layouts/frontend/img/svg/stack.svg");
        //  }
        //}


        > div {
          display: flex;
          flex-direction: column;
        }
        p {
          font-size: 18px;
          line-height: 140%;

          &:first-child {
            font-weight: 600;
            font-size: 18px;
            line-height: 120%;
            margin-bottom: 7px;
            white-space: nowrap;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }

          }

          &:last-child {
            margin-bottom: 0;

            @include media-breakpoint-down(sm) {
              display: none;
            }

          }
        }
      }

      &__img {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }


  .content-box {
    display: flex;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }


}
