.christmasSection--50 {
  position: relative;

  // tree
  &:before {
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    width: 383px;
    height: 210px;
    background-size: 383px 210px;
    z-index: 16;
  }

  &.bgImageActive  {
    // tree
    &:before {
      background-image: url('/layouts/frontend/img/christmas-landing/section50/tree.png');

      @include retina {
        background-image: url('/layouts/frontend/img/christmas-landing/section50/tree_2x.png');
      }
    }

    // glitter
    .feeds {
      //&:after {
      //  background-image: url('/layouts/frontend/img/christmas-landing/glitter_vertical.png');
      //}

      //&:before {
      //  background-image: url('/layouts/frontend/img/christmas-landing/glitter_horizontal.png');
      //}
    }

    .feedElement--red-background .feedElement__header {
      &:before {
        width: 777px;
        height: 271px;
        background-image: url('/layouts/frontend/img/christmas-landing/section50/redBg.jpg');
        top: -15px;
      }

      @include media-breakpoint-down(sm) {
        &:before {
          top: -50px;
          width: 558px;
        }
      }

      @include media-breakpoint-down(xs) {
        max-width: 305px;
        margin-top: 80px;

        &:before {
          width: 320px;
          height: 212px;
          background-image: url('/layouts/frontend/img/christmas-landing/section50/redBg_mobile.jpg');
          top: -20px;
          left: -15px;
        }
      }
    }
  }

  // glitter
  .feeds {
    //&:after {
    //  content: '';
    //  z-index: 8;
    //  position: absolute;
    //  top: 480px;
    //  left: 50%;
    //  margin-left: -750px;
    //  width: 275px;
    //  height: 500px;
    //  background-size: 275px 500px;
    //
    //  @include media-breakpoint-down(sm) {
    //    display: none;
    //  }
    //}

    //&:before {
    //  content: '';
    //  z-index: 8;
    //  position: absolute;
    //  top: -40px;
    //  right: 50%;
    //  margin-right: -620px;
    //  width: 500px;
    //  height: 275px;
    //  background-size: 500px 275px;
    //
    //  @include media-breakpoint-down(sm) {
    //    display: none;
    //  }
    //}

    .container {
      padding-top: 50px;
      padding-bottom: 80px;
    }
  }

  .feedElement__scrollLeft, .feedElement__scrollRight {
    margin-top: 50px;
  }

  .feedElement.feedElement--withArrow .feedElement__arrowText {
    display: none;
  }

  .feedSection--stressfrei-kochen {
    padding: 30px 325px 25px 25px;

    @include media-breakpoint-down(sm) {
      padding: 20px 20px 15px 20px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    &:before {
      top: 25px;
      right: 10px;
      left: auto;
      width: 280px;
      height: 210px;

      @include media-breakpoint-down(sm) {
        position: static;
        margin: 0 auto;
        display: block;
        margin-bottom: 20px;
        max-width: 280px;
      }
    }

    &.bgImageActive {
      &:before {
        background-image: url('/layouts/frontend/img/christmas-landing/section50/textImage.jpg');
      }
    }
  }

  @media (max-width: 575px) {
    .feedElement[data-id="desserts-zu-weihnachten"] .feedElement__header {
      max-width: 275px;
      margin-top: 20px;
    }
  }
}
