.snackbar-container {
  transition: all 500ms ease;
  transition-property: top, right, bottom, left, opacity;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  min-height: 14px;
  background-color: #000000;
  position: fixed;
  //display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  line-height: 22px;
  padding: 18px 24px;
  bottom: -100px;
  top: -100px;
  opacity: 0;
  z-index: 9999;
}

.snackbar-container .action {
  background: inherit;
  display: inline-block;
  border: none;
  font-size: inherit;
  //text-transform: uppercase;
  color: white !important;
  text-decoration: underline;
  margin-top: 25px;
  padding: 0;
  min-width: min-content;
  cursor: pointer;
}

@media (min-width: 640px) {
  .snackbar-container {
    min-width: 288px;
    max-width: 568px;
    //display: inline-flex;
    border-radius: 2px;
    margin: 24px;
  }
}

@media (max-width: 640px) {
  .snackbar-container {
    left: 0;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .snackbar-container {
    padding: 18px 12px;
  }
}

.snackbar-pos.bottom-center {
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.bottom-left {
  top: auto !important;
  bottom: 0;
  left: 0;

  @media (max-width: 639px) {
    left: 10px;
    bottom: 10px;
    right: 10px;
  }
}

.snackbar-pos.bottom-right {
  top: auto !important;
  bottom: 0;
  right: 0;

  @media (max-width: 639px) {
    left: 10px;
    bottom: 10px;
    right: 10px;
  }
}

.snackbar-pos.top-left {
  bottom: auto !important;
  top: 0;
  left: 0;
}

.snackbar-pos.top-center {
  bottom: auto !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.snackbar-pos.top-right {
  bottom: auto !important;
  top: 0;
  right: 0;
}

@media (max-width: 640px) {
  .snackbar-pos.bottom-center, .snackbar-pos.top-center {
    left: 0;
    transform: none;
  }
}

.lidl-snackbar {
  background: url('/layouts/frontend/img/svg/cook_head.svg') no-repeat 20px center !important; //snackbar library...
  background-color: #2E9489 !important;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 25px;
  padding-left: 90px;
  font-size: 16px;
  font-family: 'Lidl Font';
  border-width: 0;

  &.favourites {
    background-image: url('/layouts/frontend/img/svg/heart.svg') !important; //snackbar library...
  }

  &.chef {
    //nothing - basic image
  }

  &.shoplist {
    background-image: url('/layouts/frontend/img/svg/shoplist.svg') !important; //snackbar library...
  }

  .action {
    background: none !important;
    margin-top: 15px;
    font-weight: bold;
    text-decoration: none;
    border-bottom: 1px solid #FFF;
    line-height: 17px;
  }

  p {
    font-size: 15px !important;
    //text-transform: uppercase;
  }
}