.weekRecipe {
  position: fixed;
  right: -248px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 51;
  transition: right 0.5s;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  &--visible {
    right: 0;

    .weekRecipe__sidebar {
      .iconset--arrow {
        transform: rotate(90deg);
      }
    }
  }

  &__sidebar {
    z-index: 98;
    background: $lidlPrimaryColor;
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-family: 'Lidl Font'; font-weight: bold;
    padding: 20px 0 50px 30px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 87px;
    right: -30px;
    width: 250px;
    transform-origin: 0 100%;
    border-radius: 5px 5px 0 0;
    display: flex;
    align-items: center;

    .iconset--arrow {
      transition: 0.25s transform;
      transform: rotate(-90deg);
      margin-left: 20px;
    }
  }

  &--w26 {
    .weekRecipe__sidebar {
      bottom: 125px;
    }
  }

  &__box {
    width: 248px;
    position: relative;
    z-index: 99;
    border-radius: 5px 0 0 5px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.08), 0px 4px 32px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(0, 0, 0, 0.05);
    padding: 16px 0;

    &__header {
      padding: 0 24px 5px;
      font-size: 16px;
      line-height: 150%;
      font-family: 'Lidl Font'; font-weight: bold;
    }

    &__img {
      display: block;
      margin: 0 auto 20px;
      max-width: 100%;

      &__b {
        display: none;
      }
    }

    &__button {
      margin: 0 25px;
      width: auto;
    }
  }
}
