.christmas-section60 {
  background-size: 440px 440px;
  position: relative;
  padding-bottom: 50px;



  @include media-breakpoint-down(xs) {
    padding-bottom: 20px;
  }

  &.christmas-landing {
    .feedSection {
      margin-right: 0;
      margin-left: 0;
      border: none;
      border-radius: initial;
    }
    .feedSection {
      &:after {
        @include media-breakpoint-down(xs) {
          display: none;
        }
      }
    }
  }

  &.bgImageActive {
    background-image: url("/layouts/frontend/img/christmas-landing/section60/section6-background.jpg");

    .feedSection {
      &--section6 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section60/section6-photo.jpg");
        }
      }
      &--section6-2 {
        &:before {
          background-image: url("/layouts/frontend/img/christmas-landing/section60/section6-photo2.jpg");
        }
      }
    }
  }

  .feedElement {

    &:after,
    &:before {
      top: 146px;
      height: calc(100% - 146px);
    }

    &:after {
      background: linear-gradient(270deg, #F4E3D6 0%, rgba(255, 255, 255, 0) 100%);
    }

    &:before {
      background: linear-gradient(90deg, #F4E3D6 0%, rgba(255, 255, 255, 0) 100%);
    }

    &__header {
      &__text {
        color: #BC2C35;
      }
    }
  }




  &__title {
    margin-top: 60px;
    font-size: 40px;
    line-height: 48px;
    font-family: 'Lidl Font'; font-weight: bold;
    color: #000000;
    margin-bottom: 0;

    @include media-breakpoint-down(xs) {
      margin-top: 30px;
      font-size: 24px;
      line-height: 29px;
    }
  }


  .decoration {
    &:before {
      content: '';
      width: 260px;
      display: inline-block;
      height: 400px;
      position: absolute;
      background-image: url(/layouts/frontend/img/christmas-landing/cone.png);
      background-repeat: no-repeat;
      background-size: contain;
      top: -100px;
      right: 165px;


      @include media-breakpoint-down(sm) {
        right: 65px;
        top: -157px;
        width: 280px;
      }

      @media (max-width: 750px) {
        display: none;

      }
    }
  }

}
