
body .multiselect {
  &__tags {
    max-height: 40px;
    overflow: hidden;
  }

  &__tag {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    color: #000;

    i {
      display: none;
    }
  }

  &__option {
    position: relative;
    padding-left: 40px;

    &:after {
      display: none;
      content: '✓' !important;
      background: none !important;
      color: #212529;
      text-align: center;
      position: absolute;
      width: 20px;
      height: 20px;
      left: -3px;
      top: 0;
      font-weight: 800;
      transition: 0.2s all ease-in-out;
    }

    &:before {
      content: '';
      top: 9px;
      left: 12px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-color: white;
      border: 1px solid $borderColor;
    }
    &--selected {
      &:after {
        display: block;
      }
    }
  }

  .multiselect__option--selected,
  .multiselect__option--highlight,
  .multiselect__option--selected.multiselect__option--highlight {
    background: none !important;
    color: #000;
    font-weight: normal;

    &:after {
      content: '✓';
      color: #212529;
    }
  }
}
