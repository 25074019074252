.shoplistTable {
  padding-top: 25px;
  position: relative;
  padding-bottom: 35px;

  @include media-breakpoint-down(xs) {
    margin-bottom: -50px;
  }

  &__list-view {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    a {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        width: auto;
        padding: 0 15px;
      }
    }
  }


  &__popup {
    display: none;

    &--visible {
      display: flex;
    }

  }
  .shoplistTable__slider img {
    display: none;
  }

  .shoplistTable__slider.slick-initialized img {
    display: block;
  }


  &__photo {
    &-box {
      height: 240px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    &-box-mobile {
      display: none;
      @include media-breakpoint-down(sm) {
        height: 237px;
        display: block;
      }

      @media (max-height: 600px) {
        max-height: 33vh;
      }
    }

    &--desktop {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    &--mobile {
      display: none !important;
      max-width: 320px;
      width: 100%;

      @include media-breakpoint-down(sm) {
        display: block !important;
      }

      @include media-breakpoint-down(xs) {
        margin-top: 30px;
      }
    }
  }

  &__box {
    padding: 30px 0px 125px 0px;
    text-align: left;
    position: relative;
    min-height: 610px;

    @include media-breakpoint-down(sm) {
      width: 100%;
      max-width: 100%;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);

    }
    @include media-breakpoint-down(xs) {
      padding: 30px 15px 125px 15px;
    }
  }

  &__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    padding: 0 30px;

    @include media-breakpoint-down(sm) {
      margin-top: 100px;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 0px;
    }
  }


  .slick-list {
    padding: 0 0 !important;
  }

  .slick-dots {
    bottom: -50px;
    left: -14px;
    li {
      button {
          height: 20px;
          width: 20px;
          cursor: pointer;
          border: 0;
          background: transparent;
          display: block;
          outline: none;
          line-height: 0;
          font-size: 0;
          color: transparent;
          padding: 5px;
        &:before {
          position: absolute;
          background: transparent;
          top: 0;
          left: 0;
          content: "\2022";
          width: 20px;
          height: 20px;
          font-family: serif;
          line-height: 20px;
          text-align: center;
          -webkit-font-smoothing: antialiased;
          font-size: 28px;
          opacity: 1;
          color: #eee;
        }
      }
      &.slick-active {
        button {
          &:before {
            font-size: 28px;
            opacity: 1;

            color: #1b517d;
          }
        }
      }
    }
  }


  &__onboarding {
    flex-wrap: wrap;
    left: 50%;
    max-width: 606px;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 9999;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(xs) {
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
    }

    &__box {
      position: relative;
    }

    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      @include media-breakpoint-down(sm) {
        font-size: 24px;
        line-height: 30px;
        position: relative;
        bottom: -32px;
      }
      @media (max-width: 360px) {
        bottom: 0;
      }
    }

    button {
      position: absolute;
      bottom: 75px;
    }

    .shoplistTable__slider {
      width: 100%;
      text-align: center;
      margin: 0;
      background: white;
      a {
        position: absolute;
        text-transform: uppercase;
        color: $lidlPrimaryColor;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        left: 30px;
        top: 24px;
        z-index: 3;


        @include media-breakpoint-down(sm) {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .slick-prev {
        display: none !important;
      }
    }

    p {
      font-size: 16px;
      line-height: 25px;
      margin-top: 16px;
      margin-bottom: 35px;
      @include media-breakpoint-down(sm) {
        position: relative;
        bottom: -30px;
      }
      @media (max-width: 360px) {
        bottom: -20px;
      }
    }

    img {
      &.slide3 {
        max-width: 202px;
        max-height: 218px;
      }
      width: 100%;

      &.slide1 {
        max-width: 344px;
        max-height: 242px;
      }
      &.slide2 {
        max-width: 320px;
        max-height: 242px;
      }

      &.slide4 {
        max-width: 333px;
        max-height: 221px;
      }

      &.slide5 {
        max-width: 606px;
        max-height: 264px;
      }

      &.slide6 {
        max-width: 388px;
        max-height: 216px;
      }

    }



  }

  &__layer {
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    @include media-breakpoint-down(xs) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    h2 {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 0;
    }

    &__buttons {
      display: flex;

      a {
        width: auto;
        padding: 0 20px;
      }

      @include media-breakpoint-down(xs) {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        a {
          width: 100%;
          margin-top: 20px;
          margin-right: 0;
        }
      }
    }
  }

  .listElement {
    border-bottom: 1px solid #DDDDDD;

    &__title {
      width: 100%;
      font-size: 18px;
      color: $lidlPrimaryColor;

      @include media-breakpoint-down(sm) {
        color: $lidlDarkBlue;
      }
    }

    &__open {
      padding: 20px 0;
      display: block;
    }

    &__delete {
      @include media-breakpoint-down(sm) {
        border-left: 0;
      }
    }
  }
}


.listElement {
  border-bottom: 1px solid #E3E4E5;
  display: flex;
  height: 65px;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #212121;
  }

  &__element {
    background: white;
    display: none;
    padding: 20px;

    &--open {
      display: flex;
      position: absolute;
      flex-direction: column;
      right: 5px;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 6px;
      margin-top: 140px;
      z-index: 3;
    }
  }

  &__ingredients_count {
    margin-right:82px
  }

  &__dots {
    width: 4px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url("/layouts/frontend/img/svg/dots.svg");
    position: relative;
    padding: 0 20px;
    cursor: pointer;
    background-position: center;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      display: block;
      background-color: #EEEEEE;
      left: -43px;
      position: absolute;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    a {
      color: $lidlDarkBlue;
      padding-bottom: 10px;

      &:first-child {
        padding-bottom: 15px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__icon {
    &--delete {
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 16px;
        background: url('/layouts/frontend/img/svg/delete-shopping-list.svg') no-repeat;
        margin-right: 20px;
      }
    }

    &--rename {
      &:before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(/layouts/frontend/img/svg/rename-shopping-list.svg) no-repeat;
        margin-right: 19px;
      }
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
