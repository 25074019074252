.christmas-section10 {

  .section1 {
    position: relative;
    background: url("/layouts/frontend/img/christmas-landing/section1/section1-main-background.jpg?v=2") no-repeat;
    background-size: cover;
    height: 425px;
    text-align: center;

    @media (max-width: 800px) {
      background-size: cover;
    }

    @include media-breakpoint-down(xs) {
      height: 340px;
    }
  }

  &__box {
    position: relative;
    height: 100%;

    @include media-breakpoint-down(xs) {
      margin-top: 100px;
    }

  }

  &__snow-box {
    display: none;
    user-select: none;

    @media (max-width: 910px) {
      display: block;
    }

    .snow1 {
      position: absolute;
      width: 21px;
      top: 75px;
      right: 192px;

      @media (max-width: 833px) {
        top: 80px;
        right: 94px;
      }

      @include media-breakpoint-down(sm) {
        top: 65px;
      }

      @media (max-width: 634px) {
        top: 17px;
        right: 78px;
      }

      @media (max-width: 480px) {
        right: 2px;
      }
    }

    .snow2 {
      position: absolute;
      width: 58px;
      top: 222px;
      left: 200px;

      @media (max-width: 833px) {
        left: 143px;
      }

      @media (max-width: 667px) {
        left: 120px;
      }

      @media (max-width: 634px) {
        left: 94px;
      }
      @include media-breakpoint-down(xs) {
        top: 154px;
      }

      @media (max-width: 480px) {
        left: 26px;
      }
    }

    .snow3 {
      position: absolute;
      width: 74px;
      top: 222px;
      right: 195px;

      @media (max-width: 833px) {
        bottom: 148px;
        right: 134px;
      }

      @media (max-width: 667px) {
        bottom: 117px;
        right: 112px;
      }

      @media (max-width: 634px) {
        bottom: 108px;
        right: 94px;
      }

      @include media-breakpoint-down(xs) {
        top: 154px;
      }

      @media (max-width: 480px) {
        right: 2px;
      }
    }
  }

  &__content {

    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    font-family: 'Lidl Font'; font-weight: bold;

    @include media-breakpoint-down(sm) {
      top: 40%;
    }

    @include media-breakpoint-down(xs) {
      top: 27%;
    }

    h1 {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #FFFFFF;
      user-select: none;
      text-shadow: 0px 0px 12.4719px rgba(0, 0, 0, 0.5);

      @media (max-width: 640px) {
        font-size: 20px;
        line-height: 160%;
        margin-bottom: 0;
      }

      span {
        color: white;
        background: linear-gradient(180deg, #FDF5D2 0%, #CC9E3C 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: 'Norican', cursive;
        font-style: normal;
        font-weight: normal;
        font-size: 90px;
        display: block;
        line-height: 102px;
        background-size: cover;
        user-select: none;
        text-shadow: none;

        // @see: https://stackoverflow.com/questions/3802218/how-do-i-combined-css-text-shadow-and-background-image-webkit-gradient
        filter: drop-shadow(5px 3px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4.988763809204102px 62.35954666137695px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.65));


        @media (max-width: 640px)  {
          font-size: 56px;
          line-height: 130%;
          margin-bottom: 0;
        }

        &.text {
          background-clip: text;
          -webkit-background-clip: text;
          color: rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &__photo {
    position: absolute;
    top: 20px;
    user-select: none;

    img {
      width: 100%;
    }

    @media (max-width: 1392px) {
      top: 52px;
    }

    &--left {
      left: -50px;
      max-width: 416px;

      @media (max-width: 1392px) {
        max-width: 324px;
        left: -10px;
        top: 64px;
      }

      @include media-breakpoint-down(sm) {
        left: -57px;
        max-width: 275px;
      }
      @media (max-width: 910px) {
        display: none;
      }
    }

    &--right {
      right: -45px;
      max-width: 503px;

      @media (max-width: 1392px) {
        max-width: 354px;
        right: 14px;
        top: 64px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 309px;
        right: -35px;
      }
      @media (max-width: 910px) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      max-width: 320px;
      min-width: 320px;

      @media (max-width: 910px) {
        display: block;
        bottom: 0;
        top: initial;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
