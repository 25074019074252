.ingredients__counter {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 0.5rem !important;

  &__member__portions {
    height: 48px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4E5761;
    background-color: white;
    border-radius: 2px;

    &__value {
      background: none;
      border: none;
      width: 34px;
      text-align: center;
      padding: 0;
      margin: 0;
      font-size: 18px;
      line-height: 140%;
      color: #353B42;

      &--wrongData {
        color: #E60A14;
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    @include media-breakpoint-down(xs) {
      max-width: 90%;
    }

    &--wrongData {
      border: 1px solid #E60A14;
    }
  }

  &--minus,
  &--plus {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
  }

  &--plus {
    margin-left: 5px;

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      content: ' ';
      height: 18px;
      width: 1px;
      background-color: #0050AA;
    }

    &:before {
      transform: rotate(0deg) translateX(-50%);
    }

    &:after {
      transform: rotate(90deg);
    }
  }


  &--minus {
    margin-right: 5px;

    &:after {
      position: absolute;
      left: 50%;
      content: ' ';
      height: 18px;
      width: 1px;
      background-color: #0050AA;
      transform: rotate(90deg) translateX(-50%);
    }
  }
}