.view-new {

  .veganize-it-container {
    border-bottom: 1px solid #ddd;
    padding-bottom: 12px;
  }

  .button-lidl__veganize-it {
    background-color: #4AB8A0;
  }

  .add-to-plan-btn.button-lidl__blue.button--withIcons {
    width: 230px;
  }

  .slider__item__promo {
    width: 95px;
    height: 95px;
    position: absolute;
    background-size: contain;
    left: 20px;
    bottom: 20px;
    z-index: 3;

    @include media-breakpoint-down(xs) {
      width: 55px;
      height: 55px;
      left: 10px;
      bottom: 10px;
    }

    &--sally {
      width: 94px;
      height: 65px;

      @include media-breakpoint-down(xs) {
        width: 55px;
        height: 38px;
      }
    }
  }

  &.recipeViewBiggerLabels120x120 {
    @include media-breakpoint-up(sm) {
      .slider__item__promo {
        width: 120px;
        height: 120px;

        &--sally {
          height: auto;
        }
      }
    }

    .recipeImage__ribbon--11 { // 50-jahre-lidl
      .recipeImage__ribbon__label {
        width: 145px;
        height: 67px;
        left: 28px;
        bottom: 50px;

        @include media-breakpoint-down(sm) {
          width: 78px !important;
          height: 36px;
          left: 10px;
          bottom: 20px;
        }
      }
      .recipeImage__ribbon__title {
        margin-left: 200px;

        @include media-breakpoint-down(sm) {
          margin-left: 105px;
        }
      }
    }
  }

  .recipe-action__iconText {
    @media (max-width: 430px) {
      font-size: 12px;
    }
  }

  .recipe-detail__icon {
    margin-right: 18px;
    @media (max-width: 430px) {
      margin-right: 10px;
    }

  }

  @media (max-width: 490px) {
    &:last-child {
      margin-right: 0;
    }
  }


  .rating-average {
    @media (max-width: 430px) {
      margin-right: 0;
    }
  }

  .recipe-detail__value {
    white-space: nowrap;
    @media (max-width: 470px) {
      font-size: 14px;
      line-height: 156%;
    }

    &--title {
      @media (max-width: 470px) {
        font-size: 9px;
      }
    }

  }

  .rating-average__string {

    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
    @media (max-width: 490px) {
    padding: 0 15px !important;
    }
  }

  .rating-average .rating-average__star {
    @include media-breakpoint-down(sm) {
      width: 26px;
    }
  }

  .recipe__header {
    @include media-breakpoint-down(sm) {
      font-size: 26px;
      margin: 10px 0 0 0;
    }
  }

  .rating-average__stars {
    @include media-breakpoint-down(xs) {
      padding: 0 0 0 15px;
    }
    svg {
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .rating-average {
    .row {
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        background: #EEEEEE;;
        position: absolute;
        top: 0;
        right: 56px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .detail-container {
    display: flex;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      margin-bottom: 20px;
    }

    .buttonBubble__element {
      display: none;
    }

    .recipe-detail-container {
      flex: 1;
      display: flex;
      max-width: initial;
      align-items: center;
      margin-top: 0;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
      }

      @media (max-width: 575px) {
        padding-right: 0;
        padding-left: 0;
        margin-top: 20px;
      }

      @include media-breakpoint-down(xs) {
        .recipe-detail-data .recipe-detail {
          display: none !important;
        }
      }

      .recipe-detail-data {
        display: flex;
        min-width: 520px;
        align-items: center;

        @include media-breakpoint-down(md) {
          margin-top: 20px;
        }
        @include media-breakpoint-down(xs) {
          margin-top: 10px;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
          min-width: initial;
        }

        .recipe-detail {
          position: relative;
          flex: 1;
          max-width: 165px;

          &:nth-child(2) {
            margin-right: 10px;
            @include media-breakpoint-down(md) {
              margin-right: 0;
            }
          }

          @include media-breakpoint-down(md) {
            padding: 0;
          }

          @include media-breakpoint-down(sm) {
            flex: 1;
            margin-right: 0;
            padding-right: 25px;
            justify-content: center;
          }

          @media (max-width: 430px) {
            padding-right: 5px;
          }
        }
      }
    }
  }
  .add-to-plan {
    align-self: center;
    display: inline-block;
    color: #FFF !important;
    a {
      margin-right: 0;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
    @media (max-width: 690px) {
      display: none;
    }
    button {
      margin-right: 0;

      span {
        line-height: 1;
      }

      @media (max-width: 690px) {
        width: 100%;
      }
    }

    &--mobile {
      display: none;
      color: #FFF !important;
      width: 100%;
      a {
        width: 100%;
        margin-right: 0;
      }
      @media (max-width: 690px) {
        display: block;
      }
    }
  }

  .slider-icons {
    .button-print {
      position: absolute;
      bottom: 15px;
      right: 30px;
      padding: 0 15px;
      background: white;
      opacity: 0.9;
      border-radius: 8px;
      height: 40px;
      display: flex;
      align-items: center;
      z-index: 10;

      @include media-breakpoint-down(sm) {
        bottom: 12px;
        right: 26px;
        padding: 0px 10px 0 0;

        .recipe-action {
          margin: 0 0.75rem 0 0.75rem;

          &__iconText {
            display: none;
          }
        }
      }

      @media (max-width: 490px) {
        bottom: 12px;
        right: 26px;
        padding: 0 5px;
      }
    }

    .single-item__likes.add-to-favourites {
      top: 15px;
      right: 15px;
      height: 40px;
      background: white;
      opacity: 0.9;
      border-radius: 8px;
      display: flex;
      align-items: center;
      @include media-breakpoint-down(sm) {
        right: 15px;
        top: 15px;
        z-index: 2;
      }
      @media (max-width: 490px) {
        top: 12px;
        right: 12px;
      }
    }
    .recipe-action__iconText {
      @include media-breakpoint-down(sm) {
        font-size: 10px;
      }
    }
  }

  &.christmas-view {
    .recipe__pdfFirstPage .recipe-action__iconText {
      color: #30303b;
    }
    .add-to-plan-btn.button-lidl__blue.button--withIcons,
    .add-to-plan-btn.button--withIcons.button-lidl__white {
      width: 224px;
    }
  }

  .recipe--healthScore,
  .recipe--zeroWasteScore {
    .recipe-detail {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .recipe-detail-mobile {
      display: flex;

      .recipe-detail {
        @include media-breakpoint-down(sm) {
          display: flex;

          margin: 20px 0 10px;

          &:nth-child(2) {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }

    .recipe-detail__value {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;

      @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 25px;
      }

      .recipe-detail__value--title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        @include media-breakpoint-down(sm) {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }

    .detail-container .recipe-detail-container .recipe-detail-data .recipe-detail {
      padding-left: 10px;
      padding-right: 10px;

      &:nth-child(2) {
        margin-right: 0;
      }
    }
  }
  .recipeView {
    &__bottomText {
      margin: 50px 0 10px;
    }
  }
}



