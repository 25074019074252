.registration {
  margin-bottom: -70px;

  &__background {
    background-image: url('/layouts/frontend/img/login/registration-background.jpg');
    background-size: 50vw auto;
    background-repeat: no-repeat;
    background-position: top right;

    @media (min-width: 1950px) {
      background-size: contain;
      background-position: 50vw center;
    }

    @media (max-width: 1650px) {
      background-size: 720px auto;
    }

    @media (max-width: 1350px) {
      background-position: 120% top;
    }

    @media (max-width: 1200px) {
      background-size: cover;
      background-position: center;
    }
  }

  .registration__header {
    @media (max-width: 1650px) {
      font-size: 30px;
    }
    @media (max-width: 1200px) {
      font-size: 24px;
      line-height: 29px;
    }
  }

  &__login {
    padding: 16px 132px;
    border: 2px solid #F1F2F3;
    margin-top: 24px;
    width: 100%;
    text-align: center;

    @media (max-width: 1200px) {
      border: initial;
      border-top: 2px solid #F1F2F3;
    }

    @media (max-width: 900px) {
      padding: 16px 0;
    }
    .login__link {
      font-size: 16px;
      line-height: 25px;
      text-decoration: none;

      @media (max-width: 1200px) {
        font-size: 14px;
      }
      p {
        margin-bottom: 0;
        color: $lidlPrimaryColor;
        text-transform: uppercase;
        font-weight: 600;
        line-height: 19px;
        margin-top: 8px;
      }
    }
  }

  &__header {
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: #353B42;
  }

  .login__registerInputs {
    label {
      margin-bottom: 0;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .min-characters {
      font-size: 10px;
      line-height: 12px;
      color: #353B42;
      position: relative;
      top: -5px;
      margin-bottom: 0;
    }
    .required-inputs {
      font-size: 10px;
      line-height: 12px;
      color: #353B42;
      position: relative;
      top: 3px;
      @media (max-width: 1650px) {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    width: 100%;
    @media (max-width: 1200px) {
      margin: 0 20px;
    }
    button {
      width: 100%;
    }
  }


  &__left-side {
    max-width: 50%;
    padding: 30px 107px 30px 0;
    @media (max-width: 1690px) {
      background: white;
      padding: 3px 50px 30px 0;
    }
    @media (max-width: 1200px) {
      padding: 0px 16px 0px 16px;
      margin-bottom: 40px;
      margin-top: 40px;
      max-width: 100%;
    }
    @media (max-width: 640px) {
      margin-top: 140px;
    }
  }
  .center-on-mobile {
    @media (max-width: 1200px) {
      display: flex;
      justify-content: center;
    }
  }

  .terms__newsletter__beforeText,
  .terms__newsletter__afterText,
  .terms__newsletter
  {
    font-size: 12px;
    @media (max-width: 1650px) {
     font-size: 11px;
    }
  }
  .terms__newsletter {
    @media (max-width: 1200px) {
      padding: 0 16px;
    }
    .col-form-label {
      @media (max-width: 1200px) {
        margin-left: 0;
      }
    }
  }

  .terms__newsletter__beforeText {
    margin: 10px 0;

    p {
      margin-bottom: 0;
    }
  }

  &__about {
    background: #F7FAF8;
    border-radius: 4px;
    padding: 20px 40px 20px 20px;
    @media (max-width: 1650px) {
      padding: 20px 40px 20px 20px;
    }
    @media (max-width: 1200px) {
      padding: 20px 10px 20px 0;
      margin-left: -16px;
      margin-right: -16px;
    }
    ul {
      margin-bottom: 0;
      li {
        font-size: 12px;
        &:not(:last-child) {
          margin-bottom: 8px;
        }
        position: relative;
        list-style-type: none;

        &:after {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url('/layouts/frontend/img/svg/confirm.svg') no-repeat;
          position: absolute;
          left: -27px;
          top: 7px;
        }
      }
    }
  }

  &__welcomeText {
    margin: 1em auto;
    font-size: 1.4em;

    @include media-breakpoint-down(xs) {
      font-size: 1.2em;
    }
  }
}
.login__container {
  align-items: center;
  padding-top: 50px;
  margin-bottom: 80px;

  @include media-breakpoint-down(sm) {
    padding-top: 120px;
  }

  .form-control {
    background-color: transparent;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-transform: uppercase;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      text-transform: uppercase;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      text-transform: uppercase;
    }
  }
}

.login__box {
  max-width: 100%;
  border: 3px solid black;
  height: auto;
  margin: 0 auto;
}

 .login__row {
  height: 100%;
}

.login__left {
  width: auto;
  height: auto;
  background-color: black;
  background-image: url('/layouts/frontend/img/login/background.png');
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  color: white;
  font-size: 4em;
  font-weight: bold;
  background-size: cover;
}

.login__right {
  padding: 60px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .checkbox .form-control-feedback {
    margin-left: 15px;
  }

  .alert {
    margin-bottom: 0;
  }
}

.login__header {
  font-weight: 700;
  font-size: 2.6em;
  text-align: center;
  text-transform: uppercase;
}

.login__message {
  font-size: 1.14em;
  text-align: center;
  margin-top: 40px;
}


.login__link {
  color: black;
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    color: black;
  }
}

.terms__newsletter {
  .col-form-label {
    padding-top: 0;
  }

  &__beforeText {
    margin: 20px 0 10px 25px;
  }

  &__afterText {

  }
}

.newsletter__show-more {
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0;
}

.newsletter__hidden {
  width: 100%;
  font-weight: bold;
  display: none;
  text-align: justify;
}

@include media-breakpoint-down(xs) {
  .login__container {
    align-items: flex-start;
  }
  .login__box {
    height: auto;
  }
  .login__row {
    height: auto;
  }
  .login__header {
    font-size: 2em;
  }
}
