@import "slick/slick";
@import "slick/slick-theme";
@import "snackbar/snackbar";

.slick-prev,
.slick-next {
  width: 48px;
  height: 48px;
  box-shadow: 0px 30px 50px rgba(0, 0, 0, 0.15);
  border-radius: 48px;
  background: no-repeat center;
  background-color: #D6E3F1;

  @media (min-width: 1300px) {
    background-image: url('/layouts/frontend/img/homepage_de/slider_arrow_blue.svg');
  }

  &:hover {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.4);
  }
}

.slick-next {
  transform: rotate(-180deg) translate(0, 50%);
  box-shadow: 0px -30px 50px rgba(0, 0, 0, 0.15);
}

[v-cloak] {
  display: none !important;
}