.infoIcon {
  $self: &;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: 12px;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__text {
    &__desc {
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      color: #212121;
    }

    &__value {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
      padding-top: 2px;
    }
  }

  &--prepTime {
    #{$self}__icon {
      background-image: url('/layouts/frontend/img/components/info-icon/preparationTime.svg');
    }
  }

  &--noMeat {
    #{$self}__icon {
      background-image: url('/layouts/frontend/img/components/info-icon/noMeat.svg');
    }
  }

  &--calories {
    #{$self}__icon {
      background-image: url('/layouts/frontend/img/components/info-icon/calories.svg');
    }
  }

  &--price {
    #{$self}__icon {
      background-image: url('/layouts/frontend/img/components/info-icon/price.svg');
    }
  }

  &--family {
    #{$self}__icon {
      background-image: url('/layouts/frontend/img/components/info-icon/family.svg');
    }
  }
}

.upsellBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $bannerColor;
  padding: 12px 14px;
  color: #212121;
  border-radius: 4px;

  &__text {
    &__header {
      font-size: 20px;
      font-weight: 600;
    }

    &__desc {
      font-size: 12px;

    }
  }

  &__button {
    padding-left: 10px;
    min-width: 230px;

    a {
      margin-right: 0;
      width: 100%;

      @media (max-width: 639px) {
        width: auto;
        padding: 0 15px;
      }
    }
  }
}
