.kinderLanding {

  .feedSection--banner {
    background-image: url("/layouts/frontend/img/kinder-landing/banner.png");

    @include media-breakpoint-down(sm) {
      background-image: url("/layouts/frontend/img/kinder-landing/banner-mobile.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .feeds .feedNavigation ul li a {
    min-height: 76px;
    display: flex;
    align-items: center;
    white-space: normal;
    padding: 18px 13px;
    text-align: center;

    @include media-breakpoint-down(md) {
      white-space: nowrap;
    }

  }

  .feedSection--section1 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section1.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section1-mobile.png");
      }
    }
  }

  .feedSection--section2 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section2.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section2-mobile.png");
      }
    }
  }

  .feedSection--section3 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section3.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section3-mobile.png");
      }
    }
  }

  .feedSection--section4 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section4.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section4-mobile.png");
      }
    }
  }

  .feedSection--section5 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section5.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section5-mobile.png");
      }
    }
  }
  .feedSection--section6 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section6.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section6-mobile.png");
      }
    }
  }

  .feedSection--section7 {
    &.bgImageActive:before {
      background-image: url("/layouts/frontend/img/kinder-landing/section7.png");
      @media (max-width: 700px)  {
        background-image: url("/layouts/frontend/img/kinder-landing/section7-mobile.png");
      }
    }
  }

}