.christmas-section100 {

  &.bgImageActive {
    .christmas-section100__banner {
      //background-image: url('/layouts/frontend/img/christmas-landing/section100/banner-background.jpg');
      background-image: url('/layouts/frontend/img/christmas-landing/section1/section1-main-background.jpg?v=2');
      //border-image: url("/layouts/frontend/img/christmas-landing/gold-layer.jpg") 50 round;
      border-color: #CC9E3C;
    }
  }

   &__banner {
     position: relative;
     min-height: 330px;
     background-repeat: no-repeat;
     background-size: cover;
     width: 100%;
     border: 8px solid transparent;
     display: flex;
     flex-direction: row-reverse;
     padding: 30px 0 0 30px;
     justify-content: space-between;

     @media (max-width: 1095px) {
       flex-direction: column;
       padding: 0;
     }

     &__box {
       max-width: 563px;
       max-height: 359px;
       margin-bottom: 50px;
     }

     &--photo {
       width: 100%;
       height: 100%;
     }


     &--content {
       color: white;

       @include media-breakpoint-down(md) {
        padding: 0 40px 40px 40px;
       }

       @include media-breakpoint-down(sm) {
         margin-bottom: 30px;
         margin-top: 0px;
         padding: 0 40px;
       }

       @include media-breakpoint-down(sm) {
         padding: 0 15px;
       }

       h4 {
         font-family: Norican;
         font-size: 48px;
         line-height: 68px;
         white-space: nowrap;
         margin-top: 20px;
         user-select: none;
         text-shadow: 0px 0px 12.4719px rgba(0, 0, 0, 0.5);

         span {
           color: white;
           background: linear-gradient(180deg, #FDF5D2 0%, #CC9E3C 100%);
           //background: url('/layouts/frontend/img/christmas-landing/gold-layer.jpg') no-repeat;
           -webkit-background-clip: text;
           -webkit-text-fill-color: transparent;
           background-size: cover;

           //-webkit-filter: drop-shadow(0px 5px 63px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.5));
           filter: drop-shadow(5px 3px 2px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4.988763809204102px 62.35954666137695px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.65));
           text-shadow: none;
         }

         @include media-breakpoint-down(sm) {
           font-size: 32px;
           line-height: 45px;
           white-space: initial;
           margin-top: 0;
         }
       }

       div {
         margin-top: 15px;
         ul {
           li {
             font-size: 16px;
             line-height: 160%;
             &:not(:last-child) {
               padding-bottom: 7px;
             }

             @include media-breakpoint-down(sm) {
               font-size: 14px;
             }
           }
         }
       }

       a {
         padding: 24px 32px;
         background: linear-gradient(90deg, #F4BF25 0%, #F6E36B 25%, #F5D862 48.44%, #E1B83A 73.96%, #FEEF88 100%);
         border: none;
         font-size: 14px;
         line-height: 17px;
         font-weight: bold;
         text-transform: uppercase;
         cursor: pointer;
         font-family: 'Lidl Font'; font-weight: bold;
         margin-top: 20px;
         color: black;
         display: inline-block;

         @include media-breakpoint-down(xs) {
           width: 100%;
           padding: 15px 32px;
           text-align: center;
         }
       }
     }
   }

}
