@font-face {
  font-display: swap;
  font-family: 'Lidl Font';
  src: url('/layouts/frontend/fonts/LidlFontCondPro-Regular.woff2') format('woff2');
  font-weight: normal;
}
@font-face {
  font-display: swap;
  font-family: 'Lidl Font';
  src: url('/layouts/frontend/fonts/LidlFontCondPro-Bold.woff2') format('woff2');
  font-weight: bold;
}
@font-face {
  font-display: swap;
  font-family: 'Lidl Font';
  src: url('/layouts/frontend/fonts/LidlFontCondPro-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-display: swap;
  font-family: 'Lidl Font';
  src: url('/layouts/frontend/fonts/LidlFontCondPro-Semibold.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-display: swap;
  font-family: 'Lidl Font Price';
  src: url('/layouts/frontend/fonts/LIDLFONTPRICESCRIPT.woff2') format('woff2');
  font-weight: 600;
}

* {
  box-sizing: border-box;
}

.bold {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.pointer-events-none {
  pointer-events: none;
}

body, html {
  font-family: 'Lidl Font', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  background-color: $white;
}

.main-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1500px;
  width: 100%;

  @media (max-width: 1530px) {
    padding: 0 15px;
  }
}

.page-top-margin {
  margin-top: 35px;

  &.cookbook {
    margin-top: 0;
  }

  @media (max-width: 639px) {
    margin-top: 120px;

    &.cookbook {
      margin-top: 15px;
      padding-top: 0;

      &--withoutBreadcrumb {
        padding-top: 100px;
      }
    }


    &.recipe {
      margin-top: 0;
      padding-top: 0;
    }
  }
}

.page-top-margin--with-stash {
  margin-top: 80px;

  &.recipe {
    margin-top: 0;

    @media (max-width: 639px) {
      margin-top: 0;
    }

    .recipe__pdfFirstPage {
      padding-top: 35px;

      @media (max-width: 639px) {
        padding-top: 10px;
      }
    }
  }

  &.cookbook {
    margin-top: 0;

    @media (max-width: 639px) {
      margin-top: 25px;
      padding-top: 0;
    }
  }

  &.stash--empty {
      //margin-top: 80px;
  }
}

.no-scroll {
  overflow: hidden;

  @supports (-webkit-overflow-scrolling: touch) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
}

.print-recipe-photo, .print-external-recipe-link, .print-lidl-logo {
  display: none;
}
