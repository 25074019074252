@import 'lifestyle';
@import 'diet';
@import 'ingredients';
@import 'selectedMeals';
@import 'basic-data';
@import 'avatars-popup';
@import "calculator-popup";

.summary-header {
  font-size: 0.8em;
}

.summary-box {
  margin-top: 5px;
}

.summary-box__inner {
  background-color: white;
  height: 100%;
  padding: 30px;

  .input-text {
    background-color: $white;
    &:focus, &:active, &:hover {
      background-color: $white;
    }
  }
}

.summary-box__header {
  font-size: 1.2em;
  color: black;
  text-transform: uppercase;
  font-weight: bold;
}

.summary__subtitle {
  font-weight: bold;

  &.chosenMeals .editable-container{
    display: inline-block;
  }

  #editMeals {
    text-decoration: underline;
    cursor: pointer;
    margin: 0 1em;
    font-weight: normal;
    display: inline-block;
  }
}

.summary__subdescription {
  color: $gray_140;
  font-size: 0.9em;
}

.summary-box__required-fields {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.new-person {
  cursor: pointer;
}

.new-person__plus {
  width: 50px;
  height: 50px;
  background-color: black;
  color: white;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-size: 1.67em;
}

.summary__chosen-meals {
  display: flex;
  margin-top: 1em;
  margin-bottom: 2em;
  overflow-x: auto;
  overflow-y: hidden;

  .chosen-meals__meal {
    margin: 0 1em;

    img {
      max-width: 150px;
      max-height: 150px;
    }
  }
}

.open-calculator {
  text-decoration: underline;
  cursor: pointer;
}
