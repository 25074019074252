@mixin retina {
  @media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min--moz-device-pixel-ratio: 2),
  screen and (-moz-min-device-pixel-ratio: 2),
  screen and (-o-min-device-pixel-ratio: 2/1),
  screen and (min-device-pixel-ratio: 2),
  screen and (min-resolution: 192dpi),
  screen and (min-resolution: 2dppx) {
    @content;
  }
}
