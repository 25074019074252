$descriptionHeight: 70px;
$descriptionMobileHeight: 100px;

.fasttrack, .profiling {
  @include media-breakpoint-down(xs) {
    margin-top: 120px;
  }
}

.profiling {
  &__saveButton {
    display: flex;
    align-items: center;
  }
}

.profiling__header {
  .lidl-subheader {
    margin-top: 5px;
    font-size: 2rem;
    text-align: left;

    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;

      //&--ornament--smaller {
      //  &:after,
      //  &:before {
      //    width: 30px;
      //    left: 0;
      //
      //    .editMode & {
      //      width: 70px;
      //      left: 0;
      //      right: 0;
      //    }
      //  }
      //
      //  &:after {
      //    left: auto;
      //    right: 0;
      //
      //    .editMode & {
      //      right: 0;
      //    }
      //  }
      //}
    }
  }
}

.profiling__description {
  font-size: 2em;
  //position: -webkit-sticky;
  //position: sticky;
  //top: 0;
  //left: 0;
  //z-index: 9;
  //background: #FFF;

  .lidl-container {
    padding: 0px;

    @include media-breakpoint-down(xs) {
      padding-bottom: 3px;
    }
  }


  @media (max-width: 639px) {
    top: 100px;
  }

  .lidl-subheader-2 {
    margin-bottom: 10px;
    padding-top: 5px;
    font-size: 20px;
    text-align: left;

    @include media-breakpoint-down(xs) {
      margin-bottom: 10px;
    }
  }

  &__text {
    font-size: 16px;
  }

  .editMode {
    @include media-breakpoint-down(xs) {
      //display: flex;
      //align-items: center;
      //justify-content: center;
    }
  }

  @include media-breakpoint-down(xs) {
    font-size: 1.2em;
  }
}

.profiling__fixed {
  position: fixed;
  left: 0;
  right: 0;
  max-width: 100%;
  z-index: 100;
  background-color: #f7f8f9;
  min-height: $descriptionHeight;
  //display: flex;
  //justify-content: center;
  //align-items: center;
  top: 0;
  margin-top: 0 !important;

  @include media-breakpoint-down(xs) {
    min-height: $descriptionMobileHeight;
  }

  .lidl-subheader-2 {
    margin-bottom: 10px;
  }
}

.profiling__padded {
  padding-top: $descriptionHeight;

  &.profiling__members {
    @include media-breakpoint-down(xs) {
      padding-top: $descriptionHeight ;
    }
  }

  @include media-breakpoint-down(xs) {
    padding-top: $descriptionMobileHeight;
  }
}

.meal-navigation {
  padding-top: 50px;
}
.meal-navigation__header {
  letter-spacing: 2px;
}

.meal-navigation__list {
  list-style-type: none;
  font-size: 0.8em;
}

.meal-navigation__list-item {
  float: left;
  position: relative;
  cursor: pointer;
  &:not(:last-child) {
    margin-right: 25px;

    &:after {
      content: '-';
      position: absolute;
      top: -1px;
      right: -14px;
      font-weight: 400;
    }
  }
}

.meals-grid {
  min-height: 500px;
}

.meals-time-title {
  font-weight: bold;
}

.meals-grid__wrapper {
  margin-bottom: 30px;

  @include media-breakpoint-down(xs) {
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.meals-grid__wrapper--summary {
  width: 145px;
  height: 220px;
  display: inline-block;
  margin-right: 20px;
}

.meals-grid__photo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 250px;
  position: relative;

  @media (max-width: 500px) {
    height: 140px;
  }

  &:hover {
    .meals-grid__photo-overlay {
      opacity: 1;

      .touch_device & {
        opacity: 0;
      }
    }
  }

  .meals-grid__photo-overlay {
    background-color: $blackAlpha;
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
    @include transition(0.2s opacity);

    &.selected {

      &,
      .touch_device & { // make selector stronger
        opacity: 1;
      }

      &:after {
        content: url('/layouts/frontend/img/svg/profilowanie/tick.png');
        position: absolute;
        top: 50%;
        margin-top: -19px;
        left: 0;
        right: 0;
      }
    }
  }
}

.meals-grid__photo--summary {
  @extend .meals-grid__photo;
  height: calc(100% - 80px);
}


.meals-grid__name {
  font-weight: 700;

  @include hyphens;
}

.meals-grid__name--summary {
  @extend .meals-grid__name;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 60px;
}


