.related-recipes {
  padding: 100px 0 0;

  @include media-breakpoint-down(xs) {
    padding: 50px 0 0 0;
  }

  .label-promo-related {
    width: 55px;
    height: auto;
    background-size: contain;
    position: absolute;
    left: 10px;
    bottom: 10px;
  }

  .recipe__h2 {
    margin-bottom: 50px;
    @include media-breakpoint-down(xs) {
      margin-bottom: 20px;
    }
  }

  &.recipeViewSimilarRecipesNewPosition {
    padding-top: 20px;

    .single-item {
      margin-bottom: 0;
    }
  }
}

.related-recipes-list {
  display: flex;
  flex-wrap: wrap;

  #popularRecipes {
    align-items: stretch;

    .link-black {
      display: block;
      height: 100%;
    }

    .single-item__recipe {
      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 0;
      }

      .single-item__name {
        height: calc(100% - 260px);
        margin-bottom: 15px;

        @include media-breakpoint-down(sm) {
          height: auto;
        }
      }
    }
  }
}

.related-recipes__single {
  margin-bottom: 35px;
  width: 23%;

  @include media-breakpoint-down(sm) {
    width: 48%;
  }

  @include media-breakpoint-down(xs) {
    width: 100%;
  }
}

.single-recipe__image {
  margin-bottom: 10px;
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center;
}

.single-recipe__info-bar {
  display: flex;
  justify-content: space-between;
}

.single-recipe__author {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;

  @include media-breakpoint-down(lg) {
    width: 80px;
  }

  @include media-breakpoint-down(sm) {
    width: 180px;

  }
  @include media-breakpoint-down(xs) {
    width: 210px;
  }

}

.single-recipe__name {
  color: $black;
  font-weight: 700;

  &:hover {
    color: $black;
    text-decoration: none;
  }
}

.recipeViewSimilarRecipesNewPosition {
  .single-item__name {
    background: #FFF;
  }
}