.calculator_popup {
  width: 0;
  height: 0;

  &.popup--open {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    .calculator_popup__content {
      transform: scale(1);
      z-index: 103;
    }
  }

  .calculator_popup__content {
    z-index: 99;
    background-color: white;
    width: 550px;
    font-size: 1rem;
    max-width: 90%;
    padding: 50px;
    max-height: 90%;
    overflow-y: auto;
    transition: 0.2s transform;
    transform: scale(0);
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-down(sm) {
      max-height: 100vh;
      max-width: 100vw;
    }
  }

  .close-popup-btn {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close-popup-btn:hover {
    opacity: 1;
  }
  .close-popup-btn:before, .close-popup-btn:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 25px;
    width: 2px;
    background-color: black;
  }
  .close-popup-btn:before {
    transform: rotate(45deg);
  }
  .close-popup-btn:after {
    transform: rotate(-45deg);
  }

  .input-text {
    background-color: white;
    height: 25px;
  }
}

.button--calculator {
  width: 150px;
  max-width: 100%;
  margin: 0 auto;
  height: 45px;
  line-height: 45px;

  p {
    z-index: 1;
    position: relative
  }
}

.calculator-select {
  border: none;
  border-bottom: 1px solid;
  height: 35px;
  width: 100%;
}

.calculator__target {
  font-size: 2em;

  .editable-container {
    display: inline-block;
  }
}