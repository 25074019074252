#zero-waste-feeds {
  background: #FFFDD6;
  padding-bottom: 40px;
  margin-top: 30px;
}
#zero-waste-feeds .feedElement__header__text {
  display: flex;
  align-items: center;
  font-size: 26px;
  line-height: 28px;
}
#zero-waste-feeds .feedElement__header__text:before {
  content: '';
  min-width: 31px;
  width: 31px;
  height: 48px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 31px 48px;
}
#zero-waste-feeds .feedElement.bgImageActive .feedElement__header__text:before {
  background-image: url('/layouts/frontend/img/planner/icons/zero-waste.svg');
}
#zero-waste-feeds .feedElement__description {
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 20px;
}
#zero-waste-feeds .feedParent::before,
#zero-waste-feeds .feedParent::after {
  top: auto;
  bottom: 0;
  height: 360px;
  background-image: linear-gradient(270deg, rgb(255, 253, 214) 0%, rgba(255, 253, 214, 0) 100%);
}
#zero-waste-feeds .feedParent::before {
  background-image: linear-gradient(90deg, rgba(255, 253, 214) 0%, rgb(255, 253, 214, 0) 100%);
}
#zero-waste-feeds .feedElement__recipes__recipe {
  background: #FFF;
  display: flex;
  flex-direction: column;
}
#zero-waste-feeds .feedElement__recipes__recipe .single-item__name {
  height: 100%;
  position: relative;
}
#zero-waste-feeds .feedElement__recipes__recipe .single-item__name .feedElement__recipes__recipe__ingredientName {
  font-size: 14px;
  line-height: 14px;
  margin-top: 12px;
  color: rgba(0,0,0, 0.5);
}
#zero-waste-feeds .feedElement__scrollLeft,
#zero-waste-feeds .feedElement__scrollRight {
  top: auto;
  bottom: 160px;
}