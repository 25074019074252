.ingredient-section {
  margin-top: 50px;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__box {
      display: flex;
      flex-direction: column;
    }
  }

  @include media-breakpoint-down(xs) {
    margin-top: 0;
  }

  &__notDefaultRecipePortions {
    padding-top: 25px;
  }

  .recipe__h2 {
    margin-bottom: 0;
  }
}

.recipe-members {
  margin-top: 30px;
}

.member {
  display: flex;
  align-items: center;
}

.member__avatar {
  height: 48px;
  width: 48px;
  border: 1px solid #D7D7D7;
  border-radius: 50%;
  margin-right: 10px;
}

.member__portions {
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #D7D7D7;
  border-radius: 100px;
  background-color: white;
}

.member-portions__value {
  background: none;
  border: none;
  width: 25px;
  text-align: center;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  pointer-events: none;
}

.member-portions__value::-webkit-outer-spin-button,
.member-portions__value::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

.member-portions__value[type=number] {
  -moz-appearance: textfield;
  /* Firefox */
}

.member-portions__btn {
  background-color: transparent;
  border: none;
  font-size: 21px;
  height: 20px;
  line-height: 20px;
  color: #1B517D;
  cursor: pointer;
  padding: 0 5px;
  margin: 0;
}

.member-portions__btn:hover {
  color: black;
}

.table-wrapper {
  background-color: white;
  padding: 15px 30px;
  border: 1.5px solid #F2F2F2;
  margin-top: 30px;
  border-radius: 4px;

  .loader {
    display: none;
  }

  &[v-cloak] .loader {
    display: block;
  }

  .ingredients-table {
    margin: 0 auto;
    border-radius: 4px;
    width: 100%;
    border: none;
    background-color: white;

    tr:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    td {
      width: 50%;
      padding: 15px 0;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
        white-space: nowrap;
      }

      .ingredient {
        &__name {
          display: flex;
          align-items: center;
        }


        &__unavailable,
        &__promotion,
        &__coupon {
          position: relative;
          background: rgb(210, 46, 38);
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          border-radius: 100px;
          color: #FFF;
          padding: 4px 10px;
          margin-left: 10px;
          white-space: nowrap;

          .buttonBubble__element {
            min-width: 200px;
            max-width: 270px;
            left: 50%;
            transform: translate(-50%, 0);
            white-space: normal;
          }

          &:hover {
            .buttonBubble__element {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &__coupon {
          background: rgba(25, 81, 166, 1);
        }

        &__unavailable {
          background: darken(#d5d8da, 15%);
          min-width: 105px;
          text-align: center;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    padding: 20px 15px;
    border: none;
  }
}

.member-portions__info-text {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;

  &--incorrect {
    font-size: 14px;
    line-height: 140%;
    margin-top: 5px;
    color: #E60A14;
  }
}

.recipe-edit-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: #30303B;
}

.add-to-shopping-list {
  cursor: pointer;

  i {
    margin-right: 12px;
    margin-top: 5px;

    svg {
      width: 22px;
      fill: #FFF;
    }
  }

  &:hover {
    i {
      position: relative;
      z-index: 100;
    }
  }
}

@include media-breakpoint-down(xs) {
  .table-section {
    padding: 0;
  }
}

@media (max-width: 639px) {

  .addToShoppingButton {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100vw;
    background: #FFF;
    padding: 10px 20px;
    padding-bottom: 10px !important;
    z-index: 9;

    &--active {
      .addToShoppingButton__popup {
        max-height: 200px;
      }

      display: block;
    }

    .buttonBubble__element {
      display: none !important;
    }

    &__desc {
      text-align: center;
      font-size: 12px;
      line-height: 14px;
      padding-top: 10px;
      margin: 0;
      display: block;
    }
  }
}

.ingredient-waste-score {
  $this: &;
  display: flex;
  align-items: center;
  margin-left: 10px;
  visibility: hidden;

  &-pack {
    position: relative;
    display: flex;
    width: 13px;
    height: 20px;

    &__bg {
      position: absolute;
      height: 1%;
      background-color: crimson;
      z-index: 0;
      bottom: 0;
      left: 0.5px;
      right: 0.5px;
      // margin: 0 1px;
      box-sizing: border-box;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        border-top: var(--bordertop) solid white; // from 0px to 10px is 100% (top of the pack from 75% - 100%)
        border-left: var(--borderside) solid var(--bordercolor); // from 0px to 4px is 100% (top of the pack from 75% - 100%)
        width: 0;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-top: var(--bordertop) solid white; // from 0px to 10px is 100% (top of the pack from 75% - 100%)
        border-right: var(--borderside) solid var(--bordercolor); // from 0px to 4px is 100% (top of the pack from 75% - 100%)
        width: 0;
      }
    }

    &__icon {
      height: 100%;
      z-index: 1;
      position: relative;
    }
  }

  &:hover,
  &--active {
    #{$this}-info #{$this}-info__tooltip {
      visibility: visible !important;
      opacity: 1 !important;
    }
  }

  &-info {
    width: 14px;
    height: 14px;
    position: relative;

    &__icon {
      display: block;
      width: 100%;
      height: 100%;
    }

    &__tooltip {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      padding: 8px 15px 15px 15px;
      background: #30303B;
      border-radius: 2px;
      color: #FFF;
      font-size: 12px;
      line-height: 14px;
      width: 225px;
      transition: 0.3s all;
      z-index: 1000;
      left: 50%;
      top: calc(100% + 10px);
      transform: translateX(-50%);

      &__header {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-bottom: 8px;

        &__percentage {
          font-size: 22px;
          font-weight: 700;
          line-height: 33px;
          text-align: left;
        }

        &__title {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          text-align: left;
        }
      }

      &__footer {
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        margin-top: 8px;

        a {
          display: block;
          margin-top: 10px;
          color: #FFF;
          text-decoration: underline;
        }
      }

      &__delimiter {
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.2);
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-bottom-color: #30303B;
        border-top: 0;
        margin-left: -10px;
        margin-top: -9px;
      }
    }
  }
}