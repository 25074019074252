.main-popup {
  .popup__content {
    width: 560px !important;
    min-height: 0 !important;
    margin-top: -224px !important;

    @include media-breakpoint-down(xs) {
      min-width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      max-height: 80vh;
      bottom: 0;
      margin-top: -191px !important;
      padding: 32px 31px !important;
    }
  }

  &__header {
    margin-bottom: 0px !important;
    text-align: left;


    h3 {
      margin-bottom: 16px;
      color: #212121;

      @include media-breakpoint-down(xs) {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    input {
      border: none;
      border-bottom: 1px solid;
      font-size: 18px;
      line-height: 150%;
      padding-bottom: 5px;
      padding-left: 10px;
      width: 100%;
    }

    p {
      @include media-breakpoint-down(xs) {
        margin-bottom: 25px;
      }
    }


    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      flex-direction: column;
      text-align: left;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    @include media-breakpoint-down(xs) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      width: 100%;
    }

    a {
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      color: $lidlPrimaryColor;
      text-transform: uppercase;

      @include media-breakpoint-down(xs) {
        margin-bottom: 20px;
        margin-right: 0 !important;
      }
    }

    button {
      margin-top: 0 !important;
      width: 163px;
      margin-right: 0;

      @include media-breakpoint-down(xs) {
        margin-bottom: 30px;
        width: 100%;
      }
    }
  }


}
