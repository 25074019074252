.bannerStyle {
  display: block;
  color: black;
  margin: 30px 0 0;
  padding: 10px 15px;
  border: 2px solid $lidlPrimaryColor;
  border-radius: 5px;
  background: #FFF;

  &__header {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;

    @include media-breakpoint-down(sm) {
      font-size: 18px;
      line-height: 22px;
    }

  }

  &, &:hover {
    text-decoration: none;
  }

  a {
    color: #0076bf;
    font-family: 'Lidl Font'; font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 17px;
    }

  }

  span {
    color: #0076bf;
    font-weight: bold;
  }
}


.recipe-banner {
  &--shoppinglistBanner {
    display: flex;
    align-items: stretch;
    min-height: 250px;
    font-size: 18px;

    @media (max-width: 600px) {
      flex-wrap: wrap-reverse;
      justify-content: center;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .bannerStyle {
      &__text {
        font-size: 16px;
        line-height: 19px;

        @media (max-width: 1400px) {
          font-size: 16px;
          line-height: 17px;
        }
      }
    }

    .bannerStyle__header {
      padding-top: 10px;
    }

    .bannerStyle__left {
      width: 68%;

      @media (max-width: 1400px) {
        width: 70%;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    .bannerStyle__right {
      width: 32%;
      margin-top: 5px !important;
      margin-bottom: 5px !important;;
      margin-left: 20px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;

      @media (max-width: 600px) {
        width: 50%;
        height: 280px;
        margin-right: 0;
        background-size: cover;
      }
    }

    &.bgImageActive {
      .bannerStyle__right {
        background-image: url('/layouts/frontend/img/homepage_de/phone-promotion.png') !important;
      }
    }
  }

  &--shoppinglistOsternBanner {
    .bannerStyle__right {
      background-size: 360% 130%;

      @media (max-width: 1400px) {
        width: 30%;
        background-size: 360% 90%;
      }
    }


  }

  &--lidl-spoon {
    .banner {
      @extend .bannerStyle;
      margin: 5px 0;

      img {
        width: 30%;
        margin: 0 auto;
        display: block;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }

    small {
      display: block;
      margin-bottom: 10px;
    }

    img {
      width: 100%;

      &.desktop {
        @media (max-width: 600px) {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media (max-width: 600px) {
          display: block;
        }
      }

    }
  }

  &--goodToGo {
    @extend .bannerStyle;
    margin: 0;

    img {
      float: left;
      width: 120px;
      margin-right: 20px;
      margin-top: 35px;
      margin-bottom: 5px;

      @media (max-width: 600px) {
        width: 80px;
      }
    }
  }

  &--veganDinner {
    @extend .bannerStyle;

    border: 4px solid #8ABD40;
    overflow: hidden;

    a {
      color: #8ABD40;
      font-weight: bold;
    }

    img {
      max-width: 200px;
      float: right;
      margin-left: 20px;

      @media (max-width: 600px) {
        display: block;
        margin: 0 auto 10px;
        max-width: 100%;
        float: none;
      }
    }
  }
}
