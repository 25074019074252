@font-face {
  font-family: lidl-dings;
  src: url('/layouts/frontend/fonts/lidl-dings-repaint-de-20190204.eot');
  src: url('/layouts/frontend/fonts/lidl-dings-repaint-de-20190204.eot?#iefix') format("embedded-opentype"), url('/layouts/frontend/fonts/lidl-dings-repaint-de-20190204.ttf') format("truetype"), url('/layouts/frontend/fonts/lidl-dings-repaint-de-20190204.svg#lidl-dings') format("svg"), url('/layouts/frontend/fonts/lidl-dings-repaint-de-20190204.woff') format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


[class*=" lidl-icon-"]:before,
[class^=lidl-icon-]:before,
[class*="lidl-icon-footer"]:before {
  font-family: lidl-dings;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none !important;
  text-align: left;
  speak: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  text-decoration: none;
  width: 1em;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
}

.lidl-icon-arrow-left:before {
  content: "\61"
}

.lidl-icon-arrow-down:before {
  content: "\62"
}

.lidl-icon-arrow-up:before {
  content: "\63"
}

.lidl-icon-arrow-top:before {
  content: "\63"
}

.lidl-icon-arrow-right:before {
  content: "\64"
}

.lidl-icon-bake:before {
  content: "\65"
}

.lidl-icon-360:before {
  content: "\66"
}

.lidl-icon-check:before {
  content: "\67"
}

.lidl-icon-clock:before {
  content: "\68"
}

.lidl-icon-buzzer:before {
  content: "\69"
}

.lidl-icon-bulb:before {
  content: "\6a"
}

.lidl-icon-drugstore:before {
  content: "\6b"
}

.lidl-icon-cook:before {
  content: "\6c"
}

.lidl-icon-contact:before {
  content: "\6d"
}

.lidl-icon-photos:before {
  content: "\6e"
}

.lidl-icon-mobile:before {
  content: "\6f"
}

.lidl-icon-play:before {
  content: "\70"
}

.lidl-icon-feedback:before {
  content: "\71"
}

.lidl-icon-flyer:before {
  content: "\72"
}

.lidl-icon-jobs:before {
  content: "\73"
}

.lidl-icon-info:before {
  content: "\74"
}

.lidl-icon-installment:before {
  content: "\76"
}

.lidl-icon-pbg:before {
  content: "\77"
}

.lidl-icon-media:before {
  content: "\78"
}

.lidl-icon-login:before {
  content: "\79"
}

.lidl-icon-pb:before {
  content: "\7a"
}

.lidl-icon-zoom-in:before {
  content: "\41"
}

.lidl-icon-truck:before {
  content: "\42"
}

.lidl-icon-x:before {
  content: "\43"
}

.lidl-icon-star:before {
  content: "\44"
}

.lidl-icon-user:before {
  content: "\45"
}

.lidl-icon-shopping:before {
  content: "\47"
}

.lidl-icon-store:before {
  content: "\48"
}

.lidl-icon-receipe:before {
  content: "\49"
}

.lidl-icon-social:before {
  content: "\4a"
}

.lidl-icon-qr:before {
  content: "\4b"
}

.lidl-icon-print:before {
  content: "\4c"
}

.lidl-icon-search:before {
  content: "\4d"
}

.lidl-icon-zoom-out:before {
  content: "\4e"
}

.lidl-icon-eye:before {
  content: "\50"
}

.lidl-icon-hook:before {
  content: "\51"
}

.lidl-icon-company:before {
  content: "\52"
}

.lidl-icon-video:before {
  content: "\53"
}

.lidl-icon-menu:before {
  content: "\54"
}

.lidl-icon-help:before {
  content: "\55"
}

.lidl-icon-social-facebook:before {
  content: "\56"
}

.lidl-icon-social-google-plus:before {
  content: "\57"
}

.lidl-icon-social-twitter:before {
  content: "\58"
}

.lidl-icon-social-youtube:before {
  content: "\59"
}

.lidl-icon-target:before {
  content: "\34"
}

.lidl-icon-filter:before {
  content: "\35"
}

.lidl-icon-play-filled:before {
  content: "\36"
}

.lidl-icon-eec:before {
  content: "\37"
}

.lidl-icon-mail:before {
  content: "\38"
}

.lidl-icon-pause:before {
  content: "\39"
}

.lidl-icon-logout:before {
  content: "\21"
}

.lidl-icon-home:before {
  content: "\22"
}

.lidl-icon-list:before {
  content: "\23"
}

.lidl-icon-grid:before {
  content: "\24"
}

.lidl-icon-account-payment:before {
  content: "\25"
}

.lidl-icon-account-password:before {
  content: "\26"
}

.lidl-icon-account-invoice:before {
  content: "\27"
}

.lidl-icon-account-email:before {
  content: "\28"
}

.lidl-icon-account-delivery:before {
  content: "\29"
}

.lidl-icon-account-delete:before {
  content: "\2a"
}

.lidl-icon-account-user:before {
  content: "\2b"
}

.lidl-icon-map:before {
  content: "\2c"
}

.lidl-icon-direction-arrow:before {
  content: "\2d"
}

.lidl-icon-size-calculator:before {
  content: "\2e"
}

.lidl-icon-faq-online:before {
  content: "\2f"
}

.lidl-icon-faq-videos:before {
  content: "\3a"
}

.lidl-icon-store-sarch:before {
  content: "\3b"
}

.lidl-icon-faq-flyer:before {
  content: "\3c"
}

.lidl-icon-faq-service:before {
  content: "\3d"
}

.lidl-icon-faq-size-calculator:before {
  content: "\3e"
}

.lidl-icon-faq-contact:before {
  content: "\3f"
}

.lidl-icon-faq-store:before {
  content: "\40"
}

.lidl-icon-faq-ean:before {
  content: "\5b"
}

.lidl-icon-faq-legal:before {
  content: "\5d"
}

.lidl-icon-faq-navigation:before {
  content: "\5e"
}

.lidl-icon-faq-payment:before {
  content: "\5f"
}

.lidl-icon-faq-travel:before {
  content: "\60"
}

.lidl-icon-faq-apps:before {
  content: "\7b"
}

.lidl-icon-service-friend:before {
  content: "\7c"
}

.lidl-icon-service-register:before {
  content: "\7d"
}

.lidl-icon-service-badge:before {
  content: "\7e"
}

.lidl-icon-service-compare:before {
  content: "\b1"
}

.lidl-icon-sim:before {
  content: "\fb01"
}

.lidl-icon-presentation:before {
  content: "\b7"
}

.lidl-icon-dog:before {
  content: "\af"
}

.lidl-icon-bird:before {
  content: "\e000"
}

.lidl-icon-coffee-beans:before {
  content: "\e001"
}

.lidl-icon-coffee-can:before {
  content: "\e002"
}

.lidl-icon-coffee-maschine:before {
  content: "\e003"
}

.lidl-icon-fish:before {
  content: "\e004"
}

.lidl-icon-horse:before {
  content: "\e005"
}

.lidl-icon-mouse:before {
  content: "\e006"
}

.lidl-icon-pad-maschine:before {
  content: "\e007"
}

.lidl-icon-cat:before {
  content: "\e008"
}

.lidl-icon-rating-star0:before {
  content: "\2dc"
}

.lidl-icon-rating-star1:before {
  content: "\e009"
}

.lidl-icon-rating-star2:before {
  content: "\e00a"
}

.lidl-icon-rating-star3:before {
  content: "\e00b"
}

.lidl-icon-rating-star4:before {
  content: "\e00c"
}

.lidl-icon-rating-star5:before {
  content: "\e00d"
}

.lidl-icon-rating-star6:before {
  content: "\e00e"
}

.lidl-icon-rating-star7:before {
  content: "\e00f"
}

.lidl-icon-rating-star8:before {
  content: "\e010"
}

.lidl-icon-rating-star9:before {
  content: "\e011"
}

.lidl-icon-rating-star10:before {
  content: "\e012"
}

.lidl-icon-tap:before {
  content: "\2d9"
}

.lidl-icon-voucher:before {
  content: "\2da"
}

.lidl-icon-os-button:before {
  content: "\e013"
}

.lidl-icon-cause:before {
  content: "\f8ff"
}

.lidl-icon-extras-gift:before {
  content: "\e017"
}

.lidl-icon-extras-card:before {
  content: "\e018"
}

.lidl-icon-extras-bow:before {
  content: "\e019"
}

.lidl-icon-csr-environment:before {
  content: "\e020"
}

.lidl-icon-csr-partner:before {
  content: "\e021"
}

.lidl-icon-csr-range:before {
  content: "\e022"
}

.lidl-icon-csr-society:before {
  content: "\e023"
}

.lidl-icon-discover:before {
  content: "\e024"
}

.lidl-icon-freesoftwareeu:before {
  content: "\e025"
}

.lidl-icon-freesoftwareuk:before {
  content: "\e026"
}

.lidl-icon-highquality:before {
  content: "\e027"
}

.lidl-icon-highqualitylowpriceeu:before {
  content: "\e028"
}

.lidl-icon-highqualitylowpriceuk:before {
  content: "\e029"
}

.lidl-icon-productcollection:before {
  content: "\e030"
}

.lidl-icon-smallpriceseu:before {
  content: "\e031"
}

.lidl-icon-smallpricesuk:before {
  content: "\e032"
}

.lidl-icon-walldeco:before {
  content: "\e033"
}

.lidl-icon-user-nav-basket:before {
  content: "\e034"
}

.lidl-icon-user-nav-check:before {
  content: "\e035"
}

.lidl-icon-user-nav-flyer:before {
  content: "\e036"
}

.lidl-icon-user-nav-newsletter:before {
  content: "\e037"
}

.lidl-icon-user-nav-x:before {
  content: "\e038"
}

.lidl-icon-searchfilter-photos:before {
  content: "\e039"
}

.lidl-icon-new-jobs:before {
  content: "\e040"
}

.lidl-icon-new-os-button:before {
  content: "\e041"
}

.lidl-icon-calendar:before {
  content: "\2019"
}

.lidl-icon-extras-package:before {
  content: "\152"
}

.lidl-icon-account-remember:before {
  content: "\e01a"
}

.lidl-icon-account-minimum-term:before {
  content: "\2db"
}

.lidl-icon-account-satisfaction:before {
  content: "\e01b"
}

.lidl-icon-color:before {
  content: "\2c7"
}

.lidl-icon-csr:before {
  content: "\b0"
}

.lidl-icon-edit:before {
  content: "\e01e"
}

.lidl-icon-vote:before {
  content: "\e01f"
}

.lidl-icon-hcstar:before {
  content: "\f7"
}

.lidl-icon-language:before {
  content: "\fb02"
}

.lidl-icon-csr-employee:before {
  content: "\2014"
}

.lidl-icon-pdf:before {
  content: "\d3"
}

.lidl-icon-designcollection:before {
  content: "\2030"
}

.lidl-icon-photobooks:before {
  content: "\e02a"
}

.lidl-icon-photopics:before {
  content: "\e02b"
}

.lidl-icon-photopresents:before {
  content: "\e02c"
}

.lidl-icon-onlinealbum:before {
  content: "\e02d"
}

.lidl-icon-nofeeuk:before {
  content: "\e02e"
}

.lidl-icon-nofeeeu:before {
  content: "\e02f"
}

.lidl-icon-user-nav-account:before {
  content: "\2c6"
}

.lidl-icon-user-nav-small-down-arrow:before {
  content: "\ac"
}

.lidl-icon-user-nav-store:before {
  content: "\2265"
}

.lidl-icon-user-nav-watchlist:before {
  content: "\e03a"
}

.lidl-icon-user-nav-circle:before {
  content: "\201c"
}

.lidl-icon-searchfilter-product:before {
  content: "\e03b"
}

.lidl-icon-searchfilter-receipe:before {
  content: "\e03c"
}

.lidl-icon-searchfilter-travel:before {
  content: "\e03d"
}

.lidl-icon-new-company:before {
  content: "\201e"
}

.lidl-icon-new-deal:before {
  content: "\e03f"
}

.lidl-icon-new-contact:before {
  content: "\bb"
}

.lidl-icon-new-mobile:before {
  content: "\e5"
}

.lidl-icon-new-search:before {
  content: "\2021"
}

.lidl-icon-social-snapchat:before {
  content: "\a5"
}

.lidl-icon-hotdrinks:before {
  content: "\e03e"
}

.lidl-icon-pick-up-service:before {
  content: "\2c0"
}

.lidl-icon-car:before {
  content: "\f9"
}

.lidl-icon-airplane:before {
  content: "\a3"
}

.lidl-icon-cal:before {
  content: "\b8"
}

.lidl-icon-airport:before {
  content: "\cd"
}

.lidl-icon-picture-hanger:before {
  content: "\104"
}

.lidl-icon-lidl-you:before {
  content: "\105"
}

.lidl-icon-lidl-you-outline:before {
  content: "\106"
}

.lidl-icon-footer-bestprice:before {
  content: "\131"
}

.lidl-icon-footer-delivery-belgium:before {
  content: "\b5"
}

.lidl-icon-footer-protection:before {
  content: "\e01c"
}

.lidl-icon-footer-respite:before {
  content: "\2044"
}

.lidl-icon-footer-safety:before {
  content: "\33"
}

.lidl-icon-footer-thuiswinkel:before {
  content: "\e044"
}

.lidl-icon-footer-always-the-right-offer:before {
  content: "\258"
}

.lidl-icon-footer-creditcard:before {
  content: "\250"
}

.lidl-icon-footer-delivery:before {
  content: "\5a"
}

.lidl-icon-footer-free-eveningdelivery:before {
  content: "\e1"
}

.lidl-icon-footer-free:before {
  content: "\e014"
}

.lidl-icon-footer-fresh:before {
  content: "\e015"
}

.lidl-icon-footer-friendly:before {
  content: "\251"
}

.lidl-icon-footer-giftcard:before {
  content: "\30"
}

.lidl-icon-footer-giro:before {
  content: "\252"
}

.lidl-icon-footer-installment:before {
  content: "\31"
}

.lidl-icon-footer-money-back:before {
  content: "\253"
}

.lidl-icon-footer-online-album:before {
  content: "\254"
}

.lidl-icon-footer-presents:before {
  content: "\255"
}

.lidl-icon-footer-return:before {
  content: "\32"
}

.lidl-icon-footer-short-times:before {
  content: "\256"
}

.lidl-icon-footer-train:before {
  content: "\e01d"
}

.lidl-icon-footer-watchlist:before {
  content: "\257"
}

.lidl-icon-footer-wish:before {
  content: "\e016"
}

.lidl-icon-footer-wishdelivery:before {
  content: "\e043"
}

.lidl-icon-social-instagram:before {
  content: "\2202"
}

.lidl-icon-position:before {
  content: "\2204"
}

.lidl-icon-footer-account-satisfaction:before {
  content: "\2203"
}

.lidl-icon-flyer-new:before {
  content: "\2205"
}

.lidl-icon-social-linkedin:before {
  content: "\2206"
}

.lidl-icon-user-nav-bestellsoftware:before {
  content: "\2207"
}

.lidl-icon-footer-90day-return-deadline:before {
  content: "\2208"
}

.lidl-icon-footer-packing-station:before {
  content: "\220a"
}

.lidl-icon-phoneno:before {
  content: "\220b"
}

.lidl-icon-packstation:before {
  content: "\220f"
}

.lidl-icon-social-pinterest:before {
  content: "\2211"
}

.lidl-icon-asterisk:before {
  content: "\4f"
}

.lidl-icon-flowers:before {
  content: "\75"
}

.lidl-icon-searchfilter-flowers:before {
  content: "\c6"
}

.lidl-icon-account-flower:before {
  content: "\203a"
}

.lidl-icon-travel:before {
  content: "\46"
}

.lidl-icon-thumbsup:before {
  content: "\204a"
}

.lidl-icon-thumbsdown:before {
  content: "\204a";
  transform: rotateX(180deg)
}

.lidl-icon-application:before {
  content: "\e05"
}

.lidl-icon-vouchercard:before {
  content: "\e042"
}

.lidl-icon-detergent:before {
  content: "\e045"
}

.lidl-icon-delivered:before {
  content: "\e046"
}

.lidl-icon-sent:before {
  content: "\e047"
}

.lidl-icon-footer-free-return:before {
  content: "\2209"
}

.lidl-icon-footer-collaboration:before {
  content: "\e048"
}

.lidl-icon-paid:before {
  content: "\e049"
}

.lidl-icon-collaboration:before {
  content: "\1036"
}

.lidl-icon-collaboration-small:before {
  content: "\1036"
}

.lidl-icon-free-return:before {
  content: "\e04a"
}

.lidl-icon-free-return-small-1:before {
  content: "\e04a"
}

.lidl-icon-free-return-small-2:before {
  content: "\e04b"
}

.lidl-icon-free-return-small-3:before {
  content: "\e04c"
}

.lidl-icon-configuration-control:before {
  content: "\e050"
}
