.shoppingListPopup {

  a {
    color: $lidlPrimaryColor;
  }

  input {
    margin-right: 12px;
  }

  a {
    color: $lidlPrimaryColor !important;
  }

  &__box {
    max-height: 168px;
    overflow-y: auto;
  }


  &__shoplist {
    display: flex;
    align-items: center;
  }

  &__buttons {
    justify-content: flex-end;

    a {
      margin-right: 35px;
      text-transform: initial;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &__list {
    margin-top: 20px;
    list-style-type: none;
    padding: 0;

      li {
        padding-bottom: 5px;
      }
  }

  h3 {
    font-size: 22px;
    line-height: 26px;
    color: #212121;
    font-weight: 600;
    max-width: 80%;

    @media (max-width: 576px) {
      max-width: 100%;
      text-align: left;
    }
  }
}