#vue-add-to-plan {
  .plan-select {
    border: none;
    width: 310px;
    margin: 55px auto;

    @include media-breakpoint-down(xs) {
      width: 250px;
    }
  }

  .button--go-to-plan {
    width: 300px;
  }

  .button--bordered {
    padding: 0 50px;
  }

  .vdp-datepicker__calendar {
    margin: 30px auto 0 auto;

    .cell:not(.blank):not(.disabled).day:hover {
      border: 1px solid black;
    }

    .cell.selected {
      background: black;
      color: white;

      &:hover {
        background: black;
        color: white;
        border: 1px solid black;
      }
    }
  }

  .nav__profile-img {
    margin: 0;
  }

  .household-list {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }

  .household-list__element {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 15px;
  }

  .household__portions {
    padding: 0 9px;
    border: 1.5px solid #d7d7d7;
    border-radius: 20px;
  }

  .household-portions__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 19px;
    color: $lidlDarkBlue;
    padding: 0 5px;
    margin: 0;
  }

  .household__name {
    margin-bottom: 0;
    margin-right: 30px;
  }

  .household-portions__amount {
    background-color: #f7f8f9;
    width: 70px;
    text-align: center;
    border: 0;
    padding: 0;
  }

  .household-portions__amount::-webkit-outer-spin-button,
  .household-portions__amount::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  .household-portions__amount[type=number] {
    -moz-appearance: textfield; /* Firefox */
  }
  
  svg {
    width: 100px;
    display: block;
    margin: 50px auto 50px auto;
  }

  .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
  }

  .path.circle {
    -webkit-animation: dash 0.9s ease-in-out;
    animation: dash 0.9s ease-in-out;
  }

  .path.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
    animation: dash 0.9s 0.35s ease-in-out forwards;
  }

  .path.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
    animation: dash-check 0.9s 0.35s ease-in-out forwards;
  }

  .household__name {
    text-align: center;
    margin: 0;
    font-size: 1.25em;
  }

  p.success {
    color: #73AF55;
  }

  p.error {
    color: #D06079;
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  .checkbox-wrapper input[type=checkbox]:checked ~ label:after, .checkbox-wrapper input[type=radio]:checked ~ label:before {
    background-color: black;
  }
}
